import { push } from 'services/api';

function detectHttps() {
  //return true;
  return window.location.href.indexOf('https://') === 0;
}

function isServiceWorkerSupported() {
  return 'serviceWorker' in navigator;
}

function isNotificationSupported() {
  return 'Notification' in window;
}

function registerWorker() {
  return navigator.serviceWorker.register('/push-worker.js?v=3');
}

const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export async function subscribe(cb) {
  if (
    !detectHttps() ||
    !isServiceWorkerSupported() ||
    !isNotificationSupported()
  ) {
    return;
  }

  await registerWorker();

  const permission = await window.Notification.requestPermission();
  if (permission === 'granted') {
    navigator.serviceWorker.addEventListener('message', cb);

    navigator.serviceWorker.ready
      .then((worker) => {
        return worker.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(
            process.env.REACT_APP_PUSH_KEY
          )
        });
      })
      .then((subscription) => {
        const subStr = JSON.stringify(subscription);
        const currentSub = window.localStorage.getItem('__subscription');

        if (currentSub !== subStr) {
          window.localStorage.setItem('__subscription', subStr);

          return push.create({
            type: 'web',
            data: subStr
          });
        }

        return null;
      })
      .catch((e) => {
        window.localStorage.removeItem('__subscription');
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }
}

export async function unsubscribe() {
  if (!isServiceWorkerSupported() || !isNotificationSupported()) {
    return;
  }

  const permission = Notification.permission;

  if (permission === 'granted') {
    return navigator.serviceWorker.ready
      .then((worker) => {
        return worker.pushManager.getSubscription();
      })
      .then((subscription) => {
        return subscription.unsubscribe();
      })
      .then(() => {
        window.localStorage.removeItem('__subscription');
      })
      .catch((e) => {
        window.localStorage.removeItem('__subscription');
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }
}
