import ApiRequest from './ApiRequest';

export default class Clients extends ApiRequest {
  getList(params) {
    return this.get(`/clients`, {
      qs: params
    });
  }

  getOne(id) {
    return this.get(`/clients/${id}`);
  }

  createOne(req) {
    return this.post(`/clients`, {
      body: req
    });
  }

  updateOne(id, req) {
    return this.put(`/clients/${id}`, {
      body: req
    });
  }

  deleteOne(id) {
    return this.delete(`/clients/${id}`);
  }

  getClientMessages(id, req) {
    return this.get(`/clients/${id}/messages`, {
      qs: req
    });
  }

  createClientMessage(client_id, req) {
    return this.post(`/clients/${client_id}/messages`, {
      body: req
    });
  }

  deleteClientMessage(client_id, item_id) {
    return this.delete(`/clients/${client_id}/messages/${item_id}`);
  }

  getClientFiles(client_id, req) {
    return this.get(`/clients/${client_id}/files`, {
      qs: req
    });
  }

  addClientFile(client_id, req) {
    return this.post(`/clients/${client_id}/files`, {
      body: req,
      encodeType: 'form'
    });
  }

  deleteClientFile(client_id, item_id) {
    return this.delete(`/clients/${client_id}/files/${item_id}`);
  }

  getClientFileToken(client_id, item_id) {
    return this.get(`/clients/${client_id}/files/${item_id}`);
  }
}
