import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/config';

import { concatGeoFullName } from 'services/utils';

import { LabelField } from 'components/Field';
import { Marker, Popup, utils as mapUtils } from 'components/maps';
import Progress from 'components/Progress';

const useStyles = makeStyles((theme) => ({
  popup: {
    // maxHeight: '400px',
    // overflowY: 'auto',
    fontSize: 12,
    '& .leaflet-popup-content': {
      margin: '13px 5px'
    }
  },
  regionCenterTitle: {
    color: '#0f0'
  },
  timelineContainer: {},
  timelineDayContainer: {},
  timelineDayTitle: {
    fontWeight: 'bold'
  },
  timelineTimesContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  timelineTimePoint: {
    outline: 'rgb(210, 210, 210) solid 1px',
    backgroundColor: 'rgb(238, 238, 238)',
    padding: '2px',
    fontSize: '12px',
    marginRight: '3px',
    marginBottom: '3px',
    cursor: 'pointer'
  }
}));

const OrderMapMarker = ({ order, onSelectTimepoint }) => {
  const classes = useStyles();
  if (!order || !order.geopoint || !mapUtils.isValidGeopoint(order.geopoint)) {
    return null;
  }
  const {
    geopoint,
    street,
    locality,
    address_house,
    address_apartment,
    comment,
    executor,
    timeline
  } = order;
  const position = mapUtils.getCoordsFromGeopoint(geopoint);
  const target = street || locality;
  const address = concatGeoFullName(target);
  const full_address = [address, address_house, address_apartment].join(', ');

  let date = null;
  const isSame = moment(order.date_from).isSame(order.date_to, 'day');
  if (isSame) {
    const d = moment(order.date_from).format(DATE_FORMAT);
    const t_from = moment(order.date_from).format(TIME_FORMAT);
    const t_to = moment(order.date_to).format(TIME_FORMAT);
    date = (
      <>
        {d} (<b>{`${t_from} - ${t_to}`}</b>)
      </>
    );
  } else {
    const d_from = moment(order.date_from).format(DATE_FORMAT);
    const t_from = moment(order.date_from).format(TIME_FORMAT);
    const d_to = moment(order.date_to).format(DATE_FORMAT);
    const t_to = moment(order.date_to).format(TIME_FORMAT);
    date = (
      <>
        {d_from} (<b>{t_from}</b>) &nbsp;
        {d_to} (<b>{t_to}</b>)
      </>
    );
  }

  const selectTimepointHandler = (timepoint) => {
    onSelectTimepoint &&
      onSelectTimepoint({
        executor: order.executor,
        timepoint
      });
  };

  return (
    <Marker variant="secondary" position={position}>
      <Popup className={classes.popup}>
        <div className={classes.popupContainer}>
          {full_address ? (
            <div>
              <b>Адрес:</b> {full_address}
            </div>
          ) : null}
          <div>
            <b>Дата, время:</b> {date}
          </div>
          <div>
            <b>Цена:</b> {(order.price / 100).toFixed(2)}
          </div>
          <div>
            <b>Примечание:</b> {comment ? comment.slice(0, 100) : ''}
          </div>
          <div>
            <LabelField
              label="Активен:"
              value={order.is_active}
              type="boolean"
              format={(v) => v === 'yes'}
              iconSize="inherit"
            />
          </div>
          <div>
            <LabelField
              label="Выполнен:"
              value={order.is_finished}
              type="boolean"
              format={(v) => v === 'yes'}
              iconSize="inherit"
            />
          </div>
          <div>
            <LabelField
              label="Вредители:"
              type="reference"
              path={`/orders/${order.id}/reasons`}
              format={(res) =>
                res.results.map((it) => (it.reason && it.reason.name) || '')
              }>
              {(props) => {
                const { loading, result } = props;
                if (loading)
                  return (
                    <div>
                      <Progress size={18} />
                    </div>
                  );
                if (!result) return '';
                return result.join(', ');
              }}
            </LabelField>
          </div>
          <div>
            <LabelField
              label="Услуги:"
              type="reference"
              path={`/orders/${order.id}/methods`}
              format={(res) =>
                res.results.map((it) => (it.method && it.method.name) || '')
              }>
              {(props) => {
                const { loading, result } = props;
                if (loading)
                  return (
                    <div>
                      <Progress size={18} />
                    </div>
                  );
                if (!result) return '';
                return result.join(', ');
              }}
            </LabelField>
          </div>
        </div>
        <div>
          <b>Дезинфектор:</b> {executor ? executor.name : ''}
        </div>
        <Timeline
          timeline={timeline}
          onSelectTimepoint={selectTimepointHandler}
        />
      </Popup>
    </Marker>
  );
};

export default OrderMapMarker;

const Timeline = ({ timeline, onSelectTimepoint }) => {
  const classes = useStyles();
  if (!timeline || !Object.keys(timeline).length) {
    return null;
  }
  return (
    <div className={classes.timelineContainer}>
      {timeline
        ? Object.entries(timeline).map(([date, val]) => {
            return (
              <div key={date} className={classes.timelineDayContainer}>
                <div className={classes.timelineDayTitle}>{date}</div>
                <div className={classes.timelineTimesContainer}>
                  {val.map((it) => {
                    return (
                      <div
                        className={classes.timelineTimePoint}
                        key={it.key}
                        onClick={() => onSelectTimepoint(it)}>
                        {it.title}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
};
