import moment from 'moment';

import { executors as executorsApi, orders as ordersApi } from 'services/api';
import {
  breakToDistances,
  createTimeRangeString,
  exludeDateDistances,
  mergeDateDistances
} from 'services/dateUtils';

export const getNearOrdersByPoint = async (geopoint, params = {}) => {
  const { date_from } = params;
  const dayDiff = moment(date_from)
    .startOf('day')
    .diff(moment().startOf('day'), 'd');
  const start =
    dayDiff > 0
      ? moment(date_from).startOf('day')
      : moment().add(1, 'd').startOf('day');
  const end = start.clone().add(2, 'd').endOf('day');

  const startISOString = start.toISOString();
  const endISOString = end.toISOString();

  const next = {
    order: 'distance',
    limit: 10,
    offset: 0,
    geopoint: JSON.stringify(geopoint),
    where: {
      is_active: 'yes',
      date_from: {
        $gte: startISOString,
        $lte: endISOString
      }
    },
    distance: 25000
  };
  try {
    const { results: orders } = await ordersApi.getList(next);
    if (!orders || !orders.length) {
      return orders;
    }

    const result = {};
    const executors_ids = Array.from(
      new Set(orders.filter((it) => it.executor).map((it) => it.executor.id))
    );
    if (!executors_ids || !executors_ids.length) {
      return orders;
    }
    const res = await Promise.all([
      executorsApi.getSchedule({
        offset: 0,
        limit: 1000,
        where: {
          executor_id: executors_ids
        }
      }),
      executorsApi.getAvailableList({
        order: 'date_from',
        offset: 0,
        limit: 1000,
        where: {
          executor_id: executors_ids,
          date_from: { $lte: endISOString },
          date_to: { $gte: startISOString }
        }
      }),
      ordersApi.getList({
        order: 'date_from',
        offset: 0,
        limit: 1000,
        where: {
          executor_id: executors_ids,
          date_from: { $lte: endISOString },
          date_to: { $gte: startISOString }
        }
      })
    ]);
    const scheduleList = res[0].results;
    const availableList = res[1].results;
    const ordersList = res[2].results;

    executors_ids.forEach((id) => {
      if (!result[id]) {
        result[id] = {
          id,
          orders: [],
          available: [],
          schedule: {}
        };
      }
    });

    ordersList.forEach((it) => {
      const { executor_id } = it;
      if (result[executor_id]) {
        result[executor_id].orders.push(it);
      }
    });

    availableList.forEach((it) => {
      const { executor_id } = it;
      if (result[executor_id]) {
        result[executor_id].available.push(it);
      }
    });

    scheduleList.forEach((it) => {
      const { executor_id, week_day } = it;
      if (result[executor_id]) {
        if (!result[executor_id].schedule[week_day]) {
          result[executor_id].schedule[week_day] = [];
        }
        result[executor_id].schedule[week_day].push({
          ...it
        });
      }
    });
    Object.values(result).forEach((data) => {
      const {
        orders = [],
        available = [],
        schedule = {},
        id: executor_id
      } = data;
      const availableYes = [];
      const availableNo = [];

      if (orders && orders.length) {
        orders.forEach((it) => {
          if (it.is_active === 'yes') {
            availableNo.push({
              from: moment(it.date_from),
              to: moment(it.date_to)
            });
          }
        });
      }

      if (available && available.length) {
        available.forEach((it) => {
          const from = moment(it.date_from);
          const to = moment(it.date_to);
          if (it.is_available === 'no') {
            availableNo.push({
              from,
              to
            });
          } else if (it.is_available === 'yes') {
            availableYes.push({
              from,
              to
            });
          }
        });
      }

      const diff = end.diff(start, 'd');
      for (let i = 0; i <= diff; i++) {
        const cur = start.clone().add(i, 'd');
        const weekDay = cur.isoWeekday();
        const times = schedule[weekDay];
        if (times && times.length) {
          times.forEach((it) => {
            const from = moment(cur.format('YYYY-MM-DD') + 'T' + it.time_from);
            const to = moment(cur.format('YYYY-MM-DD') + 'T' + it.time_to);
            availableYes.push({
              from,
              to
            });
          });
        }
      }

      const timeline = {};
      const availableMerged = mergeDateDistances(availableYes);
      const unAvailableMerged = mergeDateDistances(availableNo);
      const availableWithoutUnavailable = exludeDateDistances(
        availableMerged,
        unAvailableMerged
      );
      const availableBreaked = breakToDistances(availableWithoutUnavailable);
      availableBreaked.forEach((it, i) => {
        const _date = it.from.format('DD.MM.YYYY');
        if (!timeline[_date]) {
          timeline[_date] = [];
        }
        const titleTimeRange = createTimeRangeString(it.from, it.to);
        timeline[_date].push({
          key: `ex-${executor_id}-available-${i}`,
          executor_id,
          title: titleTimeRange,
          start: it.from.toISOString(),
          end: it.to.toISOString()
        });
      });
      result[executor_id].timeline = timeline;
    });
    return orders.map((it) => {
      if (!it.executor_id || !result[it.executor_id]) {
        return it;
      }
      return {
        ...it,
        timeline: result[it.executor_id].timeline
      };
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return [];
  }
};
