import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Chip from '@material-ui/core/Chip';

import { DATE_FORMAT, MATERIAL_UNIT } from 'constants/config';

import { makeUrlProjectId } from 'services/projectUtils';
import Query from 'services/Query';
import { convertAmountToUnits, inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import { IconBtnDelete, IconBtnEdit } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterDateRangeField,
  FilterSearchSelectField,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { actions } = this.props;
      actions.getList(params);
    }
  };

  handleDelete = async (id) => {
    const { actions, record, dispatch } = this.props;
    const { params = {}, list = [] } = record;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Вы уверены что хотите удалить запись?'
      })
    );

    if (res) {
      try {
        await actions.deleteOne(id);
        const { offset = 0, limit = 0 } = params;
        if (offset && list.length === 1) {
          params.offset = offset - limit;
        }
        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { basePath = '/', handleDelete } = props;

  const isCanCreate = useCan('warehouse:create');
  const isCanUpdate = useCan('warehouse:update');
  const isCanDelete = useCan('warehouse:delete');

  return (
    <Screen title="Приход/Расход">
      <List
        {...props}
        rowLink={(row) => `${basePath}/show/${row.id}`}
        toolBarInner={isCanCreate && <AddBtn to={`${basePath}/add`} />}
        filter={
          <Filter>
            <FilterSearchSelectField
              alwaysOn
              source="place_id"
              label="Дислокация"
              requestPath="/executor_places"
              responseFormat={(v) => v.results}
              searchBy="name"
              getValue={(o) => o.id}
            />
            <FilterSearchSelectField
              source="material_id"
              alwaysOn
              label="Материал"
              requestPath="/materials"
              responseFormat={(v) => v.results}
              searchBy="name"
              getValue={(o) => o.id}
            />
            <FilterSelectField
              alwaysOn
              source="direction"
              label="Тип"
              options={[
                { value: '', label: 'Все' },
                { value: 'INCOMING', label: 'Приход' },
                { value: 'OUTGOING', label: 'Расход' },
                { value: 'MOVE', label: 'Перемещение' }
              ]}
            />
            <FilterDateRangeField
              source="date"
              label="Дата"
              labels={['Дата,с', 'Дата,по']}
              maxWidth={275}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field label="Дислокация" source="place">
          {({ value }) => {
            if (!value) return '';
            return <div>{value.name}</div>;
          }}
        </Field>
        <Field
          label="Материал"
          source="material"
          format={(v) => {
            if (!v) {
              return '';
            }
            return v.name;
          }}
        />
        <Field
          label="Тип"
          source="direction"
          format={(v) => {
            if (v === 'INCOMING') {
              return <Chip label="Приход" />;
            }
            if (v === 'OUTGOING') {
              return <Chip label="Расход" />;
            }
            if (v === 'MOVE') {
              return <Chip label="Перемещение" />;
            }
            return '';
          }}
        />
        <Field
          label="Кол-во"
          source="*"
          sortable="value"
          align="right"
          format={(v) => {
            const { material = {}, value } = v;
            if (!material) {
              return '';
            }
            const { unit } = material;
            if (!MATERIAL_UNIT[unit]) {
              return 'Error';
            }
            return `${convertAmountToUnits(value, unit)} ${
              MATERIAL_UNIT[unit].short
            }`;
          }}
        />
        <Field
          label="Дата"
          source="date"
          type="date"
          dateFormat={DATE_FORMAT}
          sortable
        />
        <Field label="Заявка" source="order_id" isAction>
          {({ value }) => {
            if (!value) return '';
            return (
              <Link
                to={makeUrlProjectId(
                  `/orders/show/${value}`
                )}>{`Заявка: #${value}`}</Link>
            );
          }}
        </Field>
        <Field source="id" isAction>
          {({ value }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {isCanUpdate ? (
                  <IconBtnEdit to={`${basePath}/edit/${value}`} />
                ) : null}
                {isCanDelete ? (
                  <IconBtnDelete onClick={() => handleDelete(value)} />
                ) : null}
              </div>
            );
          }}
        </Field>
      </List>
    </Screen>
  );
};

const WarehouseList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default WarehouseList;
