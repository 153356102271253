import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Field from './Field';
import ReferenceField from './ReferenceField';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: (props) => (props.type === 'boolean' ? 'center' : 'baseline')
    }
  },
  label: {
    width: (props) => props.width || 'auto',
    paddingRight: theme.spacing(1),
    flexShrink: 0,
    fontWeight: 'bold'
  }
}));

const LabelField = (props) => {
  const { label, type, styles = {}, ...rest } = props;
  const classes = useStyles(props);
  const { root: rootS = {}, label: labelS = {} } = styles;
  const Component = type === 'reference' ? ReferenceField : Field;
  if (!label) return <Component {...rest} />;
  return (
    <div className={classes.root} style={rootS}>
      <div className={classes.label} style={labelS}>
        {label}
      </div>
      <Component {...rest} type={type} />
    </div>
  );
};

export default LabelField;
