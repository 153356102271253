import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Query from 'services/Query';
import { inChildren } from 'services/utils';

import { Btn } from 'components/buttons/Btn';
import Field, { FieldSelect } from 'components/Field';
import Filter, {
  FilterDateRangeField,
  FilterSearchField,
  FilterSearchGeoElasticAddress,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  refresh = () => {
    const { record = {} } = this.props;
    const { params = {} } = record;
    this.getList(params);
  };

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { customersOrdersActions } = this.props;
      customersOrdersActions.getList(params);
    }
  };

  handleUpdate = async (order_id, req) => {
    const { customersOrdersActions } = this.props;
    try {
      await customersOrdersActions.updateOne(order_id, req);
      this.refresh();
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleUpdate: this.handleUpdate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  customersOrdersActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleUpdate, basePath = '/', params = {} } = props;

  const { filter = {} } = params;

  const is_writable = useCan('customer_orders:write');

  return (
    <Screen title="Обращения">
      <List
        {...props}
        rowLink={(row) => `${basePath}/show/${row.id}`}
        filter={
          <Filter>
            <FilterSearchField source="id" label="ID" alwaysOn />
            <FilterDateRangeField
              source="date"
              labels={['Заявка,с', 'Заявка,по']}
              alwaysOn
              maxWidth={275}
            />
            <FilterSelectField
              source="status"
              label="Статус"
              alwaysOn
              options={[
                { value: '', label: 'Все' },
                { value: 'new', label: 'Новые' },
                { value: 'processing', label: 'В работе' },
                { value: 'closed', label: 'Закрытые' }
              ]}
            />
            <FilterSearchGeoElasticAddress
              source="locality_id,street_id,address_house"
              label="Адрес"
              filter={filter}
              maxWidth={600}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field
          label="Клиент"
          source="customer"
          format={(customer) => {
            if (!customer) return '';
            return <div>{customer.name}</div>;
          }}
        />
        <Field label="Номер" source="phone" type="phone" />
        <Field
          label="Дата заявки"
          type="date"
          source="date"
          sortable
          dateFormat="DD.MM.YYYY"
        />
        <Field
          label="Дата, создания"
          source="created_at"
          type="date"
          sortable
        />
        <Field label="Статус" source="status">
          {(props) => {
            const { row, value } = props;
            let label = '';
            let color = 'default';
            let style = {};
            switch (value) {
              case 'new': {
                label = 'Новая';
                color = 'success';
                break;
              }
              case 'processing': {
                label = 'В работе';
                color = 'primary';
                break;
              }
              case 'closed': {
                label = 'Закрыта';
                color = 'default';
                style = { color: '#919191' };
                break;
              }
              default: {
                label = '';
                color = 'default';
                style = {};
              }
            }
            return (
              <Btn
                size="small"
                color={color}
                style={style}
                disabled={!is_writable}>
                <FieldSelect
                  value={label}
                  onChange={(it) => {
                    if (it.value !== value)
                      handleUpdate(row.id, { status: it.value });
                  }}
                  options={[
                    {
                      value: 'new',
                      label: 'Новая'
                    },
                    {
                      value: 'processing',
                      label: 'В работе'
                    },
                    {
                      value: 'closed',
                      label: 'Закрыта'
                    }
                  ]}
                />
              </Btn>
            );
          }}
        </Field>
      </List>
    </Screen>
  );
};

const CustomersOrdersList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default CustomersOrdersList;
