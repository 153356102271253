import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import momentTz from 'moment-timezone';
import { bindActionCreators } from 'redux';
import { stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { PHONE_FORMAT } from 'constants/config';

import { inChildren, validateErrors } from 'services/utils';

import {
  FormCheckboxField,
  FormColorPickerField,
  FormNumberField,
  FormPhoneField,
  FormSearchGeoLocalityField,
  FormSearchSelectField,
  FormSelectField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const timezones = momentTz.tz.names();

const validate = (values) => {
  const errors = {};

  const { name, email, phone, locality, place, owner, sort } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  errors.owner = validateErrors(owner, {
    require: 'Обязательное поле'
  });

  errors.locality = validateErrors(locality, {
    require: 'Обязательное поле'
  });

  errors.place = validateErrors(place, {
    require: 'Обязательное поле'
  });

  errors.sort = validateErrors(sort, {
    isNumeric: 'Должно быть числом'
  });

  if (email) {
    errors.email = validateErrors(email, {
      email: 'Не валидный e-mail'
    });
  }

  if (phone) {
    errors.phone = validateErrors(phone, {
      phone: PHONE_FORMAT.placeholder
    });
  }

  return errors;
};

const formName = 'edit_executor';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      executorsActions,
      history = {}
    } = this.props;
    try {
      await executorsActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const {
      executorsActions,
      dispatch,
      record = {},
      loading,
      history
    } = this.props;
    if (loading) return;
    try {
      await executorsActions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/executor-exist') {
        dispatch(stopSubmit(formName, { name: 'Уже существует' }));
      } else if (err && err.code === 'api/owner-exist') {
        dispatch(stopSubmit(formName, { owner: 'Уже используется' }));
      } else if (err && err.code === 'api/user-not-exist') {
        dispatch(stopSubmit(formName, { owner: 'Пользователь не существует' }));
      }
    }
  };

  handleDelete = async () => {
    const {
      executorsActions,
      record = {},
      dispatch,
      history = {}
    } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить дезинфектора?'
      })
    );

    if (res) {
      try {
        await executorsActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleUpdate, handleDelete, record = {}, loading } = props;

  const itemReceived = record && record.id;
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Редактировать дезинфектора: ${record.name}`;
  }

  return (
    <Screen title={title}>
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        onBack={back}
        onDelete={handleDelete}
        direction="row"
        validate={validate}
        initialValues={record}
        parse={(values) => {
          const res = {
            ...values,
            name: values.name || '',
            email: values.email || '',
            phone: values.phone || '',
            sort: values.sort ? Number(values.sort) : 0
          };
          if (values.locality) {
            res.locality_id = values.locality.id;
            delete res.locality;
          }
          if (values.place) {
            res.place_id = values.place.id;
            delete res.place;
          }
          if (values.owner) {
            res.owner_id = values.owner.id;
            delete res.owner;
          }
          return res;
        }}>
        <Grid item xs={12} sm={6}>
          <FormTextField source="name" label="Имя" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSearchSelectField
            source="owner"
            label="Пользователь"
            requestPath="/users"
            responseFormat={(v) => v.results}
            getOptionLabel={(it) =>
              it ? [it.first_name, it.last_name].join(' ') : ''
            }
            disabled={loading}
            searchBy={['first_name', 'last_name']}
            disableClearable={true}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField source="email" label="E-mail" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormPhoneField source="phone" label="Тел." disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSearchGeoLocalityField
            source="locality"
            label="Населенный пункт"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSearchSelectField
            source="place"
            label="Дислокация"
            requestPath="/executor_places"
            responseFormat={(v) => v.results}
            renderOption={(it) => it.name}
            searchBy="name"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormNumberField
            source="sort"
            label="Сортировка"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormColorPickerField
            source="color"
            label="Цвет"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSelectField
            source="timezone"
            label="Timezone"
            disabled={loading}
            defaultValue="Europe/Kiev"
            options={timezones.map((it) => ({ value: it, label: it }))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormCheckboxField
            source="is_active"
            label="Активен"
            disabled={loading}
            parse={(v) => (v ? 'yes' : 'no')}
            format={(v) => v === 'yes'}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const ExecutorsEdit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ExecutorsEdit;
