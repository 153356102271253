import React from 'react';

import { concatGeoFullName } from 'services/utils';

import FormSearchGeoField from './FormSearchGeoField';

function renderOptionDefault(opt) {
  const { name } = opt;
  return (
    <div>
      <div>{name}</div>
      <div style={{ fontSize: '0.75em' }}>
        {concatGeoFullName(opt, [0, 1, 2, 3])}
      </div>
    </div>
  );
}

const FormSearchGeoStreetsField = ({ where, ...rest }) => {
  return (
    <FormSearchGeoField
      where={{ ...where, type: 'street' }}
      geotype="streets"
      getOptionLabel={(opt) => opt?.name || ''}
      renderOption={renderOptionDefault}
      {...rest}
    />
  );
};

export default FormSearchGeoStreetsField;
