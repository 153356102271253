import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BugReportIcon from '@material-ui/icons/BugReport';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ExpandLess from '@material-ui/icons/ExpandLess';
import FolderIcon from '@material-ui/icons/Folder';
import HistoryIcon from '@material-ui/icons/History';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import PlaceIcon from '@material-ui/icons/Place';
import RoomIcon from '@material-ui/icons/Room';
import StyleIcon from '@material-ui/icons/Style';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

import { useMakeUrlProjectId } from 'services/projectUtils';

import { getStatsBookkeeping } from 'store/actions/statsActions';

import IconSvg from 'components/IconSvg';
import Rbac from 'components/Rbac';
import SelectProjectsMenu from 'components/SelectProjectsMenu';
import Stats from 'components/Stats';

const useStylesDrawer = makeStyles((theme) => ({
  drawerPaper: {
    width: theme.drawerWidth
  },
  drawerPaperOpen: {
    [theme.breakpoints.up('md')]: {
      width: theme.drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    }
  },
  drawerPaperClose: {
    border: 'none',
    [theme.breakpoints.up('md')]: {
      width: 0,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    }
  },
  toolbarLogo: {
    margin: 0
  },
  title: {},
  linkTextWrap: {},
  linkItem: {
    flexShrink: 0,
    justifyContent: 'center',
    padding: theme.spacing(0.5, 1),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    },
    '&$active': {
      backgroundColor: 'rgba(0, 0, 0, 0.2)'
    },
    '&$active $linkIcon': {
      color: '#000'
    },
    '&$active $linkText': {
      fontWeight: 'bold'
    }
  },
  linkIcon: {
    minWidth: 0,
    marginRight: theme.spacing(1)
  },
  linkText: {
    fontSize: '0.8rem'
  },
  active: {
    // color: '#f00'
    // fontWeight: 'bold',
    // '$linkIcon': {
    //   color: '#000',
    // },
    // '$linkText': {
    //   fontWeight: 'bold',
    // }
  },
  linkItemWrap: {
    // '&.active': {
    //   backgroundColor: 'rgba(0, 0, 0, 0.2)'
    // },
    '&.active $setctionItemTitle': {
      fontWeight: 'bold'
    },
    '&.active $linkIcon': {
      color: '#000'
    }
  },
  setctionItemTitle: {
    // color: '#000',
    // fontWeight: 'bold'
  },
  titleWithBadge: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  badge: {
    color: '#fff',
    backgroundColor: theme.colors.red,
    borderRadius: '50%',
    paddingLeft: 3,
    paddingRight: 3,
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: 12
  }
}));

const MenuLink = (props) => {
  const classes = useStylesDrawer();

  const {
    title = '',
    icon: MenuLinkIcon = null,
    to = '',
    onClick,
    exact
  } = props;

  return (
    <ListItem
      exact={exact}
      button
      component={NavLink}
      to={to}
      className={classes.linkItem}
      activeClassName={classes.active}
      classes={{
        root: classes.linkItemRoot
      }}
      onClick={onClick}>
      {MenuLinkIcon && (
        <ListItemIcon className={classes.linkIcon}>
          <MenuLinkIcon />
        </ListItemIcon>
      )}
      <ListItemText
        primary={title}
        classes={{
          root: classes.linkTextWrap,
          primary: classes.linkText
        }}
      />
    </ListItem>
  );
};

const MenuSection = (props) => {
  const classes = useStylesDrawer();

  const {
    active,
    children,
    title = '',
    icon: IconComponent = FolderIcon,
    onClose,
    onOpen
  } = props;

  const [collapsed, setCollapsed] = useState(!active);

  const toogleCollapsed = () => {
    const nextCollapsed = !collapsed;
    setCollapsed(nextCollapsed);
    if (nextCollapsed && onClose) onClose();
    if (!nextCollapsed && onOpen) onOpen();
  };
  return (
    <div style={{ flexShrink: 0 }}>
      <ListItem
        button
        onClick={toogleCollapsed}
        className={classes.linkItem}
        classes={{
          root: `${classes.linkItemWrap} ${active ? 'active' : ''}`
        }}>
        <ListItemIcon className={classes.linkIcon}>
          {collapsed ? <IconComponent /> : <ExpandLess />}
        </ListItemIcon>
        <ListItemText
          primary={<span className={classes.setctionItemTitle}>{title}</span>}
          classes={{
            primary: classes.linkText,
            root: classes.linkTextWrap
          }}
        />
      </ListItem>
      <Collapse in={!collapsed} timeout="auto">
        <div
          style={{
            paddingLeft: 15
          }}>
          {children}
        </div>
      </Collapse>
    </div>
  );
};

function useIsActiveSection() {
  const { pathname } = useLocation();
  const pathWithoutProj = pathname.replace(/project\/[\d]+\//, '');
  return (config) => {
    const entries = Object.entries(config);
    for (let [key, paths] of entries) {
      const finded = paths.some((path) => {
        return pathWithoutProj.startsWith(path);
      });
      if (finded) {
        return key;
      }
    }
  };
}

const AppDrawer = (props) => {
  const { drawerOpen, handleDrawerClose, classes: parentClasses } = props;

  const makeUrlProjectId = useMakeUrlProjectId();
  const project_id = useSelector((state) => state.auth.project_id);
  const classes = useStylesDrawer();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const linkClick = isMobile ? handleDrawerClose : undefined;

  const checkPaths = useIsActiveSection();
  const activeSectionKey = checkPaths({
    orders: [
      '/schedule',
      '/orders',
      '/orders-map',
      '/orders-history',
      '/customers-orders'
    ],
    bookkeep: ['/bookkeeper-orders-new', '/bookkeeper-orders-changed'],
    clients: ['/client-groups', '/clients'],
    executors: ['/executors', '/executor_places', '/executors_available'],
    dictionaries: ['/pay-types', '/methods', '/reasons'],
    users: ['/groups', '/users'],
    warehouse: ['/warehouse', '/warehouse-balances', '/materials'],
    geo: [
      '/geo-regions',
      '/geo-districts',
      '/geo-parts',
      '/geo-locality',
      '/geo-streets'
    ]
  });

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'permanent'}
      onClose={handleDrawerClose}
      className={clsx(classes.drawer, {
        [classes.drawerPaperOpen]: drawerOpen,
        [classes.drawerPaperClose]: !drawerOpen
      })}
      classes={{
        paper: clsx(classes.drawerPaper, {
          [classes.drawerPaperOpen]: drawerOpen,
          [classes.drawerPaperClose]: !drawerOpen
        })
      }}
      open={drawerOpen}>
      <div className={parentClasses.toolbar}>
        <h1 className={classes.toolbarLogo}>ERP</h1>
        <Stats key={project_id} />
        <IconButton onClick={handleDrawerClose}>
          <MenuIcon />
        </IconButton>
      </div>
      <Divider />
      <SelectProjectsMenu />
      <Divider />
      <Rbac anyOf={['orders:read', 'client_groups:read']}>
        <MenuSection
          title="Заявки"
          defaultCollapsed={false}
          active={activeSectionKey === 'orders'}>
          <Rbac operation="orders:read">
            <MenuLink
              title="Расписание"
              to={makeUrlProjectId('/schedule')}
              icon={DateRangeIcon}
              onClick={linkClick}
            />
          </Rbac>
          <Rbac operation="orders:read">
            <MenuLink
              title="Список заявок"
              to={makeUrlProjectId('/orders')}
              icon={ListAltIcon}
              onClick={linkClick}
            />
          </Rbac>
          <Rbac operation="orders:read">
            <MenuLink
              title="Карта заявок"
              to={makeUrlProjectId('/orders-map')}
              icon={RoomIcon}
              onClick={linkClick}
            />
          </Rbac>
          <Rbac operation="orders:read">
            <MenuLink
              title="История"
              to={makeUrlProjectId('/orders-history')}
              icon={HistoryIcon}
              onClick={linkClick}
            />
          </Rbac>
          <Rbac operation="customers:read">
            <MenuLink
              title="Обращения"
              to={makeUrlProjectId('/customers-orders')}
              icon={ContactMailIcon}
              onClick={linkClick}
            />
          </Rbac>
        </MenuSection>
      </Rbac>
      <Rbac operation="orders:bookkeeping">
        <BookkeeperSection
          key={project_id}
          active={activeSectionKey === 'bookkeep'}
          makeUrlProjectId={makeUrlProjectId}
          linkClick={linkClick}
        />
      </Rbac>
      <Divider />
      <Rbac anyOf={['clients:read', 'client_groups:read']}>
        <MenuSection title="Клиенты" active={activeSectionKey === 'clients'}>
          <Rbac operation="client_groups:read">
            <MenuLink
              title="Группы клиентов"
              to={makeUrlProjectId('/client-groups')}
              icon={SupervisedUserCircleIcon}
              onClick={linkClick}
            />
          </Rbac>
          <Rbac operation="clients:read">
            <MenuLink
              title="Клиенты"
              to={makeUrlProjectId('/clients')}
              icon={AccountCircleIcon}
              onClick={linkClick}
            />
          </Rbac>
        </MenuSection>
      </Rbac>
      <Divider />
      <Rbac anyOf={['executors:read', 'places:read', 'available:read']}>
        <MenuSection
          title="Дезинфекторы"
          active={activeSectionKey === 'executors'}>
          <Rbac operation="executors:read">
            <MenuLink
              title="Дезинфекторы"
              to={makeUrlProjectId('/executors')}
              icon={HowToRegIcon}
              onClick={linkClick}
            />
          </Rbac>
          <Rbac operation="places:read">
            <MenuLink
              title="Дислокации"
              to={makeUrlProjectId('/executor_places')}
              icon={PlaceIcon}
              onClick={linkClick}
            />
          </Rbac>
          <Rbac operation="available:read">
            <MenuLink
              title="Отсутствие"
              to={makeUrlProjectId('/executors_available')}
              icon={EventAvailableIcon}
              onClick={linkClick}
            />
          </Rbac>
        </MenuSection>
      </Rbac>
      <Divider />
      <Rbac anyOf={['pay_types:read', 'methods:read', 'reasons:read']}>
        <MenuSection
          title="Справочники"
          active={activeSectionKey === 'dictionaries'}>
          <Rbac operation="pay_types:read">
            <MenuLink
              title="Способы оплаты"
              to={makeUrlProjectId('/pay-types')}
              icon={AttachMoneyIcon}
              onClick={linkClick}
            />
          </Rbac>
          <Rbac operation="methods:read">
            <MenuLink
              title="Услуги"
              to={makeUrlProjectId('/methods')}
              icon={EmojiObjectsIcon}
              onClick={linkClick}
            />
          </Rbac>
          <Rbac operation="reasons:read">
            <MenuLink
              title="Вредители"
              to={makeUrlProjectId('/reasons')}
              icon={BugReportIcon}
              onClick={linkClick}
            />
          </Rbac>
        </MenuSection>
      </Rbac>
      <Divider />
      <Rbac anyOf={['groups:read', 'users:read']}>
        <MenuSection title="Пользователи" active={activeSectionKey === 'users'}>
          <Rbac operation="groups:read">
            <MenuLink
              title="Группы пользователей"
              to={makeUrlProjectId('/groups')}
              icon={PeopleIcon}
              onClick={linkClick}
            />
          </Rbac>
          <Rbac operation="users:read">
            <MenuLink
              title="Пользователи"
              to={makeUrlProjectId('/users')}
              icon={PersonIcon}
              onClick={linkClick}
            />
          </Rbac>
        </MenuSection>
      </Rbac>
      <Divider />
      <Rbac operation="warehouse:read">
        <MenuSection title="Склад" active={activeSectionKey === 'warehouse'}>
          <MenuLink
            title="Приход/Расход"
            to={makeUrlProjectId('/warehouse')}
            icon={SyncAltIcon}
            onClick={linkClick}
          />
          <MenuLink
            title="Остатки"
            to={makeUrlProjectId('/warehouse-balances')}
            icon={TrendingDownIcon}
            onClick={linkClick}
          />
          <Rbac operation="materials:read">
            <MenuLink
              title="Материалы"
              to={makeUrlProjectId('/materials')}
              icon={StyleIcon}
              onClick={linkClick}
            />
          </Rbac>
        </MenuSection>
      </Rbac>
      <Divider />
      <Rbac operation="geo:read">
        <MenuSection title="Геолокация" active={activeSectionKey === 'geo'}>
          <MenuLink
            title="Регионы"
            to={makeUrlProjectId('/geo-regions')}
            icon={() => <IconSvg name="ua" />}
            onClick={linkClick}
          />
          <MenuLink
            title="Районы"
            to={makeUrlProjectId('/geo-districts')}
            icon={() => <IconSvg name="district" />}
            onClick={linkClick}
          />
          <MenuLink
            title="Нас. пункт"
            to={makeUrlProjectId('/geo-locality')}
            icon={PlaceIcon}
            onClick={linkClick}
          />
          <MenuLink
            title="Улицы"
            to={makeUrlProjectId('/geo-streets')}
            icon={() => <IconSvg name="street" />}
            onClick={linkClick}
          />
        </MenuSection>
        <Divider />
      </Rbac>
      <MenuLink
        title="Отчеты"
        to={makeUrlProjectId('/reports')}
        icon={AssignmentReturnedIcon}
        onClick={linkClick}
      />
      <Divider />
      <Rbac operation="projects:read">
        <MenuLink
          title="Проекты"
          to={makeUrlProjectId('/projects')}
          icon={AccountTreeIcon}
          onClick={linkClick}
        />
      </Rbac>
      <Divider />
    </Drawer>
  );
};

export default AppDrawer;

const TitleWithBadge = ({ title = '', count = 0 }) => {
  const classes = useStylesDrawer();
  if (count > 99) count = '99+';
  return (
    <div className={classes.titleWithBadge}>
      <div>{title}</div>
      {count ? <div className={classes.badge}>{count}</div> : null}
    </div>
  );
};

const BookkeeperSection = ({ linkClick, makeUrlProjectId, active }) => {
  const dispatch = useDispatch();
  const { bookkeeping_change_count = 0, bookkeeping_new_count = 0 } =
    useSelector((state) => state.stats);

  const updateStats = React.useCallback(() => {
    dispatch(getStatsBookkeeping());
  }, [dispatch]);
  React.useEffect(() => {
    updateStats();
  }, [updateStats]);
  return (
    <MenuSection
      title="Бухгалтерия"
      onOpen={updateStats}
      active={active}
      defaultCollapsed={false}>
      <MenuLink
        title={
          <TitleWithBadge
            title="Заявки (новые)"
            count={bookkeeping_new_count}
          />
        }
        to={makeUrlProjectId('/bookkeeper-orders-new')}
        icon={AssignmentIndIcon}
        onClick={linkClick}
      />
      <MenuLink
        title={
          <TitleWithBadge
            title="Заявки (изменения)"
            count={bookkeeping_change_count}
          />
        }
        to={makeUrlProjectId('/bookkeeper-orders-changed')}
        icon={AssignmentIndIcon}
        onClick={linkClick}
      />
    </MenuSection>
  );
};
