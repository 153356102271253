export default function StreetIcon(props) {
  return (
    <svg
      width="24"
      fill="currentColor"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 197.206 197.206"
      xmlSpace="preserve"
      {...props}>
      <g>
        <path
          d="M189.706,145.021H7.5c-4.142,0-7.5,3.357-7.5,7.5c0,4.143,3.358,7.5,7.5,7.5h182.206c4.142,0,7.5-3.357,7.5-7.5
		C197.206,148.378,193.848,145.021,189.706,145.021z"
        />
        <rect x="80.382" y="91.103" width="36.441" height="15" />
        <rect x="7.5" y="91.103" width="36.441" height="15" />
        <path
          d="M7.5,52.185h182.206c4.142,0,7.5-3.357,7.5-7.5c0-4.143-3.358-7.5-7.5-7.5H7.5c-4.142,0-7.5,3.357-7.5,7.5
		C0,48.828,3.358,52.185,7.5,52.185z"
        />
        <rect x="153.265" y="91.103" width="36.441" height="15" />
      </g>
    </svg>
  );
}
