import React, { Component } from 'react';
import _ from 'lodash';
import { reduxForm } from 'redux-form';

class Form extends Component {
  render() {
    const {
      children,
      handleSubmit,
      form = 'simple',
      className = '',
      onSubmit,
      disabled
    } = this.props;
    return (
      <form
        className={className}
        form={form}
        onSubmit={handleSubmit(onSubmit)}
        disabled={disabled}>
        {children}
      </form>
    );
  }
}

const ReduxForm = reduxForm({
  form: 'simple'
})(Form);

const Wrap = (props) => {
  const {
    onSubmit,
    disabled,
    parse,
    notRegistredField = true,
    ...rest
  } = props;
  const handleSubmit = (values, dispatch, formAttrs) => {
    if (disabled) return;
    if (notRegistredField && formAttrs.registeredFields) {
      values = _.pick(values, Object.keys(formAttrs.registeredFields));
    }
    if (parse) values = parse(values);
    if (onSubmit) {
      onSubmit(values);
    }
  };
  return <ReduxForm {...rest} onSubmit={handleSubmit} disabled={disabled} />;
};
export default Wrap;
