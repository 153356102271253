import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { projects as resourceApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';
import { showSnackbarError, showSnackbarSuccess } from 'modules/notifier';

export const actionTypes = {
  GET_LIST: 'PROJECTS_GET_LIST',

  GET_ONE: 'PROJECTS_GET_ONE',
  CREATE_ONE: 'PROJECTS_CREATE_ONE',
  UPDATE_ONE: 'PROJECTS_UPDATE_ONE',
  DELETE_ONE: 'PROJECTS_DELETE_ONE',

  GET_LIST_USERS: 'PROJECTS_GET_LIST_USERS'
};

export const getList = (param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch({
    type: actionType,
    payload: {
      loading: true,
      params: next,
      list: []
    }
  });

  try {
    const res = await resourceApi.getList(request);

    dispatch({
      type: actionType,
      payload: {
        list: res.results,
        count: res.count,
        loading: false
      }
    });
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch({
      type: actionType,
      payload: {
        loading: false
      }
    });
    return Promise.reject(err);
  }
};

export const getOne = (id) => async (dispatch, getState) => {
  const actionType = actionTypes.GET_ONE;

  const payload = { loading: true };
  const oldItem = getState().projects.one.data;
  if (oldItem && Number(id) !== oldItem.id) {
    payload.data = null;
  }
  dispatch({
    type: actionType,
    payload
  });

  try {
    const res = await resourceApi.getOne(id);
    dispatch({
      type: actionType,
      payload: {
        data: res,
        loading: false
      }
    });
    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: actionType,
      payload: {
        loading: false
      }
    });
    if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Проект не найден' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const createOne = (data) => async (dispatch) => {
  const actionType = actionTypes.CREATE_ONE;

  dispatch({
    type: actionType,
    payload: {
      loading: true
    }
  });

  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.createOne(request);
    dispatch({
      type: actionType,
      payload: {
        loading: false
      }
    });
    dispatch(showSnackbarSuccess({ message: 'Проект создан' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: actionType,
      payload: {
        loading: false
      }
    });
    if (err && err.code === 'api/exist') {
      dispatch(showSnackbarError({ message: 'Такой проект существует' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const deleteOne = (id) => async (dispatch) => {
  const actionType = actionTypes.DELETE_ONE;

  dispatch({
    type: actionType,
    payload: {
      loading: true
    }
  });

  try {
    const res = await resourceApi.deleteOne(id);
    dispatch({
      type: actionType,
      payload: {
        loading: false,
        data: null
      }
    });
    dispatch(showSnackbarSuccess({ message: 'Проект удален' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: actionType,
      payload: {
        loading: false
      }
    });
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};

export const updateOne = (id, data) => async (dispatch) => {
  const actionType = actionTypes.UPDATE_ONE;

  dispatch({
    type: actionType,
    payload: {
      loading: true
    }
  });

  const request = Query.createRequest(data);
  try {
    const res = await resourceApi.updateOne(id, request);
    dispatch({
      type: actionType,
      payload: {
        loading: false
      }
    });
    dispatch(showSnackbarSuccess({ message: 'Проект обновлен' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: actionType,
      payload: {
        loading: false
      }
    });
    if (err && err.code === 'api/exist') {
      dispatch(showSnackbarError({ message: 'Такой проект существует' }));
    } else if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Проект не найден' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const getProjectUsers = (project_id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_USERS;

  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  dispatch({
    type: actionType,
    payload: {
      loading: true,
      params: next,
      list: []
    }
  });

  const request = Query.createGetRequest(next);

  try {
    const { results: list = [], count = 0 } = await resourceApi.getUsersList(
      project_id,
      request
    );

    const res = {
      list,
      count,
      loading: false
    };

    dispatch({
      type: actionType,
      payload: {
        ...res
      }
    });
    return Promise.resolve(res);
  } catch (err) {
    dispatch({
      type: actionType,
      payload: {
        loading: false
      }
    });
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};

export const attachUser = (project_id, user_id) => async (dispatch) => {
  try {
    const res = await resourceApi.attachUser(project_id, user_id);
    dispatch(showSnackbarSuccess({ message: 'Пользователь добавлен' }));
    return Promise.resolve(res);
  } catch (err) {
    if (err && err.code === 'api/exist')
      dispatch(showSnackbarError({ message: 'Уже существует' }));
    else dispatch(ErrorService.dispatchError(err));
  }
};

export const detachUser = (project_id, user_id) => async (dispatch) => {
  try {
    const res = await resourceApi.detachUser(project_id, user_id);
    dispatch(
      showSnackbarSuccess({ message: 'Пользователь удален из проекта' })
    );
    return Promise.resolve(res);
  } catch (err) {
    if (err && err.code === 'api/exist')
      dispatch(showSnackbarError({ message: 'Такая связь уже существует' }));
    else dispatch(ErrorService.dispatchError(err));
  }
};
