import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { inChildren } from 'services/utils';

import * as authActions from 'store/actions/authActions';

class AuthContainer extends Component {
  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest
    });
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  authActions: bindActionCreators(authActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);
