import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import AppBarMaterial from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import RemindIcon from '@material-ui/icons/AccessAlarm';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { useMakeUrlProjectId } from 'services/projectUtils';
import AuthContainer from 'containers/AuthContainer';

import Rbac from 'components/Rbac';

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.drawerWidth,
        width: `calc(100% - ${theme.drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      }
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    hide: {
      display: 'none'
    },
    center: {
      flexGrow: 1,
      overflow: 'hidden',
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center'
    },
    title: {
      textOverflow: 'ellipsis',
      lineHeight: 1,
      overflow: 'hidden'
    },
    controls: {
      flexShrink: 0
    },
    inputRoot: {
      color: 'inherit'
    },
    neighbor: {
      display: 'flex'
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200
      }
    },
    userMenuBtn: {
      textTransform: 'none'
    }
  };
});

const Title = connect((state) => {
  return {
    title: state.ui.title
  };
})(({ title }) => {
  const classes = useStyles();
  return (
    <Typography variant="h6" className={classes.title}>
      {title || ''}
    </Typography>
  );
});

const AppBar = (props) => {
  const { drawerOpen, handleDrawerOpen, admin } = props;
  const classes = useStyles();
  const makeUrlProjectId = useMakeUrlProjectId();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  let notifications_count = (admin && admin.notifications_count) || 0;
  let reminders_count = (admin && admin.reminders_count) || 0;
  let user_name =
    admin && (admin.first_name || admin.last_name)
      ? [admin.first_name, admin.last_name].join(' ')
      : '';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <div>
        <AuthContainer>
          {({ authActions = {} }) => (
            <MenuItem
              aria-label="logout"
              color="inherit"
              onClick={authActions.logout}>
              Выход
            </MenuItem>
          )}
        </AuthContainer>
      </div>
    </Menu>
  );

  return (
    <AppBarMaterial
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: drawerOpen
      })}>
      <Toolbar classes={{ root: classes.toolbar }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.burgerButton, {
            [classes.hide]: drawerOpen
          })}>
          <MenuIcon />
        </IconButton>
        <div className={classes.center}>
          <Title />
        </div>
        <div className={classes.controls}>
          <Rbac operation="reminders:read">
            <IconButton
              component={Link}
              to={makeUrlProjectId('/reminders?filter[user_id]=' + admin.id)}
              aria-label="show new reminders"
              color="inherit">
              <Badge
                overlap="rectangular"
                badgeContent={reminders_count}
                color="secondary">
                <RemindIcon />
              </Badge>
            </IconButton>
          </Rbac>
          <IconButton
            component={Link}
            to={makeUrlProjectId('/notifications')}
            aria-label="show new notifications"
            color="inherit">
            <Badge
              overlap="rectangular"
              badgeContent={notifications_count}
              color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Button
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            className={classes.userMenuBtn}>
            {user_name ? (
              <span className={classes.userName}>{user_name}</span>
            ) : (
              <AccountCircleIcon />
            )}
          </Button>
        </div>
      </Toolbar>
      {renderMenu}
    </AppBarMaterial>
  );
};
const mapStateToProps = (state) => {
  return {
    admin: state.auth.admin
  };
};
export default connect(mapStateToProps)(AppBar);
