import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { clients as resourceApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';
import { showSnackbarError, showSnackbarSuccess } from 'modules/notifier';

const REDUX_PREFIX = 'CLIENTS';

export const actionTypes = {
  GET_LIST: `${REDUX_PREFIX}_GET_LIST`,
  GET_ONE: `${REDUX_PREFIX}_GET_ONE`,
  CLEAR_ONE: `${REDUX_PREFIX}_CLEAR_ONE`,
  CREATE_ONE: `${REDUX_PREFIX}_CREATE_ONE`,
  UPDATE_ONE: `${REDUX_PREFIX}_UPDATE_ONE`,
  DELETE_ONE: `${REDUX_PREFIX}_DELETE_ONE`,
  GET_LIST_MESSAGES: `${REDUX_PREFIX}_GET_LIST_MESSAGES`,
  GET_LIST_FILES: `${REDUX_PREFIX}_GET_LIST_FILES`
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

export const getList = (param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } = await resourceApi.getList(
      request
    );

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const getOne = (id) => async (dispatch, getState) => {
  const actionType = actionTypes.GET_ONE;

  const payload = { loading: true };
  const oldItem = getState().clients.one.data;
  if (oldItem && Number(id) !== oldItem.id) {
    payload.data = null;
  }

  dispatch(updateRedux(actionType, payload));

  try {
    const res = await resourceApi.getOne(id);
    dispatch(
      updateRedux(actionType, {
        data: res,
        loading: false
      })
    );
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Клиент не найден' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const createOne = (data) => async (dispatch) => {
  const actionType = actionTypes.CREATE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));
  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.createOne(request);
    dispatch(
      updateRedux(actionType, {
        loading: false
      })
    );
    dispatch(showSnackbarSuccess({ message: 'Клиент создан' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.code === 'api/exist') {
      dispatch(showSnackbarError({ message: 'Такой клиент уже существует' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const updateOne = (id, data) => async (dispatch) => {
  const actionType = actionTypes.UPDATE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));

  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.updateOne(id, request);
    dispatch(
      updateRedux(actionType, {
        loading: false
      })
    );
    dispatch(showSnackbarSuccess({ message: 'Клиент обновлен' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.code === 'api/exist') {
      dispatch(showSnackbarError({ message: 'Такой клиент уже существует' }));
    } else if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Клиент не найден' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const deleteOne = (id) => async (dispatch) => {
  const actionType = actionTypes.DELETE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));

  try {
    const res = await resourceApi.deleteOne(id);
    dispatch(updateRedux(actionType, { loading: false, data: null }));
    dispatch(showSnackbarSuccess({ message: 'Клиент удален' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};

export const getClientMessages = (client_id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_MESSAGES;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } =
      await resourceApi.getClientMessages(client_id, request);
    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const getClientMessagesLoad =
  (client_id, param) => async (dispatch, getState) => {
    const actionType = actionTypes.GET_LIST_MESSAGES;
    const next = {
      order: 'desc',
      orderBy: 'id',
      filter: {},
      offset: 0,
      limit: PAGINATION_TABLE_LIMIT,
      ...param
    };

    const { list: oldList = [] } = getState().clients.messages;
    next.offset = oldList.length;

    const request = Query.createGetRequest(next);

    dispatch(
      updateRedux(actionType, {
        loading: true,
        params: next
      })
    );

    try {
      const { results: list = [], count = 0 } =
        await resourceApi.getClientMessages(client_id, request);
      const res = {
        list: oldList.concat(list),
        count,
        loading: false
      };

      dispatch(updateRedux(actionType, res));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      dispatch(updateRedux(actionType, { loading: false }));
      return Promise.reject(err);
    }
  };

export const createClientMessage = (client_id, params) => async (dispatch) => {
  try {
    const res = await resourceApi.createClientMessage(client_id, params);
    dispatch(showSnackbarSuccess({ message: 'Добавлено' }));
    return res;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};

export const deleteClientMessage = (client_id, item_id) => async (dispatch) => {
  try {
    const res = await resourceApi.deleteClientMessage(client_id, item_id);
    dispatch(showSnackbarSuccess({ message: 'Удалено' }));
    return res;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};

export const getClientFiles = (client_id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_FILES;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };
  const request = Query.createGetRequest(next);
  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );
  try {
    const { results: list = [], count = 0 } = await resourceApi.getClientFiles(
      client_id,
      request
    );
    const res = {
      list,
      count,
      loading: false
    };
    dispatch(updateRedux(actionType, res));
    return true;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return false;
  }
};

export const uploadFile = (client_id, file) => async (dispatch) => {
  try {
    const res = await resourceApi.addClientFile(client_id, { file });
    dispatch(showSnackbarSuccess({ message: 'Файл добавлен' }));
    return res;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};

export const deleteClientFile = (client_id, item_id) => async (dispatch) => {
  try {
    const res = await resourceApi.deleteClientFile(client_id, item_id);
    dispatch(showSnackbarSuccess({ message: 'Удалено' }));
    return res;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};

export const getClientFileToken = (client_id, item_id) => async (dispatch) => {
  try {
    return await resourceApi.getClientFileToken(client_id, item_id);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    return false;
  }
};
