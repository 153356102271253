import Add from './Add';
import Edit from './Edit';
import List from './List';
import reducer from './reducer';
import Show from './Show';

const Clients = {
  List,
  Add,
  Edit,
  Show,
  reducer
};

export default Clients;
