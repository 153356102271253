import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { reset } from 'redux-form';

import { inChildren } from 'services/utils';
import AuthContainer from 'containers/AuthContainer';

import View from './View';

class LoginController extends Component {
  onSubmit = async (params) => {
    const { auth = {}, authActions = {}, dispatch } = this.props;
    if (auth.inProcess) return;
    try {
      await authActions.login(params);
    } catch (err) {
      dispatch(reset('login'));
    }
  };

  render() {
    const { children, auth = {} } = this.props;
    if (auth.admin) return <Redirect to="/" />;
    return inChildren(children, {
      onSubmit: this.onSubmit,
      auth
    });
  }
}

const LoginScreen = (props) => (
  <AuthContainer {...props}>
    <LoginController>
      <View />
    </LoginController>
  </AuthContainer>
);

export default LoginScreen;
