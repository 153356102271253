import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { change, stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { inChildren, validateErrors } from 'services/utils';

import {
  FormField,
  FormSearchGeoLocalityField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import { constants as MAP_CONSTANTS, utils as mapUtils } from 'components/maps';
import Screen from 'components/Screen';

import PlacesMapField from './components/PlacesMapField';
import * as actions from './actions';

const validate = (values) => {
  const errors = {};

  const { name, locality, geopoint } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле'
  });

  errors.locality = validateErrors(locality, {
    require: 'Обязательное поле'
  });

  if (!geopoint) {
    errors.geopoint = 'Укажите точку на карте';
  }

  return errors;
};

const formName = 'add_executor_place';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      geopoints: [],
      zoom: MAP_CONSTANTS.DEFAULT_ZOOM
    };
  }

  handleCreate = async (data) => {
    const {
      loading,
      executorPlacesActions,
      dispatch,
      history = {}
    } = this.props;
    if (loading) return;

    try {
      await executorPlacesActions.createOne(data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(formName, { name: 'Уже существует' }));
      }
    }
  };

  handleChangeLocality = (locality) => {
    this.handleChangeGeopoint({
      locality
    });
  };

  handleChangeGeopoint = async ({ locality }) => {
    const { dispatch } = this.props;
    if (this.geopointTimer) {
      clearTimeout(this.geopointTimer);
    }
    this.geopointTimer = setTimeout(async () => {
      const geopoints = await mapUtils.getMapDataByEntries({ locality });
      const firstFinded = geopoints && geopoints.length ? geopoints[0] : null;
      const geopoint = mapUtils.getGeopointFromMapData(firstFinded);
      dispatch(
        change(formName, 'geopoint', geopoint || mapUtils.makeNullGeopoint())
      );
      this.setState({
        locality,
        geopoints: geopoints || [],
        zoom: mapUtils.getZoomWithEntries({
          geopoint,
          locality
        })
      });
    }, 500);
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      state: this.state,
      handleCreate: this.handleCreate,
      handleChangeLocality: this.handleChangeLocality
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorPlacesActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleCreate, handleChangeLocality, loading, state } = props;
  const history = useHistory();
  const back = history.goBack;
  return (
    <Screen title="Добавить дислокацию">
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleCreate}
        disabled={loading}
        onBack={back}
        direction="row"
        validate={validate}
        initialValues={{
          name: ''
        }}
        parse={(values) => {
          const res = {
            ...values,
            name: values.name || ''
          };
          if (values.locality) {
            res.locality_id = values.locality.id;
            delete res.locality;
          }
          return res;
        }}>
        <Grid item sm={12}>
          <FormTextField source="name" label="Название" disabled={loading} />
        </Grid>

        <Grid item xs={12}>
          <FormSearchGeoLocalityField
            source="locality"
            label="Населенный пункт"
            disabled={loading}
            onChange={handleChangeLocality}
          />
        </Grid>
        <Grid item xs={12}>
          <FormField
            source="geopoint"
            zoom={state.zoom}
            options={state.geopoints}
            component={PlacesMapField}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const ExecutorsPlacesAdd = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ExecutorsPlacesAdd;
