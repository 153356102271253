import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import * as executorActions from 'resources/Executors/actions';
import * as ordersActions from 'resources/Orders/actions';
import Btn from 'components/buttons/Btn';
import { IconBtnClose } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import List from 'components/List';
import { show as showModalConfirm } from 'modals/Confirm';

const useStyles = makeStyles((theme) => ({
  modalDividers: {
    padding: 0
  },
  modalContentRoot: {
    padding: theme.spacing(1),
    '&:first-child': {
      paddingTop: theme.spacing(1)
    }
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const ExecutorUsers = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    // title = "",
    isOpen,
    handleClose = () => null,
    cancelText = 'Закрыть',
    data = {}
  } = props;
  const { executorId, order } = data;
  const [callInProcess, setCallInProcess] = React.useState(false);
  const users = useSelector((state) => state.executors.users);
  const { count = 0, list = [], loading, params = {} } = users;

  const getList = React.useCallback(
    (params = {}) => {
      dispatch(
        executorActions.getExecutorsUsers(executorId, {
          ...params,
          filter: {
            ...params.filter,
            user: { is_active: 'yes' }
          }
        })
      );
    },
    [dispatch, executorId]
  );

  React.useEffect(() => {
    if (executorId) {
      getList();
    }
  }, [executorId, getList]);

  const createPhoneCall = async (user) => {
    const res = await dispatch(
      showModalConfirm({
        title: 'Создать звонок?',
        content: `Данное действие создаст звонок между клиентом ${order?.client?.name} и исполнителем ${user.first_name} ${user.last_name}`
      })
    );
    if (res) {
      try {
        setCallInProcess(true);
        await dispatch(
          ordersActions.orderPhoneCall(order.id, { user_id: user.id })
        );
        setCallInProcess(false);
        handleClose();
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      scroll={'paper'}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth={true}
      maxWidth="md"
      onClose={() => handleClose(false)}>
      <div className={classes.modalHeader}>
        <DialogTitle>Создать звонок?</DialogTitle>
        <IconBtnClose onClick={handleClose} />
      </div>
      <DialogContent
        dividers={true}
        classes={{
          root: classes.modalContentRoot,
          dividers: classes.modalDividers
        }}>
        <List
          getList={getList}
          record={{
            count,
            list,
            loading,
            params
          }}
          toolbar={false}
          // filter={(
          //   <Filter>
          //     <FilterSearchField source="user_id" label="ID" searchPath="user.id" alwaysOn/>
          //     <FilterSearchField source="user_first_name" label="Имя" searchPath="user.first_name"/>
          //     <FilterSearchField source="user_last_name" label="Фамилия" searchPath="user.last_name"/>
          //     <FilterSearchField source="user_phone" label="Тел." searchPath="user.phone"/>
          //   </Filter>
          // )}
        >
          <Field label="ID" source="user.id" sortable />
          <Field label="Имя" source="user.first_name" sortable />
          <Field label="Фамилия" source="user.last_name" sortable />
          <Field label="Тел." source="user.phone" type="phone" />
          <Field source="user" isAction>
            {({ value }) => {
              return (
                <div style={{ whiteSpace: 'nowrap' }}>
                  <Btn
                    ariaLabel="add"
                    color="success"
                    size="small"
                    disabled={callInProcess || !value.phone}
                    onClick={() => createPhoneCall(value)}>
                    Создать
                  </Btn>
                </div>
              );
            }}
          </Field>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(false)}
          color="primary"
          variant="contained">
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExecutorUsers;
