import { actionTypes } from './actions';

const initialState = {
  list: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  }
};

const bookkeeperOrdersNewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST: {
      const { payload = {} } = action;
      const { list = {} } = state;
      return {
        ...state,
        list: {
          ...list,
          ...payload
        }
      };
    }
    default:
      return state;
  }
};

export default bookkeeperOrdersNewReducer;
