import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';

import {
  FilterSearchField,
  FilterSearchGeoLocalityField,
  FilterSearchGeoStreetsField
} from 'components/Filter';

const FilterSearchGeoElasticAddress = (props) => {
  const { filter = {}, searchPath = '', source, label, ...rest } = props;

  const [filterLocalityId, setFilterLocalityId] = React.useState(null);

  useEffect(() => {
    const locality_id =
      filter.locality_id && filter.locality_id.q ? filter.locality_id.q : null;

    setFilterLocalityId(locality_id);
  }, [filter.locality_id, setFilterLocalityId]);

  const handleChangeLocality = (locality_id) => {
    setFilterLocalityId(locality_id);
  };

  return (
    <Grid source={source} label={label} direction="row" container {...rest}>
      <div style={{ flex: 1 }}>
        <FilterSearchGeoLocalityField
          searchPath={searchPath ? searchPath + '.locality_id' : 'locality_id'}
          source="locality_id"
          label="Населенный пункт"
          onChange={handleChangeLocality}
        />
      </div>
      <div style={{ flex: 1 }}>
        <FilterSearchGeoStreetsField
          searchPath={searchPath ? searchPath + '.street_id' : 'street_id'}
          source="street_id"
          where={
            filterLocalityId
              ? { type: 'street', locality_id: filterLocalityId }
              : { type: 'street' }
          }
          label="Улица"
        />
      </div>
      <div style={{ width: 100 }}>
        <FilterSearchField
          searchPath={
            searchPath ? searchPath + '.address_house' : 'address_house'
          }
          source="address_house"
          label="Дом"
        />
      </div>
    </Grid>
  );
};

export default React.memo(FilterSearchGeoElasticAddress);
