import { actionTypes } from './actions';

const initialState = {
  list: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  one: {
    loading: false,
    data: null
  },
  add: {
    loading: false,
    data: null
  },
  methods: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  reasons: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  warehouse: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  messages: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  files: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  clientMessages: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  }
};

const OrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST: {
      const { payload = {} } = action;
      const { list = {} } = state;
      return {
        ...state,
        list: {
          ...list,
          ...payload
        }
      };
    }

    case actionTypes.CLEAR_ONE: {
      return {
        ...state,
        one: {
          loading: false,
          data: null
        }
      };
    }
    case actionTypes.UPDATE_ONE:
    case actionTypes.DELETE_ONE:
    case actionTypes.CREATE_ONE:
    case actionTypes.GET_ONE: {
      const { one = {} } = state;
      const { payload = {} } = action;
      return {
        ...state,
        one: {
          ...one,
          ...payload
        }
      };
    }
    case actionTypes.GET_LIST_METHODS: {
      const { payload = {} } = action;
      const { methods = {} } = state;
      return {
        ...state,
        methods: {
          ...methods,
          ...payload
        }
      };
    }
    case actionTypes.GET_LIST_REASONS: {
      const { payload = {} } = action;
      const { reasons = {} } = state;
      return {
        ...state,
        reasons: {
          ...reasons,
          ...payload
        }
      };
    }
    case actionTypes.GET_LIST_WAREHOUSE: {
      const { payload = {} } = action;
      const { warehouse = {} } = state;
      return {
        ...state,
        warehouse: {
          ...warehouse,
          ...payload
        }
      };
    }
    case actionTypes.GET_LIST_MESSAGES: {
      const { payload = {} } = action;
      const { messages = {} } = state;
      return {
        ...state,
        messages: {
          ...messages,
          ...payload
        }
      };
    }
    case actionTypes.GET_LIST_CLIENT_MESSAGES: {
      const { payload = {} } = action;
      const { clientMessages = {} } = state;
      return {
        ...state,
        clientMessages: {
          ...clientMessages,
          ...payload
        }
      };
    }
    case actionTypes.GET_LIST_FILES: {
      const { payload = {} } = action;
      const { files = {} } = state;
      return {
        ...state,
        files: {
          ...files,
          ...payload
        }
      };
    }
    default:
      return state;
  }
};

export default OrdersReducer;
