import ApiRequest from './ApiRequest';

export default class Orders extends ApiRequest {
  getList(params) {
    return this.get(`/orders`, {
      qs: params
    });
  }

  getOne(id) {
    return this.get(`/orders/${id}`);
  }

  createOne(req) {
    return this.post(`/orders`, {
      body: req
    });
  }

  updateOne(id, req) {
    return this.put(`/orders/${id}`, {
      body: req
    });
  }

  deleteOne(id) {
    return this.delete(`/orders/${id}`);
  }

  getOrderMethods(id, req) {
    return this.get(`/orders/${id}/methods`, {
      qs: req
    });
  }

  createOrderMethod(id, method_id) {
    return this.post(`/orders/${id}/methods/${method_id}`);
  }

  deleteOrderMethod(id, method_id) {
    return this.delete(`/orders/${id}/methods/${method_id}`);
  }

  getOrderReasons(id, req) {
    return this.get(`/orders/${id}/reasons`, {
      qs: req
    });
  }

  createOrderReason(id, method_id) {
    return this.post(`/orders/${id}/reasons/${method_id}`);
  }

  deleteOrderReason(id, method_id) {
    return this.delete(`/orders/${id}/reasons/${method_id}`);
  }

  getOrderWarehouse(id, req) {
    return this.get(`/orders/${id}/warehouse`, {
      qs: req
    });
  }

  createOrderWarehouse(order_id, req) {
    return this.post(`/orders/${order_id}/warehouse`, {
      body: req
    });
  }

  deleteOrderWarehouse(order_id, item_id) {
    return this.delete(`/orders/${order_id}/warehouse/${item_id}`);
  }

  getOrderMessages(id, req) {
    return this.get(`/orders/${id}/messages`, {
      qs: req
    });
  }

  createOrderMessage(order_id, req) {
    return this.post(`/orders/${order_id}/messages`, {
      body: req
    });
  }

  deleteOrderMessage(order_id, item_id) {
    return this.delete(`/orders/${order_id}/messages/${item_id}`);
  }

  getOrderFiles(id, req) {
    return this.get(`/orders/${id}/files`, {
      qs: req
    });
  }

  addOrderFile(order_id, req) {
    return this.post(`/orders/${order_id}/files`, {
      body: req,
      encodeType: 'form'
    });
  }

  deleteOrderFile(order_id, item_id) {
    return this.delete(`/orders/${order_id}/files/${item_id}`);
  }

  getOrderFileToken(order_id, item_id) {
    return this.get(`/orders/${order_id}/files/${item_id}`);
  }

  createOrderPhoneCall(order_id, req) {
    return this.post(`/orders/${order_id}/phone_call`, {
      body: req
    });
  }
}
