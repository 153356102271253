import React from 'react';

import FormSearchGeoField from './FormSearchGeoField';

function renderOptionDefault(opt) {
  if (!opt) {
    return null;
  }
  const { name } = opt;
  return (
    <div>
      <div>{name}</div>
    </div>
  );
}

function getOptionLabel(opt) {
  return opt?.name ?? '';
}

const FormSearchGeoRegionField = ({ where, ...rest }) => {
  return (
    <FormSearchGeoField
      where={{ ...where, type: 'region' }}
      geotype="regions"
      getOptionLabel={getOptionLabel}
      renderOption={renderOptionDefault}
      {...rest}
    />
  );
};

export default FormSearchGeoRegionField;
