import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { MATERIAL_UNIT } from 'constants/config';

import { inChildren, toCoins } from 'services/utils';
import { isPrice } from 'services/validation';

import { FormSelectField, FormTextField, SimpleForm } from 'components/Form';
import Screen from 'components/Screen';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};

  const { name, unit, price = '' } = values;

  if (!name) {
    errors.name = 'Обязательное поле';
  }

  if (!price) {
    errors.price = 'Укажите цену';
  } else if (!isPrice(price)) {
    errors.price = 'Невалидная цена';
  }

  if (!unit) {
    errors.unit = 'Обязательное поле';
  }

  return errors;
};

const formName = 'ADD_MATERIAL';

class Container extends Component {
  handleCreate = async (data) => {
    const { loading, actions, dispatch, history = {} } = this.props;
    if (loading) return;

    try {
      await actions.createOne(data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(formName, { name: 'Уже существует' }));
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleCreate: this.handleCreate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleCreate, loading } = props;
  const history = useHistory();
  const back = history.goBack;
  return (
    <Screen title="Добавить материал">
      <SimpleForm
        direction="row"
        loading={loading}
        form={formName}
        onSubmit={handleCreate}
        disabled={loading}
        onBack={back}
        validate={validate}
        initialValues={{
          name: '',
          price: '0',
          description: ''
        }}
        parse={(values) => {
          const res = {
            ...values,
            price: values.price >= 0 ? toCoins(values.price) : 0
          };
          return res;
        }}>
        <Grid item xs={12} sm={6}>
          <FormTextField source="name" label="Название" disabled={loading} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormSelectField
            source="unit"
            label="Единица измерения"
            disabled={loading}
            options={Object.entries(MATERIAL_UNIT).map(([key, data]) => ({
              value: key,
              label: `${data.label} (${data.short})`
            }))}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormTextField
            source="price"
            label="Цена"
            disabled={loading}
            type="number"
            inputProps={{
              step: '0.01'
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormTextField
            source="description"
            label="Описание"
            disabled={loading}
            multiline={true}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const Add = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default Add;
