import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import { BackBtn, DeleteBtn, EditBtn } from 'components/buttons/Btn';
import FooterBar from 'components/FooterBar';
import NotFoundItems from 'components/NotFoundItems';
import Progress from 'components/Progress';
import Rbac, { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import Tabs, { Tab } from 'components/Tabs';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';
import ShowGroupInfo from './ShowGroupInfo';
import ShowGroupUsers from './ShowGroupUsers';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false
    };
  }

  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const { match: { params: { id } = {} } = {}, groupsActions } = this.props;
    try {
      await groupsActions.getOne(id);
    } catch (err) {
      this.setState({ notFound: true });
    }
  };

  handleDelete = async () => {
    const { groupsActions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить группу?'
      })
    );

    if (res) {
      try {
        await groupsActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      notFound: this.state.notFound,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    record: one.data,
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  groupsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleDelete,
    record = {},
    loading,
    match = {},
    basePath = '/',
    notFound
  } = props;

  const [tab, setTab] = React.useState(match.url);
  const itemReceived = !!(record && record.id);
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Группа: ${record.name}`;
  }

  const is_writable = useCan('groups:write');

  return (
    <Screen title={title}>
      {loading && <Progress />}
      {notFound && <NotFoundItems message="Группа не найдена" />}
      {itemReceived && (
        <Tabs value={tab} onChange={(path) => setTab(path)}>
          <Tab label="Инфо" eventKey={match.url}>
            <Box p={2}>
              <ShowGroupInfo {...props} />
            </Box>
          </Tab>
          <Tab label="Пользователи" eventKey={`${match.url}/users`}>
            <Rbac operation="users:read">
              <ShowGroupUsers group_id={record.id} />
            </Rbac>
          </Tab>
        </Tabs>
      )}
      <FooterBar>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <BackBtn onClick={back}>Назад</BackBtn>
            </Grid>
            <Grid item>
              {itemReceived && is_writable && (
                <EditBtn to={`${basePath}/edit/${record.id}`}>
                  Редактировать
                </EditBtn>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {itemReceived && is_writable && (
            <DeleteBtn onClick={handleDelete}>Удалить</DeleteBtn>
          )}
        </Grid>
      </FooterBar>
    </Screen>
  );
};

const Show = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default Show;
