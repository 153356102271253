import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import { makeStyles } from '@material-ui/core/styles';

import { DATE_FORMAT } from 'constants/config';

import { parseHistory } from 'services/historyUtils';
import { useMakeUrlProjectId } from 'services/projectUtils';
import Query from 'services/Query';
import { inChildren } from 'services/utils';

import Field from 'components/Field';
import Filter, {
  FilterDateRangeField,
  FilterSearchElasticClientField,
  FilterSearchField,
  FilterSearchGeoElasticAddress
} from 'components/Filter';
import HistoryInfo from 'components/HistoryInfo';
import List from 'components/List';
import Screen from 'components/Screen';

import * as actions from './actions';

const useItemStyles = makeStyles((theme) => ({
  label: {
    marginRight: 5,
    fontWeight: 'bold'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 24
  },
  valuesContent: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  orderField: {
    minWidth: 250,
    '@media(minWidth: 780px)': {
      minWidth: 'auto'
    }
  },
  field: {
    paddingTop: 15,
    paddingBottom: 15
  },
  changesField: {
    paddingRight: 5,
    paddingLeft: 5
  }
}));

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { actions } = this.props;
      actions.getList(params);
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleDelete: this.handleDelete,
      handleShowCopy: this.handleShowCopy
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;

  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { record } = props;

  const { params = {} } = record;

  const { filter = {} } = params;

  const makePath = useMakeUrlProjectId();
  const orderOnePath = makePath('/orders');

  const classes = useItemStyles();

  return (
    <Screen title="История изменения заявок">
      <List
        {...props}
        rowLink={(row) => `${orderOnePath}/show/${row.order.id}`}
        filter={
          <Filter>
            <FilterSearchField source="id" label="ID" alwaysOn searchType="" />
            <FilterDateRangeField
              source="date_from"
              labels={['Заявка,с', 'Заявка,по']}
              alwaysOn
              maxWidth={275}
              searchPath="order.date_from"
            />
            <FilterDateRangeField
              source="created_at"
              labels={['Изменен,с', 'Изменен,по']}
              label="Дата изменения"
              maxWidth={300}
            />
            <FilterSearchElasticClientField
              source="client_id"
              alwaysOn
              label="Клиент"
              searchPath="order.client_id"
            />
            <FilterSearchGeoElasticAddress
              source="locality_id,street_id,address_house"
              label="Адрес"
              filter={filter}
              maxWidth={600}
              searchPath="order"
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable className={classes.field} />
        <Field
          label="Заказ"
          source="action_type,order,created_at"
          className={clsx(classes.field, classes.orderField)}
          format={(item) => {
            if (!item) return '';
            const address_arr = [];
            if (item.order) {
              if (item.order.locality && item.order.locality)
                address_arr.push(item.order.locality.name);
              if (item.order.street && item.order.street.name)
                address_arr.push(item.order.street.name);
              if (item.order.address_house && item.order.address_house)
                address_arr.push(item.order.address_house);
              if (
                item.order.address_appartment &&
                item.order.address_appartment
              )
                address_arr.push(item.order.address_appartment);
            }
            const address = address_arr.join(' ');
            return (
              <>
                {item.order && item.order.id && (
                  <div className={classes.valuesContent}>
                    <div className={classes.label}>Заявка №:</div>
                    <div className={classes.valuesContent}>{item.order.id}</div>
                  </div>
                )}
                {item.order && item.order.client && item.order.client.name && (
                  <div>{item.order.client.name}</div>
                )}
                {item.order && item.order.client && item.order.client.phone && (
                  <div>{item.order.client.phone}</div>
                )}
                {address && <div>{address}</div>}
                {item.order && item.order.date_from && (
                  <div className={classes.valuesContent}>
                    <div className={classes.label}>Дата заказа:</div>
                    <div className={classes.valuesContent}>
                      {moment(item.order.date_from).format(DATE_FORMAT)}
                    </div>
                  </div>
                )}
                {item.created_at && (
                  <div className={classes.valuesContent}>
                    <div className={classes.label}>Дата изменения:</div>
                    <div className={classes.valuesContent}>
                      {moment(item.created_at).format(DATE_FORMAT)}
                    </div>
                  </div>
                )}
                <div className={classes.valuesContent}>
                  <div className={classes.label}>Тип операции:</div>
                  <div className={classes.valuesContent}>
                    {item.action_type.trim()}
                  </div>
                </div>
              </>
            );
          }}
        />
        <Field
          label="Изменения"
          className={clsx(classes.field, classes.changesField)}
          source="changes"
          format={(item) => {
            const parsed = parseHistory(item, 'orders');
            return (
              <>
                {parsed && (
                  <HistoryInfo
                    items={parsed.items}
                    type={parsed.type}
                    item_type="orders"
                    devideByColumns={true}
                  />
                )}
              </>
            );
          }}></Field>
      </List>
    </Screen>
  );
};

const OrdersHistoryList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default OrdersHistoryList;
