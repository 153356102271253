import { v4 as uuid } from 'uuid';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || uuid()
    }
  };
};

export const closeSnackbar = (key) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key
});

export const removeSnackbar = (key) => ({
  type: REMOVE_SNACKBAR,
  key
});

export const showSnackbarError =
  (notification = {}) =>
  (dispatch) => {
    const { options } = notification;
    dispatch(
      enqueueSnackbar({
        ...notification,
        options: {
          ...options,
          variant: 'error'
        }
      })
    );
  };

export const showSnackbarSuccess =
  (notification = {}) =>
  (dispatch) => {
    const { options } = notification;
    dispatch(
      enqueueSnackbar({
        ...notification,
        options: {
          ...options,
          variant: 'success'
        }
      })
    );
  };

export const showSnackbarWarning =
  (notification = {}) =>
  (dispatch) => {
    const { options } = notification;
    dispatch(
      enqueueSnackbar({
        ...notification,
        options: {
          ...options,
          variant: 'warning'
        }
      })
    );
  };

export const showSnackbarInfo =
  (notification = {}) =>
  (dispatch) => {
    const { options } = notification;
    dispatch(
      enqueueSnackbar({
        ...notification,
        options: {
          ...options,
          variant: 'info'
        }
      })
    );
  };
