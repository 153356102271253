import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { executors as resourceApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';
import { showSnackbarError } from 'modules/notifier';

export const actionTypes = {
  GET_LIST: 'EXECUTOR_AVAILABLE_GET_LIST',
  GET_ONE: 'EXECUTOR_AVAILABLE_GET_ONE',
  CLEAR_ONE: 'EXECUTOR_AVAILABLE_CLEAR_ONE',

  EXECUTOR_AVAILABLE_ONE_LOADING: 'EXECUTOR_AVAILABLE_ONE_LOADING'
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

export const setLoading = (loading) => ({
  type: actionTypes.EXECUTOR_AVAILABLE_ONE_LOADING,
  loading
});

export const getList = (param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } =
      await resourceApi.getAvailableList(request);

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const getOne = (available_id) => async (dispatch, getState) => {
  const actionType = actionTypes.GET_ONE;

  const payload = { loading: true };
  const oldItem = getState().executorsAvailable.one.data;
  if (oldItem && available_id !== oldItem.id) {
    payload.data = null;
  }
  dispatch(updateRedux(actionType, payload));

  try {
    const res = await resourceApi.getAvailableOne(available_id);
    dispatch(
      updateRedux(actionType, {
        data: res,
        loading: false
      })
    );
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Не найдено' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const clearOne = () => {
  const actionType = actionTypes.CLEAR_ONE;
  return {
    type: actionType
  };
};
