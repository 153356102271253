import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Query from 'services/Query';
import { inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import { IconBtnDelete, IconBtnEdit } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterDateRangeField,
  FilterSearchField,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { userActions } = this.props;
      userActions.getList(params);
    }
  };

  handleDelete = async (id) => {
    const { userActions, record, dispatch } = this.props;
    const { params = {}, list = [] } = record;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Вы уверены что хотите удалить пользователя?'
      })
    );

    if (res) {
      try {
        await userActions.deleteOne(id);
        const { offset = 0, limit = 0 } = params;
        if (offset && list.length === 1) {
          params.offset = offset - limit;
        }
        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  userActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleDelete, basePath = '/' } = props;

  const isCanCreateUser = useCan('users:create');
  const isCanUpdateUser = useCan('users:update');
  const isCanDeleteUser = useCan('users:delete');

  return (
    <Screen title="Пользователи">
      <List
        {...props}
        rowLink={(row) => `${basePath}/show/${row.id}`}
        toolBarInner={isCanCreateUser && <AddBtn to={`${basePath}/add`} />}
        filter={
          <Filter>
            <FilterSearchField source="login" label="Логин" alwaysOn="yes" />
            <FilterSearchField source="email" label="E-mail" />
            <FilterSearchField source="first_name" label="Имя" />
            <FilterSearchField source="last_name" label="Фамилия" />
            <FilterSearchField source="phone" label="Тел." />
            <FilterSelectField
              source="is_active"
              label="Активен"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Активен' },
                { value: 'no', label: 'Не активен' }
              ]}
            />
            <FilterDateRangeField
              source="created_at"
              labels={['Создан,с', 'Создан,по']}
              label="Дата создания"
              maxWidth={275}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field label="Логин" source="login" sortable />
        <Field label="E-mail" source="email" />

        <Field label="Имя" source="first_name" sortable />
        <Field label="Фамилия" source="last_name" sortable />
        <Field label="Тел." source="phone" type="phone" />

        <Field
          label="Активен"
          source="is_active"
          format={(v) => v === 'yes'}
          type="boolean"
        />
        <Field
          label="Дата, создания"
          source="created_at"
          type="date"
          sortable
        />
        <Field source="id" isAction>
          {({ value }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {isCanUpdateUser && (
                  <IconBtnEdit to={`${basePath}/edit/${value}`} />
                )}
                {isCanDeleteUser && (
                  <IconBtnDelete onClick={() => handleDelete(value)} />
                )}
              </div>
            );
          }}
        </Field>
      </List>
    </Screen>
  );
};

const UsersList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default UsersList;
