import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { AVAILABLE_TYPES } from 'constants/config';

import { IconBtnClose } from 'components/buttons/IconBtn';
import Form, { FormDateField, FormSelectField } from 'components/Form';
import Progress from 'components/Progress';

const available_types = Object.keys(AVAILABLE_TYPES).map((type) => ({
  label: AVAILABLE_TYPES[type],
  value: type
}));

const validate = (values) => {
  const errors = {};
  const { date_from, date_to } = values;
  if (!date_from) errors.date_from = 'Укажите дату';
  if (!date_to) errors.date_to = 'Укажите дату';

  if (date_from && date_to) {
    if (moment(date_from).isSameOrAfter(moment(date_to), 'minute')) {
      errors.date_to = 'Должна быть больше даты, с';
    }
  }

  return errors;
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 10
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const AvailableDialog = (props) => {
  const classes = useStyles();
  const {
    title = '',
    isOpen,
    handleClose = () => null,
    successText = 'Сохранить',
    cancelText = 'Закрыть',
    handleSubmit = () => null,
    initialValues = null
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [available, setAvailable] = React.useState(null);

  React.useEffect(() => {
    if (!available && initialValues) setAvailable(initialValues.is_available);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  React.useEffect(() => {
    if (!isOpen) setAvailable(null);
  }, [isOpen]);

  const onSubmit = async (params) => {
    if (loading) return;
    setLoading(true);
    await handleSubmit(params);
    if (isOpen) setLoading(false);
  };

  return (
    <Dialog
      open={isOpen}
      scroll={'paper'}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth={true}
      maxWidth="sm"
      // onClose={() => handleClose(false)}
    >
      <Form
        name="available-executor"
        onSubmit={onSubmit}
        initialValues={{
          is_available: 'no',
          ...initialValues
        }}
        parse={(values) => {
          return {
            is_available: values.is_available,
            date_to: values.date_to,
            date_from: values.date_from,
            available_type:
              values.is_available === 'yes'
                ? null
                : values.available_type || null
          };
        }}
        validate={validate}>
        <div className={classes.modalHeader}>
          <DialogTitle>{title}</DialogTitle>
          <IconBtnClose onClick={handleClose} />
        </div>
        <DialogContent dividers={true}>
          {loading && <Progress variant="over" />}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} className={classes.formControl}>
              <FormSelectField
                source="is_available"
                label="Отсутствие"
                disabled={loading}
                onChange={(v) => setAvailable(v)}
                options={[
                  { value: 'no', label: 'Отсутствует' },
                  { value: 'yes', label: 'Доступен' }
                ]}
              />
            </Grid>
            {available !== 'yes' && (
              <Grid item xs={12} sm={6} className={classes.formControl}>
                <FormSelectField
                  source="available_type"
                  label="Причина отсутствия"
                  disabled={loading}
                  options={[
                    { value: null, label: 'Не указана' },
                    ...available_types
                  ]}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} className={classes.formControl}>
                  <FormDateField
                    label="Дата, с"
                    source="date_from"
                    dateType="datetime"
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formControl}>
                  <FormDateField
                    label="Дата, по"
                    source="date_to"
                    dateType="datetime"
                    disabled={loading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{cancelText}</Button>
          <Button type="submit" color="primary" variant="contained">
            {successText}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default AvailableDialog;
