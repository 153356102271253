import React from 'react';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const useItemStyles = makeStyles((theme) => ({
  label: {
    marginRight: 15,
    fontWeight: 'bold'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 24
  },
  valuesContent: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  callTitle: {
    fontWeight: 'bold'
  }
}));

const HistoryInfo = (props) => {
  const {
    items,
    type,
    devideByColumns = false,
    contentClassName = '',
    labelClassName = '',
    valuesClassName = '',
    callTitleClassName = ''
  } = props;

  const classes = useItemStyles();

  return (
    <Grid container>
      {items.map(({ values, label }, index) => (
        <Grid
          item
          xs={12}
          md={devideByColumns ? 6 : 12}
          lg={devideByColumns ? 4 : 12}
          key={'g' + index}>
          {type === 'phone_call' ? (
            <div className={clsx(classes.callTitle, callTitleClassName)}>
              Звонок:
            </div>
          ) : null}
          <div className={clsx(classes.content, contentClassName)}>
            <div className={clsx(classes.label, labelClassName)}>{label}:</div>
            <div className={clsx(classes.valuesContent, valuesClassName)}>
              {type === 'diff' ? (
                <>
                  {!!values[0] && (
                    <>
                      <span>{values[0]}</span>
                      <ArrowRightAltIcon />
                    </>
                  )}
                  <span>{values[1]}</span>
                </>
              ) : (
                <span>{values}</span>
              )}
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default HistoryInfo;
