import ApiRequest from './ApiRequest';

export default class Projects extends ApiRequest {
  getList(params) {
    return this.get(`/projects`, {
      qs: params
    });
  }
  createOne(req) {
    return this.post(`/projects`, {
      body: req
    });
  }
  getOne(id) {
    return this.get(`/projects/${id}`);
  }
  updateOne(id, req) {
    return this.put(`/projects/${id}`, {
      body: req
    });
  }
  deleteOne(id) {
    return this.delete(`/projects/${id}`);
  }
  getUsersList(id, req) {
    return this.get(`/projects/${id}/users`, {
      qs: req
    });
  }
  attachUser(id, user_id) {
    return this.post(`/projects/${id}/users/${user_id}`);
  }
  detachUser(id, user_id) {
    return this.delete(`/projects/${id}/users/${user_id}`);
  }
}
