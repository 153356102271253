import React from 'react';

import Grid from '@material-ui/core/Grid';

import { concatGeoFullName } from 'services/utils';

import { LabelField } from 'components/Field';

const GroupInfo = (props) => {
  const { record = {} } = props;
  return (
    <div>
      {record && record.id ? (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <LabelField width={150} label="Имя:" value={record.name} />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={150}
              label="Пользователь:"
              value={record.owner}
              format={(owner) => {
                if (!owner) return '';
                return `${owner.first_name} ${owner.last_name}`;
              }}
            />
          </Grid>
          <Grid item>
            <LabelField
              width={150}
              label="Активен:"
              value={record.is_active}
              type="boolean"
              format={(v) => v === 'yes'}
            />
          </Grid>
          <Grid item>
            <LabelField width={150} label="E-mail:" value={record.email} />
          </Grid>
          <Grid item>
            <LabelField
              width={150}
              label="Тел.:"
              value={record.phone}
              type="phone"
            />
          </Grid>
          <Grid item>
            <LabelField
              width={150}
              label="Дислокация:"
              value={record.place}
              format={(value) => {
                if (!value) return '';
                return <div>{value.name}</div>;
              }}
            />
          </Grid>
          <Grid item>
            <LabelField
              width={150}
              label="Населенный пункт:"
              value={record.locality}
              format={concatGeoFullName}
            />
          </Grid>
          <Grid item>
            <LabelField
              width={150}
              label="Автопробег:"
              type="number"
              value={record.total_mileage}
            />
          </Grid>
          <Grid item>
            <LabelField width={150} label="Сортировка:" value={record.sort} />
          </Grid>
          <Grid item>
            <LabelField
              width={150}
              label="Дата:"
              value={record.created_at}
              type="date"
            />
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default GroupInfo;
