import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { AVAILABLE_TYPES } from 'constants/config';

import { inChildren } from 'services/utils';

import * as executorsActions from 'resources/Executors/actions';
import { CopyBtn } from 'components/buttons/Btn';
import {
  FormDateField,
  FormSearchElasticExecutorField,
  FormSelectField,
  SimpleForm
} from 'components/Form';
import Rbac from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const available_types = Object.keys(AVAILABLE_TYPES).map((type) => ({
  label: AVAILABLE_TYPES[type],
  value: type
}));

const validate = (values) => {
  const errors = {};
  const { date_from, date_to, executor } = values;
  if (!date_from) errors.date_from = 'Укажите дату';
  if (!date_to) errors.date_to = 'Укажите дату';

  if (date_from && date_to) {
    if (moment(date_from).isSameOrAfter(moment(date_to), 'minute')) {
      errors.date_to = 'Должна быть больше даты, с';
    }
  }

  if (!executor) {
    errors.executor = 'Укажите дезинфектора';
  }
  return errors;
};

const formName = 'edit_executor_available';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  componentWillUnmount() {
    const { executorsAvailableActions } = this.props;
    executorsAvailableActions.clearOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      executorsAvailableActions,
      history = {}
    } = this.props;
    try {
      await executorsAvailableActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (params) => {
    const {
      executorsActions,
      executorsAvailableActions,
      record = {},
      history
    } = this.props;
    const { id: available_id } = record;
    const { executor_id, ...restData } = params;
    try {
      executorsAvailableActions.setLoading(true);
      await executorsActions.updateExecutorAvailable(
        executor_id,
        available_id,
        restData
      );
      executorsAvailableActions.setLoading(false);
      history.goBack();
    } catch (err) {
      executorsAvailableActions.setLoading(false);
    }
  };

  handleDelete = async () => {
    const {
      record = {},
      otherActions,
      executorsActions,
      executorsAvailableActions,
      history
    } = this.props;

    const res = await otherActions.showModalConfirm({
      title: 'Удаление',
      content: 'Удалить запись?'
    });

    if (res) {
      try {
        const { executor = {}, id: available_id } = record;
        const { id: executor_id } = executor;
        executorsAvailableActions.setLoading(true);
        await executorsActions.deleteExecutorAvailable(
          executor_id,
          available_id
        );
        executorsAvailableActions.setLoading(false);
        history.goBack();
      } catch (err) {
        executorsAvailableActions.setLoading(false);
      }
    }
  };

  handleShowCopy = () => {
    const { history, location, basePath, record = {} } = this.props;
    if (!record || !record.id) return;
    let from = location.pathname;
    if (location.search) from += location.search;
    history.push({
      pathname: `${basePath}/add`,
      state: { from, copy_id: record.id }
    });
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete,
      handleShowCopy: this.handleShowCopy
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorsActions: bindActionCreators(executorsActions, dispatch),
  executorsAvailableActions: bindActionCreators(actions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleUpdate,
    handleDelete,
    handleShowCopy,
    loading,
    record = {}
  } = props;

  const history = useHistory();
  const back = history.goBack;
  const [available, setAvailable] = React.useState(null);

  const itemReceived = record && record.id;

  React.useEffect(() => {
    if (!available && record) setAvailable(record.is_available);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record]);

  return (
    <Screen title="Редактировать отсутствие">
      <Box p={1}>
        {itemReceived && (
          <Grid
            container
            spacing={1}
            wrap="wrap"
            alignItems="center"
            justifyContent="flex-end">
            <Rbac operation="available:write">
              <Grid item>
                <CopyBtn onClick={handleShowCopy}>Дубликат</CopyBtn>
              </Grid>
            </Rbac>
          </Grid>
        )}
      </Box>
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        onBack={back}
        onDelete={record && record.id ? handleDelete : undefined}
        direction="row"
        validate={validate}
        initialValues={record}
        parse={(values) => {
          const res = { ...values };
          delete res.executor;
          res.executor_id = values.executor.id;
          res.available_type =
            values.is_available === 'yes'
              ? null
              : values.available_type || null;
          return res;
        }}>
        <Grid item xs={12} sm={6}>
          <FormSearchElasticExecutorField
            source="executor"
            label="Дезинфектор"
            is_active="yes"
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} sm={6}>
          <FormSelectField
            source="is_available"
            label="Отсутствие"
            disabled={loading}
            onChange={(v) => setAvailable(v)}
            options={[
              { value: 'no', label: 'Отсутствует' },
              { value: 'yes', label: 'Доступен' }
            ]}
          />
        </Grid>
        {available !== 'yes' && (
          <Grid item xs={12} sm={6}>
            <FormSelectField
              source="available_type"
              label="Причина отсутствия"
              disabled={loading}
              options={[
                { value: null, label: 'Не указана' },
                ...available_types
              ]}
            />
          </Grid>
        )}
        <Grid item xs={12} />
        <Grid item xs={12} sm={6}>
          <FormDateField
            label="Дата, с"
            source="date_from"
            dateType="datetime"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormDateField
            label="Дата, по"
            source="date_to"
            dateType="datetime"
            disabled={loading}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const ExecutorsAvailableEdit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ExecutorsAvailableEdit;
