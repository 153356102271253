import React from 'react';

import { concatGeoFullName } from 'services/utils';

import FormSearchGeoField from './FormSearchGeoField';

function renderOptionDefault(opt) {
  const { name } = opt;

  return (
    <div>
      <div>{name}</div>
      <div style={{ fontSize: '0.75em' }}>{concatGeoFullName(opt, [0, 1])}</div>
    </div>
  );
}

function getOptionLabel(opt) {
  const { name, full_name } = opt;

  let result = name;
  if (full_name) {
    const arr = JSON.parse(full_name);
    result = `${arr[2]} (${concatGeoFullName(opt, [0, 1])})`;
  }

  return result || '';
}

const FormSearchGeoLocalityField = ({ where, ...rest }) => {
  return (
    <FormSearchGeoField
      where={{ ...where, type: 'locality' }}
      geotype="locality"
      getOptionLabel={getOptionLabel}
      renderOption={renderOptionDefault}
      {...rest}
    />
  );
};

export default FormSearchGeoLocalityField;
