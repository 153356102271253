import { actionTypes } from './actions';

const initialState = {
  list: {
    loading: false,
    list: [],
    params: {},
    count: 0
  }
};

const NotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST: {
      const { payload = {} } = action;
      const { list = {} } = state;
      return {
        ...state,
        list: {
          ...list,
          ...payload
        }
      };
    }
    case actionTypes.READ_NOTIF: {
      const { payload = {} } = action;
      const { id, is_read } = payload;
      const { list = {} } = state;
      const { list: notifList = [] } = list;
      return {
        ...state,
        list: {
          ...list,
          list: notifList.map((notif) => {
            if (notif.id === id) return { ...notif, is_read };
            return notif;
          })
        }
      };
    }
    default:
      return state;
  }
};

export default NotificationsReducer;
