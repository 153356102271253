import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { inChildren, validateErrors } from 'services/utils';

import { FormTextField, SimpleForm } from 'components/Form';
import Progress from 'components/Progress';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};
  const { name } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле'
  });

  return errors;
};

const formName = 'update_project';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      projectsActions,
      history = {}
    } = this.props;
    try {
      await projectsActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const {
      projectsActions,
      record = {},
      loading,
      history,
      dispatch
    } = this.props;
    if (loading) return;
    try {
      await projectsActions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(formName, { name: 'Уже существует' }));
      }
    }
  };

  handleDelete = async () => {
    const { projectsActions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Вы уверены что хотите удалить проект?'
      })
    );

    if (res) {
      try {
        await projectsActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  projectsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleUpdate, handleDelete, record = {}, loading } = props;

  const is_writable = useCan('projects:write');

  const itemReceived = record && record.id;
  const history = useHistory();
  const back = history.goBack;
  let title = 'Редактировать проект:';
  if (itemReceived) {
    title = `Редактировать проект #${record.id}: ${record.name}`;
  }

  return (
    <Screen title={title}>
      {loading && !itemReceived && <Progress />}
      <SimpleForm
        loading={loading}
        form={formName}
        disabled={loading}
        maxWidth="sm"
        onBack={back}
        validate={validate}
        onSubmit={handleUpdate}
        onDelete={is_writable && handleDelete}
        initialValues={record}>
        <Grid item>
          <FormTextField source="name" label="Название" disabled={loading} />
        </Grid>

        <Grid item>
          <FormTextField
            source="comment"
            label="Примечание"
            disabled={loading}
            multiline={true}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const ProjectsEdit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ProjectsEdit;
