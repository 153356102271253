import React from 'react';

import { TextField as TextFieldUI } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

const TextField = ({ input = {}, meta = {}, ...attributes }) => {
  const { helperText = '', inputProps, style = {}, ...restAttrs } = attributes;
  const { touched, error } = meta;
  const visibleError = touched && error;
  const value = input.value;
  return (
    <FormControl error={!!visibleError} fullWidth style={style}>
      <TextFieldUI
        autoComplete="off"
        error={!!visibleError}
        {...input}
        value={value}
        {...restAttrs}
        helperText={visibleError || helperText}
        inputProps={{
          maxLength: '1024',
          ...inputProps
        }}
      />
    </FormControl>
  );
};

export default TextField;
