import React from 'react';
import { Field } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

const FormNumberField = (props) => {
  const { source, ...rest } = props;

  const numberformat = (v, prev) => {
    if (!v) return v;
    if (!/^[0-9]+$/.test(v)) return prev;
    return v;
  };

  return (
    <Field
      component={TextField}
      name={source}
      normalize={numberformat}
      {...rest}
    />
  );
};

export default FormNumberField;

const TextField = ({ input = {}, meta = {}, ...attributes }) => {
  const { helperText = '', label = '', ...restAttrs } = attributes;
  const { touched, error } = meta;
  const visibleError = touched && error;

  return (
    <FormControl error={!!visibleError} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Input
        {...input}
        autoComplete="off"
        error={!!visibleError}
        maxLength="1024"
        {...restAttrs}
      />
      {(visibleError || helperText) && (
        <FormHelperText>{visibleError || helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
