import React from 'react';
import { Field, submit } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { request } from 'services/api';
// import { styles } from '@material-ui/pickers/views/Calendar/Calendar';

function FormSearchElasticField(props) {
  const { source, searchPath = '', searchType = '', ...rest } = props;

  const additional = {};

  if (searchType || searchPath) {
    additional.format = (obj = {}) => {
      return obj.q || '';
    };

    additional.parse = (v) => {
      return {
        q: v,
        qt: searchType,
        qp: searchPath
      };
    };
  }

  return (
    <Field
      component={SelectComponent}
      name={source}
      {...rest}
      {...additional}
    />
  );
}

export default FormSearchElasticField;

const useStyles = makeStyles((theme) => ({
  fullWidth: { width: '100%' }
}));

const renderOptionDefault = (opt) => opt.id;
const _getOptionLabel = (opt) => (opt && opt.id ? String(opt.id) : '');

function SelectComponent({ input = {}, meta = {}, ...attrs }) {
  const {
    label = ' ',
    noOptionsText = '',
    loadingText = 'Загрузка...',
    disabled,
    responseFormat,
    requestPath = '',
    renderOption = renderOptionDefault,
    getOptionLabel = _getOptionLabel,
    helperText = '',
    searchBy,
    where = {},
    submitOnChange,
    forced,
    disableClearable = false,
    customOption,
    variant = 'standard',
    inputStyle = {},
    ...restAttrs
  } = attrs;

  const classes = useStyles(restAttrs);
  const visibleError = meta.touched && meta.error;

  const [open, setOpen] = React.useState(false);
  const [q, setQ] = React.useState('');
  const [options, setOptions] = React.useState(null);
  const [timer, setTimer] = React.useState(null);
  const [userVal, setUserValue] = React.useState(null);

  const getData = async () => {
    try {
      let response = await request.get(`${requestPath}/_search`, {
        qs: { q, where }
      });
      return response.hits.map((it) => it._source);
    } catch (err) {
      return [];
    }
  };

  React.useEffect(() => {
    if (!open) {
      setOptions(null);
      setQ('');
      return undefined;
    }
    let active = true;
    if (timer) clearTimeout(timer);
    const currenTimer = setTimeout(async () => {
      const result = await getData();
      if (active) {
        setOptions(result);
      }
    }, 100);
    setTimer(currenTimer);
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, open]);

  React.useEffect(() => {
    const init_val =
      meta.initial && meta.initial.q ? meta.initial.q : meta.initial;
    if (
      forced &&
      !userVal &&
      init_val &&
      customOption &&
      init_val === customOption.id
    ) {
      input.onChange(customOption.id);
      setUserValue(customOption);
      return;
    }
    if (forced && !userVal && init_val && Number(init_val)) {
      let active = true;
      (async () => {
        try {
          let response = await request.get(`${requestPath}/${init_val}`);
          if (active) {
            if (!forced) input.onChange(response.id);
            setUserValue(response);
          }
        } catch (err) {
          _onChange(null, null);
        }
      })();
      return () => {
        active = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.initial]);

  const _onChange = (event, option) => {
    if (disabled) return;
    let target = null;

    if (option) target = forced ? option.id : option;
    input.onChange(target);
    if (forced) setUserValue(option);
    setOpen(false);
    setTimeout(() => {
      if (submitOnChange) meta.dispatch(submit(meta.form));
    }, 0);
  };

  const _onInputChange = (event) => {
    if (event && event.target) setQ(event.target.value);
  };

  // const _onBlur = () => {
  //   if(forced)input.onBlur(userVal);
  // }

  let targetOptions = options || [];
  if (customOption) targetOptions = [customOption].concat(targetOptions);
  const loading = open && !options;
  const t = forced ? userVal : input.value;
  let inputValue = t ? getOptionLabel(t) : '';
  if (open) inputValue = q;

  return (
    <Autocomplete
      classes={{
        root: classes.fullWidth
      }}
      filterOptions={(o) => o}
      noOptionsText={noOptionsText}
      options={targetOptions}
      open={!disabled && open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loadingText={loadingText}
      loading={loading}
      disableClearable={disableClearable}
      freeSolo={true}
      onChange={_onChange}
      inputValue={inputValue}
      onInputChange={_onInputChange}
      value={input.value}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      renderInput={(params) => {
        return (
          <FormControl
            className={classes.formControl}
            error={!!visibleError}
            fullWidth>
            <TextField
              variant={variant}
              {...params}
              fullWidth
              label={label}
              error={!!visibleError}
              InputProps={{
                style: inputStyle,
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
            />
            {(visibleError || helperText) && (
              <FormHelperText>{visibleError || helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}
