import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { history as resourceApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';

const REDUX_PREFIX = 'ORDERS_HISTORY';

export const actionTypes = {
  GET_LIST: `${REDUX_PREFIX}_GET_LIST`,
  GET_ONE: `${REDUX_PREFIX}_GET_ONE`
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

export const getList = (param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } = await resourceApi.getOrdersList(
      request
    );
    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

// export const getOne = (id) => async (dispatch, getState) => {
//     const actionType = actionTypes.GET_ONE;
//     const payload = {loading: true};
//     const oldItem = getState().orders_history.one.data;
//     if (oldItem && Number(id) !== oldItem.id) {
//       payload.data = null;
//     }
//     dispatch(updateRedux(actionType, payload));

//     try{
//       const res = await resourceApi.getOne(id);
//       dispatch(updateRedux(actionType, {
//         data: res,
//         loading: false,
//       }));
//       return Promise.resolve(res);
//     } catch (err) {
//       dispatch(updateRedux(actionType, {loading: false}));
//       if (err && err.statusCode === 404) {
//         dispatch(showSnackbarError({message: 'Не найдено'}));
//       } else {
//         dispatch(ErrorService.dispatchError(err));
//       }
//       return Promise.reject(err);
//     }
//   }
