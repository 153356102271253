import React from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { IconBtnClose } from 'components/buttons/IconBtn';
import Form from 'components/Form';

import { FilterContext } from './FilterProvider';

const Filter = (props) => {
  const {
    direction = 'row',
    spacing = 2,
    justifyContent = 'flex-start',
    children,
    onFilter = () => null,
    enableReinitialize = true,
    initialValues = {},
    ...rest
  } = props;

  const [init, setInit] = React.useState(false);
  const context = React.useContext(FilterContext);
  const dispatch = useDispatch();
  const { formName, unvisibleSources = [] } = context;

  React.useEffect(() => {
    const { registredSources, registerFilters } = context;
    const unregistred = {};

    let isset = false;
    React.Children.forEach(children, (child) => {
      if (!child) return;
      const { source, alwaysOn } = child.props;

      if (!source) return;
      const isCombo = source.indexOf('.') !== -1;
      const isMultifield = source.indexOf(',') !== -1;
      if (isCombo) {
        const sources = source.split('.');
        if (registredSources.indexOf(source) === -1) {
          isset = true;

          let unvisible = true;
          sources.forEach((it) => {
            if (initialValues[it]) unvisible = false;
          });

          if (alwaysOn) unvisible = false;
          unregistred[source] = {
            ...child.props,
            unvisible: unvisible
          };
        }
      } else if (isMultifield) {
        const sources = source.split(',');
        if (registredSources.indexOf(source) === -1) {
          isset = true;

          let unvisible = true;
          sources.forEach((it) => {
            if (initialValues[it]) unvisible = false;
          });

          if (alwaysOn) unvisible = false;
          unregistred[source] = {
            ...child.props,
            unvisible: unvisible
          };
        }
      } else {
        if (registredSources.indexOf(source) === -1) {
          isset = true;
          unregistred[source] = {
            ...child.props,
            unvisible: !(alwaysOn || initialValues[source])
          };
        }
      }
    });
    if (isset) registerFilters(unregistred);
    setInit(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const { unvisibleSources, showFilters } = context;
    const toVisibleSources = [];
    unvisibleSources.forEach((source) => {
      const isCombo = source.indexOf('.') !== -1;
      const isMultifield = source.indexOf(',') !== -1;
      if (isCombo) {
        const sources = source.split('.');
        const hasValue = sources.some((it) => initialValues[it]);
        if (hasValue && unvisibleSources.indexOf(source) !== -1) {
          toVisibleSources.push(source);
        }
      } else if (isMultifield) {
        const sources = source.split(',');
        const hasValue = sources.some((it) => initialValues[it]);
        if (hasValue && unvisibleSources.indexOf(source) !== -1) {
          toVisibleSources.push(source);
        }
      } else {
        if (initialValues[source] && unvisibleSources.indexOf(source) !== -1) {
          toVisibleSources.push(source);
        }
      }
    });
    if (toVisibleSources.length) showFilters(toVisibleSources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const hideFilter = (source) => {
    context.hideFilter(source);
    // if (initialValues[source]) {
    //   const res = {};
    //   Object.keys(initialValues).forEach(key => {
    //     if (key !== source && initialValues[key]) {
    //       res[key] = initialValues[key];
    //     }
    //   });
    // _onFilter(res)
    // }
    setTimeout(() => {
      dispatch(submit(formName));
    }, 10);
  };

  const _onFilter = (values) => {
    onFilter(values);
  };
  if (!init) return null;
  return (
    <Form
      onSubmit={_onFilter}
      form={formName}
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      {...rest}>
      <Grid
        container
        direction={direction}
        spacing={spacing}
        justifyContent={justifyContent}>
        {React.Children.map(children, (child) => {
          if (!child) return null;
          const { alwaysOn, source, maxWidth, ...rest } = child.props;
          if (unvisibleSources.indexOf(source) !== -1) return null;
          return (
            <FilterItemWrap
              item
              key={source}
              container
              direction="row"
              wrap="nowrap"
              maxWidth={maxWidth}>
              {React.createElement(child.type, {
                ...rest,
                source
              })}
              {!alwaysOn && <IconBtnClose onClick={() => hideFilter(source)} />}
            </FilterItemWrap>
          );
        })}
      </Grid>
    </Form>
  );
};

export default Filter;

const useFilterItemStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: (props) => (props.maxWidth ? props.maxWidth : 250)
    }
  }
}));

const FilterItemWrap = (props) => {
  const { children, maxWidth, ...rest } = props;
  const classes = useFilterItemStyles({
    maxWidth
  });
  return (
    <Grid {...rest} classes={{ root: classes.root }}>
      {children}
    </Grid>
  );
};
