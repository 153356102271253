import React from 'react';
import clsx from 'clsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@keyframes rotate': {
    from: {
      transform: 'rotateZ(0deg)'
    },
    to: {
      transform: 'rotateZ(360deg)'
    }
  },
  root: {
    flex: 1,
    overflow: 'hidden',
    padding: theme.spacing(1)
  },
  over: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.loader
  },
  top: {
    position: 'absolute',
    top: '20px',
    left: 0,
    right: 0,
    zIndex: theme.zIndex.loader
  },
  spinner: {
    animationName: '$rotate',
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationIterationCount: 'infinite'
  }
}));

const Progress = (props) => {
  const { size = 40, className = '', variant, ...rest } = props;
  const classes = useStyles();
  return (
    <Grid
      className={clsx(classes.root, className, {
        [classes.over]: variant === 'over',
        [classes.top]: variant === 'top'
      })}
      container
      justifyContent="center"
      alignItems="center"
      {...rest}>
      <CircularProgress
        size={size}
        variant="determinate"
        value={75}
        className={classes.spinner}
      />
    </Grid>
  );
};

export default Progress;
