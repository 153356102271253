import React from 'react';
import { Field } from 'redux-form';

import TextField from './TextField';

const FormTextField = (props) => {
  const { source, ...rest } = props;

  return <Field component={TextField} name={source} {...rest} />;
};

export default FormTextField;
