import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

import ErrorService from 'services/ErrorService';
import { inChildren } from 'services/utils';
import { showSnackbarError } from 'modules/notifier';

import IconBtn, { IconBtnDelete } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, { FilterSearchField } from 'components/Filter';
import List from 'components/List';
import Progress from 'components/Progress';
import { useCan } from 'components/Rbac';
import { show as showModalConfirm } from 'modals/Confirm';
import ImageModal from 'modals/ImageModal';

import * as actions from './actions';

const API_URL = process.env.REACT_APP_API_URL;

const isImage = (path) => {
  return /\.(jpe?g|png|gif|webp)$/.test(path);
};

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentShownImg: null
    };
  }
  componentDidMount() {
    this.getList();
  }

  refresh = () => {
    const { params = {} } = this.props.record;
    this.getList(params);
  };

  getList = (params) => {
    const { order, actions } = this.props;
    actions.getOrderFiles(order.id, params);
  };

  handleUpload = async (files) => {
    if (!files || !files.length) {
      return false;
    }
    const file = files[0];
    if (!file) {
      return false;
    }
    const { dispatch } = this.props;
    try {
      if (file.size > 20 * 1024 * 1024) {
        throw new Error('File size should not exceed 20 M');
      }
      const { order, actions } = this.props;
      await actions.uploadFile(order.id, file);
      this.refresh();
    } catch (err) {
      dispatch(
        showSnackbarError({
          message: err.message
        })
      );
    }
  };

  handleDelete = async (item_id) => {
    const { dispatch, order, actions, otherActions } = this.props;
    const res = await otherActions.showModalConfirm({
      title: 'Удаление',
      content: 'Удалить документ?'
    });

    if (res) {
      try {
        await actions.deleteOrderFile(order.id, item_id);
        this.refresh();
      } catch (err) {
        dispatch(
          showSnackbarError({
            message: err.message
          })
        );
      }
    }
  };

  handleDownload = async (item_id) => {
    const { dispatch, order, actions } = this.props;
    try {
      const res = await actions.getOrderFileToken(order.id, item_id);
      const link = document.createElement('a');
      // link.target = '_blank';
      link.style.position = 'absolute';
      link.style.zIndex = '-1';
      link.style.left = '-9999px';
      link.style.opacity = 0;
      link.download = true;
      link.href = `${API_URL}/files/download/${item_id}?token=${res.token}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
    }
  };

  handleShow = async (item) => {
    const { order, actions } = this.props;
    const res = await actions.getOrderFileToken(order.id, item.id);
    if (res) {
      const url = `${API_URL}/files/download/${item.id}?token=${res.token}`;
      this.setState({
        currentShownImg: {
          item,
          url
        }
      });
    }
  };

  handleClose = () => {
    this.setState({ currentShownImg: null });
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      currentShownImg: this.state.currentShownImg,
      getList: this.getList,
      handleUpload: this.handleUpload,
      handleDelete: this.handleDelete,
      handleDownload: this.handleDownload,
      handleShow: this.handleShow,
      handleClose: this.handleClose
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { files = {} } = target;
  return {
    record: files
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    currentShownImg,
    handleUpload,
    handleDelete,
    handleDownload,
    handleShow,
    handleClose
  } = props;

  const isCanUploadFile = useCan('files:create');
  const isCanDeleteFile = useCan('files:delete');

  return (
    <div>
      <List
        {...props}
        title="Файлы"
        toolBarInner={
          isCanUploadFile && <FileUploader onUpload={handleUpload} />
        }
        filter={
          <Filter>
            <FilterSearchField alwaysOn source="name" label="Название" />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field
          label="Название"
          source="*"
          format={(item) => {
            if (!item) return '';

            return isImage(item.path) ? (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                href="#"
                onClick={(evt) => {
                  evt.preventDefault();
                  handleShow(item);
                }}>
                {item.name}
              </a>
            ) : (
              <div>{item.name}</div>
            );
          }}
        />
        <Field
          label="Пользователь"
          source="user"
          format={(item) => {
            if (!item) return '';
            return `${[item.first_name, item.last_name].join(' ')}`;
          }}
        />
        <Field label="Дата" source="created_at" type="date" />
        <Field source="id" isAction>
          {({ value }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                <IconBtn
                  title="Скачать"
                  ariaLabel="add"
                  icon={<GetAppIcon fontSize="inherit" />}
                  onClick={() => handleDownload(value)}
                />
                {isCanDeleteFile ? (
                  <IconBtnDelete onClick={() => handleDelete(value)} />
                ) : null}
              </div>
            );
          }}
        </Field>
      </List>
      <ImageModal
        isOpen={!!currentShownImg}
        image={currentShownImg}
        onClose={handleClose}
      />
    </div>
  );
};

const ShowOrdersFilesList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ShowOrdersFilesList;

const useStylesUploader = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  input: {
    display: 'none'
  }
}));

function FileUploader({ onUpload }) {
  const classes = useStylesUploader();
  const [uploding, setUploading] = React.useState(false);

  const onChange = async ({ nativeEvent }) => {
    const files = nativeEvent.target.files;
    setUploading(true);
    await onUpload(files);
    nativeEvent.target.value = '';
    setUploading(false);
  };

  return (
    <span className={classes.root}>
      <input
        className={classes.input}
        id="contained-button-file"
        type="file"
        onChange={onChange}
        disabled={uploding}
      />
      <label htmlFor="contained-button-file">
        <Button
          variant="contained"
          color="primary"
          disabled={uploding}
          component="span">
          Загрузить
          {uploding ? <Progress size={15} /> : null}
        </Button>
      </label>
    </span>
  );
}
