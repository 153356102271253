import React from 'react';

import { phoneFormat } from 'services/utils';

import FormSearchElasticField from './FormSearchElasticField';

function renderOptionDefault(props) {
  const { client_name, phone, id, address, date_from, agreement_number } =
    props;

  return (
    <div>
      <div>{client_name}</div>
      {phone && <div style={{ fontSize: '0.75em' }}>{phoneFormat(phone)}</div>}
      <div style={{ fontSize: '0.75em' }}>{address}</div>
      <div style={{ fontSize: '0.75em' }}>
        №{id} от {date_from}
        {agreement_number ? ' дог.№ ' + agreement_number : ''}
      </div>
    </div>
  );
}

const FormSearchElasticClientField = (props) => {
  return (
    <FormSearchElasticField
      requestPath="/orders"
      getOptionLabel={(opt) => {
        const optArr = [];
        if (opt.client_name) optArr.push(opt.client_name);
        if (opt.phone) optArr.push(phoneFormat(opt.phone));

        return optArr.length ? optArr.join(', ') : '';
      }}
      renderOption={renderOptionDefault}
      {...props}
    />
  );
};

export default FormSearchElasticClientField;
