import ApiRequest from './ApiRequest';

export default class ExecutorPlaces extends ApiRequest {
  getList(params) {
    return this.get(`/executor_places`, {
      qs: params
    });
  }
  getOne(id) {
    return this.get(`/executor_places/${id}`);
  }
  createOne(req) {
    return this.post(`/executor_places`, {
      body: req
    });
  }
  updateOne(id, req) {
    return this.put(`/executor_places/${id}`, {
      body: req
    });
  }
  deleteOne(id) {
    return this.delete(`/executor_places/${id}`);
  }
}
