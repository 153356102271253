import React from 'react';
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FilterListIcon from '@material-ui/icons/FilterList';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RedoIcon from '@material-ui/icons/Redo';
import RefreshIcon from '@material-ui/icons/Refresh';

const IconBtn = (props) => {
  const {
    title = '',
    ariaLabel = 'button',
    to = '',
    color = 'default',
    icon = null,
    children,
    ...rest
  } = props;

  const btnProps = { color };
  if (to) {
    btnProps.to = to;
    btnProps.component = Link;
  }

  if (!title) {
    return (
      <IconButton aria-label={ariaLabel} {...btnProps} {...rest}>
        {icon}
        {children}
      </IconButton>
    );
  }

  return (
    <Tooltip title={title}>
      <IconButton aria-label={ariaLabel} {...btnProps} {...rest}>
        {icon}
        {children}
      </IconButton>
    </Tooltip>
  );
};

export const IconBtnAdd = (props) => (
  <IconBtn
    title="Добавить"
    ariaLabel="add"
    icon={<AddIcon fontSize="inherit" />}
    {...props}
  />
);

export const IconBtnRefresh = (props) => (
  <IconBtn
    title="Обновить"
    ariaLabel="refresh"
    icon={<RefreshIcon fontSize="inherit" />}
    {...props}
  />
);

export const IconBtnEdit = (props) => (
  <IconBtn
    title="Редактировать"
    ariaLabel="edit"
    icon={<EditIcon fontSize="inherit" />}
    {...props}
  />
);

export const IconBtnDelete = (props) => (
  <IconBtn
    title="Удалить"
    ariaLabel="delete"
    icon={<DeleteIcon fontSize="inherit" />}
    {...props}
  />
);

export const IconBtnFilter = (props) => (
  <IconBtn
    title="Фильтр"
    ariaLabel="filter"
    icon={<FilterListIcon fontSize="inherit" />}
    {...props}
  />
);

export const IconBtnClose = (props) => (
  <IconBtn
    title="Закрыть"
    ariaLabel="close"
    icon={<HighlightOffIcon fontSize="inherit" />}
    {...props}
  />
);

export const IconBtnCopy = (props) => (
  <IconBtn
    title="Копировать"
    ariaLabel="copy"
    icon={<FileCopyIcon fontSize="inherit" />}
    {...props}
  />
);

export const IconBtnRotate = (props) => (
  <IconBtn
    title="Повернуть"
    ariaLabel="rotate"
    icon={<RedoIcon fontSize="inherit" />}
    {...props}
  />
);

export default React.memo(IconBtn);
