import React from 'react';
import { useHistory } from 'react-router-dom';
import { Field, reset } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { request } from 'services/api';
import { makeUrlProjectId } from 'services/projectUtils';
import { phoneFormat } from 'services/utils';

const useStyles = makeStyles((theme) => ({
  fullWidth: { width: '100%' }
}));

function SelectComponent({ input = {}, meta = {}, ...attrs }) {
  const {
    label = ' ',
    noOptionsText = '',
    loadingText = 'Загрузка...',
    disabled,
    responseFormat,
    requestPath = '',
    renderOption,
    getOptionLabel,
    helperText = '',
    searchBy,
    where = {},
    submitOnChange,
    disableClearable = false,
    customOption,
    variant = 'standard',
    inputStyle = {},
    ...restAttrs
  } = attrs;

  const history = useHistory();

  const classes = useStyles(restAttrs);
  const visibleError = meta.touched && meta.error;

  const [open, setOpen] = React.useState(false);
  const [q, setQ] = React.useState('');
  const [options, setOptions] = React.useState(null);
  const [timer, setTimer] = React.useState(null);

  const [search, setSearch] = React.useState('');

  const getData = async () => {
    try {
      let response = await request.get(`${requestPath}/_search`, {
        qs: { q, where }
      });
      return response.hits.map((it) => it._source);
    } catch (err) {
      return [];
    }
  };

  React.useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  React.useEffect(() => {
    let isSubscribed = true;

    async function fetchData() {
      if (!open) {
        setOptions(null);
        setQ('');
        return undefined;
      }

      if (timer) clearTimeout(timer);
      const currenTimer = setTimeout(async () => {
        const result = await getData();
        if (isSubscribed) {
          setOptions(result);
        }
      }, 100);
      if (isSubscribed) {
        setTimer(currenTimer);
      }
    }

    fetchData();
    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, open]);

  const _onChange = (event, option) => {
    if (disabled) return;
    input.onChange(option);
    setOpen(false);
    setTimeout(() => {
      setSearch('');
      meta.dispatch(reset(meta.form));
    }, 0);
  };

  const _onInputChange = (event, newInputValue) => {
    if (event && event.target) setQ(event.target.value);
    setSearch(newInputValue);
  };

  let targetOptions = options || [];
  if (customOption) targetOptions = [customOption].concat(targetOptions);
  const loading = open && !options;

  return (
    <Autocomplete
      classes={{
        root: classes.fullWidth
      }}
      inputValue={search}
      filterOptions={(o) => o}
      noOptionsText={noOptionsText}
      options={targetOptions}
      open={!disabled && open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loadingText={loadingText}
      loading={loading}
      disableClearable={disableClearable}
      freeSolo={true}
      onChange={_onChange}
      onInputChange={_onInputChange}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      renderOption={(props) => renderOption(props, history)}
      renderInput={(params) => {
        return (
          <FormControl
            className={classes.formControl}
            error={!!visibleError}
            fullWidth>
            <TextField
              variant={variant}
              {...params}
              fullWidth
              label={label}
              error={!!visibleError}
              InputProps={{
                style: inputStyle,
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
            />
            {(visibleError || helperText) && (
              <FormHelperText>{visibleError || helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}

function RenderOptionDefault(props) {
  const { client_name, phone, id, address, date_from, agreement_number } =
    props;

  return (
    <div>
      <div>{client_name}</div>
      {phone && <div style={{ fontSize: '0.75em' }}>{phoneFormat(phone)}</div>}
      <div style={{ fontSize: '0.75em' }}>{address}</div>
      <div style={{ fontSize: '0.75em' }}>
        №{id} от {date_from}
        {agreement_number ? ' дог.№ ' + agreement_number : ''}
      </div>
    </div>
  );
}

const FormGlobalSearch = (props) => {
  const { source, ...rest } = props;

  let history = useHistory();

  const onChange = (event, option) => {
    if (option.id) {
      history.push(makeUrlProjectId('/orders/show/' + option.id));
    }
  };

  return (
    <Field
      component={SelectComponent}
      requestPath="/orders"
      //getOptionLabel = {opt => opt ? [opt.client_name, phoneFormat(opt.phone)].join(', ') : ''}
      getOptionLabel={(opt) => ''}
      renderOption={RenderOptionDefault}
      onChange={onChange}
      name={source}
      {...rest}
    />
  );
};

export default FormGlobalSearch;
