import ApiRequest from './ApiRequest';

export default class History extends ApiRequest {
  getList(req) {
    return this.get(`/history`, {
      qs: req
    });
  }

  getOrdersList(params) {
    return this.get(`/history/orders`, {
      qs: params
    });
  }
}
