import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import { FormTextField, SimpleForm } from 'components/Form';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';

import * as actions from './actions';
import { validateForm } from './validation';

const formName = 'edit_geo_region';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      geoActions,
      history = {}
    } = this.props;
    try {
      await geoActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const { geoActions, record = {}, loading, history } = this.props;
    if (loading) return;
    try {
      await geoActions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  geoActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleUpdate, record = {}, loading } = props;

  const history = useHistory();
  const back = history.goBack;
  const itemReceived = record && record.id;
  let title = 'Редактировать';
  if (itemReceived) {
    title = `Редактировать регион: ${record.name}`;
  }

  const is_writable = useCan('geo:write');

  if (!is_writable) {
    return null;
  }

  return (
    <Screen title={title}>
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        direction="row"
        onBack={back}
        validate={validateForm}
        initialValues={{ ...record }}
        parse={(values) => {
          const { name, alt_name, old_name, name_lang1, name_lang2 } = values;
          const res = {
            name: name ? name.trim() : '',
            alt_name: alt_name ? alt_name.trim() : '',
            old_name: old_name ? old_name.trim() : '',
            name_lang1: name_lang1 ? name_lang1.trim() : '',
            name_lang2: name_lang2 ? name_lang2.trim() : ''
          };
          return res;
        }}>
        <Grid item xs={12}>
          <FormTextField source="name" label="Название" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="alt_name"
            label="Альтернативное название"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="old_name"
            label="Старое название"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="name_lang1"
            label="Язык №1"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="name_lang2"
            label="Язык №2:"
            disabled={loading}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const RegionsEdit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default RegionsEdit;
