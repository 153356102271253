import { ApiRequest, Request } from './Request';

const MAPS_API_URL = process.env.REACT_APP_MAPS_API_URL;

export default class Maps {
  constructor() {
    const request = new Request({
      baseUrl: MAPS_API_URL
    });
    this.api = new ApiRequest(request);
  }

  async search(req) {
    return await this.api.get('/search', {
      qs: {
        format: 'json',
        polygon_geojson: 1,
        addressdetails: 1,
        // country: "Україна",
        ...req
        // city: "харків",
        // street: "базарна 94",
      }
    });
  }

  async autocomplete(req) {
    return await this.api.get('/autocomplete', {
      qs: {
        ...req
      }
    });
  }
}
