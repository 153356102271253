import React from 'react';
import { useDispatch } from 'react-redux';

import { copyToClipboard } from 'services/utils';
import { showSnackbarSuccess } from 'modules/notifier';

import { IconBtnCopy } from 'components/buttons/IconBtn';

const CopyBufferBtn = (props) => {
  const { value = '', message = 'Скопировано' } = props;

  const dispatch = useDispatch();
  if (!value) return;

  const copy = () => {
    const status = copyToClipboard(value);
    if (status)
      dispatch(
        showSnackbarSuccess({
          message
        })
      );
  };

  return <IconBtnCopy onClick={copy} />;
};

export default CopyBufferBtn;
