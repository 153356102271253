import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import { BackBtn, DeleteBtn, EditBtn } from 'components/buttons/Btn';
import FooterBar from 'components/FooterBar';
import NotFoundItems from 'components/NotFoundItems';
import Progress from 'components/Progress';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import Tabs, { Tab } from 'components/Tabs';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';
import ShowExecutoPlaceInfo from './ShowExecutoPlaceInfo';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false
    };
  }

  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const { match: { params: { id } = {} } = {}, executorPlacesActions } =
      this.props;
    try {
      await executorPlacesActions.getOne(id);
    } catch (err) {
      this.setState({ notFound: true });
    }
  };

  handleDelete = async () => {
    const {
      executorPlacesActions,
      record = {},
      dispatch,
      history = {}
    } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить дислокацию?'
      })
    );

    if (res) {
      try {
        await executorPlacesActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      notFound: this.state.notFound,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    record: one.data,
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorPlacesActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleDelete,
    record = {},
    loading,
    match = {},
    basePath = '/',
    notFound
  } = props;

  const itemReceived = !!(record && record.id);
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Дислокация: ${record.name}`;
  }

  const is_writable = useCan('places:write');

  return (
    <Screen title={title}>
      {loading && <Progress />}
      {notFound && <NotFoundItems message="Дислокация не найдена" />}
      {itemReceived && (
        <Tabs>
          <Tab label="Инфо" eventKey={match.url}>
            <Box p={2}>
              <ShowExecutoPlaceInfo {...props} />
            </Box>
          </Tab>
        </Tabs>
      )}
      <FooterBar>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <BackBtn onClick={back}>Назад</BackBtn>
            </Grid>
            <Grid item>
              {itemReceived && is_writable && (
                <EditBtn
                  to={{
                    pathname: `${basePath}/edit/${record.id}`,
                    state: { from: `${basePath}/show/${record.id}` }
                  }}>
                  Редактировать
                </EditBtn>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {itemReceived && is_writable && (
            <DeleteBtn onClick={handleDelete}>Удалить</DeleteBtn>
          )}
        </Grid>
      </FooterBar>
    </Screen>
  );
};

const ExecutorsPlacesShow = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ExecutorsPlacesShow;
