import * as HISTORY_ITEM_CONFIG from 'constants/historyConfig';

export const parseHistory = (changes, item_type) => {
  try {
    const config = HISTORY_ITEM_CONFIG[item_type];
    const parsed = JSON.parse(changes);

    let type = '';
    let items = {};
    if (parsed.diff) {
      type = 'diff';
      items = parsed.diff;
    } else if (parsed.values) {
      type = 'values';
      items = parsed.values;
    } else if (parsed.phone_call) {
      type = 'phone_call';
      items = parsed.phone_call;
    }

    let res = Object.keys(items).map((key) => {
      let label = key;
      let values = items[key];
      const c = config && config[key] ? config[key] : null;
      if (!c) {
        return null;
      }
      if ((!values && values !== 0) || (c && c.hidden)) return null;
      if (c && c.label) label = c.label;
      if (c && c.format) {
        if (type === 'values' && values) {
          values = c.format(values);
        } else if (type === 'diff') {
          values = values.map((it) => (it || it === 0 ? c.format(it) : ''));
        }
      }

      return {
        label,
        values
      };
    });

    res = res.filter((it) => it);
    if (!res.length) {
      return null;
    }

    return {
      items: res,
      type: type
    };
  } catch (e) {
    return null;
  }
};
