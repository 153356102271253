import React from 'react';
import moment from 'moment';

import { FormDateField } from 'components/Form';

const FilterDateField = (props) => {
  const {
    variant,
    source,
    searchType = '',
    searchPath = '',
    dateType = 'datetime',
    ...rest
  } = props;

  const additional = {};

  if (searchType || searchPath) {
    additional.format = (obj = {}) => {
      return obj.q || '';
    };

    additional.parse = (v) => {
      let val = v;
      if (dateType === 'date') {
        switch (searchType) {
          case 'from':
            val = v ? moment(v).startOf('day').toISOString() : v;
            break;
          case 'to':
            val = v ? moment(v).endOf('day').toISOString() : v;
            break;
          default:
            val = v;
        }
      }
      return {
        q: val,
        qt: searchType,
        qp: searchPath
      };
    };
  }
  return (
    <FormDateField
      name={source}
      submitOnChange={true}
      dateType={dateType}
      {...rest}
      {...additional}
    />
  );
};

export default React.memo(FilterDateField);
