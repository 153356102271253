import ApiRequest from './ApiRequest';

export default class Warehouse extends ApiRequest {
  getList(params) {
    return this.get(`/warehouse`, {
      qs: params
    });
  }
  getOne(id) {
    return this.get(`/warehouse/${id}`);
  }
  createOne(req) {
    return this.post(`/warehouse`, {
      body: req
    });
  }
  updateOne(id, req) {
    return this.put(`/warehouse/${id}`, {
      body: req
    });
  }
  deleteOne(id) {
    return this.delete(`/warehouse/${id}`);
  }
}
