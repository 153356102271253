import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'App';
import initializeStore from 'store';

import ApiRequest from 'services/api/ApiRequest';

import { setNeedReload } from 'store/actions/appActions';

const store = initializeStore();

ApiRequest.onAppUpdate = () => {
  store.dispatch(setNeedReload(true));
};

const component = (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(component, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
