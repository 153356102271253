import React from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

import { makeStyles } from '@material-ui/core/styles';

import {
  DEFAULT_CENTER,
  DEFAULT_ZOOM,
  MAX_BOUNDS,
  MAX_ZOOM,
  MIN_ZOOM
} from './constants';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '400px',
    width: '100%'
  }
}));

const Map = ({
  children,
  minZoom = MIN_ZOOM,
  maxZoom = MAX_ZOOM,
  center = DEFAULT_CENTER,
  zoom = DEFAULT_ZOOM,
  maxBounds = MAX_BOUNDS,
  attributionControl = false,
  mapRef = null,
  classes: parentClasses
}) => {
  const classes = useStyles({ classes: parentClasses });
  const [map, setMap] = React.useState(null);

  React.useEffect(() => {
    if (mapRef) {
      mapRef.current = map;
    }
  }, [map, mapRef]);

  React.useEffect(() => {
    if (map) {
      map.setView(center, zoom);
    }
  }, [center, map, zoom]);

  return (
    <div className={classes.container}>
      <MapContainer
        className={classes.container}
        center={center}
        zoom={zoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
        maxBounds={maxBounds}
        attributionControl={attributionControl}
        whenCreated={setMap}>
        <TileLayer url="https://maps.itlid.com.ua/tile/{z}/{x}/{y}.png" />
        {children}
      </MapContainer>
    </div>
  );
};

export default Map;
