import React, { Component } from 'react';
import {
  connect
  // useDispatch
} from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';

// import { submit } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';

// import * as ordersActions from 'resources/Orders/actions';
import Query from 'services/Query';

import { IconBtnRefresh } from 'components/buttons/IconBtn';
import Filter, {
  FilterBtn,
  FilterDateRangeField,
  FilterProvider,
  FilterSearchElasticClientField,
  FilterSearchElasticExecutorField,
  FilterSearchField,
  FilterSearchGeoElasticAddress,
  FilterSearchSelectField,
  FilterSelectField
} from 'components/Filter';
import Map from 'components/maps';
import Progress from 'components/Progress';
import Screen from 'components/Screen';

import OrderMapMarker from './common/OrderMapMarker';
import * as ordersMapActions from './actions';
// import Rbac from 'components/Rbac';
// import SimpleModal from 'modals/SimpleModal';
// import OrderAdd from 'resources/Orders/Add';

class OrdersMap extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { ordersMapActions } = this.props;
      const start = moment().startOf('day');
      const end = start.clone().add(6, 'd').endOf('day');
      ordersMapActions.getList({
        ...params,
        filter: {
          date_from: {
            q: { $gte: start.toISOString(), $lte: end.toISOString() }
          },
          ...params.filter
        }
      });
    }
  };

  changeFilterHandler = (values) => {
    this.getList({
      filter: values
    });
  };

  refresh = () => {
    const { params = {} } = this.props.record;
    this.getList(params);
  };

  render() {
    const { record } = this.props;
    return (
      <ViewLayout
        record={record}
        onRefresh={this.refresh}
        onFilter={this.changeFilterHandler}
      />
    );
  }
}

const useStyles = makeStyles((theme) => ({
  header: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    // marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  filter: {
    flex: 1,
    // marginBottom: 15,
    [theme.breakpoints.down('xs')]: {
      order: 2
    }
  },
  headersBtns: {
    flexShrink: 0,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      order: 1,
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center'
    }
  },
  mapWrapper: {
    flexGrow: 1
  },
  mapContainer: {
    height: '100%',
    minHeight: 400
  }
}));

const ViewLayout = ({ record = {}, onRefresh, onFilter }) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const { loading, list = [], params = {} } = record;

  const { filter = {} } = params;

  // const [modalData, setModalData] = React.useState({
  //   modalAdd: null,
  //   modalInnerProps: {}
  // });

  // const handleHideAdd = () => {
  //   setModalData({
  //     modalAdd: null,
  //     modalInnerProps: null
  //   });
  // }

  // const handleCreate = async (data) => {
  //   try {
  //     await dispatch(ordersActions.createOne(data));
  //     handleHideAdd();
  //     onRefresh();
  //   } catch (err) {}
  // }

  // const handleShowAdd = (data) => {
  //   const formName = 'add_order';
  //   setModalData({
  //     modalAdd: {
  //       confirmText: 'Создать',
  //       title: 'Создание заявки',
  //       handleClose: handleHideAdd,
  //       handleSuccess: () => dispatch(submit(formName)),
  //     },
  //     modalInnerProps:{
  //       formName,
  //       record: {
  //         ...data.order,
  //         executor: data.executor,
  //         date_from: data.timepoint.start,
  //         date_to: data.timepoint.end,
  //       },
  //       handleCreate: handleCreate
  //     }
  //   });
  // }

  return (
    <Screen title="Карта заявок">
      {loading && <Progress variant="over" />}
      <FilterProvider>
        <div className={classes.header}>
          <Filter
            className={classes.filter}
            initialValues={filter}
            onFilter={onFilter}>
            <FilterDateRangeField
              source="date_from"
              labels={['Заявка,с', 'Заявка,по']}
              alwaysOn
              maxWidth={400}
              clearable={false}
            />
            <FilterSearchElasticExecutorField
              source="executor_id"
              label="Дезинфектор"
              alwaysOn
              is_active="yes"
              customOption={{
                id: 'null',
                name: 'Не указан'
              }}
            />
            <FilterSelectField
              source="is_active"
              label="Активен"
              options={[
                { value: 'all', label: 'Все' },
                { value: 'yes', label: 'Активные' },
                { value: 'no', label: 'Не активные' }
              ]}
            />
            <FilterSearchField source="id" label="ID" searchType="" />
            <FilterSearchElasticClientField source="client_id" label="Клиент" />
            <FilterSearchSelectField
              source="user_id"
              label="Оператор"
              requestPath="/users"
              responseFormat={(v) => v.results}
              getOptionLabel={(it) =>
                it ? [it.first_name, it.last_name].join(' ') : ''
              }
              searchBy={['first_name', 'last_name']}
              getValue={(o) => o.id}
            />
            <FilterSearchField
              source="agreement_number"
              label="Номер договора"
            />
            <FilterSearchGeoElasticAddress
              source="locality_id,street_id,address_house"
              label="Адрес"
              filter={filter}
              maxWidth={600}
            />
            <FilterSearchSelectField
              source="pay_type_id"
              label="Способ оплаты"
              requestPath="/pay_types"
              responseFormat={(v) => v.results}
              searchBy="name"
              getValue={(o) => o.id}
            />
            <FilterSearchSelectField
              source="reasons.reason_id"
              label="Вредители"
              requestPath="/reasons"
              responseFormat={(v) => v.results}
              searchBy="name"
              getValue={(o) => o.id}
              disableClearable={true}
            />
            <FilterSearchSelectField
              source="methods.method_id"
              label="Услуги"
              requestPath="/methods"
              responseFormat={(v) => v.results}
              searchBy="name"
              getValue={(o) => (o ? o.id : null)}
              disableClearable={true}
            />
            <FilterSelectField
              source="is_urgently"
              label="Срочно"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Срочно' },
                { value: 'no', label: 'Не cрочно' }
              ]}
            />
            <FilterSelectField
              source="is_finished"
              label="Выполнен"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Выполнен' },
                { value: 'no', label: 'Не выполнен' }
              ]}
            />
            <FilterSelectField
              source="is_qc"
              label="Контроль качества"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Да' },
                { value: 'no', label: 'Нет' }
              ]}
            />
            <FilterSelectField
              source="is_feedback"
              label="Оставлен чек"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Да' },
                { value: 'no', label: 'Нет' }
              ]}
            />
            <FilterSelectField
              source="is_repeat"
              label="Повторная заявка"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Да' },
                { value: 'no', label: 'Нет' }
              ]}
            />
            <FilterSelectField
              source="have_materials"
              label="Есть материалы"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Да' },
                { value: 'no', label: 'Нет' }
              ]}
            />
            <FilterSelectField
              source="have_files"
              label="Есть файлы"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Да' },
                { value: 'no', label: 'Нет' }
              ]}
            />
          </Filter>
          <div className={classes.headersBtns}>
            <FilterBtn />
            <IconBtnRefresh onClick={onRefresh} />
          </div>
        </div>
      </FilterProvider>
      <div className={classes.mapWrapper}>
        <Map
          classes={{
            container: classes.mapContainer
          }}>
          {list && list.length
            ? list.map((order) => {
                const { geopoint } = order;
                if (!geopoint) return null;
                return (
                  <OrderMapMarker
                    key={order.id}
                    order={order}
                    // onSelectTimepoint={handleShowAdd}
                  />
                );
              })
            : null}
        </Map>
      </div>

      {/* <Rbac operation="orders:create">
        <SimpleModal
          isOpen={!!modalData.modalAdd}
          cancelText="Отмена"
          maxWidth="xl"
          {...modalData.modalAdd}
        >
          <OrderAdd
            footerBar={false}
            title={null}
            {...modalData.modalInnerProps}
          />
        </SimpleModal>
      </Rbac> */}
    </Screen>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ordersMapActions: bindActionCreators(ordersMapActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersMap);
