import React from 'react';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { validateErrors } from 'services/utils';

import Form, { FormPasswordField, FormTextField } from 'components/Form';
import Progress from 'components/Progress';

const validate = (values) => {
  const errors = {};
  const { login, password } = values;

  errors.login = validateErrors(login, {
    require: 'Обязательное поле'
  });

  errors.password = validateErrors(password, {
    require: 'Обязательное поле'
  });

  return errors;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2)
  },
  formControlMargin: {
    marginBottom: theme.margins.form
  },
  buttonSubmitWrap: {
    position: 'relative'
  },
  buttonSubmitProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    rigth: 0,
    bottom: 0
  }
}));

const LoginLayout = (props) => {
  const classes = useStyles();
  const { auth } = props;
  return (
    <Grid
      className={classes.root}
      container
      justifyContent="center"
      alignItems="center">
      <Container maxWidth="xs">
        <Paper className={classes.paper}>
          <Form onSubmit={props.onSubmit} form="login" validate={validate}>
            <Grid container direction="column">
              <FormTextField
                className={classes.formControlMargin}
                name="login"
                label="Логин"
              />
              <FormPasswordField
                className={classes.formControlMargin}
                name="password"
                label="Пароль"
              />
            </Grid>
            <Grid container justifyContent="flex-end" alignItems="center">
              <span className={classes.buttonSubmitWrap}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={auth.inProcess}>
                  Войти
                </Button>
                {auth.inProcess && (
                  <Progress
                    size={24}
                    className={classes.buttonSubmitProgress}
                  />
                )}
              </span>
            </Grid>
          </Form>
        </Paper>
      </Container>
    </Grid>
  );
};

export default LoginLayout;
