import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';

import createReducer from './reducers';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  // const { logger } = require('redux-logger');
  // middlewares.push(logger);
}

const initializeStore = (initState = {}) => {
  const store = createStore(
    createReducer(),
    initState,
    applyMiddleware(...middlewares)
  );

  store.asyncReducers = {};
  store.injectReducer = (key, reducer) => {
    if (key && reducer) store.asyncReducers[key] = reducer;
    store.replaceReducer(createReducer(store.asyncReducers));
    return store;
  };

  return store;
};

export default initializeStore;
