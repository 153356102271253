import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { change, stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import {
  FormCheckboxField,
  FormSearchSelectField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';

import * as actions from './actions';
import { validateForm } from './validation';

const defaultFormName = 'add_geo_locality';

class Container extends Component {
  handleCreate = async (data) => {
    const { loading, localityActions, dispatch, history = {} } = this.props;
    if (loading) return;
    try {
      await localityActions.createOne(data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(defaultFormName, { name: 'Уже существует' }));
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleCreate: this.handleCreate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  localityActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

export const AddForm = (props) => {
  const dispatch = useDispatch();
  const [selectedRegionId, setSelected] = React.useState(null);

  const {
    handleCreate,
    loading,
    formName = defaultFormName,
    footerBar = true
  } = props;

  const onChangeRegionHandler = React.useCallback(
    (region) => {
      setSelected(region && region.id ? region.id : null);
      dispatch(change(formName, 'district_id', null));
    },
    [dispatch, formName]
  );

  const history = useHistory();
  const back = history.goBack;

  const is_writable = useCan('geo:write');

  if (!is_writable) {
    return null;
  }

  return (
    <SimpleForm
      loading={loading}
      form={formName}
      onSubmit={handleCreate}
      disabled={loading}
      onBack={back}
      direction="row"
      footerBar={footerBar}
      validate={validateForm}
      initialValues={{ weight: '0' }}
      parse={(values) => {
        const { name, alt_name, old_name, name_lang1, name_lang2 } = values;
        const res = {
          name: name ? name.trim() : '',
          alt_name: alt_name ? alt_name.trim() : '',
          old_name: old_name ? old_name.trim() : '',
          name_lang1: name_lang1 ? name_lang1.trim() : '',
          name_lang2: name_lang2 ? name_lang2.trim() : '',
          region_id: values.region_id ? values.region_id.id : null,
          district_id: values.district_id ? values.district_id.id : null,
          region_center: values.region_center || 'no',
          weight: values.weight ? parseInt(values.weight) : 0
        };
        return res;
      }}>
      <Grid item xs={12}>
        <FormTextField source="name" label="Название" disabled={loading} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormSearchSelectField
          source="region_id"
          label="Регион"
          disabled={loading}
          requestPath="/geo/regions"
          responseFormat={(v) => v.results}
          renderOption={(it) => it.name}
          searchBy="name"
          onChangeHandler={onChangeRegionHandler}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormSearchSelectField
          source="district_id"
          label="Район"
          disabled={loading || !selectedRegionId}
          where={selectedRegionId ? { region_id: selectedRegionId } : null}
          requestPath="/geo/district"
          responseFormat={(v) => v.results}
          renderOption={(it) => it.name}
          searchBy="name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          source="alt_name"
          label="Альтернативное название"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          source="old_name"
          label="Старое название"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField source="name_lang1" label="Язык №1" disabled={loading} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          source="name_lang2"
          label="Язык №2:"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          source="weight"
          label="Вес"
          disabled={loading}
          type="number"
          inputProps={{
            step: '1'
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormCheckboxField
          source="region_center"
          label="Регион. ценр"
          disabled={loading}
          parse={(v) => (v ? 'yes' : 'no')}
          format={(v) => v === 'yes'}
        />
      </Grid>
    </SimpleForm>
  );
};

const LocalityAdd = (props) => (
  <Screen title="Добавить населенный пункт">
    <ContainerConnect {...props}>
      <AddForm />
    </ContainerConnect>
  </Screen>
);

export default LocalityAdd;
