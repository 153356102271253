import ApiRequest from './ApiRequest';

export default class Materials extends ApiRequest {
  getList(params) {
    return this.get(`/materials`, {
      qs: params
    });
  }
  getOne(id) {
    return this.get(`/materials/${id}`);
  }
  createOne(req) {
    return this.post(`/materials`, {
      body: req
    });
  }
  updateOne(id, req) {
    return this.put(`/materials/${id}`, {
      body: req
    });
  }
  deleteOne(id) {
    return this.delete(`/materials/${id}`);
  }
}
