import React from 'react';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import HistoryIcon from '@material-ui/icons/History';

import { showHistoryDrawer as showHistoryDrawerAction } from './actions';

const ShowHistoryBtn = (props) => {
  const { itemType, itemId, showHistoryDrawerAction, ...rest } = props;

  return (
    <Button
      aria-label="open history drawer"
      variant="contained"
      color="default"
      startIcon={<HistoryIcon fontSize="inherit" />}
      onClick={() =>
        showHistoryDrawerAction({
          itemType,
          itemId
        })
      }
      {...rest}>
      История
    </Button>
  );
};

export default connect(null, {
  showHistoryDrawerAction
})(ShowHistoryBtn);
