import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { Btn } from 'components/buttons/Btn';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    padding: theme.spacing(2),
    zIndex: 10000,
    left: 0,
    right: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#d8d800',
    boxShadow: '0px 2px 3px rgba(0,0,0,0.5)'
  },
  msg: {
    fontSize: 18,
    marginRight: theme.spacing(2)
  }
}));

const UpdateMessage = ({ needReload }) => {
  const classes = useStyles();

  if (!needReload) return null;

  return (
    <div className={classes.root}>
      <div className={classes.msg}>Доступно обновление</div>
      <Btn
        ariaLabel="add"
        // startIcon={<AddIcon fontSize="inherit"/>}
        color="primary"
        onClick={() => window.location.reload(true)}>
        Обновить
      </Btn>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    needReload: state.app.needReload
  };
};
export default connect(mapStateToProps)(UpdateMessage);
