import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Query from 'services/Query';
import { inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import { IconBtnEdit } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, { FilterSearchGeoRegionField } from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { geoActions } = this.props;
      geoActions.getList(params);
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  geoActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { basePath } = props;
  const is_writable = useCan('geo:write');
  return (
    <Screen title="Регионы">
      <List
        {...props}
        rowLink={(row) => `${basePath}/show/${row.id}`}
        toolBarInner={is_writable && <AddBtn to={`${basePath}/add`} />}
        filter={
          <Filter>
            <FilterSearchGeoRegionField
              alwaysOn
              source="id"
              label="Поиск"
              maxWidth={400}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field label="Название" source="name" sortable />
        <Field source="id" isAction>
          {({ value }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {is_writable && (
                  <IconBtnEdit to={`${basePath}/edit/${value}`} />
                )}
              </div>
            );
          }}
        </Field>
      </List>
    </Screen>
  );
};

const RegionsList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default RegionsList;
