import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import { IconBtnDelete } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterSearchField,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import AddUsersList from 'modals/AddUsersList';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    this.getList();
  }

  refresh = () => {
    const { groups = {} } = this.props;
    const { params = {} } = groups;
    this.getList(params);
  };

  getList = (params) => {
    const { group_id, groupsActions } = this.props;
    groupsActions.getGroupsUsers(group_id, params);
  };

  handleAttach = async (user_id) => {
    const { group_id, groupsActions } = this.props;
    try {
      await groupsActions.attachUserGroup(group_id, user_id);
      this.refresh();
    } catch (err) {
      console.error(err);
    }
  };

  handleDetach = async (user_id) => {
    const { group_id, otherActions, groupsActions } = this.props;
    const res = await otherActions.showModalConfirm({
      title: 'Редактирование',
      content: 'Отвязать пользователя от группы?'
    });

    if (res) {
      try {
        await groupsActions.detachUserGroup(group_id, user_id);
        this.refresh();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleRefresh: this.refresh,
      handleDetach: this.handleDetach,
      handleAttach: this.handleAttach
    });
  }
}

const mapStateToProps = (state) => {
  const target = state.groups;
  const { users = {} } = target;
  return {
    record: users
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  groupsActions: bindActionCreators(actions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleDetach, handleAttach } = props;

  const [show, setShow] = useState(false);
  const onClose = () => {
    setShow(false);
  };
  const onSelect = (id) => {
    setShow(false);
    handleAttach(id);
  };

  const is_writable = useCan('groups:write');

  return (
    <div>
      <List
        {...props}
        title="Пользователи"
        toolBarInner={is_writable && <AddBtn onClick={() => setShow(true)} />}
        filter={
          <Filter>
            <FilterSearchField
              source="user_login"
              label="Логин"
              searchPath="user.login"
              alwaysOn
            />
            <FilterSearchField
              source="user_email"
              label="E-mail"
              searchPath="user.email"
            />
            <FilterSearchField
              source="user_first_name"
              label="Имя"
              searchPath="user.first_name"
            />
            <FilterSearchField
              source="user_last_name"
              label="Фамилия"
              searchPath="user.last_name"
            />
            <FilterSearchField
              source="user_phone"
              label="Тел."
              searchPath="user.phone"
            />
            <FilterSelectField
              source="user_is_active"
              label="Активен"
              searchPath="user.is_active"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Активен' },
                { value: 'no', label: 'Не активен' }
              ]}
            />
          </Filter>
        }>
        <Field label="ID" source="user.id" sortable />
        <Field label="Логин" source="user.login" sortable />
        <Field label="E-mail" source="user.email" sortable />

        <Field label="Имя" source="user.first_name" sortable />
        <Field label="Фамилия" source="user.last_name" sortable />
        <Field label="Тел." source="user.phone" type="phone" />

        <Field
          label="Активен"
          source="user.is_active"
          format={(v) => v === 'yes'}
          type="boolean"
        />
        <Field label="Дата" source="created_at" type="date" sortable />

        <Field source="user.id" isAction>
          {({ value }) => {
            return (
              <React.Fragment>
                {is_writable && (
                  <IconBtnDelete onClick={() => handleDetach(value)} />
                )}
              </React.Fragment>
            );
          }}
        </Field>
      </List>
      <AddUsersList
        isOpen={show}
        title="Добавить пользователя"
        handleClose={onClose}
        onSelect={onSelect}
      />
    </div>
  );
};

const ShowGroupsUsers = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ShowGroupsUsers;
