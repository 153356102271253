import React from 'react';
import { Field, submit } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { request } from 'services/api';
import { concatGeoFullName } from 'services/utils';

function FormSearchGeoField(props) {
  const { source, searchPath = '', searchType = '', ...rest } = props;

  const additional = {};

  if (searchType || searchPath) {
    additional.format = (obj = {}) => {
      return obj.q || '';
    };

    additional.parse = (v) => {
      return {
        q: v,
        qt: searchType,
        qp: searchPath
      };
    };
  }

  return (
    <Field component={SelectSearch} name={source} {...rest} {...additional} />
  );
}

export default FormSearchGeoField;

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%'
  }
}));

function renderOptionDefault(props) {
  const { name, full_name_text } = props;

  return (
    <div>
      <div>{name}</div>
      <div style={{ fontSize: '0.75em' }}>{full_name_text}</div>
    </div>
  );
}

const _getOptionLabel = (opt) => {
  return opt.full_name ? concatGeoFullName(opt, [2, 4]) : opt.name || '';
};

const getData = async (req) => {
  try {
    let response = await request.get('/geo/_search', {
      qs: req
    });
    return response.hits.map((it) => it._source);
  } catch (err) {
    return [];
  }
};

function SelectSearch({ input = {}, meta = {}, ...attrs }) {
  const {
    label = ' ',
    noOptionsText = '',
    loadingText = 'Загрузка...',
    disabled,
    responseFormat,
    getOptionLabel = _getOptionLabel,
    renderOption = renderOptionDefault,
    helperText = '',
    where = {},
    submitOnChange,
    forced,
    disableClearable = false,
    geotype = 'streets',
    ...restAttrs
  } = attrs;

  const classes = useStyles(restAttrs);
  const visibleError = meta.touched && meta.error;
  const timer = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [q, setQ] = React.useState('');
  const [options, setOptions] = React.useState(null);
  const [userVal, setUserValue] = React.useState(null);

  React.useEffect(() => {
    if (!open) {
      setOptions(null);
      setQ('');
      return undefined;
    }
    let active = true;
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(async () => {
      const result = await getData({
        q,
        where
      });
      if (active) {
        setOptions(result);
      }
    }, 500);
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, open]);

  React.useEffect(() => {
    const init_val =
      meta.initial && meta.initial.q ? meta.initial.q : meta.initial;

    if (forced && !userVal && init_val && Number(init_val)) {
      let active = true;
      (async () => {
        try {
          let response = await request.get(`/geo/${geotype}/${init_val}`);
          if (active) {
            setUserValue(response);
            if (!forced) input.onChange(response);
          }
        } catch (err) {
          _onChange(null, null);
        }
      })();
      return () => {
        active = false;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.initial]);

  const _onChange = (event, option) => {
    if (disabled) return;
    let target = null;
    if (option) target = forced ? option.id : option;
    if (forced) setUserValue(option);
    setOpen(false);
    input.onChange(target);
    setTimeout(() => {
      if (submitOnChange) meta.dispatch(submit(meta.form));
    }, 0);
  };

  const _onInputChange = (event) => {
    if (event && event.target) setQ(event.target.value);
  };

  const targetOptions = options || [];
  const loading = open && !options;
  const t = forced ? userVal : input.value;
  let inputValue = t ? getOptionLabel(t) : '';
  if (open) inputValue = q;

  return (
    <Autocomplete
      classes={{
        root: classes.fullWidth
      }}
      filterOptions={(o) => o}
      noOptionsText={noOptionsText}
      options={targetOptions}
      freeSolo={true}
      open={!disabled && open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loadingText={loadingText}
      loading={loading}
      disableClearable={disableClearable}
      onChange={_onChange}
      // onBlur={_onBlur}
      inputValue={inputValue}
      onInputChange={_onInputChange}
      value={input.value}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      // disableOpenOnFocus={true}
      renderInput={(params) => {
        return (
          <FormControl
            fullWidth
            className={classes.formControl}
            error={!!visibleError}>
            <TextField
              {...params}
              fullWidth
              label={label}
              error={!!visibleError}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
            />
            {(visibleError || helperText) && (
              <FormHelperText>{visibleError || helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}
