import queryString from 'qs';

async function timeWait(t = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, t);
  });
}

const storage = window.localStorage;

export default class ApiRequest {
  static defaultOptions = {
    headers: {
      'X-App-Version': process.env.REACT_APP_VERSION
    }
  };

  constructor(baseUrl) {
    this.baseUrl = baseUrl;
  }

  static refreshLock = false;

  static onAppUpdate = null;

  async _refreshToken() {
    const refresh_options = {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        refresh_token: await storage.getItem('refresh_token')
      })
    };

    // if (process.env.NODE_ENV !== 'production') {
    //   /* eslint-disable */
    //   console.log('REFRESH_REQ: ', refresh_options);
    //   /* eslint-enable */
    // }

    const refresh_response = await fetch(
      `${this.baseUrl}/auth/refresh`,
      refresh_options
    );
    const refresh_data = await refresh_response.json();

    // if (process.env.NODE_ENV !== 'production') {
    //   /* eslint-disable */
    //   console.log('REFRESH_RES: ', refresh_data);
    //   /* eslint-enable */
    // }

    if (refresh_response.status !== 200) {
      throw refresh_data;
    }

    await storage.setItem('token', refresh_data.token);
    await storage.setItem('refresh_token', refresh_data.refresh_token);

    return refresh_data;
  }

  async _fetch(
    method,
    path,
    { qs = null, body = null, options = {}, encodeType = 'json' } = {}
  ) {
    const project_id = window.project_id;

    qs = {
      ...qs,
      project_id
    };

    if (ApiRequest.refreshLock) {
      let c = 0;
      do {
        c++;
        await timeWait(50);
      } while (ApiRequest.refreshLock && c < 600000);

      if (ApiRequest.refreshLock) throw new Error('refresh lock timeout');
    }

    let token = await storage.getItem('token');

    let url = this.baseUrl + path;

    if (qs) {
      if (qs.where && Object.keys(qs.where).length)
        qs.where = JSON.stringify(qs.where);
      else delete qs.where;
      url += '?' + queryString.stringify(qs);
    }

    // if (process.env.NODE_ENV !== 'production') {
    //   /* eslint-disable */
    //   console.log('API REQUEST:', JSON.stringify({
    //     url,
    //     method,
    //     path,
    //     qs,
    //     body
    //   }, null, 2));
    //   /* eslint-enable */
    // }

    const req = {
      method,
      ...options
    };

    req.headers = new Headers({
      ...ApiRequest.defaultOptions.headers,
      ...options.headers
    });

    if (body) {
      if (!encodeType) {
        req.body = body;
      }

      if (encodeType === 'json') {
        req.headers.set('Content-Type', 'application/json; charset=utf-8');
        req.body = JSON.stringify(body);
      }

      if (encodeType === 'urlencoded') {
        req.headers.set('Content-Type', 'application/x-www-form-urlencoded');
        req.body = queryString.stringify(body);
      }

      if (encodeType === 'form') {
        // req.headers.set('Content-Type', 'multipart/form-data');
        const formData = new FormData();

        for (let i in body) {
          formData.append(i, body[i]);
        }

        req.body = formData;
      }
    }

    let resp = null;
    try {
      if (token) {
        req.headers.set('Authorization', `Bearer ${token}`);
      }

      resp = await fetch(url, req);

      if (resp.status === 401) {
        const data = await resp.json();
        if (data.code === 'auth/token-expired') {
          if (!ApiRequest.refreshLock) {
            ApiRequest.refreshLock = true;

            try {
              const refresh_res = await this._refreshToken();
              req.headers.set('Authorization', `Bearer ${refresh_res.token}`);

              ApiRequest.refreshLock = false;
            } catch (e) {
              // eslint-disable-next-line no-console
              console.error(e);
              ApiRequest.refreshLock = false;
            }
          } else {
            let c = 0;
            do {
              c++;
              await timeWait(50);
            } while (ApiRequest.refreshLock && c < 600000);

            if (ApiRequest.refreshLock) throw new Error('refresh lock timeout');
          }

          token = await storage.getItem('token');
          req.headers.set('Authorization', `Bearer ${token}`);

          resp = await fetch(url, req);

          if (resp.status === 401) {
            throw await resp.json();
          }
        } else {
          throw data;
        }
      }

      if (
        resp.headers.get('X-App-Update') === 'yes' &&
        ApiRequest.onAppUpdate
      ) {
        ApiRequest.onAppUpdate();
      }

      if (resp.status === 204) {
        return Promise.resolve(true);
      }

      const contentType = resp.headers.get('content-type');
      if (contentType && contentType.indexOf('application/json') !== -1) {
        const json = await resp.json();

        // if (process.env.NODE_ENV !== 'production') {
        //   /* eslint-disable */
        //   console.log('API RES:', JSON.stringify(json, null, 2));
        //   /* eslint-enable */
        // }

        if (resp.status >= 200 && resp.status <= 300) {
          return Promise.resolve(json);
        }
        throw json;
      }
      throw resp;
    } catch (err) {
      err.statusCode = resp && resp.status;
      err.url = resp && resp.url;
      return Promise.reject(err);
    }
  }

  get(path, { qs = null, options = {} } = {}) {
    return this._fetch('GET', path, {
      qs,
      options
    });
  }

  post(path, { qs = null, options = {}, body = {}, encodeType = 'json' } = {}) {
    return this._fetch('POST', path, {
      qs,
      options,
      body,
      encodeType
    });
  }

  patch(
    path,
    { qs = null, options = {}, body = {}, encodeType = 'json' } = {}
  ) {
    return this._fetch('PATCH', path, {
      qs,
      options,
      body,
      encodeType
    });
  }

  put(path, { qs = null, options = {}, body = {}, encodeType = 'json' } = {}) {
    return this._fetch('PUT', path, {
      qs,
      options,
      body,
      encodeType
    });
  }

  delete(path, { qs = null, options = {} } = {}) {
    return this._fetch('DELETE', path, {
      qs,
      options
    });
  }
}
