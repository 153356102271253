const emailRegExp = new RegExp(
  '^[a-zA-Z0-9][a-zA-Z0-9\\-\\_\\.]*@[a-zA-Z0-9][a-zA-Z0-9\\-\\.]+$'
);
export const phoneRegExp = new RegExp('^\\+380[\\d]{9}$');

export const validate = (val, types) => {
  if (Array.isArray(types)) {
    return types.every((type) => validateByType(val, type));
  } else {
    return validateByType(val, types);
  }
};

export const validateByType = (val, type) => {
  if (type === 'require') return isEmpty(val);
  if (type.indexOf('maxLength') === 0) return isMaxLength(val, type);
  if (type.indexOf('minLength') === 0) return isMinLength(val, type);
  if (type === 'isPrice') return isPrice(val);
  if (type === 'isNumeric') return isNumeric(val);
  if (type === 'isInteger') return isInteger(val);
  if (type === 'isPositiveInteger') return isPositiveInteger(val);
  if (type === 'email') return isEmail(val);
  if (type === 'phone') return isPhone(val);
  return true;
};

export const isEmpty = (value) => {
  const type = Object.prototype.toString.call(value).slice(8, -1).toLowerCase();
  if (type === 'array') return !!value.length;
  if (type === 'object') return !!Object.keys(value).length;
  return value ? true : false;
};

export const isMaxLength = (value, maxLength) => {
  if (!value) return true;
  const l = Number(maxLength.split('=')[1]);
  return value.toString().length <= l ? true : false;
};

export const isMinLength = (value, minLength) => {
  const l = Number(minLength.split('=')[1]);
  return value && value.toString().trim().length >= l ? true : false;
};

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function isPrice(n) {
  return /^[0-9]*([0-9]+\.[0-9]{0,2})?$/.test(n);
}

export function isInteger(num) {
  return isNumeric(num) && num - Math.floor(num) === 0;
}

export function isPositiveInteger(num) {
  return isInteger(num) && num > 0;
}

export function isEmail(value) {
  return emailRegExp.test(value);
}

export function isPhone(value) {
  return phoneRegExp.test(value);
}
