import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { geo as geoApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';
import { showSnackbarError, showSnackbarSuccess } from 'modules/notifier';

export const actionTypes = {
  GET_LIST: 'GEO_STREETS_GET_LIST',

  GET_ONE: 'GEO_STREETS_GET_ONE',
  CREATE_ONE: 'GEO_STREETS_CREATE_ONE',
  UPDATE_ONE: 'GEO_STREETS_UPDATE_ONE'
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

const selector = (state) => {
  return state.geoStreets;
};

export const getList = (param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST;
  const next = {
    order: 'asc',
    orderBy: 'name',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } = await geoApi.getStreets(request);

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const getOne = (id) => async (dispatch, getState) => {
  const actionType = actionTypes.GET_ONE;

  const payload = { loading: true };
  const oldItem = selector(getState()).one.data;
  if (oldItem && Number(id) !== oldItem.id) {
    payload.data = null;
  }
  dispatch(updateRedux(actionType, payload));

  try {
    const res = await geoApi.getStreet(id);
    dispatch(
      updateRedux(actionType, {
        data: res,
        loading: false
      })
    );
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Не найдено' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const createOne = (data) => async (dispatch) => {
  const actionType = actionTypes.CREATE_ONE;
  dispatch(updateRedux(actionType, { loading: true }));
  try {
    const request = Query.createRequest(data);
    const res = await geoApi.createStreets(request);
    dispatch(
      updateRedux(actionType, {
        loading: false
      })
    );
    dispatch(showSnackbarSuccess({ message: 'Создано' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.code === 'api/exist') {
      dispatch(showSnackbarError({ message: 'Уже существует' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const updateOne = (id, data) => async (dispatch) => {
  const actionType = actionTypes.UPDATE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));

  try {
    const request = Query.createRequest(data);
    const res = await geoApi.updateStreets(id, request);
    dispatch(
      updateRedux(actionType, {
        loading: false
      })
    );
    dispatch(showSnackbarSuccess({ message: 'Обновлено' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.code === 'api/exist') {
      dispatch(showSnackbarError({ message: 'Уже существует' }));
    } else if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Не найдено' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};
