import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { customerOrders as resourceApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';
import { showSnackbarError, showSnackbarSuccess } from 'modules/notifier';

export const actionTypes = {
  GET_LIST: 'CUSTOMERS_ORDERS_GET_LIST',
  GET_ONE: 'CUSTOMERS_ORDERS_GET_ONE',
  UPDATE_ONE: 'CUSTOMERS_ORDERS_UPDATE_ONE',
  CLEAR_ONE: 'CUSTOMERS_ORDERS_CLEAR_ONE'
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

const defaultQuery = {
  order: 'desc',
  orderBy: 'id',
  filter: {},
  offset: 0,
  limit: PAGINATION_TABLE_LIMIT
};

export const getList =
  (param = {}) =>
  async (dispatch) => {
    const actionType = actionTypes.GET_LIST;
    const next = {
      ...defaultQuery,
      ...param,
      filter: {
        ...param.filter
      }
    };

    const request = Query.createGetRequest(next);

    dispatch(
      updateRedux(actionType, {
        loading: true,
        params: next,
        list: []
      })
    );

    try {
      const { results: list = [], count = 0 } = await resourceApi.getList(
        request
      );
      const res = {
        list,
        count,
        loading: false
      };

      dispatch(updateRedux(actionType, res));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      dispatch(updateRedux(actionType, { loading: false }));
      return Promise.reject(err);
    }
  };

export const updateOne = (order_id, data) => async (dispatch) => {
  const actionType = actionTypes.GET_ONE;
  const actionTypeUpdate = actionTypes.UPDATE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));
  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.updateOne(order_id, request);
    dispatch(
      updateRedux(actionType, {
        loading: false
      })
    );
    dispatch(updateRedux(actionTypeUpdate, data));
    dispatch(showSnackbarSuccess({ message: 'Запись обновлена' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};

export const getOne = (id) => async (dispatch, getState) => {
  const actionType = actionTypes.GET_ONE;

  const payload = { loading: true };
  const oldItem = getState().customersOrders.one.data;
  if (oldItem && Number(id) !== oldItem.id) {
    payload.data = null;
  }
  dispatch(updateRedux(actionType, payload));

  try {
    const res = await resourceApi.getOne(id);
    dispatch(
      updateRedux(actionType, {
        data: res,
        loading: false
      })
    );
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Заявка не найдена' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const clearOne = () => {
  const actionType = actionTypes.CLEAR_ONE;
  return {
    type: actionType
  };
};
