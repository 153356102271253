import List from './List';
import reducer from './reducer';
import Show from './Show';

const CustomersOrders = {
  List,
  Show,
  reducer
};

export default CustomersOrders;
