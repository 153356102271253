import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { IconBtnFilter } from 'components/buttons/IconBtn';

import { FilterContext } from './FilterProvider';

const FilterBtn = (props) => {
  const context = React.useContext(FilterContext);
  const { registredFilters } = context;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const showFilter = (it) => {
    handleMenuClose();
    context.showFilter(it.source);
  };

  const unvisibleFields = [];
  Object.keys(registredFilters).forEach((source) => {
    const filter = registredFilters[source];
    if (filter && filter.unvisible) unvisibleFields.push(filter);
  });

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}>
      {unvisibleFields.map((it) => {
        if (!it.unvisible) return null;
        return (
          <MenuItem key={it.source} onClick={() => showFilter(it)}>
            {it.label ? it.label : it.source}
          </MenuItem>
        );
      })}
    </Menu>
  );

  if (!unvisibleFields.length) return null;

  return (
    <>
      <IconBtnFilter onClick={handleProfileMenuOpen} />
      {renderMenu}
    </>
  );
};

export default React.memo(FilterBtn);
