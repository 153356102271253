import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import Grid from '@material-ui/core/Grid';

import { AVAILABLE_TYPES } from 'constants/config';

import { inChildren } from 'services/utils';

import * as executorsActions from 'resources/Executors/actions';
import {
  FormDateField,
  FormSearchElasticExecutorField,
  FormSelectField,
  SimpleForm
} from 'components/Form';
import Screen from 'components/Screen';

import * as actions from './actions';

const available_types = Object.keys(AVAILABLE_TYPES).map((type) => ({
  label: AVAILABLE_TYPES[type],
  value: type
}));

const validate = (values) => {
  const errors = {};
  const { date_from, date_to, executor } = values;
  if (!date_from) errors.date_from = 'Укажите дату';
  if (!date_to) errors.date_to = 'Укажите дату';

  if (date_from && date_to) {
    if (moment(date_from).isSameOrAfter(moment(date_to), 'minute')) {
      errors.date_to = 'Должна быть больше даты, с';
    }
  }

  if (!executor) {
    errors.executor = 'Укажите дезинфекторов';
  }
  return errors;
};

const formName = 'add_executor_available';

class Container extends Component {
  constructor(props) {
    super(props);
    const { location = {} } = props;
    const { state = {} } = location;
    const { copy_id } = state;
    if (copy_id) this.handleGetOne(copy_id);
    this.state = { copy_id };
  }

  handleGetOne = async (id) => {
    const { executorsAvailableActions, history = {} } = this.props;
    try {
      await executorsAvailableActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  componentWillUnmount() {
    const { executorsAvailableActions } = this.props;
    executorsAvailableActions.clearOne();
  }

  handleCreate = async (data) => {
    const {
      loading,
      executorsActions,
      executorsAvailableActions,
      history = {}
    } = this.props;
    if (loading) return;

    try {
      const { executor_id, ...restDate } = data;

      executorsAvailableActions.setLoading(true);
      await executorsActions.createExecutorAvailable(executor_id, restDate);
      executorsAvailableActions.setLoading(false);
      history.goBack();
    } catch (err) {
      executorsAvailableActions.setLoading(false);
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleCreate: this.handleCreate,
      copy_id: this.state.copy_id
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorsActions: bindActionCreators(executorsActions, dispatch),
  executorsAvailableActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleCreate, loading, record = {}, copy_id } = props;

  const history = useHistory();
  const back = history.goBack;

  const [available, setAvailable] = React.useState('no');

  const date_from =
    copy_id && record && record.date_from
      ? moment(record.date_from).add(7, 'd')
      : null;
  const date_to =
    copy_id && record && record.date_to
      ? moment(record.date_to).add(7, 'd')
      : null;

  return (
    <Screen title="Добавить отсутствие дезинфектора">
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleCreate}
        disabled={loading}
        onBack={back}
        direction="row"
        validate={validate}
        initialValues={{
          is_available: 'no',
          available_type: null,
          ...record,
          date_from,
          date_to
        }}
        parse={(values) => {
          const res = { ...values };
          delete res.executor;
          res.executor_id = values.executor.id;
          res.available_type =
            values.is_available === 'yes'
              ? null
              : values.available_type || null;
          return res;
        }}>
        <Grid item xs={12} sm={6}>
          <FormSearchElasticExecutorField
            source="executor"
            label="Дезинфектор"
            is_active="yes"
            disabled={loading /* || !!(record && record.executor_id)*/}
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12} sm={6}>
          <FormSelectField
            source="is_available"
            label="Отсутствие"
            disabled={loading}
            onChange={(v) => setAvailable(v)}
            options={[
              { value: 'no', label: 'Отсутствует' },
              { value: 'yes', label: 'Доступен' }
            ]}
          />
        </Grid>
        {available !== 'yes' && (
          <Grid item xs={12} sm={6}>
            <FormSelectField
              source="available_type"
              label="Причина отсутствия"
              disabled={loading}
              options={[
                { value: null, label: 'Не указана' },
                ...available_types
              ]}
            />
          </Grid>
        )}
        <Grid item xs={12} />
        <Grid item xs={12} sm={6}>
          <FormDateField
            label="Дата, с"
            source="date_from"
            dateType="datetime"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormDateField
            label="Дата, по"
            source="date_to"
            dateType="datetime"
            disabled={loading}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const ExecutorsAvailableAdd = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ExecutorsAvailableAdd;
