import DistrictIcon from './icons/DistrictIcon';
import StreetIcon from './icons/StreetIcon';
import UIIcon from './icons/UAIcon';

export default function IconSvg({ name, ...rest }) {
  switch (name) {
    case 'ua':
      return <UIIcon {...rest} />;
    case 'district':
      return <DistrictIcon {...rest} />;
    case 'street':
      return <StreetIcon {...rest} />;

    default:
      return null;
  }
}
