import { TIME_FORMAT } from 'constants/config';

export const createTimeRangeString = (from, to) => {
  const t_from = from.format(TIME_FORMAT);
  const t_to = to.format(TIME_FORMAT);
  return `${t_from}-${t_to}`;
};

export const mergeDateDistances = (distances = []) => {
  const source = distances.map((it) => ({ ...it }));
  // sort if from less to
  source.sort((a, b) => (a.from.isBefore(b.from) ? -1 : 1));
  const target = [];
  let currentIt = null;

  source.forEach((it) => {
    // if first get as target
    if (!currentIt) {
      currentIt = it;
      target.push(currentIt);
      return;
    }
    // check next distance from insect to target
    const isIntersect = it.from.isBetween(
      currentIt.from,
      currentIt.to,
      null,
      '[]'
    );
    if (isIntersect) {
      const isAfter = it.to.isAfter(currentIt.to);
      // if next distance larger - target continue to him and the passes next
      if (isAfter) currentIt.to = it.to.clone();
    } else {
      // if not this is next not insected distance
      // next check with it
      currentIt = it;
      target.push(currentIt);
      return;
    }
  });
  return target;
};

// date range available
export const exludeDateDistances = (include = [], exclude = []) => {
  let result = include.map((it) => ({ ...it }));
  if (!exclude || !exclude.length) return result;

  // check every exclude distance with every include
  for (let i = 0; i < exclude.length; i++) {
    const exDis = exclude[i];
    result = result.reduce(function (previousValue, currentValue) {
      const incDis = currentValue;
      // check include from is between exclude
      const isIntersectInS = incDis.from.isBetween(
        exDis.from,
        exDis.to,
        null,
        '[]'
      );
      // check include to is between exclude
      const isIntersectInE = incDis.to.isBetween(
        exDis.from,
        exDis.to,
        null,
        '[]'
      );

      // 1 - case
      // exclude completely larger - not push include to res
      if (isIntersectInS && isIntersectInE) return previousValue;

      // check exclude from is between include
      const isIntersectExS = exDis.from.isBetween(
        incDis.from,
        incDis.to,
        null,
        '[]'
      );
      // check exclude from is between include
      const isIntersectExE = exDis.to.isBetween(
        incDis.from,
        incDis.to,
        null,
        '[]'
      );

      // 2 - case
      // exclude completely less - break include to two distance by exclude
      if (isIntersectExS && isIntersectExE) {
        const first = {
          ...incDis,
          from: incDis.from.clone(),
          to: exDis.from.clone().add(-1, 'minutes')
        };
        const second = {
          ...incDis,
          from: exDis.to.clone().add(1, 'minutes'),
          to: incDis.to.clone()
        };
        let tmpResult = previousValue;
        if (checkDateRange(first)) tmpResult = tmpResult.concat(first);
        if (checkDateRange(second)) tmpResult = tmpResult.concat(second);
        return tmpResult;
      }

      // 3 - case
      // include start outside and finish inside exclude
      if (!isIntersectInS && isIntersectInE) {
        // shorten include finish to exclude start
        const newIncDes = {
          ...incDis,
          from: incDis.from.clone(),
          to: exDis.from.clone().add(-1, 'minutes')
        };
        if (checkDateRange(newIncDes)) return previousValue.concat(newIncDes);
        return previousValue;
      }

      // 4 - case
      // include finish outside and start inside exclude
      if (isIntersectInS && !isIntersectInE) {
        // shorten include start to exclude finish
        const newIncDes = {
          ...incDis,
          from: exDis.to.clone().add(1, 'minutes'),
          to: incDis.to.clone()
        };
        if (checkDateRange(newIncDes)) return previousValue.concat(newIncDes);
        return previousValue;
      }

      // 5 - case
      // not insect distances - push include to result
      // simple check date range
      if (checkDateRange(incDis)) return previousValue.concat(incDis);
      return previousValue;
    }, []);
  }
  return result;
};

export const checkDateRange = (it, l = 1, type = 'minutes') => {
  const diffH = it.to.diff(it.from, type);
  return diffH >= l ? it : false;
};

export const breakToDistances = (
  source,
  breakPoint = 120,
  breakType = 'minutes'
) => {
  let res = [];
  const distances = source.map((it) => ({ ...it }));
  distances.forEach((distance) => {
    const diffH = distance.to.diff(distance.from, breakType);
    if (diffH < breakPoint * 2) {
      res.push(distance);
    } else {
      const breakDate = distance.from.clone();
      breakDate.add(breakPoint, breakType);
      const first = {
        ...distance,
        from: distance.from.clone(),
        to: breakDate.clone().add(-1, 'minutes')
      };
      const second = {
        ...distance,
        from: breakDate.clone(),
        to: distance.to.clone()
      };
      res.push(first);
      const temp = breakToDistances([second], breakPoint, breakType);
      res = res.concat(temp);
    }
  });
  return res;
};
