import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { stopSubmit } from 'redux-form';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { PHONE_FORMAT } from 'constants/config';

import { inChildren, validateErrors } from 'services/utils';

import { ShowHistoryBtn } from 'resources/ResourceHistory';
import {
  FormCheckboxField,
  FormPhoneField,
  FormSearchGeoStreetsField,
  FormSearchSelectField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};

  const { name, email, phone } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  if (email) {
    errors.email = validateErrors(email, {
      email: 'Не валидный e-mail'
    });
  }

  errors.phone = validateErrors(phone, {
    require: 'Обязательное поле',
    phone: PHONE_FORMAT.placeholder
  });

  return errors;
};

const defaultFormName = 'edit_client';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      clientsActions,
      history = {}
    } = this.props;
    try {
      await clientsActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const {
      clientsActions,
      record = {},
      loading,
      history,
      dispatch
    } = this.props;
    if (loading) return;
    try {
      await clientsActions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(defaultFormName, { phone: 'Уже существует' }));
      }
    }
  };

  handleDelete = async () => {
    const { clientsActions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить клиента?'
      })
    );

    if (res) {
      try {
        await clientsActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  clientsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleUpdate,
    handleDelete,
    record = {},
    loading,
    formName = defaultFormName
  } = props;

  const itemReceived = record && record.id;
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Редактировать клиента: ${record.name}`;
  }

  const can_write = useCan('clients:update');
  const can_manage_user = useCan('clients:manage_user');

  return (
    <Screen title={title}>
      <Box p={1}>
        {itemReceived && (
          <Grid container justifyContent="flex-end">
            <ShowHistoryBtn itemType="clients" itemId={record.id} />
          </Grid>
        )}
      </Box>
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        onBack={back}
        onDelete={can_write && handleDelete}
        direction="row"
        validate={validate}
        initialValues={{
          ...record,
          send_sms: (record && record.send_sms) || 'yes'
        }}
        parse={(values) => {
          const res = {
            name: values.name || '',
            email: values.email || '',
            phone: values.phone || '',
            address_house: values.address_house || '',
            address_apartment: values.address_apartment || '',
            comment: values.comment || '',
            send_sms: values.send_sms
          };

          if (values.group) res.group_id = values.group.id;
          else res.group_id = null;

          if (values.street) {
            res.locality_id = values.street.locality_id;
            res.street_id = values.street.id;
          } else {
            res.locality_id = null;
            res.street_id = null;
          }

          if (can_manage_user) {
            if (values.user_id) {
              res.user_id = values.user_id;
            } else {
              res.user_id = null;
            }
          }
          return res;
        }}>
        <Grid item xs={12} sm={6}>
          <FormTextField source="name" label="Имя" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSearchSelectField
            source="group"
            label="Группа"
            requestPath="/client_groups"
            responseFormat={(v) => v.results}
            renderOption={(it) => it.name}
            disabled={loading}
            searchBy="name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField source="email" label="E-mail" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormPhoneField source="phone" label="Тел." disabled={loading} />
        </Grid>
        <Grid item xs={12}>
          <FormSearchGeoStreetsField
            source="street"
            label="Адрес"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="address_house"
            label="Дом"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="address_apartment"
            label="Квартира"
            disabled={loading}
            type="number"
          />
        </Grid>
        {can_manage_user ? (
          <Grid item xs={12} sm={6}>
            <FormSearchSelectField
              source="user_id"
              alwaysOn
              label="Оператор"
              requestPath="/users"
              responseFormat={(v) => v.results}
              disableClearable={true}
              getValue={(it) => (it ? it.id : null)}
              getOptionLabel={(it) =>
                it ? [it.first_name, it.last_name].join(' ') : ''
              }
              searchBy={['first_name', 'last_name']}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <FormTextField
            source="comment"
            label="Примечание"
            disabled={loading}
            multiline={true}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCheckboxField
            source="send_sms"
            label="Отправлять СМС"
            disabled={loading}
            parse={(v) => (v ? 'yes' : 'no')}
            format={(v) => v === 'yes'}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const ClientsEdit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ClientsEdit;
