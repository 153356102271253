import { stats } from 'services/api';
import ErrorService from 'services/ErrorService';

export const STATS_GET = 'STATS_GET';

export const getStats = () => async (dispatch) => {
  try {
    const res = await stats.getStats();
    dispatch({
      type: STATS_GET,
      payload: res
    });
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};

export const getStatsBookkeeping = () => async (dispatch) => {
  try {
    const res = await stats.getStatsBookkeeping();
    dispatch({
      type: STATS_GET,
      payload: {
        bookkeeping_change_count: res.change_count,
        bookkeeping_new_count: res.new_count
      }
    });
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};
