import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { inChildren, validateErrors } from 'services/utils';

import { ReferenceField } from 'components/Field';
import { FormSelectField, FormTextField, SimpleForm } from 'components/Form';
import Screen from 'components/Screen';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};
  const { name } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  return errors;
};

const formName = 'add_group';

class Container extends Component {
  handleCreate = async (data) => {
    const { loading, groupsActions, dispatch, history = {} } = this.props;
    if (loading) return;

    try {
      await groupsActions.createOne(data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(formName, { name: 'Уже существует' }));
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleCreate: this.handleCreate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  groupsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleCreate, loading } = props;
  const history = useHistory();
  const back = history.goBack;
  return (
    <Screen title="Добавить группу">
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleCreate}
        disabled={loading}
        onBack={back}
        validate={validate}
        initilaValues={{
          roles: []
        }}
        parse={(values) => {
          const roles = values.roles || [];
          return {
            name: values.name || '',
            roles: JSON.stringify(roles)
          };
        }}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="name"
            label="Название группы"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReferenceField path="/roles" format={(res) => res.results}>
            {({ result }) => {
              return (
                <FormSelectField
                  label="Роли"
                  disabled={loading}
                  source="roles"
                  multiple={true}
                  options={
                    result && result.length
                      ? result.map((it) => ({ label: it, value: it }))
                      : []
                  }
                />
              );
            }}
          </ReferenceField>
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const GroupsAdd = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default GroupsAdd;
