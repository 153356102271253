import React, { Component } from 'react';

import { request } from 'services/api';
import { inChildren } from 'services/utils';

class FormReferenceField extends Component {
  constructor(props) {
    super(props);
    this.value = props.value;
    this.state = {
      loading: false,
      result: null
    };
  }

  componentDidMount() {
    this.isMount = true;
    this.get();
  }

  componentWillUnmount() {
    this.isMount = false;
  }

  componentDidUpdate(prev) {
    if (prev.path !== this.props.path || prev.value !== this.props.value) {
      this.get();
      this.value = this.props.value;
    }
  }
  get = async () => {
    const { path = '', format, value, where = {} } = this.props;
    let url = '';
    if (path) url = path;
    if (url && value !== undefined) {
      if (!value) {
        this.setState({
          loading: false,
          result: null
        });
        return;
      }
      url = `${url}/${value}`;
    }
    if (url) {
      try {
        this.setState({ loading: true });
        let res = await request.get(url, { qs: { where } });
        if (res && format) res = format(res);
        if (this.isMount && value === this.value) {
          this.setState({
            loading: false,
            result: res
          });
        }
      } catch (err) {
        if (this.isMount) this.setState({ loading: false });
      }
    }
  };

  render() {
    const { children } = this.props;
    return inChildren(children, {
      ...this.state
    });
  }
}

export default React.memo(FormReferenceField);
