import React from 'react';

import Grid from '@material-ui/core/Grid';

import { LabelField } from 'components/Field';

const UserInfo = (props) => {
  const { record = {} } = props;
  return (
    <div>
      {record && record.id ? (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <LabelField width={100} label="Логин:" value={record.login} />
          </Grid>
          <Grid item>
            <LabelField width={100} label="E-mail:" value={record.email} />
          </Grid>
          <Grid item>
            <LabelField width={100} label="Имя:" value={record.first_name} />
          </Grid>
          <Grid item>
            <LabelField width={100} label="Фамилия:" value={record.last_name} />
          </Grid>
          <Grid item>
            <LabelField
              width={100}
              label="Тел.:"
              value={record.phone}
              type="phone"
            />
          </Grid>
          <Grid item>
            <LabelField
              width={100}
              label="Активен:"
              value={record.is_active}
              type="boolean"
              format={(v) => v === 'yes'}
            />
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default UserInfo;
