import React from 'react';

import Grid from '@material-ui/core/Grid';

import { concatGeoFullName } from 'services/utils';

import { LabelField } from 'components/Field';

const GroupInfo = (props) => {
  const { record = {} } = props;
  return (
    <div>
      {record && record.id ? (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <LabelField width={100} label="Имя:" value={record.name} />
          </Grid>
          <Grid item>
            <LabelField
              width={100}
              label="Дислокация:"
              value={record.locality}
              format={concatGeoFullName}
            />
          </Grid>
          <Grid item>
            <LabelField
              label="Кол-во дезинфекторов"
              value={record.executors_count}
              type="number"
            />
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default GroupInfo;
