import { showSnackbarError } from 'modules/notifier';

import { logout } from 'store/actions/authActions';

class ErrorService {
  getError = (err) => {
    if (err && err.message === 'Failed to fetch') {
      return 'Ошибка соединения, попробуйте позже';
    }

    if (err && err.code === 'phone_call/wrong-client-phone') {
      return 'У клиента не указан номер телефона';
    }

    if (err && err.code === 'phone_call/wrong-user-phone') {
      return 'Не указан телефон пользователя';
    }

    if (err && err.code === 'phone_call/call-exist') {
      return 'Запрос на звонок по данной заявке уже создан, пожалуйста подождите';
    }

    if (err && err.statusCode === 500) {
      return 'Ошибка сервера, попробуйте позже';
    }

    if (err && err.statusCode === 401) {
      return 'Ошибка авторизации';
    }

    if (err && err.code === 'api/forbidden') {
      return 'Недостаточно прав';
    }

    if (err && err.code) {
      switch (err.code) {
        default:
          return `${err.error || ''} / ${err.code || ''}`;
      }
    }

    if (err instanceof Error) {
      return err.message;
    }

    return err.toString();
  };

  dispatchError =
    (err, messages = {}) =>
    (dispatch) => {
      let message = '';
      if (err && err.code && messages) {
        message = messages[err.code];
      }

      if (err && err.code === 'api/client-outdated') {
        window.location.reload(true);
        return;
      }

      if (!message) message = this.getError(err);

      dispatch(
        showSnackbarError({
          message
        })
      );

      if (err && err.statusCode === 401) {
        dispatch(logout());
        return;
      }
    };
}

const errorService = new ErrorService();
export default errorService;
