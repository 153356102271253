import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Chip from '@material-ui/core/Chip';

import { inChildren } from 'services/utils';

import { attachUserGroup, detachUserGroup } from 'resources/Groups/actions';
import { AddBtn } from 'components/buttons/Btn';
import { IconBtnDelete } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, { FilterSearchField } from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import { show as showModalConfirm } from 'modals/Confirm';
import GroupList from 'modals/GroupList';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    this.getList();
  }

  refresh = () => {
    const { groups = {} } = this.props;
    const { params = {} } = groups;
    this.getList(params);
  };

  getList = (params) => {
    const { user_id, usersActions } = this.props;
    usersActions.getUserGroups(user_id, params);
  };

  handleAttach = async (group_id) => {
    const { user_id, groupsActions } = this.props;
    try {
      await groupsActions.attachUserGroup(group_id, user_id);
      this.refresh();
    } catch (err) {
      console.error(err);
    }
  };

  handleDetach = async (group_id) => {
    const { user_id, otherActions, groupsActions } = this.props;
    const res = await otherActions.showModalConfirm({
      title: 'Редактирование',
      content: 'Отвязать пользователя от группы?'
    });

    if (res) {
      try {
        await groupsActions.detachUserGroup(group_id, user_id);
        this.refresh();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleRefresh: this.refresh,
      handleDetach: this.handleDetach,
      handleAttach: this.handleAttach
    });
  }
}

const mapStateToProps = (state) => {
  const target = state.users;
  const { groups = {} } = target;
  return {
    record: groups
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  usersActions: bindActionCreators(actions, dispatch),
  groupsActions: bindActionCreators(
    {
      attachUserGroup,
      detachUserGroup
    },
    dispatch
  ),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleDetach, handleAttach } = props;

  const [show, setShow] = useState(false);
  const onClose = () => {
    setShow(false);
  };
  const onSelect = (id) => {
    setShow(false);
    handleAttach(id);
  };

  const is_writable = useCan('groups:write');

  return (
    <div>
      <List
        {...props}
        title="Группы пользователя"
        toolBarInner={is_writable && <AddBtn onClick={() => setShow(true)} />}
        filter={
          <Filter>
            <FilterSearchField
              source="group"
              label="Название группы"
              alwaysOn
              searchPath="group.name"
            />
          </Filter>
        }>
        <Field label="ID" source="group.id" sortable />
        <Field label="Название группы" source="group.name" sortable />
        <Field
          label="Роли"
          source="group.roles"
          format={(v) => (v ? JSON.parse(v) : [])}>
          {({ value }) => value.map((it) => <Chip key={it} label={it} />)}
        </Field>
        <Field label="Дата" source="created_at" type="date" sortable />
        <Field source="group.id" isAction>
          {({ value }) => {
            return (
              <React.Fragment>
                {is_writable && (
                  <IconBtnDelete onClick={() => handleDetach(value)} />
                )}
              </React.Fragment>
            );
          }}
        </Field>
      </List>
      <GroupList
        isOpen={show}
        title="Добавить группу"
        handleClose={onClose}
        onSelect={onSelect}
      />
    </div>
  );
};

const UsersGroups = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default UsersGroups;
