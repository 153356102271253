import ApiRequest from './ApiRequest';

export default class Executors extends ApiRequest {
  getList(params) {
    return this.get(`/executors`, {
      qs: params
    });
  }
  getOne(id) {
    return this.get(`/executors/${id}`);
  }
  createOne(req) {
    return this.post(`/executors`, {
      body: req
    });
  }
  updateOne(id, req) {
    return this.put(`/executors/${id}`, {
      body: req
    });
  }
  deleteOne(id) {
    return this.delete(`/executors/${id}`);
  }

  getExecutorsUsers(id, req) {
    return this.get(`/executors/${id}/users`, {
      qs: req
    });
  }

  attachUserExecutor(exec_id, user_id) {
    return this.post(`/executors/${exec_id}/users/${user_id}`);
  }

  updateUserExecutor(exec_id, user_id, req) {
    return this.put(`/executors/${exec_id}/users/${user_id}`, {
      body: req
    });
  }

  detachUserExecutor(exec_id, user_id) {
    return this.delete(`/executors/${exec_id}/users/${user_id}`);
  }

  getExecutorsAvailable(exec_id, req) {
    return this.get(`/executors/${exec_id}/available`, {
      qs: req
    });
  }
  createExecutorAvailable(exec_id, req) {
    return this.post(`/executors/${exec_id}/available`, {
      body: req
    });
  }

  updateExecutorAvailable(exec_id, available_id, req) {
    return this.put(`/executors/${exec_id}/available/${available_id}`, {
      body: req
    });
  }

  deleteExecutorAvailable(exec_id, available_id) {
    return this.delete(`/executors/${exec_id}/available/${available_id}`);
  }

  getExecutorsSchedule(exec_id, req) {
    return this.get(`/executors/${exec_id}/schedule`, {
      qs: req
    });
  }
  createExecutorSchedule(exec_id, req) {
    return this.post(`/executors/${exec_id}/schedule`, {
      body: req
    });
  }

  updateExecutorSchedule(exec_id, schedule_id, req) {
    return this.put(`/executors/${exec_id}/schedule/${schedule_id}`, {
      body: req
    });
  }

  deleteExecutorSchedule(exec_id, schedule_id) {
    return this.delete(`/executors/${exec_id}/schedule/${schedule_id}`);
  }

  getSchedule(req) {
    return this.get(`/schedule`, {
      qs: req
    });
  }

  getAvailableList(req) {
    return this.get(`/available`, {
      qs: req
    });
  }

  getAvailableOne(id) {
    return this.get(`/available/${id}`);
  }

  getMileageList(exec_id, req) {
    return this.get(`/executors/${exec_id}/mileage`, {
      qs: req
    });
  }

  createMileage(exec_id, req) {
    return this.post(`/executors/${exec_id}/mileage`, {
      body: req
    });
  }

  updateMileage(exec_id, mileage_id, req) {
    return this.put(`/executors/${exec_id}/mileage/${mileage_id}`, {
      body: req
    });
  }

  deleteMileage(exec_id, mileage_id) {
    return this.delete(`/executors/${exec_id}/mileage/${mileage_id}`);
  }

  getExecutorsTurnover(date, req) {
    return this.get(`/turnover/${date}`, {
      qs: req
    });
  }
}
