import ApiRequest from './ApiRequest';

export default class WarehousBalances extends ApiRequest {
  getList(params) {
    return this.get(`/warehouse_balance/`, {
      qs: params
    });
  }
  getOne(id) {
    return this.get(`/warehouse_balance/${id}`);
  }
}
