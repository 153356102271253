import ApiRequest from './ApiRequest';
import Auth from './Auth';
import ClientGroups from './ClientGroups';
import Clients from './Clients';
import CustomerOrders from './CustomerOrders';
import ExecutorPlaces from './ExecutorPlaces';
import Executors from './Executors';
import Geo from './Geo';
import Groups from './Groups';
import History from './History';
import Maps from './Maps';
import Materials from './Materials';
import Methods from './Methods';
import Orders from './Orders';
import PayTypes from './PayTypes';
import Projects from './Projects';
import Push from './Push';
import Reasons from './Reasons';
import Reminders from './Reminders';
import Reports from './Reports';
import Sessions from './Sessions';
import Stats from './Stats';
import Users from './Users';
import WarehousBalances from './WarehousBalances';
import Warehouse from './Warehouse';

const API_URL = process.env.REACT_APP_API_URL;

export const request = new ApiRequest(API_URL);
export const auth = new Auth(API_URL);

export const groups = new Groups(API_URL);
export const users = new Users(API_URL);
export const orders = new Orders(API_URL);

export const clientGroups = new ClientGroups(API_URL);
export const clients = new Clients(API_URL);

export const executors = new Executors(API_URL);
export const executor_places = new ExecutorPlaces(API_URL);

export const reasons = new Reasons(API_URL);
export const payTypes = new PayTypes(API_URL);
export const methods = new Methods(API_URL);
export const sessions = new Sessions(API_URL);

export const push = new Push(API_URL);
export const reports = new Reports(API_URL);
export const stats = new Stats(API_URL);
export const history = new History(API_URL);
export const projects = new Projects(API_URL);
export const customerOrders = new CustomerOrders(API_URL);
export const materials = new Materials(API_URL);
export const warehouse = new Warehouse(API_URL);
export const warehousBalances = new WarehousBalances(API_URL);
export const maps = new Maps();
export const reminders = new Reminders(API_URL);
export const geo = new Geo(API_URL);
