import Add from './Add';
import Edit from './Edit';
import List from './List';
import reducer from './reducer';

const Materials = {
  List,
  Add,
  Edit,
  reducer
};

export default Materials;
