import React from 'react';

import { FormSearchGeoLocalityField } from 'components/Form';

const FilterSearchGeoLocalityField = (props) => {
  const { source, children, ...rest } = props;

  return (
    <FormSearchGeoLocalityField
      name={source}
      submitOnChange={true}
      forced={true}
      disableClearable={false}
      {...rest}
    />
  );
};

export default React.memo(FilterSearchGeoLocalityField);
