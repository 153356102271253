import React from 'react';
import { connect } from 'react-redux';

import { getStats } from 'store/actions/statsActions';

class Stats extends React.Component {
  componentDidMount() {
    this.props.getStats();
  }
  render() {
    const { stats = {} } = this.props;
    return <StatsComponent {...stats} />;
  }
}

const StatsComponent = (props) => {
  const {
    user_yesterday_count = 0,
    user_today_count = 0,
    yesterday_count = 0,
    today_count = 0
  } = props;
  return (
    <div
      style={{
        fontSize: 12,
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <div>
        вчера: <b>{user_yesterday_count}</b> ({yesterday_count})
      </div>
      <div>
        сегодня: <b>{user_today_count}</b> ({today_count})
      </div>
    </div>
  );
};

const mapStateToPtops = (state) => {
  return {
    stats: state.stats
  };
};
export default connect(mapStateToPtops, {
  getStats
})(Stats);
