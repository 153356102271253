import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import TabControl from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

import { inChildren } from 'services/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  hidden: {
    display: 'none'
  }
}));

export const Tab = (props) => {
  const { children, value, eventKey } = props;
  return (
    <div
      hidden={value !== eventKey}
      // component="div"
      role="tabpanel"
      aria-labelledby={`tab-${eventKey}`}>
      {children}
    </div>
  );
};

const TabsControl = (props) => {
  const { children, value, onChange } = props;
  const classes = useStyles();

  const nav = [];
  let firstKey = '';

  React.Children.map(children, (child, i) => {
    const { props: childProps } = child;
    const { label, eventKey } = childProps;
    if (!firstKey) firstKey = eventKey;
    nav.push({
      eventKey,
      label
    });
  });

  const initVal = value || firstKey;

  if (!nav.length) return null;

  const handleChange = (event, newValue) => {
    if (onChange) {
      onChange(newValue);
      return;
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          aria-label="tabs"
          value={initVal}
          onChange={handleChange}
          variant="scrollable">
          {nav.map((it, i) => (
            <TabControl
              key={it.eventKey}
              label={it.label}
              value={it.eventKey}
            />
          ))}
        </Tabs>
      </AppBar>
      {React.Children.map(children, (child, i) => {
        // const { childProps = {} } = child;
        // const { eventKey } = childProps;
        return inChildren(child, {
          value: initVal
        });
      })}
    </div>
  );
};

export default TabsControl;
