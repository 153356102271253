import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useMakeUrlProjectId } from 'services/projectUtils';
import Query from 'services/Query';
import { inChildren } from 'services/utils';

import { Btn } from 'components/buttons/Btn';
import { IconBtnEdit } from 'components/buttons/IconBtn';
import DropMenu from 'components/DropMenu';
import Field from 'components/Field';
import Filter, {
  FilterDateRangeField,
  FilterSearchElasticClientField,
  FilterSearchElasticExecutorField,
  FilterSearchField,
  FilterSearchGeoElasticAddress,
  FilterSearchSelectField,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';
import Rbac from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { actions } = this.props;
      actions.getList(params);
    }
  };

  handleToNewUpdate = async (id, data) => {
    const { actions, record, dispatch } = this.props;
    const { params = {}, list = [] } = record;

    const res = await dispatch(
      showModalConfirm({
        title: 'Изменение',
        content: 'Изменить статус заявки на "Новая"?'
      })
    );

    if (res) {
      try {
        await actions.updateOne(id, data);
        const { offset = 0, limit = 0 } = params;
        if (offset && list.length === 1) {
          params.offset = offset - limit;
        }
        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  handleUpdateSubmit = async (id, data) => {
    const { actions, record, dispatch } = this.props;
    const { params = {} } = record;

    const res = await dispatch(
      showModalConfirm({
        title: 'Изменение',
        content: 'Изменить статус заявки?'
      })
    );

    if (res) {
      try {
        await actions.updateOne(id, data);
        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleToNewUpdate: this.handleToNewUpdate,
      handleUpdateSubmit: this.handleUpdateSubmit
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const makePath = useMakeUrlProjectId();
  const orderOnePath = makePath('/orders');

  const { handleToNewUpdate, handleUpdateSubmit, record } = props;

  const { params = {} } = record;

  const { filter = {} } = params;

  const lastUsedOrderId = sessionStorage.getItem('last_used_order_id');
  return (
    <Screen title="Заявки">
      <List
        {...props}
        rowLink={(row) => `${orderOnePath}/show/${row.id}`}
        rowStyle={(row) => {
          const style = {
            opacity: row.is_bookkeeper_submit === 'yes' ? 0.4 : 1
          };
          return style;
        }}
        rowClass={(row) => {
          if (lastUsedOrderId && Number(lastUsedOrderId) === row.id) {
            return 'used-list-row';
          }
          return '';
        }}
        filter={
          <Filter>
            <FilterSearchSelectField
              source="pay_type_id"
              alwaysOn
              label="Способ оплаты"
              requestPath="/pay_types"
              responseFormat={(v) =>
                v.results.filter((it) => it.is_bookkeeping === 'yes')
              }
              searchBy="name"
              getValue={(o) => o.id}
            />
            <FilterSelectField
              source="is_active"
              label="Активен"
              alwaysOn
              options={[
                { value: 'all', label: 'Все' },
                { value: 'yes', label: 'Активные' },
                { value: 'no', label: 'Не активные' }
              ]}
            />
            <FilterSelectField
              source="is_bookkeeper_submit"
              label="Статус"
              alwaysOn
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Обработанные' },
                { value: 'no', label: 'Не обработанные' }
              ]}
            />
            <FilterSearchField source="id" label="ID" />
            <FilterDateRangeField
              source="date_from"
              labels={['Заявка,с', 'Заявка,по']}
              label="Дата заявки"
              maxWidth={275}
            />
            <FilterDateRangeField
              source="created_at"
              labels={['Создан,с', 'Создан,по']}
              label="Дата создания"
              maxWidth={300}
            />
            <FilterSearchElasticClientField source="client_id" label="Клиент" />
            <FilterSearchSelectField
              source="user_id"
              label="Оператор"
              requestPath="/users"
              responseFormat={(v) => v.results}
              getOptionLabel={(it) =>
                it ? [it.first_name, it.last_name].join(' ') : ''
              }
              searchBy={['first_name', 'last_name']}
              getValue={(o) => o.id}
            />

            <FilterSearchField
              source="agreement_number"
              label="Номер договора"
            />
            <FilterSearchElasticExecutorField
              source="executor_id"
              label="Дезинфектор"
              customOption={{
                id: 'null',
                name: 'Не указан'
              }}
            />
            <FilterSearchGeoElasticAddress
              source="locality_id,street_id,address_house"
              label="Адрес"
              filter={filter}
              maxWidth={600}
            />
            <FilterSearchField
              source="client_name"
              label="Клиент (имя)"
              searchType="like%"
              searchPath="client.name"
            />
            <FilterSelectField
              source="is_urgently"
              label="Срочно"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Срочно' },
                { value: 'no', label: 'Не cрочно' }
              ]}
            />
            <FilterSelectField
              source="is_finished"
              label="Выполнен"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Выполнен' },
                { value: 'no', label: 'Не выполнен' }
              ]}
            />
            <FilterSelectField
              source="is_qc"
              label="Контроль качества"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Да' },
                { value: 'no', label: 'Нет' }
              ]}
            />
            <FilterSelectField
              source="is_feedback"
              label="Оставлен чек"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Да' },
                { value: 'no', label: 'Нет' }
              ]}
            />
            <FilterSelectField
              source="is_repeat"
              label="Повторная заявка"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Да' },
                { value: 'no', label: 'Нет' }
              ]}
            />
          </Filter>
        }>
        <Field source="id" isAction align="center">
          {({ value, row }) => {
            const is_bookkeeper_submit = row.is_bookkeeper_submit === 'yes';
            return (
              <DropMenu
                component={Btn}
                color={is_bookkeeper_submit ? 'default' : 'success'}
                size="small"
                style={{
                  whiteSpace: 'nowrap'
                }}
                items={[
                  {
                    title: 'Новая',
                    onClick: () =>
                      handleToNewUpdate(value, {
                        is_new: 'yes',
                        is_bookkeeper_submit: 'no'
                      })
                  },
                  {
                    title:
                      row.is_bookkeeper_submit === 'yes'
                        ? 'Не обработана'
                        : 'Обработана',
                    onClick: () => {
                      const to =
                        row.is_bookkeeper_submit === 'yes' ? 'no' : 'yes';
                      handleUpdateSubmit(value, { is_bookkeeper_submit: to });
                    }
                  }
                ]}>
                {is_bookkeeper_submit ? 'Обработана' : 'Не обработана'}
              </DropMenu>
            );
          }}
        </Field>
        <Field label="ID" source="id" sortable />
        <Field
          label="Клиент"
          source="client"
          format={(client) => {
            if (!client) return '';
            return client.name;
          }}
        />
        <Field label="Номер" source="client.phone" type="phone" />
        <Field
          label="Цена"
          source="price"
          type="price"
          sortable
          align="right"
        />
        <Field
          label="Дата заявки"
          type="daterange"
          source="*"
          sortable="date_from"
        />
        <Field
          label="Дезинфектор"
          source="executor"
          format={(executor) => {
            if (!executor) return '';
            return executor.name;
          }}
        />
        <Field
          label="Принял"
          source="user"
          format={(item) => {
            if (!item) return '';
            return `${[item.first_name, item.last_name].join(' ')}`;
          }}
        />
        <Field
          label="Выполнен"
          source="is_finished"
          type="boolean"
          format={(v) => v === 'yes'}
        />
        <Field
          label="Дата, создания"
          source="created_at"
          type="date"
          sortable
        />
        {/* <Field label="Заявка обработана" source="is_bookkeeper_submit" format={v => v ==='yes' ? 'Обработана' : 'Не обработана'} sortable/> */}
        <Field source="id" isAction>
          {({ value }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                <Rbac operation="orders:update">
                  <IconBtnEdit to={`${orderOnePath}/edit/${value}`} />
                </Rbac>
              </div>
            );
          }}
        </Field>
      </List>
    </Screen>
  );
};

const BookkeeperOrdersChanged = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default BookkeeperOrdersChanged;
