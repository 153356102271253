const rules = {
  customer: {
    can: [
      'customers:own_read',
      'customers:own_write',
      'customer_orders:own_read',
      'customer_orders:create',
      'projects:read'
    ]
  },
  executor: {
    can: [
      'users:own_read',
      'users:own_profile',
      'users:executors',
      'pay_types:read',
      'methods:read',
      'reasons:read',
      'mileage:own_read',
      'mileage:own_write',
      'executor.orders:read',
      'executor.orders:finish',
      'executor.available:read',
      'executor.schedule:read',
      'executor.turnover:read',
      'materials:read',
      'warehouse_order:read',
      'warehouse_order:create',
      'warehouse_order:delete',
      'messages:own_read',
      'messages:create',
      'files:own_read',
      'files:create'
    ]
  },
  main_executor: {
    can: ['executor.orders:write'],
    inherits: ['executor']
  },
  operator: {
    can: [
      'users:read',
      'users:own_profile',
      'executors:read',
      'available:read',
      'mileage:read',
      'places:read',
      'schedule:read',
      'turnover:read',
      'methods:read',
      'reasons:read',
      'pay_types:read',
      'clients:read',
      'clients:create',
      'clients:update',
      'client_groups:read',
      'orders:read',
      'orders:create',
      'orders:update',
      'orders:old_read',
      'orders:future_read',
      'customers:*',
      'customer_orders:*',
      'materials:read',
      'warehouse:read',
      'warehouse_order:read',
      'messages:read',
      'messages:create',
      'files:read',
      'files:create',
      'reminders:read',
      'reminders:own_write',
      'client_messages:read',
      'client_messages:create'
    ]
  },
  manager: {
    can: [
      'warehouse:create',
      'warehouse:update',
      'warehouse_order:create',
      'warehouse_order:delete',
      'warehouse_order:finished',
      'messages:delete',
      'files:delete',
      'reminders:read',
      'reminders:own_write'
    ],
    inherits: ['operator']
  },
  bookkeeper: {
    can: [
      'users:own_read',
      'users:own_profile',
      'executors:read',
      'available:read',
      'mileage:read',
      'places:read',
      'schedule:read',
      'turnover:read',
      'methods:read',
      'reasons:read',
      'pay_types:read',
      'clients:read',
      'client_groups:read',
      'orders:read',
      'orders:bookkeeping',
      'orders:old_read',
      'orders:future_read',
      'orders:archive_read',
      'materials:read',
      'warehouse:read',
      'warehouse_order:read',
      'messages:read',
      'messages:create',
      'files:read',
      'files:create',
      'reminders:read',
      'reminders:own_write',
      'client_messages:read',
      'client_messages:create'
    ]
  },
  admin: {
    can: [
      'users:read',
      'users:own_read',
      'users:own_profile',
      'users:create',
      'users:update',
      'users:executors',
      'groups:*',
      'clients:*',
      'client_groups:*',
      'executors:*',
      'available:*',
      'mileage:*',
      'places:*',
      'schedule:*',
      'executor_users:*',
      'turnover:*',
      'pay_types:*',
      'methods:*',
      'reasons:*',
      'orders:*',
      'customers:*',
      'customer_orders:*',
      'materials:*',
      'warehouse:*',
      'warehouse_order:*',
      'messages:*',
      'files:*',
      'reminders:*',
      'geo:*',
      'client_messages:*'
    ]
  },
  superadmin: {
    can: [
      'reports:read',
      'projects:*',
      'sessions:*',
      'users:delete',
      'reminders:*'
    ],
    inherits: ['admin']
  }
};

export default rules;
