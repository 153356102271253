import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useChangeProjId } from 'services/projectUtils';

const useStyles = makeStyles((theme) => ({
  selectBtn: {
    width: '100%'
  },
  selectBtnTitle: {
    flex: 1,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  popoverPaper: {
    width: theme.drawerWidth,
    left: '0 !important'
  },
  selectedItem: {
    background: '#eee'
  }
}));

const SelectProjectsMenu = (props) => {
  const { admin, project_id } = props;
  let projects = [];
  if (admin && admin.projects) {
    projects = admin.projects.map((it) => it.project).filter((it) => it);
  }

  const classes = useStyles();
  const changeProjId = useChangeProjId();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const current_project = projects.find((it) => it.id === project_id);
  const btnTitle = (current_project && current_project.name) || 'Не выбран';

  const projectsMenu = (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      variant="menu"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      getContentAnchorEl={null}
      classes={{ paper: classes.popoverPaper }}>
      {projects.map((proj) => {
        if (!proj) return null;
        return (
          <MenuItem
            key={proj.id}
            aria-label="project"
            color="inherit"
            classes={{
              root: clsx(proj.id === project_id && classes.selectedItem)
            }}
            onClick={() => {
              handleMenuClose();
              if (project_id !== proj.id) {
                changeProjId(proj.id);
              }
            }}>
            {proj.name}
          </MenuItem>
        );
      })}
    </Menu>
  );

  return (
    <div>
      <Button
        className={classes.selectBtn}
        aria-label="select user project"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        variant="contained">
        <div className={classes.selectBtnTitle}>{btnTitle}</div>
        {isMenuOpen ? <ChevronLeftIcon /> : <ExpandMoreIcon />}
      </Button>
      {projectsMenu}
    </div>
  );
};
const mapStateToProps = (state) => ({
  admin: state.auth.admin,
  project_id: state.auth.project_id
});

export default connect(mapStateToProps)(SelectProjectsMenu);
