import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    mapItemContainer: {
      maxHeight: '250px',
      overflowY: 'auto',
      padding: '10px'
    },
    mapItem: {
      borderBottom: '1px solid #bfbfbf',
      marginBottom: '5px',
      fontSize: '14px',
      paddingBottom: '5px',
      display: 'block',
      cursor: 'pointer'
    },
    mapItemTitle: {
      fontWeight: 'bold'
    },
    mapItemDescr: {
      fontSize: '12px'
    }
  };
});

const MapAddressesList = ({ options = [], onSelect }) => {
  const classes = useStyles();
  return options ? (
    <div className={classes.mapItemContainer}>
      {options.map((it) => {
        const splitAddr = it.display_name.split(', ');
        return (
          <div
            key={it.place_id}
            className={classes.mapItem}
            onClick={(e) => {
              onSelect(it);
            }}>
            <div className={classes.mapItemTitle}>
              <a href={`#${it.place_id}`} onClick={(e) => e.preventDefault()}>
                {splitAddr.slice(0, 3).join(', ')}
              </a>
            </div>
            <div className={classes.mapItemDescr}>
              {splitAddr.slice(3).join(', ')}
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
};

export default MapAddressesList;
