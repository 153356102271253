import { actionTypes } from './actions';

const initialState = {
  list: {
    loading: false,
    list: [],
    params: {},
    count: 0
  },
  one: {
    loading: false,
    data: null
  }
};

const RemindersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST: {
      const { payload = {} } = action;
      const { list = {} } = state;
      return {
        ...state,
        list: {
          ...list,
          ...payload
        }
      };
    }
    case actionTypes.DELETE_ONE:
    case actionTypes.GET_ONE:
    case actionTypes.CREATE_ONE:
    case actionTypes.UPDATE_ONE: {
      const { one = {} } = state;
      const { payload = {} } = action;
      return {
        ...state,
        one: {
          ...one,
          ...payload
        }
      };
    }
    case actionTypes.READ_REMINDER: {
      const { payload = {} } = action;
      const { id, is_alarmed } = payload;
      const { list = {} } = state;
      const { list: remindersList = [] } = list;
      return {
        ...state,
        list: {
          ...list,
          list: remindersList.map((reminder) => {
            if (reminder.id === id) return { ...reminder, is_alarmed };
            return reminder;
          })
        }
      };
    }
    default:
      return state;
  }
};

export default RemindersReducer;
