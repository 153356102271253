import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { AVAILABLE_TYPES } from 'constants/config';

import { inChildren } from 'services/utils';

import * as executorsActions from 'resources/Executors/actions';
import { BackBtn, CopyBtn, DeleteBtn, EditBtn } from 'components/buttons/Btn';
import { LabelField } from 'components/Field';
import FooterBar from 'components/FooterBar';
import NotFoundItems from 'components/NotFoundItems';
import Progress from 'components/Progress';
import Rbac, { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false
    };
  }

  componentDidMount() {
    this.handleGetOne();
  }

  componentWillUnmount() {
    const { executorsAvailableActions } = this.props;
    executorsAvailableActions.clearOne();
  }

  handleGetOne = async () => {
    const { match: { params: { id } = {} } = {}, executorsAvailableActions } =
      this.props;
    try {
      await executorsAvailableActions.getOne(id);
    } catch (err) {
      this.setState({ notFound: true });
    }
  };

  handleDelete = async () => {
    const {
      record = {},
      otherActions,
      executorsActions,
      executorsAvailableActions,
      history
    } = this.props;

    const res = await otherActions.showModalConfirm({
      title: 'Удаление',
      content: 'Удалить запись?'
    });

    if (res) {
      executorsAvailableActions.setLoading(true);
      try {
        const { executor = {}, id: available_id } = record;
        const { id: executor_id } = executor;
        await executorsActions.deleteExecutorAvailable(
          executor_id,
          available_id
        );
        history.goBack();
      } catch (err) {
        console.error(err);
      }
      executorsAvailableActions.setLoading(false);
    }
  };

  handleShowCopy = () => {
    const { history, location, basePath, record = {} } = this.props;
    if (!record || !record.id) return;
    let from = location.pathname;
    if (location.search) from += location.search;
    history.push({
      pathname: `${basePath}/add`,
      state: { from, copy_id: record.id }
    });
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      notFound: this.state.notFound,
      handleDelete: this.handleDelete,
      handleShowCopy: this.handleShowCopy
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    record: one.data,
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorsActions: bindActionCreators(executorsActions, dispatch),
  executorsAvailableActions: bindActionCreators(actions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleDelete,
    handleShowCopy,
    record = {},
    loading,
    basePath = '/',
    notFound
  } = props;

  const itemReceived = !!(record && record.id);
  const history = useHistory();
  const back = history.goBack;

  let title = '';
  if (itemReceived) {
    const { executor = {} } = record;
    const name = (executor && executor.name) || '';
    title = `Дезинфектор: ${name}`;
  }

  const is_writable = useCan('available:write');

  return (
    <Screen title={title}>
      <Box p={1}>
        {itemReceived && (
          <Grid
            container
            spacing={1}
            wrap="wrap"
            alignItems="center"
            justifyContent="flex-end">
            <Rbac operation="available:write">
              <Grid item>
                <CopyBtn onClick={handleShowCopy}>Дубликат</CopyBtn>
              </Grid>
            </Rbac>
          </Grid>
        )}
      </Box>
      {loading && <Progress />}
      {notFound && <NotFoundItems message="Не найдено" />}
      <Box p={2}>
        {itemReceived && (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              {record.is_available === 'yes' ? (
                <LabelField
                  width={150}
                  label="Доступен"
                  value={record.is_available}
                  format={(v) => v === 'yes'}
                  type="boolean"
                />
              ) : (
                <LabelField
                  width={150}
                  label="Не доступен"
                  value={record.available_type}
                  format={(v) => {
                    if (!v) return 'Не указана';
                    return AVAILABLE_TYPES[v];
                  }}
                />
              )}
            </Grid>
            <Grid item>
              <LabelField
                width={150}
                label="Дата"
                type="daterange"
                value={record}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <FooterBar>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <BackBtn onClick={back}>Назад</BackBtn>
            </Grid>
            <Grid item>
              {itemReceived && is_writable && (
                <EditBtn
                  to={{
                    pathname: `${basePath}/edit/${record.id}`,
                    state: { from: `${basePath}/show/${record.id}` }
                  }}>
                  Редактировать
                </EditBtn>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {itemReceived && is_writable && (
            <DeleteBtn onClick={handleDelete}>Удалить</DeleteBtn>
          )}
        </Grid>
      </FooterBar>
    </Screen>
  );
};

const ExecutorsAvailableShow = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ExecutorsAvailableShow;
