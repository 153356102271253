import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { useSafeState } from 'services/hooks';
import { inChildren } from 'services/utils';

import {
  FormCheckboxField,
  FormSearchSelectField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';

import * as actions from './actions';
import { validateEditForm } from './validation';

const defaultFormName = 'edit_geo_locality';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      geoActions,
      history = {}
    } = this.props;
    try {
      await geoActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const { geoActions, record = {}, loading, history } = this.props;
    if (loading) return;
    try {
      await geoActions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  geoActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

export const EditForm = (props) => {
  const dispatch = useDispatch();

  const {
    handleUpdate,
    loading,
    formName = defaultFormName,
    footerBar = true,
    record = {}
  } = props;

  const itemReceived = record && record.id;
  const record_region_id = record && record.region_id;
  const [regionId, setRegionId] = useSafeState(record_region_id);

  let title = 'Редактировать';
  if (itemReceived) {
    title = `Редактировать нас. пункт: ${record.name}`;
  }

  const onChangeRegionHandler = React.useCallback(
    (region) => {
      setRegionId(region ? region.id : null);
      dispatch(change(formName, 'district_id', null));
    },
    [dispatch, formName, setRegionId]
  );

  const history = useHistory();
  const back = history.goBack;

  const is_writable = useCan('geo:write');

  React.useEffect(() => {
    setRegionId(record_region_id);
  }, [record_region_id, setRegionId]);

  if (!is_writable) {
    return null;
  }

  return (
    <Screen title={title}>
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        onBack={back}
        direction="row"
        footerBar={footerBar}
        validate={validateEditForm}
        initialValues={{ ...record }}
        parse={(values) => {
          const { name, alt_name, old_name, name_lang1, name_lang2 } = values;
          const res = {
            name: name ? name.trim() : '',
            alt_name: alt_name ? alt_name.trim() : '',
            old_name: old_name ? old_name.trim() : '',
            name_lang1: name_lang1 ? name_lang1.trim() : '',
            name_lang2: name_lang2 ? name_lang2.trim() : '',
            region_id: values.region_id ? values.region_id.id : null,
            district_id: values.district_id ? values.district_id.id : null,
            region_center: values.region_center || 'no',
            weight: values.weight ? parseInt(values.weight) : 0
          };
          return res;
        }}>
        <Grid item xs={12}>
          <FormTextField source="name" label="Название" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSearchSelectField
            source="region_id"
            label="Регион"
            disabled={loading}
            requestPath="/geo/regions"
            responseFormat={(v) => v.results}
            renderOption={(it) => it.name}
            searchBy="name"
            onChangeHandler={onChangeRegionHandler}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSearchSelectField
            source="district_id"
            label="Район"
            disabled={loading || !regionId}
            where={regionId ? { region_id: regionId } : null}
            requestPath="/geo/district"
            responseFormat={(v) => v.results}
            renderOption={(it) => it.name}
            searchBy="name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="alt_name"
            label="Альтернативное название"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="old_name"
            label="Старое название"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="name_lang1"
            label="Язык №1"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="name_lang2"
            label="Язык №2:"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="weight"
            label="Вес"
            disabled={loading}
            type="number"
            inputProps={{
              step: '1'
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCheckboxField
            source="region_center"
            label="Регион. ценр"
            disabled={loading}
            parse={(v) => (v ? 'yes' : 'no')}
            format={(v) => v === 'yes'}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const LocalityEdit = (props) => (
  <ContainerConnect {...props}>
    <EditForm />
  </ContainerConnect>
);

export default LocalityEdit;
