import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import {
  clients as resourceClients,
  orders as resourceApi
} from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';
import { showSnackbarError, showSnackbarSuccess } from 'modules/notifier';

import { getStats, getStatsBookkeeping } from 'store/actions/statsActions';

const REDUX_PREFIX = 'ORDERS';

export const actionTypes = {
  GET_LIST: `${REDUX_PREFIX}_GET_LIST`,
  GET_ONE: `${REDUX_PREFIX}_GET_ONE`,
  CLEAR_ONE: `${REDUX_PREFIX}_CLEAR_ONE`,
  CREATE_ONE: `${REDUX_PREFIX}_CREATE_ONE`,
  UPDATE_ONE: `${REDUX_PREFIX}_UPDATE_ONE`,
  DELETE_ONE: `${REDUX_PREFIX}_DELETE_ONE`,
  GET_LIST_REASONS: `${REDUX_PREFIX}_GET_LIST_REASONS`,
  GET_LIST_METHODS: `${REDUX_PREFIX}_GET_LIST_METHODS`,

  GET_LIST_WAREHOUSE: `${REDUX_PREFIX}_GET_LIST_WAREHOUSE`,
  GET_LIST_MESSAGES: `${REDUX_PREFIX}_GET_LIST_MESSAGES`,
  GET_LIST_FILES: `${REDUX_PREFIX}_GET_LIST_FILES`,
  GET_LIST_CLIENT_MESSAGES: `${REDUX_PREFIX}_GET_LIST__CLIENT_MESSAGES`
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

const defaultQuery = {
  order: 'desc',
  orderBy: 'id',
  filter: {},
  offset: 0,
  limit: PAGINATION_TABLE_LIMIT
};

export const getList =
  (param = {}) =>
  async (dispatch) => {
    const actionType = actionTypes.GET_LIST;
    const next = {
      ...defaultQuery,
      ...param,
      filter: {
        is_active: 'yes',
        ...param.filter
      }
    };

    const request = Query.createGetRequest(next);

    if (next.filter.is_active === 'all') delete request.where.is_active;

    dispatch(
      updateRedux(actionType, {
        loading: true,
        params: next,
        list: []
      })
    );

    try {
      const { results: list = [], count = 0 } = await resourceApi.getList(
        request
      );
      const res = {
        list,
        count,
        loading: false
      };

      dispatch(updateRedux(actionType, res));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      dispatch(updateRedux(actionType, { loading: false }));
      return Promise.reject(err);
    }
  };

export const getOne = (id) => async (dispatch, getState) => {
  const actionType = actionTypes.GET_ONE;

  const payload = { loading: true };
  const oldItem = getState().orders.one.data;
  if (oldItem && Number(id) !== oldItem.id) {
    payload.data = null;
  }

  sessionStorage.setItem('last_used_order_id', id);
  dispatch(updateRedux(actionType, payload));

  try {
    const res = await resourceApi.getOne(id);
    let reasons = [];
    let methods = [];

    try {
      const res = await Promise.all([
        resourceApi.getOrderReasons(id, {
          order: '-id',
          offset: 0,
          limit: 100
        }),
        resourceApi.getOrderMethods(id, {
          order: '-id',
          offset: 0,
          limit: 100
        })
      ]);
      reasons = res[0].results.map((it) => it.reason);
      methods = res[1].results.map((it) => it.method);
    } catch (err) {
      console.error(err);
    }

    res.reasons = reasons;
    res.methods = methods;

    dispatch(
      updateRedux(actionType, {
        data: res,
        loading: false
      })
    );

    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Заявка не найдена' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const clearOne = () => {
  const actionType = actionTypes.CLEAR_ONE;
  return {
    type: actionType
  };
};

export const createOne =
  (data = {}) =>
  async (dispatch) => {
    const actionType = actionTypes.CREATE_ONE;

    dispatch(updateRedux(actionType, { loading: true }));
    try {
      const request = Query.createRequest(data);
      const res = await resourceApi.createOne(request);
      dispatch(
        updateRedux(actionType, {
          // data: res,
          loading: false
        })
      );
      dispatch(showSnackbarSuccess({ message: 'Заявка создана' }));
      dispatch(getStats());
      dispatch(getStatsBookkeeping());
      return Promise.resolve(res);
    } catch (err) {
      dispatch(updateRedux(actionType, { loading: false }));
      if (err && err.code === 'api/exist') {
        dispatch(
          showSnackbarError({
            message: 'Данный дезинфектор занят на это время'
          })
        );
      } else if (err && err.code === 'api/unavailable') {
        dispatch(
          showSnackbarError({
            message: 'Данный дезинфектор не доступен на это время'
          })
        );
      } else {
        dispatch(ErrorService.dispatchError(err));
      }
      return Promise.reject(err);
    }
  };

export const updateOne = (id, data) => async (dispatch) => {
  const actionType = actionTypes.UPDATE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));

  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.updateOne(id, request);
    dispatch(
      updateRedux(actionType, {
        loading: false
      })
    );
    dispatch(showSnackbarSuccess({ message: 'Запись обновлена' }));
    dispatch(getStats());
    dispatch(getStatsBookkeeping());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.code === 'api/exist') {
      dispatch(
        showSnackbarError({ message: 'Данный дезинфектор занят на это время' })
      );
    } else if (err && err.code === 'api/unavailable') {
      dispatch(
        showSnackbarError({
          message: 'Данный дезинфектор не доступен на это время'
        })
      );
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const deleteOne = (id) => async (dispatch) => {
  const actionType = actionTypes.DELETE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));

  try {
    const res = await resourceApi.deleteOne(id);
    dispatch(updateRedux(actionType, { loading: false, data: null }));
    dispatch(showSnackbarSuccess({ message: 'Заявка удалена' }));
    dispatch(getStats());
    dispatch(getStatsBookkeeping());
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};

export const getOrderReasons = (order_id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_REASONS;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } = await resourceApi.getOrderReasons(
      order_id,
      request
    );

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const createOrderReason = (order_id, reason_id) => async (dispatch) => {
  try {
    const res = await resourceApi.createOrderReason(order_id, reason_id);
    dispatch(showSnackbarSuccess({ message: 'Связь добавлена' }));
    return Promise.resolve(res);
  } catch (err) {
    if (err && err.code === 'api/exist')
      dispatch(showSnackbarError({ message: 'Такая связь уже существует' }));
    else dispatch(ErrorService.dispatchError(err));
  }
};

export const deleteOrderReason = (order_id, reason_id) => async (dispatch) => {
  try {
    const res = await resourceApi.deleteOrderReason(order_id, reason_id);
    dispatch(showSnackbarSuccess({ message: 'Связь удалена' }));
    return Promise.resolve(res);
  } catch (err) {
    if (err && err.code === 'api/exist')
      dispatch(showSnackbarError({ message: 'Такая связь уже существует' }));
    else dispatch(ErrorService.dispatchError(err));
  }
};

export const getOrderMethods = (order_id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_METHODS;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } = await resourceApi.getOrderMethods(
      order_id,
      request
    );

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const createOrderMethod = (order_id, method_id) => async (dispatch) => {
  try {
    const res = await resourceApi.createOrderMethod(order_id, method_id);
    dispatch(showSnackbarSuccess({ message: 'Связь добавлена' }));
    return Promise.resolve(res);
  } catch (err) {
    if (err && err.code === 'api/exist')
      dispatch(showSnackbarError({ message: 'Такая связь уже существует' }));
    else dispatch(ErrorService.dispatchError(err));
  }
};

export const deleteOrderMethod = (order_id, method_id) => async (dispatch) => {
  try {
    const res = await resourceApi.deleteOrderMethod(order_id, method_id);
    dispatch(showSnackbarSuccess({ message: 'Связь удалена' }));
    return Promise.resolve(res);
  } catch (err) {
    if (err && err.code === 'api/exist')
      dispatch(showSnackbarError({ message: 'Такая связь уже существует' }));
    else dispatch(ErrorService.dispatchError(err));
  }
};

export const getOrderWarehouse = (order_id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_WAREHOUSE;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } =
      await resourceApi.getOrderWarehouse(order_id, request);

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const createOrderWarehouse = (order_id, params) => async (dispatch) => {
  try {
    const res = await resourceApi.createOrderWarehouse(order_id, params);
    dispatch(showSnackbarSuccess({ message: 'Добавлено' }));
    return Promise.resolve(res);
  } catch (err) {
    if (err && err.code === 'api/exist')
      dispatch(showSnackbarError({ message: 'Такая связь уже существует' }));
    else dispatch(ErrorService.dispatchError(err));
  }
};

export const deleteOrderWarehouse = (order_id, item_id) => async (dispatch) => {
  try {
    const res = await resourceApi.deleteOrderWarehouse(order_id, item_id);
    dispatch(showSnackbarSuccess({ message: 'Удалено' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};

export const getOrderMessages = (order_id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_MESSAGES;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } =
      await resourceApi.getOrderMessages(order_id, request);
    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const getOrderMessagesLoad =
  (order_id, param) => async (dispatch, getState) => {
    const actionType = actionTypes.GET_LIST_MESSAGES;
    const next = {
      order: 'desc',
      orderBy: 'id',
      filter: {},
      offset: 0,
      limit: PAGINATION_TABLE_LIMIT,
      ...param
    };

    const { list: oldList = [] } = getState().orders.messages;
    next.offset = oldList.length;

    const request = Query.createGetRequest(next);

    dispatch(
      updateRedux(actionType, {
        loading: true,
        params: next
      })
    );

    try {
      const { results: list = [], count = 0 } =
        await resourceApi.getOrderMessages(order_id, request);
      const res = {
        list: oldList.concat(list),
        count,
        loading: false
      };

      dispatch(updateRedux(actionType, res));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      dispatch(updateRedux(actionType, { loading: false }));
      return Promise.reject(err);
    }
  };

export const createOrderMessage = (order_id, params) => async (dispatch) => {
  try {
    const res = await resourceApi.createOrderMessage(order_id, params);
    dispatch(showSnackbarSuccess({ message: 'Добавлено' }));
    return res;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};

export const deleteOrderMessage = (order_id, item_id) => async (dispatch) => {
  try {
    const res = await resourceApi.deleteOrderMessage(order_id, item_id);
    dispatch(showSnackbarSuccess({ message: 'Удалено' }));
    return res;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};

export const getOrderFiles = (order_id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_FILES;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } = await resourceApi.getOrderFiles(
      order_id,
      request
    );
    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const uploadFile = (order_id, file) => async (dispatch) => {
  try {
    const res = await resourceApi.addOrderFile(order_id, { file });
    dispatch(showSnackbarSuccess({ message: 'Файл добавлен' }));
    return res;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};

export const deleteOrderFile = (order_id, item_id) => async (dispatch) => {
  try {
    const res = await resourceApi.deleteOrderFile(order_id, item_id);
    dispatch(showSnackbarSuccess({ message: 'Удалено' }));
    return res;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};

export const getOrderFileToken = (order_id, item_id) => async (dispatch) => {
  try {
    return await resourceApi.getOrderFileToken(order_id, item_id);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    return false;
  }
};

export const orderPhoneCall = (order_id, params) => async (dispatch) => {
  try {
    await resourceApi.createOrderPhoneCall(order_id, params);
    dispatch(
      showSnackbarSuccess({
        message: 'Звонок создан. Устанавливаеться соединение'
      })
    );
    return true;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    return false;
  }
};

export const getClientMessages = (client_id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_CLIENT_MESSAGES;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } =
      await resourceClients.getClientMessages(client_id, request);
    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const getClientMessagesLoad =
  (client_id, param) => async (dispatch, getState) => {
    const actionType = actionTypes.GET_LIST_CLIENT_MESSAGES;
    const next = {
      order: 'desc',
      orderBy: 'id',
      filter: {},
      offset: 0,
      limit: PAGINATION_TABLE_LIMIT,
      ...param
    };

    const { list: oldList = [] } = getState().orders.clientMessages;
    next.offset = oldList.length;

    const request = Query.createGetRequest(next);

    dispatch(
      updateRedux(actionType, {
        loading: true,
        params: next
      })
    );

    try {
      const { results: list = [], count = 0 } =
        await resourceClients.getClientMessages(client_id, request);
      const res = {
        list: oldList.concat(list),
        count,
        loading: false
      };

      dispatch(updateRedux(actionType, res));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      dispatch(updateRedux(actionType, { loading: false }));
      return Promise.reject(err);
    }
  };

export const createClientMessage = (client_id, params) => async (dispatch) => {
  try {
    const res = await resourceClients.createClientMessage(client_id, params);
    dispatch(showSnackbarSuccess({ message: 'Добавлено' }));
    return res;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};

export const deleteClientMessage = (client_id, item_id) => async (dispatch) => {
  try {
    const res = await resourceClients.deleteClientMessage(client_id, item_id);
    dispatch(showSnackbarSuccess({ message: 'Удалено' }));
    return res;
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};
