import React, { Component } from 'react';
import { connect } from 'react-redux';

import Modal from './Modal';

class Modals extends Component {
  render() {
    const { modals } = this.props;

    if (!modals || !modals.length) return null;

    return (
      <>
        {modals.map((it) => (
          <Modal key={it.uid} {...it} />
        ))}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    modals: state.reduxModals.modals
  };
}

export default connect(mapStateToProps)(Modals);
