export const MIN_ZOOM = 6;
export const MAX_ZOOM = 18;
export const DEFAULT_CENTER = [49.025821, 31.484073];
export const DEFAULT_ZOOM = 6;
export const CITY_ZOOM = 10;
export const STREET_ZOOM = 14;
export const HOUSE_ZOOM = 17;
export const MAX_BOUNDS = [
  [55.063414, 17.731491],
  [42.070259, 44.757031]
];
