import React from 'react';
import { connect, useStore } from 'react-redux';

import { RBAC } from 'services/rbac';

export const useHasRole = (roles) => {
  const store = useStore();
  const admin = store.getState().auth.admin;
  return roles.some((role) => {
    return admin.roles.includes(role);
  });
};

export const useCan = (operation, params) => {
  const [is_can, set_is_can] = React.useState(false);

  const store = useStore();
  const admin = store.getState().auth.admin;

  const roles = admin ? admin.roles : ['public'];

  React.useEffect(() => {
    let active = true;
    RBAC.can(roles, operation, params)
      .then((res) => {
        if (active) set_is_can(res);
      })
      .catch((e) => {
        if (active) set_is_can(false);
      });
    return () => (active = false);
  });
  return is_can;
};

const Rbac = connect((state) => {
  return {
    admin: state.auth.admin
  };
})((props) => {
  const { operation, anyOf, everyOf, params, children } = props;

  let is_can = false;

  if (anyOf) {
    const can = anyOf.map((item) => useCan(item, params));
    is_can = can.some((item) => item);
  } else if (everyOf) {
    const can = everyOf.map((item) => useCan(item, params));
    is_can = can.every((item) => item);
  } else {
    is_can = useCan(operation, params);
  }

  return is_can ? children : null;
});

export default Rbac;
