import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { MATERIAL_UNIT } from 'constants/config';

import Query from 'services/Query';
import { convertAmountToUnits, inChildren } from 'services/utils';

import Field from 'components/Field';
import Filter, { FilterSearchSelectField } from 'components/Filter';
import List from 'components/List';
import Screen from 'components/Screen';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { actions } = this.props;
      actions.getList(params);
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  return (
    <Screen title="Остатки">
      <List
        {...props}
        filter={
          <Filter>
            <FilterSearchSelectField
              alwaysOn
              source="place_id"
              label="Дислокация"
              requestPath="/executor_places"
              responseFormat={(v) => v.results}
              getOptionLabel={(it) => it.name || ''}
              searchBy="name"
              getValue={(o) => o.id}
            />
            <FilterSearchSelectField
              source="material_id"
              alwaysOn
              label="Материал"
              requestPath="/materials"
              responseFormat={(v) => v.results}
              getOptionLabel={(opt) => opt.name || ''}
              searchBy="name"
              getValue={(o) => o.id}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field label="Дислокация" source="place">
          {({ value }) => {
            if (!value) return '';
            return <div>{value.name}</div>;
          }}
        </Field>
        <Field
          label="Материал"
          source="material"
          format={(v) => {
            if (!v) {
              return '';
            }
            return v.name;
          }}
        />
        <Field
          label="Кол-во"
          source="*"
          sortable="value"
          align="right"
          format={(v) => {
            const { material = {}, value } = v;
            if (!material) {
              return '';
            }
            const { unit } = material;
            if (!MATERIAL_UNIT[unit]) {
              return 'Error';
            }
            return `${convertAmountToUnits(value, unit)} ${
              MATERIAL_UNIT[unit].short
            }`;
          }}
        />
      </List>
    </Screen>
  );
};

const WarehouseBalancesList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default WarehouseBalancesList;
