import React from 'react';

import { concatGeoFullName } from 'services/utils';

import FormSearchGeoField from './FormSearchGeoField';

function renderOptionDefault(opt) {
  if (!opt) {
    return null;
  }
  const { name, full_name } = opt;
  return (
    <div>
      <div>{name}</div>
      {full_name ? (
        <div style={{ fontSize: '0.75em' }}>{concatGeoFullName(opt, [0])}</div>
      ) : null}
    </div>
  );
}

function getOptionLabel(opt) {
  if (!opt) {
    return '';
  }
  if (opt.full_name) {
    return concatGeoFullName(opt, [1, 0]);
  }
  return opt?.name || '';
}

const FormSearchGeoDistrictField = ({ where, ...rest }) => {
  return (
    <FormSearchGeoField
      where={{ ...where, type: 'district' }}
      geotype="district"
      getOptionLabel={getOptionLabel}
      renderOption={renderOptionDefault}
      {...rest}
    />
  );
};

export default FormSearchGeoDistrictField;
