import { UI_SET_TITLE } from 'store/actions/uiActions';

const initialState = {
  title: ''
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_SET_TITLE: {
      return {
        ...state,
        title: action.title || ''
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
