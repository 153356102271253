import reducer from './reducer';

export { default as Drawer } from './Drawer';
export { default as ShowHistoryBtn } from './ShowHistoryBtn';

const ResourceHistory = {
  reducer
};

export default ResourceHistory;
