import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import { DATE_FORMAT } from 'constants/config';

import { inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import { IconBtnDelete, IconBtnEdit } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterDateField,
  FilterDateRangeField
} from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';
import ModalMileageDialog from './ModalMileageDialog';

class Container extends Component {
  componentDidMount() {
    this.getList();
  }

  refresh = () => {
    const { record = {} } = this.props;
    const { params = {} } = record;
    this.getList(params);
  };

  getList = (params) => {
    const { executor_id, executorsActions } = this.props;
    executorsActions.getExecutorsMileage(executor_id, params);
  };

  handleCreateMileage = async (params) => {
    const { executor_id, executorsActions } = this.props;
    await executorsActions.createExecutorMileage(executor_id, params);
  };

  handleUpdateMileage = async (mileage_id, params) => {
    const { executor_id, executorsActions } = this.props;
    await executorsActions.updateExecutorMileage(
      executor_id,
      mileage_id,
      params
    );
  };

  handleDeleteMileage = async (available_id) => {
    const {
      executor_id,
      otherActions,
      executorsActions,
      record = {}
    } = this.props;
    const res = await otherActions.showModalConfirm({
      title: 'Удаление',
      content: 'Удалить пробег?'
    });

    if (res) {
      try {
        const { params = {}, list = [] } = record;
        await executorsActions.deleteExecutorMileage(executor_id, available_id);
        const { offset = 0, limit = 0 } = params;
        if (offset && list.length === 1) {
          params.offset = offset - limit;
        }
        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleRefresh: this.refresh,
      handleCreateMileage: this.handleCreateMileage,
      handleUpdateMileage: this.handleUpdateMileage,
      handleDeleteMileage: this.handleDeleteMileage
    });
  }
}

const mapStateToProps = (state) => {
  const { executors = {} } = state;
  const { mileage = {} } = executors;
  return {
    record: mileage
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorsActions: bindActionCreators(actions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleRefresh,
    handleCreateMileage,
    handleUpdateMileage,
    handleDeleteMileage
  } = props;

  const [modalProps, setModalProps] = useState(false);

  const showAdd = () => {
    setModalProps({
      initialValues: null,
      handleSubmit: async (props) => {
        try {
          await handleCreateMileage(props);
          setModalProps(false);
          handleRefresh();
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const showEdit = (id, item) => {
    setModalProps({
      initialValues: item,
      handleSubmit: async (params) => {
        try {
          await handleUpdateMileage(id, params);
          setModalProps(false);
          handleRefresh();
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const onClose = () => {
    setModalProps(false);
  };

  const is_writable = useCan('mileage:write');

  return (
    <div>
      <List
        {...props}
        toolBarInner={is_writable ? <AddBtn onClick={() => showAdd()} /> : null}
        filter={
          <Filter
            parse={(values) => {
              const res = { created_at: values.created_at };
              if (values.mileage_date)
                res.mileage_date = moment(values.mileage_date).format(
                  'YYYY-MM-DD'
                );
              return res;
            }}>
            <FilterDateField
              source="mileage_date"
              label="Дата"
              alwaysOn
              dateType="date"
            />
            <FilterDateRangeField
              source="created_at"
              labels={['Создан,с', 'Создан,по']}
              label="Дата создания"
              maxWidth={300}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field
          label="Дата"
          source="mileage_date"
          type="date"
          dateFormat={DATE_FORMAT}
          sortable
        />
        <Field label="Пробег,с (км)" source="mileage_from" type="number" />
        <Field label="Пробег,по (км)" source="mileage_to" type="number" />
        <Field
          label="Дата, создания"
          source="created_at"
          type="date"
          sortable
        />
        <Field source="id" isAction>
          {({ value, row }) => {
            return (
              <React.Fragment>
                {is_writable ? (
                  <IconBtnEdit onClick={() => showEdit(value, row)} />
                ) : null}
                {is_writable ? (
                  <IconBtnDelete onClick={() => handleDeleteMileage(value)} />
                ) : null}
              </React.Fragment>
            );
          }}
        </Field>
      </List>
      <ModalMileageDialog
        isOpen={!!modalProps}
        title="Автопробег"
        {...modalProps}
        handleClose={onClose}
      />
    </div>
  );
};

const ShowExecutorsMileage = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ShowExecutorsMileage;
