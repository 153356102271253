import React from 'react';
import { useMapEvents } from 'react-leaflet';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';

import Map, {
  constants as MAP_CONSTANTS,
  MapAddressesList,
  Marker,
  utils as mapUtils
} from 'components/maps';

const PlacesMapField = ({ input = {}, meta = {}, ...attributes }) => {
  const { onChange, value } = input;
  const { touched, error } = meta;
  const { options = [], zoom: parentZoom } = attributes;
  const visibleError = touched && error;
  const mapRef = React.useRef();
  const [zoom, setZoom] = React.useState(parentZoom);

  React.useEffect(() => {
    setZoom(parentZoom);
  }, [parentZoom]);

  const changeHandler = React.useCallback(
    (it) => {
      onChange(mapUtils.getGeopointFromMapData(it));
    },
    [onChange]
  );

  const changeMarkerHandler = React.useCallback(
    (it) => {
      if (!it || !it.latlng) {
        return;
      }
      const { lat, lng } = it.latlng;
      if (mapRef?.current) setZoom(mapRef.current.getZoom());
      onChange(
        mapUtils.getGeopointFromMapData({
          lat: lat,
          lon: lng
        })
      );
    },
    [onChange]
  );

  const { isValidGeopoint, center } = React.useMemo(() => {
    const isValid = mapUtils.isValidGeopoint(value);
    return {
      isValidGeopoint: isValid,
      center: isValid
        ? mapUtils.getCoordsFromGeopoint(value)
        : MAP_CONSTANTS.DEFAULT_CENTER
    };
  }, [value]);

  return (
    <FormControl error={!!visibleError} fullWidth>
      <Grid container>
        <Grid item xs={12}>
          {visibleError && <FormHelperText>{visibleError}</FormHelperText>}
          <Map center={center} zoom={zoom} mapRef={mapRef}>
            <MarkerChangePosition
              showMarker={isValidGeopoint}
              position={center}
              onChange={changeMarkerHandler}
            />
          </Map>
        </Grid>
        <Grid item xs={12}>
          <MapAddressesList options={options} onSelect={changeHandler} />
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default PlacesMapField;

function MarkerChangePosition({ showMarker, position, onChange }) {
  useMapEvents({
    click: (evt) => {
      onChange && onChange(evt);
    }
  });

  return !!showMarker && <Marker position={position} />;
}
