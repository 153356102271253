import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import { BackBtn, DeleteBtn, EditBtn } from 'components/buttons/Btn';
import { LabelField } from 'components/Field';
// import Toolbar from 'components/Toolbar';
import FooterBar from 'components/FooterBar';
import NotFoundItems from 'components/NotFoundItems';
import Progress from 'components/Progress';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false
    };
  }

  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const { match: { params: { id } = {} } = {}, clientGroupsActions } =
      this.props;
    try {
      await clientGroupsActions.getOne(id);
    } catch (err) {
      this.setState({ notFound: true });
    }
  };

  handleDelete = async () => {
    const {
      clientGroupsActions,
      record = {},
      dispatch,
      history = {}
    } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить группу?'
      })
    );

    if (res) {
      try {
        await clientGroupsActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      notFound: this.state.notFound,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    record: one.data,
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  clientGroupsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleDelete,
    record = {},
    loading,
    basePath = '/',
    notFound
  } = props;

  const itemReceived = !!(record && record.id);
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Группа клиентов: ${record.name}`;
  }

  const is_writable = useCan('client_groups:write');

  return (
    <Screen title={title}>
      {loading && <Progress />}
      {notFound && <NotFoundItems message="Группа не найдена" />}
      {itemReceived && (
        <Box p={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <LabelField
                width={150}
                label="Название группы:"
                value={record.name}
              />
            </Grid>
            <Grid item>
              <LabelField width={150} label="E-mail:" value={record.email} />
            </Grid>
            <Grid item>
              <LabelField
                width={150}
                label="Тел.:"
                value={record.phone}
                type="phone"
              />
            </Grid>
            <Grid item>
              <LabelField
                width={150}
                label="Кол-во клиентов:"
                type="number"
                value={record.clients_count}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <FooterBar>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <BackBtn onClick={back}>Назад</BackBtn>
            </Grid>
            {itemReceived && is_writable && (
              <Grid item>
                <EditBtn
                  to={{
                    pathname: `${basePath}/edit/${record.id}`,
                    state: { from: `${basePath}/show/${record.id}` }
                  }}>
                  Редактировать
                </EditBtn>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          {itemReceived && is_writable && (
            <DeleteBtn onClick={handleDelete}>Удалить</DeleteBtn>
          )}
        </Grid>
      </FooterBar>
    </Screen>
  );
};

const ClientsGroupsShow = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ClientsGroupsShow;
