import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    background: theme.palette.grey[200]
  }
}));

const FooterBar = ({ children, ...rest }) => {
  const classes = useStyles(rest);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        spacing={1}
        justifyContent="space-between"
        {...rest}>
        {children}
      </Grid>
    </div>
  );
};

export default FooterBar;
