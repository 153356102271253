import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SaveIcon from '@material-ui/icons/Save';

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0
  },
  success: {
    color: theme.palette.getContrastText(green[600]),
    // padding: '6px 16px',
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}));

export const Btn = (props) => {
  const {
    ariaLabel = 'button',
    to = '',
    color = 'default',
    children,
    ...rest
  } = props;

  const classes = useStyles();

  const btnProps = {};
  if (to) {
    btnProps.to = to;
    btnProps.component = Link;
  }

  if (color === 'success') {
    btnProps.color = 'default';
  }

  return (
    <Button
      aria-label={ariaLabel}
      variant="contained"
      color={color}
      classes={{
        root: clsx(classes.root, color === 'success' && classes.success)
      }}
      {...btnProps}
      {...rest}>
      {children}
    </Button>
  );
};

export const BackBtn = (props) => (
  <Btn
    ariaLabel="back"
    startIcon={<ArrowBackIosIcon fontSize="inherit" />}
    {...props}
  />
);

export const SaveBtn = (props) => (
  <Btn
    ariaLabel="save"
    startIcon={<SaveIcon fontSize="inherit" />}
    color="primary"
    {...props}
  />
);

export const DeleteBtn = (props) => (
  <Btn
    ariaLabel="remove"
    startIcon={<DeleteIcon fontSize="inherit" />}
    color="secondary"
    variant="outlined"
    {...props}
  />
);

export const EditBtn = (props) => (
  <Btn
    ariaLabel="edit"
    startIcon={<EditIcon fontSize="inherit" />}
    color="success"
    {...props}
  />
);

export const CopyBtn = (props) => (
  <Btn
    ariaLabel="copy"
    startIcon={<FileCopyIcon fontSize="inherit" />}
    {...props}
  />
);

export const AddBtn = (props) => (
  <Btn
    ariaLabel="add"
    startIcon={<AddIcon fontSize="inherit" />}
    color="primary"
    {...props}>
    Добавить
  </Btn>
);

export default React.memo(Btn);
