import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';

import { makeAction } from 'modules/redux-modals';

import { IconBtnClose, IconBtnRotate } from 'components/buttons/IconBtn';

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1
  },
  headerBtn: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.6)'
    }
  },
  imageContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rotator: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    display: 'inline-block',
    maxWidth: '100%',
    maxHeight: '100%'
  }
}));

const ImageModal = (props) => {
  const { isOpen, image, onClose } = props;

  return (
    <Dialog
      fullScreen={true}
      fullWidth={false}
      open={isOpen}
      onClose={onClose}
      scroll={'body'}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description">
      <InnerView image={image} onClose={onClose} />
    </Dialog>
  );
};

export const show = makeAction(ImageModal);
export default ImageModal;

function InnerView({ image, onClose }) {
  const containerRef = React.useRef(null);
  const classes = useStyles();

  const [layout, setLayout] = React.useState({
    width: undefined,
    height: undefined,
    rotate: 0
  });

  React.useLayoutEffect(() => {
    let _w = containerRef.current.clientWidth || '100%';
    let _h = containerRef.current.clientHeight || '100%';
    setLayout({
      rotate: 0,
      width: _w,
      height: _h
    });
  }, []);

  const onRotate = () => {
    let next = layout.rotate + 90;
    if (next >= 360) {
      next = 0;
    }
    let _w = containerRef.current.clientWidth || '100%';
    let _h = containerRef.current.clientHeight || '100%';
    if (next === 90 || next === 270) {
      const tmp = _h;
      _h = _w;
      _w = tmp;
    }
    setLayout({
      rotate: next,
      width: _w,
      height: _h
    });
  };

  return (
    <>
      <div className={classes.modalHeader}>
        <IconBtnRotate className={classes.headerBtn} onClick={onRotate} />
        <IconBtnClose className={classes.headerBtn} onClick={onClose} />
      </div>
      <div ref={containerRef} className={classes.imageContainer}>
        {image ? (
          <div
            className={classes.rotator}
            style={{
              width: layout.width,
              height: layout.height,
              transform: `rotateZ(${layout.rotate}deg)`
            }}>
            <img className={classes.image} src={image.url} alt={image.name} />
          </div>
        ) : null}
      </div>
    </>
  );
}
