import React from 'react';

import ContainerUI from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Form from 'components/Form';
import Progress from 'components/Progress';

const useStyles = makeStyles((theme) => ({
  form: {
    position: 'relative'
  },
  container: {
    margin: 0,
    padding: 0
  },
  fieldsWrap: {
    position: 'relative'
  },
  formBody: {
    padding: theme.spacing(1, 2)
  },
  gridItem: {
    minWidth: 250,
    padding: theme.spacing(0, 1)
  },
  deleteBtn: {
    marginLeft: theme.spacing(1)
  }
}));

const SearchForm = (props) => {
  const {
    loading,
    children,
    direction = 'column',
    alignItems,
    spacing = 1,
    onDelete,
    onBack,
    maxWidth = 'md',
    enableReinitialize = true,
    ...rest
  } = props;

  const classes = useStyles(props);

  return (
    <Form
      className={classes.form}
      enableReinitialize={enableReinitialize}
      {...rest}>
      <div className={classes.fieldsWrap}>
        {loading && <Progress variant="over" />}
        <ContainerUI maxWidth={maxWidth} className={classes.container}>
          <Grid
            container
            direction={direction}
            alignItems={alignItems}
            spacing={spacing}
            className={classes.formBody}>
            {children}
          </Grid>
        </ContainerUI>
      </div>
    </Form>
  );
};

export default React.memo(SearchForm);
