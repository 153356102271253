import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    padding: theme.spacing(2)
  }
}));

const NotFoundItems = ({ message }) => {
  const classes = useStyles();
  if (!message) return null;
  return (
    <Typography component="h2" variant="h5" className={classes.root}>
      {message}
    </Typography>
  );
};

export default NotFoundItems;
