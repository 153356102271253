import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { change, stopSubmit, submit } from 'redux-form';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { request } from 'services/api';
import {
  getPriceWithoutVat,
  inChildren,
  numberToPercent,
  toBills,
  toCoins
} from 'services/utils';
import { isPrice } from 'services/validation';

import { createOne as createOneClientAction } from 'resources/Clients/actions';
import { AddForm as AddFormClient } from 'resources/Clients/Add';
import { ShowHistoryBtn } from 'resources/ResourceHistory';
import { CopyBtn } from 'components/buttons/Btn';
import CopyBufferBtn from 'components/buttons/CopyBufferBtn';
import { IconBtnAdd } from 'components/buttons/IconBtn';
import {
  FormCheckboxField,
  FormDateField,
  FormField,
  FormSearchElasticClientField,
  FormSearchElasticExecutorField,
  FormSearchGeoLocalityField,
  FormSearchGeoStreetsField,
  FormSearchMultipleField,
  FormSearchSelectField,
  FormSelectField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import { utils as mapUtils } from 'components/maps';
import Rbac, { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';
import SimpleModal from 'modals/SimpleModal';

import * as orderHelpers from './common/helpers';
import OrderMapField from './common/OrderMapField';
import * as actions from './actions';

const validate = (values) => {
  const errors = {};

  const {
    pay_type,
    date_from,
    date_to,
    price,
    transport_price,
    trap_price,
    client,
    user,
    is_repeat
  } = values;

  if (!price && price !== 0) {
    errors.price = 'Укажите цену';
  } else if (!isPrice(price)) {
    errors.price = 'Невалидная цена';
  }

  if (!pay_type) {
    errors.pay_type = 'Укажите способ оплаты';
  }

  if (!date_from) {
    errors.date_from = 'Укажите дату, с';
  }
  if (!date_to) {
    errors.date_to = 'Укажите дату, по';
  }

  if (date_from && date_to && date_from >= date_to) {
    errors.date_to = 'Должна быть больше даты, с';
  }

  if (!client) {
    errors.client = 'Укажите клиента';
  }

  if (!user) {
    errors.user = 'Укажите оператора';
  }

  if (!transport_price && transport_price !== 0) {
    errors.transport_price = 'Укажите цену';
  } else if (!isPrice(transport_price)) {
    errors.transport_price = 'Невалидная цена';
  }

  if (!trap_price && trap_price !== 0) {
    errors.trap_price = 'Укажите цену';
  } else if (!isPrice(trap_price)) {
    errors.trap_price = 'Невалидная цена';
  }

  if (!is_repeat) {
    errors.is_repeat = 'Обязательное поле';
  }

  return errors;
};

const defaultformName = 'add_order';
const createClientFormName = 'addClientFromOrder';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locality: null,
      street: null,
      client: null,
      geopoints: []
    };
  }

  componentDidUpdate(prev) {
    const { record } = this.props;
    if (record !== prev.record && record) {
      this.setState({
        ...record,
        zoom: mapUtils.getZoomWithEntries({
          geopoint: record.geopoint,
          locality: record.locality,
          street: record.street,
          address_house: record.address_house || ''
        })
      });
      this.handleGetNearOrders(record.geopoint, {
        date_from: record.date_from
      });
    }
  }

  componentDidMount() {
    const { asModal } = this.props;
    if (asModal) {
      const { ordersActions, order = {} } = this.props;
      ordersActions.updateRedux('ORDERS_GET_ONE', {
        data: order
      });
    } else {
      this.handleGetOne();
    }
  }

  componentWillUnmount() {
    const { ordersActions } = this.props;
    ordersActions.clearOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      ordersActions,
      history = {}
    } = this.props;
    try {
      await ordersActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const { ordersActions, record = {}, loading, history } = this.props;
    if (loading) return;
    try {
      await ordersActions.updateOne(record.id, data);
      history.goBack();
      // eslint-disable-next-line no-console
    } catch (err) {
      console.error(err);
    }
  };

  handleDelete = async () => {
    const { ordersActions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить заявку?'
      })
    );

    if (res) {
      try {
        await ordersActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  handleCreateClient = async (data) => {
    const { loading, createOneClient, dispatch } = this.props;
    if (loading) return;

    try {
      const res = await createOneClient(data);
      return Promise.resolve(res);
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(createClientFormName, { phone: 'Уже существует' }));
      }
      return Promise.resolve(err);
    }
  };

  handleShowCopy = () => {
    const { history, location, basePath, record = {} } = this.props;
    if (!record || !record.id) return;
    let from = location.pathname;
    if (location.search) from += location.search;
    history.push({
      pathname: `${basePath}/add`,
      state: { from, copy_id: record.id }
    });
  };

  handleChangeGeopoint = async ({ locality, street, address_house }) => {
    const { formName, dispatch } = this.props;
    if (this.geopointTimer) {
      clearTimeout(this.geopointTimer);
    }
    const { date_from } = this.state;
    this.geopointTimer = setTimeout(async () => {
      const geopoints = await mapUtils.getMapDataByEntries({
        locality,
        street,
        address_house
      });
      const firstFinded = geopoints && geopoints.length ? geopoints[0] : null;
      const geopoint = mapUtils.getGeopointFromMapData(firstFinded);
      dispatch(
        change(formName, 'geopoint', geopoint || mapUtils.makeNullGeopoint())
      );
      this.setState({
        geopoints: geopoints || [],
        geopoint,
        zoom: mapUtils.getZoomWithEntries({
          geopoint,
          locality,
          street,
          address_house
        })
      });
      this.handleGetNearOrders(geopoint, { date_from });
    }, 500);
  };

  handleGetNearOrders = async (geopoint, params) => {
    const { record } = this.props;
    let nearOrders = [];
    if (geopoint && mapUtils.isValidGeopoint(geopoint)) {
      nearOrders = await orderHelpers.getNearOrdersByPoint(geopoint, params);
      if (record) {
        nearOrders = nearOrders.filter((it) => it.id !== record.id);
      }
    }
    this.setState({
      nearOrders
    });
  };

  handleChangeLocality = (locality) => {
    const { formName, dispatch } = this.props;
    dispatch(change(formName, 'locality', locality));
    dispatch(change(formName, 'street', null));
    this.setState({
      locality,
      street: null
    });
    this.handleChangeGeopoint({
      locality,
      street: null,
      address_house: ''
    });
  };

  handleChangeStreet = async (street) => {
    const { formName, dispatch } = this.props;
    const { locality } = this.state;
    let _l = locality;
    dispatch(change(formName, 'street', street));
    if (!_l && street) {
      const { locality_id } = street;
      _l = await getLocality(locality_id);
      dispatch(change(formName, 'locality', _l));
    }
    this.setState({ locality: _l, street });
    dispatch(change(formName, 'address_house', ''));

    this.handleChangeGeopoint({
      locality: _l,
      street,
      address_house: ''
    });
  };

  handleChangeAddrHouse = (e, value) => {
    const { locality, street } = this.state;
    this.setState({
      address_house: value
    });
    if (locality && street) {
      this.handleChangeGeopoint({
        locality,
        street,
        address_house: value
      });
    }
  };

  handleChangeAddrHouse = (e, value) => {
    const { locality, street } = this.state;
    this.setState({
      address_house: value
    });
    if (locality && street) {
      this.handleChangeGeopoint({
        locality,
        street,
        address_house: value
      });
    }
  };

  handleChangeDateFrom = (date_from) => {
    const { geopoint } = this.state;
    this.setState({ date_from });
    this.handleGetNearOrders(geopoint, { date_from });
  };

  handleClickTimepoint = (data) => {
    const { formName, dispatch } = this.props;
    const { executor, timepoint } = data;
    dispatch(change(formName, 'executor', executor));
    dispatch(change(formName, 'date_from', timepoint.start));
    dispatch(change(formName, 'date_to', timepoint.end));
    this.handleChangeDateFrom(timepoint.start);
  };

  handleChangeClient = async (client) => {
    const { formName, dispatch } = this.props;

    dispatch(change(formName, 'client', client));

    this.setState({ client });
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      handleUpdate: this.handleUpdate,
      handleCreateClient: this.handleCreateClient,
      handleDelete: this.handleDelete,
      handleShowCopy: this.handleShowCopy,
      handleChangeLocality: this.handleChangeLocality,
      handleChangeStreet: this.handleChangeStreet,
      handleChangeClient: this.handleChangeClient,
      handleChangeAddrHouse: this.handleChangeAddrHouse,
      handleChangeDateFrom: this.handleChangeDateFrom,
      handleClickTimepoint: this.handleClickTimepoint,
      ...rest,
      state: this.state
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    formName: ownProps.formName || defaultformName,
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  createOneClient: (data) => dispatch(createOneClientAction(data)),
  ordersActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const getLocality = async (locality_id) => {
  let locality = null;
  if (locality_id) {
    try {
      locality = await request.get(`/geo/locality/${locality_id}`);
    } catch (err) {
      console.error(err);
    }
  }
  return locality;
};

const View = (props) => {
  const {
    handleUpdate,
    handleDelete,
    handleCreateClient,
    loading,
    record = {},
    footerBar = true,
    formName = defaultformName,
    handleShowCopy,
    handleChangeLocality,
    handleChangeStreet,
    handleChangeClient,
    handleChangeAddrHouse,
    handleChangeDateFrom,
    handleClickTimepoint,
    state
  } = props;

  let { title = 'Редактировать заявку' } = props;

  const history = useHistory();
  const back = history.goBack;
  const dispatch = useDispatch();

  const [isOpenCreateClient, setIsOpenCreateClient] = React.useState(false);
  // const [locality, setLocality] = React.useState(null);
  const [price, setPrice] = React.useState('0');
  const [payType, setPayType] = React.useState(null);

  // React.useEffect(()=>{
  //   if (record && !locality) setLocality(record.locality)
  // }, [record, locality]);

  const createClient = async (props) => {
    const res = await handleCreateClient(props);
    if (res && res.id) {
      handleChangeClient(res);
      setIsOpenCreateClient(false);
    }
  };

  const itemReceived = record && record.id;
  if (title !== null && itemReceived) {
    title = `Редактировать заявку: ${record.id}`;
  }

  const is_writable = useCan('orders:update');
  const is_past_writable = useCan('orders:past_update');
  const is_delete = useCan('orders:delete');
  const is_past =
    record && moment(record.date_to).add(12, 'h').isBefore(moment(), 'second');
  const is_user_writable = useCan('orders:update_user');
  const is_urgently_writable = useCan('orders:urgently');

  const client = state.client || (record && record.client);

  const payTypeHelperText = React.useMemo(() => {
    if (!payType || !payType.vat) {
      return '';
    }
    return `НДС - ${numberToPercent(payType.vat)}%`;
  }, [payType]);

  const priceHelperText = React.useMemo(() => {
    if (!payType || !payType.vat) {
      return '';
    }
    const priceWithoutVat = getPriceWithoutVat(price, payType.vat);
    if (priceWithoutVat) {
      return `Без НДС - ${priceWithoutVat}; `;
    }
  }, [payType, price]);

  React.useEffect(() => {
    setPrice(record ? toBills(record.price) : '0');
    setPayType(record ? record.pay_type : null);
  }, [record]);

  return (
    <Screen title={title}>
      <Box p={1}>
        {itemReceived && (
          <Grid
            container
            spacing={1}
            wrap="wrap"
            alignItems="center"
            justifyContent="flex-end">
            <Rbac operation="orders:create">
              <CopyBtn onClick={handleShowCopy}>Дубликат</CopyBtn>
            </Rbac>
            <Grid item>
              <ShowHistoryBtn itemType="orders" itemId={record.id} />
            </Grid>
          </Grid>
        )}
      </Box>
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        onBack={back}
        maxWidth={false}
        alignItems="flex-start"
        onDelete={is_delete && handleDelete}
        direction="row"
        footerBar={footerBar}
        validate={validate}
        initialValues={{
          ...record,
          price: record && record.price >= 0 ? toBills(record.price) : '0',
          transport_price:
            record && record.transport_price >= 0
              ? toBills(record.transport_price)
              : '0',
          trap_price:
            record && record.trap_price >= 0 ? toBills(record.trap_price) : '0',
          methods:
            record && Array.isArray(record.methods) ? record.methods : [],
          reasons: record && Array.isArray(record.reasons) ? record.reasons : []
        }}
        parse={(values) => {
          const res = {
            agreement_number: values.agreement_number || '',
            address_apartment: values.address_apartment || '',
            address_house: values.address_house || '',
            comment: values.comment || '',
            comment_fin: values.comment_fin || '',
            date_from: values.date_from,
            date_to: values.date_to,
            is_active: values.is_active,
            is_urgently: values.is_urgently,
            is_finished: values.is_finished,
            is_qc: values.is_qc,
            is_feedback: values.is_feedback,
            price: values.price >= 0 ? toCoins(values.price) : 0,
            transport_price:
              values.transport_price >= 0 ? toCoins(values.transport_price) : 0,
            trap_price: values.trap_price >= 0 ? toCoins(values.trap_price) : 0,
            is_repeat: values.is_repeat
          };

          res.locality_id = null;
          if (values.street) {
            res.locality_id = values.street.locality_id;
            res.street_id = values.street.id;
          } else {
            res.locality_id = null;
            res.street_id = null;
          }

          if (!res.locality_id && values.locality) {
            res.locality_id = values.locality.id;
          }

          if (values.client) {
            res.client_id = values.client.id;
          }

          if (values.executor) {
            res.executor_id = values.executor.id;
          } else {
            res.executor_id = null;
          }

          if (values.pay_type) {
            res.pay_type_id = values.pay_type.id;
          }

          if (values.methods && Array.isArray(values.methods)) {
            res.methods = values.methods.map((it) => it.id);
          } else {
            res.methods = [];
          }

          if (values.reasons && Array.isArray(values.reasons)) {
            res.reasons = values.reasons.map((it) => it.id);
          } else {
            res.reasons = [];
          }

          if (values.user) {
            res.user_id = values.user.id;
          } else {
            res.user_id = null;
          }

          res.geopoint = values.geopoint || mapUtils.makeNullGeopoint();

          return res;
        }}>
        <Grid item container xs={12} md={6} spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormTextField
              source="price"
              label="Цена"
              disabled={!is_writable || (!is_past_writable && is_past)}
              type="number"
              inputProps={{
                step: '0.01'
              }}
              onChange={(e) => setPrice(e.target.value)}
              helperText={priceHelperText}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSearchSelectField
              source="pay_type"
              label="Способ оплаты"
              requestPath="/pay_types"
              responseFormat={(v) => v.results}
              disabled={!is_writable || (!is_past_writable && is_past)}
              searchBy="name"
              onChange={setPayType}
              helperText={payTypeHelperText}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormDateField
              label="Дата, с"
              source="date_from"
              dateType="datetime"
              disabled={!is_writable || (!is_past_writable && is_past)}
              onChange={handleChangeDateFrom}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormDateField
              label="Дата, по"
              source="date_to"
              dateType="datetime"
              disabled={!is_writable || (!is_past_writable && is_past)}
            />
          </Grid>

          <Grid item container xs={12} alignItems="center">
            <Grid item container xs>
              <Grid item xs>
                <FormSearchElasticClientField
                  source="client"
                  label="Клиент"
                  disableClearable={true}
                  onChange={(client) => handleChangeClient(client)}
                  disabled={!is_writable}
                />
              </Grid>
              <Grid item>
                <Rbac anyOf={['clients:create', 'orders:update']}>
                  <IconBtnAdd
                    onClick={() => {
                      setIsOpenCreateClient(true);
                    }}
                  />
                </Rbac>
              </Grid>
            </Grid>
            <Grid item>
              {client && client.phone && <CopyBufferBtn value={client.phone} />}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSelectField
              source="is_repeat"
              label="Повтор"
              disabled={!is_writable}
              options={[
                { value: 'no', label: 'Нет' },
                { value: 'yes', label: 'Да' }
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSearchMultipleField
              label="Вредители"
              name="reasons"
              requestPath="/reasons"
              getOptionLabel={(o) => o.name}
              searchBy="name"
              disabled={!is_writable}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSearchMultipleField
              label="Услуги"
              name="methods"
              requestPath="/methods"
              getOptionLabel={(o) => o.name}
              searchBy="name"
              disabled={!is_writable}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSearchGeoLocalityField
              source="locality"
              label="Населенный пункт"
              disabled={!is_writable}
              onChange={handleChangeLocality}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSearchGeoStreetsField
              source="street"
              label="Улица"
              disabled={!is_writable}
              where={
                state.locality
                  ? { type: 'street', locality_id: state.locality.id }
                  : { type: 'street' }
              }
              onChange={handleChangeStreet}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              source="address_house"
              label="Дом"
              disabled={!is_writable}
              onChange={handleChangeAddrHouse}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              source="address_apartment"
              label="Квартира"
              disabled={!is_writable}
            />
          </Grid>

          <Grid item xs={12}>
            <FormTextField
              source="comment"
              label="Примечание"
              disabled={!is_writable}
              multiline={true}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              source="comment_fin"
              label="Примечание, фин"
              disabled={!is_writable}
              multiline={true}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextField
              source="agreement_number"
              label="Номер договора"
              disabled={!is_writable}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextField
              source="transport_price"
              label="Цена, транспорт"
              disabled={!is_writable || (!is_past_writable && is_past)}
              type="number"
              inputProps={{
                step: '0.01'
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSearchElasticExecutorField
              source="executor"
              label="Дезинфектор"
              is_active="yes"
              disabled={!is_writable || (!is_past_writable && is_past)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextField
              source="trap_price"
              label="Цена, ловушка"
              disabled={!is_writable || (!is_past_writable && is_past)}
              type="number"
              inputProps={{
                step: '0.01'
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSearchSelectField
              source="user"
              alwaysOn
              label="Оператор"
              requestPath="/users"
              responseFormat={(v) => v.results}
              getOptionLabel={(it) =>
                it ? [it.first_name, it.last_name].join(' ') : ''
              }
              searchBy={['first_name', 'last_name']}
              disableClearable={true}
              disabled={
                !is_writable ||
                !is_user_writable ||
                (!is_past_writable && is_past)
              }
            />
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <div>
                <FormCheckboxField
                  source="is_active"
                  label="Активен"
                  disabled={!is_writable}
                  parse={(v) => (v ? 'yes' : 'no')}
                  format={(v) => v === 'yes'}
                />
              </div>
              <div>
                <FormCheckboxField
                  source="is_urgently"
                  label="Срочно"
                  disabled={
                    !is_writable ||
                    !is_urgently_writable ||
                    (!is_past_writable && is_past)
                  }
                  parse={(v) => (v ? 'yes' : 'no')}
                  format={(v) => v === 'yes'}
                />
              </div>
              <div>
                <FormCheckboxField
                  source="is_finished"
                  label="Выполнен"
                  disabled={!is_writable}
                  parse={(v) => (v ? 'yes' : 'no')}
                  format={(v) => v === 'yes'}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <FormCheckboxField
                  source="is_qc"
                  label="Контроль качества"
                  disabled={!is_writable}
                  parse={(v) => (v ? 'yes' : 'no')}
                  format={(v) => v === 'yes'}
                />
              </div>
              <div>
                <FormCheckboxField
                  source="is_feedback"
                  label="Оставлен чек"
                  disabled={!is_writable}
                  parse={(v) => (v ? 'yes' : 'no')}
                  format={(v) => v === 'yes'}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6}>
          {itemReceived ? (
            <FormField
              source="geopoint"
              options={state.geopoints}
              zoom={state.zoom}
              nearOrders={state.nearOrders}
              component={OrderMapField}
              onSelectTimepoint={handleClickTimepoint}
            />
          ) : null}
        </Grid>
      </SimpleForm>
      <SimpleModal
        isOpen={isOpenCreateClient}
        handleClose={() => setIsOpenCreateClient(false)}
        cancelText="Отмена"
        confirmText="Создать"
        handleSuccess={() => dispatch(submit(createClientFormName))}>
        <AddFormClient
          formName={createClientFormName}
          footerBar={false}
          confirmText="Создать"
          handleCreate={createClient}
        />
      </SimpleModal>
    </Screen>
  );
};

const OrdersEdit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default OrdersEdit;
