import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const EnhancedTableHead = (props) => {
  const { columns = [], order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {columns.map((col, i) => {
          const { sortable, source } = col;
          let sortBy = '';
          if (sortable && typeof sortable === 'string') sortBy = sortable;
          else if (sortable) sortBy = source;
          return (
            <TableCell key={`${col.source}-${i}`} align={col.align}>
              {sortBy ? (
                <TableSortLabel
                  active={col.source === orderBy || sortable === orderBy}
                  direction={order}
                  onClick={createSortHandler(sortBy)}>
                  {col.label}
                </TableSortLabel>
              ) : (
                col.label
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default React.memo(EnhancedTableHead);
