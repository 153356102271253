import { createTheme } from '@material-ui/core/styles';

const global = {
  '@global': {
    html: {
      height: '100%'
    },
    body: {
      height: '100%'
    },
    '#root': {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    '.MuiTableCell-head': {
      fontWeight: 'bold'
    },
    '.MuiTableCell-root .MuiIconButton-root': {
      padding: 6
    },
    '.scheduler': {
      margin: 0
    },
    '.ant-popover': {
      zIndex: 1250,
      '& .ant-radio-group': {
        display: 'none'
      }
    },
    '.used-list-row': {
      '& td': {
        color: '#0000ec'
      }
    }
  }
};

const theme = createTheme({
  colors: {
    lightRed: '#fdd8d8',
    lightGreen: '#bcffbc',
    red: '#f50057'
  },
  drawerWidth: 240,
  margins: {
    form: 16
  },
  zIndex: {
    loader: 1350
  },
  palette: {
    success: {
      light: '#88dc67',
      main: '#4aaf22',
      dark: '#388a17',
      contrastText: '#fff'
    }
  }
});

export { global, theme };
