import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Field, submit } from 'redux-form';

import { TextField as TextFieldUI } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import DateRangeIcon from '@material-ui/icons/DateRange';
import EventIcon from '@material-ui/icons/Event';
import { Calendar } from '@material-ui/pickers';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/config';

import TextField from './TextField';

const DateField = (props) => {
  const { meta, input, ...attrs } = props;

  const {
    dateType = 'datetime',
    InputProps = {},
    inputProps = {},
    disabled,
    clearable = true,
    helperText = '',
    label = '',
    submitOnChange,
    dateformat
  } = attrs;

  const error = meta.touched && meta.error;

  let df = DATE_FORMAT;
  if (dateType === 'datetime') {
    df = `${DATE_FORMAT}, ${TIME_FORMAT}`;
  } else if (dateType === 'time') {
    df = `${TIME_FORMAT}`;
  }
  if (dateformat) df = dateformat;

  const dispatch = meta.dispatch;
  const form = meta.form;

  const formatValue = (value) => {
    let res = null;
    if (value && dateType === 'time') {
      res = moment(value, [moment.ISO_8601, 'HH:mm:ss']);
    } else if (value) {
      res = moment(value);
    }
    return res;
  };

  const parseValue = (value) => {
    let res = null;
    if (value) {
      if (dateType === 'time') {
        res = value.second(0).format('HH:mm:ss');
      } else if (dateType === 'datetime') {
        res = value.second(0).toISOString();
      } else {
        res = value.endOf('day').toISOString();
      }
    }
    return res;
  };

  const _onChange = (date) => {
    let res = parseValue(date);
    input.onChange(res);
    setTimeout(() => {
      if (submitOnChange) dispatch(submit(form));
    }, 0);
  };

  const handleOpen = () => {
    if (disabled) return;
    setOpen(true);
  };

  const handleClose = () => {
    if (disabled) return;
    setOpen(false);
  };

  const value = formatValue(input.value || null);
  const inputValue = value ? value.format(df) : '';
  const [open, setOpen] = React.useState(false);

  const _onFocus = () => input.onFocus(input.value);
  const _onBlur = () => input.onBlur(input.value);

  return (
    <FormControl fullWidth error={!!error}>
      <div onClick={handleOpen}>
        <TextFieldUI
          fullWidth
          autoComplete="off"
          error={!!error}
          disabled={disabled}
          label={label}
          InputProps={{
            ...InputProps,
            endAdornment: <Adornment />
          }}
          inputProps={{
            maxLength: '1024',
            ...inputProps
          }}
          onFocus={_onFocus}
          onBlur={_onBlur}
          value={inputValue}
        />
      </div>
      <SimpleModal
        isOpen={open}
        onChange={(v) => _onChange(v)}
        handleClose={handleClose}
        dateType={dateType}
        clearable={clearable}
        value={value}
      />
      {(error || helperText) && (
        <FormHelperText>{error || helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

const Adornment = () => (
  <InputAdornment position="end">
    <IconButton aria-label="calendar" tabIndex={-1}>
      <EventIcon />
    </IconButton>
  </InputAdornment>
);

const FormDateField = (props) => {
  const { source, ...rest } = props;
  return <Field component={DateField} name={source} {...rest} />;
};

export default FormDateField;

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    margin: 8,
    width: 300,
    maxWidth: 'none !important'
  },
  modalHeader: {
    backgroundColor: '#3f51b5'
  },
  modalHeaderTitle: {
    padding: theme.spacing(2),
    lineHeight: 1,
    color: '#efefef',
    display: 'flex',
    fontSize: 20,
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  modalHeaderTitleTime: {
    fontSize: 32
  },
  modalHeaderTypeSelect: {
    display: 'flex'
  },
  selectBtn: {
    flex: 1,
    color: '#efefef',
    borderRadius: 0,
    position: 'relative'
  },
  selectBtnActive: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#8a1c4a',
    height: 5
  },
  contentDividers: {
    padding: theme.spacing(1)
  },
  actionsWrap: {
    justifyContent: 'space-between'
  }
}));

const SimpleModal = (props) => {
  const {
    isOpen,
    handleClose = () => null,
    onChange = () => null,
    confirmText = 'Готово',
    cancelText = 'Отмена',
    resetText = 'Сбросить',
    clearable,
    dateType,
    value
  } = props;

  const classes = useStyles();

  const isDate = dateType === 'datetime' || dateType === 'date';
  const isTime = dateType === 'datetime' || dateType === 'time';

  const [tmpVal, setTmpValue] = React.useState(
    value ? value.clone() : moment()
  );
  const [selectedType, setSelectedType] = React.useState(
    !isDate ? 'time' : 'date'
  );

  React.useEffect(() => {
    setTmpValue(value ? value.clone() : moment());
  }, [value]);

  const onChangeTime = (v) => {
    setTmpValue(v);
  };

  const onChangeDate = (v) => {
    setTmpValue(v);
    if (isTime) setSelectedType('time');
  };

  const handleClear = () => {
    onChange(null);
    handleClose();
  };
  const handleSuccess = () => {
    onChange(tmpVal);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      // onClose={handleClose}
      scroll={'body'}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      classes={{
        paper: classes.modalPaper
      }}>
      <div className={classes.modalHeader}>
        <div className={classes.modalHeaderTitle}>
          {isDate && <div>{tmpVal.format(`dd, ${DATE_FORMAT}`)}</div>}
          {isTime && (
            <div className={classes.modalHeaderTitleTime}>
              {tmpVal.format('HH:mm')}
            </div>
          )}
        </div>
        {dateType === 'datetime' && (
          <>
            <Divider />
            <div className={classes.modalHeaderTypeSelect}>
              <IconButton
                aria-label="date type"
                className={classes.selectBtn}
                onClick={() => setSelectedType('date')}>
                <DateRangeIcon fontSize="inherit" />
                {selectedType === 'date' && (
                  <div className={classes.selectBtnActive}></div>
                )}
              </IconButton>
              <IconButton
                aria-label="time type"
                className={classes.selectBtn}
                onClick={() => setSelectedType('time')}>
                <AccessAlarmIcon fontSize="inherit" />
                {selectedType === 'time' && (
                  <div className={classes.selectBtnActive}></div>
                )}
              </IconButton>
            </div>
          </>
        )}
      </div>
      <DialogContent
        dividers={true}
        classes={{
          dividers: classes.contentDividers
        }}>
        {selectedType === 'time' ? (
          <Clock date={tmpVal} onChange={(v) => onChangeTime(v)} />
        ) : (
          <Calendar date={tmpVal} onChange={(v) => onChangeDate(v)} />
        )}
      </DialogContent>
      <DialogActions classes={{ root: classes.actionsWrap }}>
        <div>
          {clearable && (
            <Button onClick={handleClear} color="primary">
              {resetText}
            </Button>
          )}
        </div>
        <div>
          {cancelText && handleClose && (
            <Button onClick={handleClose} color="primary">
              {cancelText}
            </Button>
          )}
          {confirmText && handleSuccess && (
            <Button onClick={handleSuccess} color="primary">
              {confirmText}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
};

const useClockStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  rootH: {
    flex: 2
  },
  rootM: {
    flex: 1
  },
  rootS: {
    flex: 1
  },
  button: {
    color: '#191919',
    width: 26,
    border: '1px solid #d0d0d0',
    cursor: 'pointer',
    height: 26,
    margin: 2,
    padding: 0,
    minWidth: 0
  },
  btnActive: {
    background: '#3f51b5',
    color: '#efefef',
    '&:hover': {
      background: '#3f51b5',
      color: '#efefef'
    }
  }
}));

const Clock = (props) => {
  const { date, onChange } = props;
  const stepM = 5;
  const classes = useClockStyles();
  const hours = Array(24).fill(1);
  const minutes = Array(60 / stepM).fill(1);

  const [h, setH] = React.useState('');
  const [m, setM] = React.useState('');

  React.useEffect(() => {
    setH(date ? date.get('h') : '');
    setM(date ? date.get('m') : '');
  }, [date]);

  const onChangeH = (v) => onChange(date.clone().hour(v));
  const onChangeM = (v) => onChange(date.clone().minute(v));

  const onChangeInputH = (e) => {
    const value = e.currentTarget.value;
    if (!value) {
      setH('');
      return;
    }
    if (/^\d{1,2}$/.test(value) && value < 24 && value >= 0) onChangeH(value);
  };

  const onChangeInputM = (e) => {
    const value = e.currentTarget.value;
    if (!value) {
      setM('');
      return;
    }
    if (/^\d{1,2}$/.test(value) && value < 60 && value >= 0) onChangeM(value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.rootH}>
        <div style={{ marginBottom: 5 }}>
          <TextField
            label="Часы"
            value={h}
            type="number"
            onChange={onChangeInputH}
          />
        </div>
        <div>
          {hours.map((it, i) => {
            const title = i < 10 ? '0' + i : i;
            return (
              <Button
                key={title}
                onClick={() => onChangeH(i)}
                className={clsx(classes.button, {
                  [classes.btnActive]: i === h
                })}>
                {title}
              </Button>
            );
          })}
        </div>
      </div>
      <Divider orientation="vertical" />
      <div className={classes.rootM}>
        <div style={{ marginBottom: 5 }}>
          <TextField
            label="Минуты"
            value={m}
            type="number"
            onChange={onChangeInputM}
          />
        </div>
        <div>
          {minutes.map((it, i) => {
            const minute = i * stepM;
            let title = minute < 10 ? '0' + minute : minute;
            return (
              <Button
                key={title}
                onClick={() => onChangeM(minute)}
                className={clsx(classes.button, {
                  [classes.btnActive]: m === minute
                })}>
                {title}
              </Button>
            );
          })}
        </div>
      </div>
    </div>
  );
};
