import React from 'react';

import { FormSearchElasticOrderField } from 'components/Form';

const FilterSearchElasticOrderField = (props) => {
  const { source, children, ...rest } = props;

  return (
    <FormSearchElasticOrderField
      name={source}
      submitOnChange={true}
      forced={true}
      disableClearable={false}
      {...rest}
    />
  );
};

export default React.memo(FilterSearchElasticOrderField);
