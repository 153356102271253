import React from 'react';
import { Field } from 'redux-form';

import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(0, 1)
  }
}));

const FormCheckbox = ({ source, ...rest }) => (
  <Field component={ComponentCheckbox} name={source} {...rest} />
);

const ComponentCheckbox = ({ input = {}, meta = {}, ...attributes }) => {
  const {
    inputProps,
    label = '',
    color = 'primary',
    format,
    parse,
    ...restAttrs
  } = attributes;

  const classes = useStyles();
  const { touched, error } = meta;
  const visibleError = touched && error;
  return (
    <FormControl error={!!visibleError}>
      <FormControlLabel
        control={
          <Checkbox
            checked={!!input.value}
            color={color}
            classes={{
              root: classes.checkbox
            }}
            inputProps={{
              'aria-label': 'checkbox',
              ...inputProps
            }}
            {...restAttrs}
            {...input}
          />
        }
        label={label}
      />
    </FormControl>
  );
};

export default React.memo(FormCheckbox);
