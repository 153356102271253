import React from 'react';
import moment from 'moment';
import { Field, submit } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import { DatePicker, DateTimePicker, TimePicker } from '@material-ui/pickers';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/config';

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    backgroundColor: '#3f51b5'
  },
  modalHeaderTitle: {
    padding: theme.spacing(2),
    lineHeight: 1,
    color: '#efefef',
    display: 'flex',
    fontSize: 20,
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  modalHeaderTitleTime: {
    fontSize: 32
  }
}));

const DateField = (props) => {
  const classes = useStyles();
  const {
    meta,
    input: { value, onChange, onBlur, ...inputProps },
    disabled,
    InputProps,
    dateType = 'datetime',
    helperText = '',
    dateformat,
    submitOnChange,
    withSeconds = false,
    ...others
  } = props;

  const dispatch = meta.dispatch;
  const form = meta.form;
  let pickerValue = null;
  if (value && dateType === 'time') {
    pickerValue = moment(value, [moment.ISO_8601, 'HH:mm:ss']);
  } else if (value) {
    pickerValue = moment(value);
  }
  const issetError = meta.touched && meta.error;

  let Control = DatePicker;
  let df = DATE_FORMAT;

  if (dateType === 'datetime') {
    Control = DateTimePicker;
    df = `${DATE_FORMAT}, ${TIME_FORMAT}`;
  }

  if (dateType === 'time') {
    Control = TimePicker;
    df = `${TIME_FORMAT}:ss`;
  }

  if (dateformat) df = dateformat;

  const createResult = (value) => {
    let res = null;
    if (dateType === 'time') {
      res = value && moment(value);
      if (res) {
        if (!withSeconds) res.second(0);
        res = res.format(df);
      }
    } else {
      res = value && value.second(0).toISOString();
    }
    return res;
  };

  const _onChange = (date) => {
    onChange(createResult(date));
    setTimeout(() => {
      if (submitOnChange) dispatch(submit(form));
    }, 0);
  };

  const _onBlur = (e) => {
    onBlur(createResult(pickerValue));
  };

  const addit = {};
  if (dateType === 'time') addit.views = ['hours', 'minutes'];
  if (dateType === 'time' && withSeconds) addit.views.push('seconds');

  const isDate = dateType === 'datetime' || dateType === 'date';
  const isTime = dateType === 'datetime' || dateType === 'time';

  return (
    <FormControl fullWidth error={!!issetError}>
      <Control
        {...inputProps}
        clearable
        variant="dialog"
        value={pickerValue}
        onChange={_onChange}
        tabIndex={-1}
        error={!!issetError}
        onBlur={_onBlur}
        autoComplete="off"
        cancelLabel="Отмена"
        okLabel="Готово"
        clearLabel="Сбросить"
        ampm={false}
        {...others}
        format={df}
        {...addit}
        InputProps={{
          endAdornment: <Adornment />,
          ...InputProps
        }}
        ToolbarComponent={(_data) => {
          return (
            <div className={classes.modalHeader}>
              <div className={classes.modalHeaderTitle}>
                {isDate && (
                  <div>
                    {_data.date ? _data.date.format(`dd, ${DATE_FORMAT}`) : ''}
                  </div>
                )}
                {isTime && (
                  <div className={classes.modalHeaderTitleTime}>
                    {_data.date ? _data.date.format('HH:mm:ss') : ''}
                  </div>
                )}
              </div>
            </div>
          );
        }}
        disabled={disabled}
      />
      {(issetError || helperText) && (
        <FormHelperText>{issetError || helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

const Adornment = () => (
  <InputAdornment position="end">
    <IconButton aria-label="calendar" tabIndex={-1}>
      <EventIcon />
    </IconButton>
  </InputAdornment>
);

const FormDateField = (props) => {
  const { source, ...rest } = props;
  return <Field component={DateField} name={source} {...rest} />;
};

export default FormDateField;
