import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import { makeAction } from 'modules/redux-modals';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 300
  }
}));

const ModalConfirm = (props) => {
  const {
    title = '',
    content = '',
    isOpen,
    handleClose = () => null,
    confirmText = 'Да',
    cancelText = 'Нет'
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      // onClose={() => handleClose(false)}
      scroll={'paper'}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      classes={{
        paper: classes.paper
      }}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {!!content && <DialogContent dividers={true}>{content}</DialogContent>}

      <DialogActions>
        <Button onClick={() => handleClose(true)} color="primary">
          {confirmText}
        </Button>
        <Button
          onClick={() => handleClose(false)}
          color="primary"
          variant="contained">
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const show = makeAction(ModalConfirm);
export default ModalConfirm;
