import React from 'react';

import { FormSearchElasticExecutorField } from 'components/Form';

const FilterSearchElasticExecutorField = (props) => {
  const { source, children, ...rest } = props;

  return (
    <FormSearchElasticExecutorField
      name={source}
      submitOnChange={true}
      forced={true}
      disableClearable={false}
      getValue={(o) => o.id}
      {...rest}
    />
  );
};

export default React.memo(FilterSearchElasticExecutorField);
