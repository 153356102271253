import ApiRequest from './ApiRequest';

export default class PayTypes extends ApiRequest {
  getList(params) {
    return this.get(`/pay_types`, {
      qs: params
    });
  }
  getOne(id) {
    return this.get(`/pay_types/${id}`);
  }
  createOne(req) {
    return this.post(`/pay_types`, {
      body: req
    });
  }
  updateOne(id, req) {
    return this.put(`/pay_types/${id}`, {
      body: req
    });
  }
  deleteOne(id) {
    return this.delete(`/pay_types/${id}`);
  }
}
