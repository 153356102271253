import React from 'react';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import {
  concatGeoFullName,
  getPriceWithoutVat,
  joinArrayBySeparate,
  numberToPercent,
  toBills
} from 'services/utils';

import Field, { LabelField } from 'components/Field';

const OrderInfo = (props) => {
  const { record = {} } = props;
  const labelWidth = 150;
  return (
    <div>
      {record && record.id ? (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Сумма:"
              value={record}
              format={(v) => {
                let res = toBills(v.price);
                const vat = (v.pay_type && v.pay_type.vat) || 0;
                if (vat) {
                  const priceWithoutVat = getPriceWithoutVat(res, vat);
                  res += ` (Без НДС - ${priceWithoutVat})`;
                }
                return res;
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Транспорт:"
              value={record.transport_price}
              type="price"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Ловушки:"
              value={record.trap_price}
              type="price"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Способ оплаты:"
              value={record.pay_type}
              format={(pType) => {
                if (!pType) return '';
                let res = pType.name;
                if (pType.vat) {
                  res += ` (НДС - ${numberToPercent(pType.vat)}%)`;
                }
                return res;
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Дата:"
              type="daterange"
              value={record}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Дата создания:"
              type="date"
              value={record.created_at}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelField
              width={labelWidth}
              label="Клиент:"
              value={record.client}
              format={(client) => {
                if (!client) return '';
                return (
                  <>
                    <span>{client.name}</span>
                    &nbsp;
                    {client.phone && (
                      <Field type="phone" value={client.phone} />
                    )}
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Повторная заявка:"
              value={record.is_repeat}
              format={(v) => {
                if (v === 'yes') return 'Да';
                if (v === 'no') return 'Нет';
                return 'Не указано';
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Отправить СМС:"
              value={record.send_sms}
              format={(v) => {
                if (v === 'yes') return 'Да';
                if (v === 'no') return 'Нет';
                return 'Не указано';
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Вредители:"
              value={record.reasons}
              format={(reasons) => {
                if (!reasons) return '';
                return reasons.map((it) => (
                  <Chip key={it.id} label={it.name} />
                ));
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Услуги:"
              value={record.methods}
              format={(methods) => {
                if (!methods) return '';
                return methods.map((it) => (
                  <Chip key={it.id} label={it.name} />
                ));
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelField
              width={150}
              label="Адрес:"
              value={record}
              format={(record) => {
                const { street, locality, address_house, address_apartment } =
                  record;
                const target = street || locality;
                const rowValues = [
                  concatGeoFullName(target),
                  address_house ? `д.${address_house}` : '',
                  address_apartment ? `кв.${address_apartment}` : ''
                ];
                return joinArrayBySeparate(rowValues);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Примечание:"
              value={record.comment}
              type="longtext"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Примечание, фин:"
              value={record.comment_fin}
              type="longtext"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Номер договора:"
              value={record.agreement_number}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Дезинфектор:"
              value={record.executor}
              format={(executor) => {
                if (!executor) return '';
                return (
                  <>
                    <span>{executor.name}</span>
                    &nbsp;
                    {executor.phone && (
                      <Field type="phone" value={executor.phone} />
                    )}
                  </>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Заявку принял:"
              value={record.user}
              format={(user) => {
                if (!user) return '';
                return `${user.first_name} ${user.last_name}`;
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Активен:"
              value={record.is_active}
              type="boolean"
              format={(v) => v === 'yes'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Срочно:"
              value={record.is_urgently}
              type="boolean"
              format={(v) => v === 'yes'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Выполнен:"
              value={record.is_finished}
              type="boolean"
              format={(v) => v === 'yes'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Контроль качества:"
              value={record.is_qc}
              type="boolean"
              format={(v) => v === 'yes'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Оставлен чек:"
              value={record.is_feedback}
              type="boolean"
              format={(v) => v === 'yes'}
            />
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default OrderInfo;
