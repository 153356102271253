import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import { BackBtn, DeleteBtn, EditBtn } from 'components/buttons/Btn';
// import Toolbar from 'components/Toolbar';
import FooterBar from 'components/FooterBar';
import NotFoundItems from 'components/NotFoundItems';
import Progress from 'components/Progress';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';
import ShowInfo from './ShowInfo';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false
    };
  }
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const { match: { params: { id } = {} } = {}, actions } = this.props;
    try {
      await actions.getOne(id);
    } catch (err) {
      this.setState({ notFound: true });
      // history.goBack();
    }
  };

  handleDelete = async () => {
    const { actions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Вы уверены что хотите удалить запись?'
      })
    );

    if (res) {
      try {
        await actions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      notFound: this.state.notFound,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    record: one.data,
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    record = {},
    loading,
    basePath = '/',
    notFound,
    handleDelete
  } = props;

  const itemReceived = !!(record && record.id);
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `${record.material?.name || ''}, ${record.place.name}`;
  }

  const isCanUpdated = useCan('warehouse:update');
  const isCanDelete = useCan('warehouse:delete');

  return (
    <Screen title={title}>
      {loading && <Progress />}
      {notFound && <NotFoundItems message="Пользователь не найден" />}
      {itemReceived && (
        <Box p={2}>
          <ShowInfo {...props} />
        </Box>
      )}
      <FooterBar>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <BackBtn onClick={back}>Назад</BackBtn>
            </Grid>
            <Grid item>
              {itemReceived && isCanUpdated && (
                <EditBtn to={`${basePath}/edit/${record.id}`}>
                  Редактировать
                </EditBtn>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {itemReceived && isCanDelete && (
            <DeleteBtn onClick={handleDelete}>Удалить</DeleteBtn>
          )}
        </Grid>
      </FooterBar>
    </Screen>
  );
};

const Show = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default Show;
