import moment from 'moment';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/config';

import {
  arrayParseJsonAndToString,
  phoneFormat,
  toBills
} from 'services/utils';

export const orders = {
  is_urgently: {
    label: 'Срочно',
    format: (v) => (v === 'yes' ? 'Да' : 'Нет')
  },
  is_finished: {
    label: 'Выполнен',
    format: (v) => (v === 'yes' ? 'Да' : 'Нет')
  },
  is_qc: {
    label: 'Контроль качества',
    format: (v) => (v === 'yes' ? 'Да' : 'Нет')
  },
  is_feedback: {
    label: 'Оставлен чек',
    format: (v) => (v === 'yes' ? 'Да' : 'Нет')
  },
  agreement_number: {
    label: 'Номер договора'
  },
  address_apartment: {
    label: 'Квартира'
  },
  address_house: {
    label: 'Дом'
  },
  comment: {
    label: 'Примечание'
  },
  comment_fin: {
    label: 'Примечание, фин'
  },
  date_from: {
    label: 'Время, с',
    format: (v) => moment(v).format(`${DATE_FORMAT}, ${TIME_FORMAT}`)
  },
  date_to: {
    label: 'Время, по',
    format: (v) => moment(v).format(`${DATE_FORMAT}, ${TIME_FORMAT}`)
  },
  is_active: {
    label: 'Активен',
    format: (v) => (v === 'yes' ? 'Да' : 'Нет')
  },
  price: {
    label: 'Цена',
    format: toBills
  },
  transport_price: {
    label: 'Цена, транспорт',
    format: toBills
  },
  trap_price: {
    label: 'Цена, ловушка',
    format: toBills
  },
  locality_id: {
    hidden: true
  },
  locality: {
    label: 'Населенный пункт',
    format: (v) => arrayParseJsonAndToString(v)
  },
  street_id: {
    hidden: true
  },
  street: {
    label: 'Улица',
    format: (v) => arrayParseJsonAndToString(v)
  },
  client_id: {
    hidden: true
  },
  client: {
    label: 'Клиент'
  },
  executor_id: {
    hidden: true
  },
  executor: {
    label: 'Исполнитель'
  },
  pay_type_id: {
    hidden: true
  },
  pay_type: {
    label: 'Способ оплаты'
  },
  user: {
    label: 'Принял'
  },
  updated_at: {
    hidden: true
  },
  created_at: {
    hidden: true
  },
  methods: {
    label: 'Услуги'
  },
  reasons: {
    label: 'Вредители'
  },
  is_repeat: {
    label: 'Повторная заявка',
    format: (v) => (v === 'yes' ? 'Да' : v === 'no' ? 'Нет' : '')
  },
  send_sms: {
    label: 'Оправлять sms',
    format: (v) => (v === 'yes' ? 'Да' : v === 'no' ? 'Нет' : '')
  },
  is_new: {
    label: 'Новая',
    format: (v) => (v === 'yes' ? 'Да' : v === 'no' ? 'Нет' : '')
  },
  geopoint: {
    // hidden: true,
    label: 'Координаты',
    format: (v) => {
      if (!v || !v.coordinates) return '';
      return `${v.coordinates[1]}, ${v.coordinates[0]}`;
    }
  },
  id: {
    label: 'ID'
  },
  phone: {
    label: 'Тел.'
  },
  first_name: {
    label: 'Имя'
  },
  last_name: {
    label: 'Фамилия'
  }
};

export const clients = {
  id: {
    hidden: true
  },
  group_id: {
    hidden: true
  },
  locality_id: {
    hidden: true
  },
  street_id: {
    hidden: true
  },
  address_house: {
    label: 'Дом'
  },
  address_apartment: {
    label: 'Квартира'
  },
  comment: {
    label: 'Примечание'
  },
  name: {
    label: 'Имя'
  },
  phone: {
    label: 'Тел.',
    format: phoneFormat
  },
  email: {
    label: 'E-mail'
  },
  created_at: {
    label: 'Дата создания',
    format: (v) => moment(v).format(`${DATE_FORMAT}, ${TIME_FORMAT}`)
  },
  updated_at: {
    hidden: true
  },
  street: {
    label: 'Адрес',
    format: (v) => arrayParseJsonAndToString(v)
  },
  group: {
    label: 'Группа'
  }
};
