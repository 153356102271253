import React from 'react';

import Grid from '@material-ui/core/Grid';

import { LabelField } from 'components/Field';

const ShowProjectInfo = (props) => {
  const { record = {} } = props;
  if (!record || !record.id) return null;
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <LabelField width={100} label="Название:" value={record.name} />
      </Grid>
      <Grid item>
        <LabelField
          width={100}
          label="Примечание:"
          value={record.comment}
          type="longtext"
        />
      </Grid>
    </Grid>
  );
};

export default ShowProjectInfo;
