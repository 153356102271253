import queryString from 'qs';

import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { classOf } from './utils';

const QueryService = {
  createQs(params) {
    if (!params) return '';
    const next = this.removeEmptyParams(params);
    if (next) return '?' + queryString.stringify(next);
    return '';
  },

  parseQuery(query) {
    if (!query) return '';
    const params = queryString.parse(query, { ignoreQueryPrefix: true });
    if (params.offset) params.offset = Number(params.offset);
    return params;
  },

  cleanFilter(filter) {
    if (!filter) return false;
    const res = {};
    Object.keys(filter).forEach((key) => {
      let item = filter[key];
      const type = classOf(item);
      if (
        type === 'object' &&
        Object.prototype.hasOwnProperty.call(item, 'q')
      ) {
        let q = item.q;
        if (typeof q === 'string') q = q.trim();
        if (!q) return;
      } else if (type === 'string') {
        item = item.trim();
      }
      if (!item) return;
      res[key] = item;
    });
    if (!Object.keys(res).length) return false;
    return res;
  },

  removeEmptyParams(params = {}) {
    const res = {};
    Object.keys(params).forEach((it) => {
      let param = params[it];
      const type = classOf(param);

      if (type === 'string') param = param.trim();
      if (!param) return;

      switch (type) {
        case 'object': {
          let temp = '';
          if (it === 'filter') {
            temp = this.cleanFilter(param);
          } else {
            temp = this.removeEmptyParams(param);
          }
          if (temp) res[it] = temp;
          break;
        }
        case 'array': {
          if (param.length) res[it] = param;
          break;
        }
        default: {
          if (param) res[it] = param;
        }
      }
    });
    if (Object.keys(res).length) return res;
    return '';
  },

  createRequest(data) {
    const res = {};
    if (data) {
      Object.keys(data).forEach((key) => {
        const param = data[key];
        const type = classOf(param);
        if (type === 'string') res[key] = param.trim();
        else res[key] = param;
      });
    }
    return res;
  },

  createGetLink(filter) {
    let resultFilter = {};
    const cleanedFilter = this.cleanFilter(filter);
    if (cleanedFilter) resultFilter = this.createRequestFilter(cleanedFilter);

    let getPairs = (obj, keys = []) =>
      Object.entries(obj).reduce((pairs, [key, value]) => {
        if (typeof value === 'object')
          pairs.push(...getPairs(value, [...keys, key]));
        else pairs.push([[...keys, key], value]);
        return pairs;
      }, []);

    let stringifiedLink = getPairs(resultFilter)
      .map(
        ([[key0, ...keysRest], value]) =>
          `${key0}${keysRest.map((a) => `[${a}]`).join('')}=${value}`
      )
      .join('&');

    return stringifiedLink;
  },

  createGetRequest(params) {
    const {
      order = 'desc',
      orderBy = 'id',
      filter = {},
      offset = 0,
      limit = PAGINATION_TABLE_LIMIT
    } = params;

    let where = {};
    const cleanedFilter = this.cleanFilter(filter);
    if (cleanedFilter) where = this.createRequestFilter(cleanedFilter);

    const request = {
      count: 1,
      order: `${order === 'desc' ? '-' : ''}${orderBy}`,
      offset: Number(offset),
      limit: Number(limit),
      where
    };
    return request;
  },

  createRequestFilter(filter) {
    if (!filter) return {};
    const res = {};
    Object.keys(filter).forEach((key) => {
      let item = filter[key];
      const type = classOf(item);
      let finalKey = key;
      switch (type) {
        case 'object': {
          if (!Object.prototype.hasOwnProperty.call(item, 'q')) {
            res[finalKey] = item;
            return;
          }
          if (Object.prototype.hasOwnProperty.call(item, 'q') && !item.q)
            return;
          let value = item.q;

          let path = res;

          if (item.qp) {
            const subpaths = item.qp.split('.');
            finalKey = subpaths.pop();
            subpaths.reduce((o, i) => {
              if (!o[i]) o[i] = {};
              path = o[i];
              return o[i];
            }, res);
          }

          if (!path[finalKey]) path[finalKey] = {};

          if (item.qt) {
            switch (item.qt) {
              case 'like': {
                path[finalKey].$like = `%${value}%`;
                break;
              }
              case 'like%': {
                path[finalKey].$like = `${value}%`;
                break;
              }
              case '%like': {
                path[finalKey].$like = `%${value}`;
                break;
              }
              case 'from': {
                path[finalKey].$gte = value;
                break;
              }
              case 'to': {
                path[finalKey].$lte = value;
                break;
              }
              case 'in': {
                path[finalKey].$in = value;
                break;
              }
              default:
                path[finalKey] = value;
            }
          } else {
            path[finalKey] = value;
          }

          break;
        }
        case 'array': {
          if (item.length) res[finalKey] = item;
          return;
        }
        case 'string': {
          item = item.trim();
          if (item === 'null') res[finalKey] = null;
          else if (item) res[finalKey] = item;
          return;
        }
        default: {
          if (item) res[finalKey] = item;
        }
      }
    });
    return res;
  }
};

export default QueryService;
