import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { IconBtnRefresh } from 'components/buttons/IconBtn';
import { FilterBtn, FilterProvider } from 'components/Filter';
import Progress from 'components/Progress';
import Table from 'components/Table';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'flex-start',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  filter: {
    padding: theme.spacing(1),
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      order: 2
    }
  },
  topBarActions: {
    flexShrink: 0,
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      order: 1,
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center'
    }
  }
}));

const List = (props) => {
  const classes = useStyles();
  const {
    toolbar = true,
    record = {},
    getList = () => {},
    hasShow = true,
    toolBarInner,
    filter,
    children
  } = props;

  const { loading, params = {}, list = [] } = record;

  const onRefresh = getList;

  const handleChangeFilter = (filter) => {
    const nextParams = {
      ...params,
      offset: 0,
      filter: filter
    };
    getList(nextParams, {
      eventType: 'filter'
    });
  };

  return (
    <div className={classes.root}>
      {toolbar ? (
        <FilterProvider>
          <div className={classes.topBar}>
            <div className={classes.filter}>
              {!!filter &&
                React.cloneElement(filter, {
                  onFilter: handleChangeFilter,
                  initialValues: (params && params.filter) || {}
                })}
            </div>
            <div className={classes.topBarActions}>
              {!!filter && <FilterBtn />}
              {onRefresh && (
                <IconBtnRefresh onClick={() => onRefresh(params)} />
              )}
              {toolBarInner}
            </div>
          </div>
        </FilterProvider>
      ) : null}
      {loading && <Progress variant="top" />}
      <Table hasShow={hasShow} rows={list} {...props}>
        {children}
      </Table>
    </div>
  );
};

export default React.memo(List);
