import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Grid from '@material-ui/core/Grid';

import { inChildren, validateErrors } from 'services/utils';

import { FormTextField, SimpleForm } from 'components/Form';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};
  errors.name = validateErrors(values.name, {
    require: 'Обязательное поле'
  });
  return errors;
};

const formName = 'edit_method';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      methodsActions,
      history = {}
    } = this.props;
    try {
      await methodsActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const { methodsActions, record = {}, loading, history } = this.props;
    if (loading) return;
    try {
      await methodsActions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };

  handleDelete = async () => {
    const { methodsActions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить услугу?'
      })
    );

    if (res) {
      try {
        await methodsActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  methodsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleUpdate, handleDelete, record = {}, loading } = props;

  const itemReceived = record && record.id;
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Редактировать услугу: ${record.name}`;
  }

  return (
    <Screen title={title}>
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        onBack={back}
        onDelete={handleDelete}
        validate={validate}
        initialValues={{ ...record }}
        parse={(v) => ({ name: v.name || '' })}>
        <Grid item xs={12} sm={6}>
          <FormTextField source="name" label="Название" disabled={loading} />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const MethodsEdit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default MethodsEdit;
