import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { change, stopSubmit, submit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { request } from 'services/api';
import {
  getPriceWithoutVat,
  inChildren,
  numberToPercent,
  toBills,
  toCoins
} from 'services/utils';
import { isPrice } from 'services/validation';

import {
  createOne as createOneClientAction,
  getList as getListClientAction
} from 'resources/Clients/actions';
import { AddForm as AddFormClient } from 'resources/Clients/Add';
import CopyBufferBtn from 'components/buttons/CopyBufferBtn';
import { IconBtnAdd } from 'components/buttons/IconBtn';
import {
  FormCheckboxField,
  FormDateField,
  FormField,
  FormSearchElasticClientField,
  FormSearchElasticExecutorField,
  FormSearchGeoLocalityField,
  FormSearchGeoStreetsField,
  FormSearchMultipleField,
  FormSearchSelectField,
  FormSelectField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import { utils as mapUtils } from 'components/maps';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import SimpleModal from 'modals/SimpleModal';

import * as orderHelpers from './common/helpers';
import OrderMapField from './common/OrderMapField';
import * as actions from './actions';

const validate = (values) => {
  const errors = {};
  const {
    pay_type,
    date_from,
    date_to,
    price,
    transport_price,
    trap_price,
    client,
    is_repeat,
    send_sms
  } = values;

  if (!price) {
    errors.price = 'Укажите цену';
  } else if (!isPrice(price)) {
    errors.price = 'Невалидная цена';
  }

  if (!pay_type) {
    errors.pay_type = 'Укажите способ оплаты';
  }

  if (!date_from) {
    errors.date_from = 'Укажите дату, с';
  }

  if (!date_from) {
    errors.date_from = 'Укажите дату, с';
  }
  if (!date_to) {
    errors.date_to = 'Укажите дату, по';
  }

  if (date_from && date_to && date_from >= date_to) {
    errors.date_to = 'Должна быть больше даты, с';
  }

  if (!client) {
    errors.client = 'Укажите клиента';
  }

  if (!transport_price && transport_price !== 0) {
    errors.transport_price = 'Укажите цену';
  } else if (!isPrice(transport_price)) {
    errors.transport_price = 'Невалидная цена';
  }

  if (!trap_price && trap_price !== 0) {
    errors.trap_price = 'Укажите цену';
  } else if (!isPrice(trap_price)) {
    errors.trap_price = 'Невалидная цена';
  }

  if (!is_repeat) {
    errors.is_repeat = 'Обязательное поле';
  }

  if (!send_sms) {
    errors.send_sms = 'Обязательное поле';
  }

  return errors;
};

const getLocality = async (locality_id) => {
  let locality = null;
  if (locality_id) {
    try {
      locality = await request.get(`/geo/locality/${locality_id}`);
    } catch (err) {
      console.error(err);
    }
  }
  return locality;
};

const defaultformName = 'add_order';
const createClientFormName = 'addClientFromOrder';

class Container extends Component {
  constructor(props) {
    super(props);

    const { location = {} } = props;
    const { state = {} } = location;
    const { copy_id } = state;

    if (copy_id) this.handleGetOne(copy_id);

    this.state = {
      client: null,
      locality: null,
      street: null,
      address_house: '',
      geopoints: [],
      date_from: props.record ? props.record.date_from : null,
      copy: copy_id ? true : false
    };
  }

  componentDidUpdate(prev) {
    if (!prev.record.id && this.props.record.id) {
      const { record } = this.props;
      this.setState({
        ...record,
        zoom: mapUtils.getZoomWithEntries({
          geopoint: record.geopoint,
          locality: record.locality,
          street: record.street,
          address_house: record.address_house || ''
        })
      });
      this.handleGetNearOrders(record.geopoint, {
        date_from: record.date_from
      });
    }
  }

  componentWillUnmount() {
    const { ordersActions } = this.props;
    ordersActions.clearOne();
  }

  handleGetOne = async (id) => {
    const { ordersActions } = this.props;
    try {
      await ordersActions.getOne(id);
    } catch (err) {
      console.error(err);
    }
  };

  handleCreate = async (data) => {
    const { loading, ordersActions, history = {} } = this.props;
    if (loading) return;

    await ordersActions.createOne(data);
    history.goBack();
  };

  handleChangeGeopoint = async ({ locality, street, address_house }) => {
    const { formName, dispatch } = this.props;
    if (this.geopointTimer) {
      clearTimeout(this.geopointTimer);
    }
    const { date_from } = this.state;
    this.geopointTimer = setTimeout(async () => {
      const geopoints = await mapUtils.getMapDataByEntries({
        locality,
        street,
        address_house
      });
      const firstFinded = geopoints && geopoints.length ? geopoints[0] : null;
      const geopoint = mapUtils.getGeopointFromMapData(firstFinded);
      dispatch(
        change(formName, 'geopoint', geopoint || mapUtils.makeNullGeopoint())
      );
      this.setState({
        geopoints: geopoints || [],
        geopoint,
        zoom: mapUtils.getZoomWithEntries({
          geopoint,
          locality,
          street,
          address_house
        })
      });
      this.handleGetNearOrders(geopoint, { date_from });
    }, 500);
  };

  handleGetNearOrders = async (geopoint, params) => {
    let nearOrders = [];
    if (geopoint && mapUtils.isValidGeopoint(geopoint)) {
      nearOrders = await orderHelpers.getNearOrdersByPoint(geopoint, params);
    }
    this.setState({
      nearOrders
    });
  };

  handleChangeLocality = (locality) => {
    const { formName, dispatch } = this.props;
    dispatch(change(formName, 'locality', locality));
    dispatch(change(formName, 'street', null));
    this.setState({
      locality,
      street: null
    });
    this.handleChangeGeopoint({
      locality,
      street: null,
      address_house: ''
    });
  };

  handleChangeStreet = async (street) => {
    const { formName, dispatch } = this.props;
    const { locality } = this.state;
    let _l = locality;
    dispatch(change(formName, 'street', street));
    if (!_l && street) {
      const { locality_id } = street;
      _l = await getLocality(locality_id);
      dispatch(change(formName, 'locality', _l));
    }
    this.setState({ locality: _l, street });
    dispatch(change(formName, 'address_house', ''));

    this.handleChangeGeopoint({
      locality: _l,
      street,
      address_house: ''
    });
  };

  handleChangeAddrHouse = (e, value) => {
    const { locality, street } = this.state;
    this.setState({
      address_house: value
    });
    if (locality && street) {
      this.handleChangeGeopoint({
        locality,
        street,
        address_house: value
      });
    }
  };

  handleCreateClient = async (data) => {
    const { loading, createOneClient, dispatch, getListClient } = this.props;
    if (loading) return;

    try {
      const res = await createOneClient(data);
      return Promise.resolve(res);
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(createClientFormName, { phone: 'Уже существует' }));

        const items = await getListClient({
          filter: {
            phone: data.phone
          }
        });

        if (items.count > 0) {
          return Promise.resolve(items.list[0]);
        }
      }

      return Promise.resolve(err);
    }
  };

  handleChangeClient = async (client) => {
    const { formName, dispatch } = this.props;

    let address_house = '';
    let address_apartment = '';
    let street = null;
    let locality = null;

    if (client) {
      address_house = client.address_house;
      address_apartment = client.address_apartment || '';
      street = client.street || null;
    }

    dispatch(change(formName, 'client', client));
    dispatch(
      change(
        formName,
        'send_sms',
        client && client.send_sms === 'no' ? 'no' : ''
      )
    );

    this.setState({ client });

    const {
      locality: s_locality,
      street: s_street,
      address_house: s_addrHouse
    } = this.state;

    if (s_locality || s_street || s_addrHouse) return;

    dispatch(change(formName, 'address_house', address_house));
    dispatch(change(formName, 'address_apartment', address_apartment));
    dispatch(change(formName, 'street', street));
    const locality_id = client && client.locality_id;
    if (locality_id) locality = await getLocality(locality_id);
    dispatch(change(formName, 'locality', locality));
    this.setState({
      locality,
      street,
      address_house: address_house
    });
    this.handleChangeGeopoint({
      locality,
      street,
      address_house: address_house
    });
  };

  handleChangeDateFrom = (date_from) => {
    const { geopoint } = this.state;
    this.setState({ date_from });
    this.handleGetNearOrders(geopoint, { date_from });
  };

  handleClickTimepoint = (data) => {
    const { formName, dispatch } = this.props;
    const { executor, timepoint } = data;
    dispatch(change(formName, 'executor', executor));
    dispatch(change(formName, 'date_from', timepoint.start));
    dispatch(change(formName, 'date_to', timepoint.end));
    this.handleChangeDateFrom(timepoint.start);
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      handleCreate: this.handleCreate,
      handleCreateClient: this.handleCreateClient,
      handleChangeAddrHouse: this.handleChangeAddrHouse,
      handleChangeLocality: this.handleChangeLocality,
      handleChangeStreet: this.handleChangeStreet,
      handleChangeClient: this.handleChangeClient,
      handleChangeDateFrom: this.handleChangeDateFrom,
      handleClickTimepoint: this.handleClickTimepoint,
      state: { ...this.state },
      ...rest
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    formName: ownProps.formName || defaultformName,
    loading: state.orders.one.loading,
    record: {
      ...ownProps.record,
      ...state.orders.one.data
    }
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  createOneClient: (data) => dispatch(createOneClientAction(data)),
  getListClient: (data) => dispatch(getListClientAction(data)),
  ordersActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleCreate,
    handleCreateClient,
    loading,
    footerBar = true,
    record = {},
    formName,
    title = 'Создать заявку',
    handleChangeAddrHouse,
    handleChangeLocality,
    handleChangeStreet,
    handleChangeClient,
    handleChangeDateFrom,
    handleClickTimepoint,
    state
  } = props;

  const history = useHistory();
  const back = history.goBack;
  const dispatch = useDispatch();

  const [isOpenCreateClient, setIsOpenCreateClient] = React.useState(false);
  const [price, setPrice] = React.useState('0');
  const [payType, setPayType] = React.useState(null);

  const createClient = async (props) => {
    const res = await handleCreateClient(props);
    if (res && res.id) {
      dispatch(change(formName, 'client', res));
      setIsOpenCreateClient(false);
      handleChangeClient(res);
    }
  };

  const is_urgently_writable = useCan('orders:urgently');
  const payTypeHelperText = React.useMemo(() => {
    if (!payType || !payType.vat) {
      return '';
    }
    return `НДС - ${numberToPercent(payType.vat)}%`;
  }, [payType]);

  const priceHelperText = React.useMemo(() => {
    if (!payType || !payType.vat) {
      return '';
    }
    const priceWithoutVat = getPriceWithoutVat(price, payType.vat);
    if (priceWithoutVat) {
      return `Без НДС - ${priceWithoutVat}; `;
    }
  }, [payType, price]);

  return (
    <Screen title={title}>
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleCreate}
        disabled={loading}
        onBack={back}
        direction="row"
        alignItems="flex-start"
        maxWidth={false}
        validate={validate}
        footerBar={footerBar}
        initialValues={{
          ...record,
          price: record && record.price >= 0 ? toBills(record.price) : '0',
          transport_price:
            record && record.transport_price >= 0
              ? toBills(record.transport_price)
              : '0',
          trap_price:
            record && record.trap_price >= 0 ? toBills(record.trap_price) : '0',
          is_active: (record && record.is_active) || 'yes',
          methods:
            record && Array.isArray(record.methods) ? record.methods : [],
          reasons:
            record && Array.isArray(record.reasons) ? record.reasons : [],
          is_urgently: 'no',
          is_feedback: 'no'
        }}
        parse={(values) => {
          const res = {
            agreement_number: values.agreement_number || '',
            address_apartment: values.address_apartment || '',
            address_house: values.address_house || '',
            comment: values.comment || '',
            comment_fin: values.comment_fin || '',
            date_from: values.date_from,
            date_to: values.date_to,
            is_active: values.is_active,
            is_urgently: values.is_urgently,
            is_qc: values.is_qc,
            is_feedback: values.is_feedback,
            price: values.price >= 0 ? toCoins(values.price) : 0,
            transport_price:
              values.transport_price >= 0 ? toCoins(values.transport_price) : 0,
            trap_price: values.trap_price >= 0 ? toCoins(values.trap_price) : 0,
            is_repeat: values.is_repeat,
            send_sms: values.send_sms
          };

          res.locality_id = null;
          if (values.street) {
            res.locality_id = values.street.locality_id;
            res.street_id = values.street.id;
          } else {
            res.locality_id = null;
            res.street_id = null;
          }

          if (!res.locality_id && values.locality) {
            res.locality_id = values.locality.id;
          }

          if (values.client) {
            res.client_id = values.client.id;
          }
          if (values.executor) {
            res.executor_id = values.executor.id;
          }
          if (values.pay_type) {
            res.pay_type_id = values.pay_type.id;
          }
          if (values.methods && Array.isArray(values.methods)) {
            res.methods = values.methods.map((it) => it.id);
          }
          if (values.reasons && Array.isArray(values.reasons)) {
            res.reasons = values.reasons.map((it) => it.id);
          }
          res.geopoint = values.geopoint || mapUtils.makeNullGeopoint();
          return res;
        }}>
        <Grid item container xs={12} md={6} spacing={1}>
          <Grid item xs={12} sm={6}>
            <FormTextField
              source="price"
              label="Цена"
              disabled={loading}
              type="number"
              inputProps={{
                step: '0.01'
              }}
              onChange={(e) => setPrice(e.target.value)}
              helperText={priceHelperText}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSearchSelectField
              source="pay_type"
              label="Способ оплаты"
              requestPath="/pay_types"
              responseFormat={(v) => v.results}
              getOptionLabel={(it) => it?.name || ''}
              disabled={loading}
              searchBy="name"
              onChange={setPayType}
              helperText={payTypeHelperText}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormDateField
              label="Дата, с"
              source="date_from"
              dateType="datetime"
              disabled={loading}
              onChange={handleChangeDateFrom}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormDateField
              label="Дата, по"
              source="date_to"
              dateType="datetime"
              disabled={loading}
            />
          </Grid>

          <Grid item container xs={12} alignItems="center">
            <Grid item container xs>
              <Grid item xs>
                <FormSearchElasticClientField
                  source="client"
                  label="Клиент"
                  disableClearable={true}
                  disabled={loading}
                  onChange={(client) => handleChangeClient(client)}
                />
              </Grid>
              <Grid item>
                <IconBtnAdd
                  onClick={() => {
                    setIsOpenCreateClient(true);
                  }}
                />
              </Grid>
            </Grid>
            <Grid item>
              {state.client && state.client.phone && (
                <CopyBufferBtn value={state.client.phone} />
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSelectField
              source="is_repeat"
              label="Повтор"
              disabled={loading}
              options={[
                { value: 'no', label: 'Нет' },
                { value: 'yes', label: 'Да' }
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSelectField
              source="send_sms"
              label="Отправить СМС"
              disabled={loading}
              options={[
                { value: 'no', label: 'Нет' },
                { value: 'yes', label: 'Да' }
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSearchMultipleField
              label="Вредители"
              name="reasons"
              requestPath="/reasons"
              getOptionLabel={(o) => o?.name || ''}
              searchBy="name"
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSearchMultipleField
              label="Услуги"
              name="methods"
              requestPath="/methods"
              getOptionLabel={(o) => o?.name || ''}
              searchBy="name"
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSearchGeoLocalityField
              source="locality"
              label="Населенный пункт"
              disabled={loading}
              onChange={handleChangeLocality}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSearchGeoStreetsField
              source="street"
              label="Улица"
              disabled={loading}
              where={
                state.locality
                  ? { type: 'street', locality_id: state.locality.id }
                  : { type: 'street' }
              }
              onChange={handleChangeStreet}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              source="address_house"
              label="Дом"
              disabled={loading}
              onChange={handleChangeAddrHouse}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              source="address_apartment"
              label="Квартира"
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12}>
            <FormTextField
              source="comment"
              label="Примечание"
              disabled={loading}
              multiline={true}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              source="comment_fin"
              label="Примечание, фин"
              disabled={loading}
              multiline={true}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextField
              source="agreement_number"
              label="Номер договора"
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextField
              source="transport_price"
              label="Цена, транспорт"
              disabled={loading}
              type="number"
              inputProps={{
                step: '0.01'
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormSearchElasticExecutorField
              source="executor"
              label="Дезинфектор"
              is_active="yes"
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextField
              source="trap_price"
              label="Цена, ловушка"
              disabled={loading}
              type="number"
              inputProps={{
                step: '0.01'
              }}
            />
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <div>
                <FormCheckboxField
                  source="is_active"
                  label="Активен"
                  disabled={loading}
                  parse={(v) => (v ? 'yes' : 'no')}
                  format={(v) => v === 'yes'}
                />
              </div>
              <div>
                <FormCheckboxField
                  source="is_urgently"
                  label="Срочно"
                  disabled={loading || !is_urgently_writable}
                  parse={(v) => (v ? 'yes' : 'no')}
                  format={(v) => v === 'yes'}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div>
                <FormCheckboxField
                  source="is_qc"
                  label="Контроль качества"
                  disabled={loading}
                  parse={(v) => (v ? 'yes' : 'no')}
                  format={(v) => v === 'yes'}
                />
              </div>
              <div>
                <FormCheckboxField
                  source="is_feedback"
                  label="Оставлен чек"
                  disabled={loading}
                  parse={(v) => (v ? 'yes' : 'no')}
                  format={(v) => v === 'yes'}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6}>
          <FormField
            source="geopoint"
            options={state.geopoints}
            zoom={state.zoom}
            nearOrders={state.nearOrders}
            component={OrderMapField}
            onSelectTimepoint={handleClickTimepoint}
          />
        </Grid>
      </SimpleForm>
      <SimpleModal
        isOpen={isOpenCreateClient}
        handleClose={() => setIsOpenCreateClient(false)}
        cancelText="Отмена"
        confirmText="Создать"
        handleSuccess={() => dispatch(submit(createClientFormName))}>
        <AddFormClient
          formName={createClientFormName}
          footerBar={false}
          confirmText="Создать"
          handleCreate={createClient}
        />
      </SimpleModal>
    </Screen>
  );
};

const OrdersAdd = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default OrdersAdd;
