import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { inChildren } from 'services/utils';

import { IconBtnDelete } from 'components/buttons/IconBtn';
import Field, { FieldSelect } from 'components/Field';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  }
}));

class Container extends Component {
  componentDidMount() {
    this.getList();
  }

  refresh = () => {
    const { groups = {} } = this.props;
    const { params = {} } = groups;
    this.getList(params);
  };

  getList = (params) => {
    const { user_id, usersActions } = this.props;
    usersActions.getUserSessions(user_id, params);
  };

  handleUpdate = async (session_id, req) => {
    const { usersActions } = this.props;
    try {
      await usersActions.updateSession(session_id, req);
      this.refresh();
    } catch (err) {
      console.error(err);
    }
  };

  handleDelete = async (session_id) => {
    const { usersActions, otherActions } = this.props;
    const res = await otherActions.showModalConfirm({
      title: 'Удаление',
      content: 'Удалить сессию?'
    });

    if (res) {
      try {
        await usersActions.deleteSession(session_id);
        this.refresh();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleDelete: this.handleDelete,
      handleUpdate: this.handleUpdate
    });
  }
}

const mapStateToProps = (state) => {
  const target = state.users;
  const { sessions = {} } = target;
  return {
    record: sessions
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  usersActions: bindActionCreators(actions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const classes = useStyles();
  const { handleUpdate, handleDelete } = props;

  const isCanSessionsWrite = useCan('sessions:write');

  return (
    <div>
      <List {...props} title="Сессии пользователя">
        <Field label="ID" source="id" sortable />
        <Field label="ip" source="ip" />
        <Field label="ua" source="ua" />
        <Field label="Активна" source="is_active">
          {(props) => {
            const { row, value } = props;
            return (
              <FieldSelect
                type="boolean"
                value={value === 'yes'}
                onChange={(it) => {
                  if (it.value !== value)
                    handleUpdate(row.id, { is_active: it.value });
                }}
                options={[
                  {
                    value: 'yes',
                    label: <CheckIcon className={classes.success} />
                  },
                  {
                    value: 'no',
                    label: <CloseIcon className={classes.error} />
                  }
                ]}
              />
            );
          }}
        </Field>

        <Field label="Дата создания" source="created_at" type="date" sortable />
        <Field source="id" isAction>
          {({ value }) => {
            return (
              <React.Fragment>
                {isCanSessionsWrite && (
                  <IconBtnDelete onClick={() => handleDelete(value)} />
                )}
              </React.Fragment>
            );
          }}
        </Field>
      </List>
    </div>
  );
};

const UsersSessions = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default UsersSessions;
