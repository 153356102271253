import ApiRequest from './ApiRequest';

export default class Groups extends ApiRequest {
  getList(params) {
    return this.get(`/groups`, {
      qs: params
    });
  }

  getOne(id) {
    return this.get(`/groups/${id}`);
  }

  createOne(req) {
    return this.post(`/groups`, {
      body: req
    });
  }

  updateOne(id, req) {
    return this.put(`/groups/${id}`, {
      body: req
    });
  }

  deleteOne(id) {
    return this.delete(`/groups/${id}`);
  }

  getGroupUsers(id, req) {
    return this.get(`/groups/${id}/users`, {
      qs: req
    });
  }

  attachUserGroup(group_id, user_id) {
    return this.post(`/groups/${group_id}/users/${user_id}`);
  }

  detachUserGroup(group_id, user_id) {
    return this.delete(`/groups/${group_id}/users/${user_id}`);
  }
}
