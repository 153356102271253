import React from 'react';
import moment from 'moment';

import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/config';

import { parseHistory } from 'services/historyUtils';

import HistoryInfo from 'components/HistoryInfo';

const useItemStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1, 2)
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flexWrap: 'wrap',
    marginBottom: 5,
    color: '#484848',
    fontWeight: 'bold'
  },
  callTitle: {
    fontSize: 12
  },
  date: {
    fontSize: 12
  },
  label: {
    color: '#484848'
  },
  content: {
    fontSize: 12
  }
}));

const HistoryList = (props) => {
  const { list } = props;

  return (
    <div>
      {list.map((it) => {
        return <ListItem key={it.id} {...it} />;
      })}
    </div>
  );
};

export default HistoryList;

const ListItem = (props) => {
  const classes = useItemStyles();
  const { id, user = {}, item_type, created_at, changes } = props;

  const parsed = parseHistory(changes, item_type);

  return (
    <>
      {parsed && parsed.items.length && (
        <div key={id}>
          <div className={classes.container}>
            <div className={classes.title}>
              <div className={classes.user}>
                {user ? [user.first_name, user.last_name].join(' ') : ''}
              </div>
              <div className={classes.date}>
                {moment(created_at).format(`${DATE_FORMAT}, ${TIME_FORMAT}`)}
              </div>
            </div>
            <HistoryInfo
              items={parsed.items}
              type={parsed.type}
              devideByColumns={false}
              contentClassName={classes.content}
              labelClassName={classes.label}
              callTitleClassName={classes.callTitle}
            />
          </div>
          <Divider />
        </div>
      )}
    </>
  );
};
