import {
  orders as ordersApi
  // executors as executorsApi
} from 'services/api';
// import moment from 'moment';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';

// import { PAGINATION_TABLE_LIMIT } from 'constants/config';
import { utils as mapUtils } from 'components/maps';
// import {mergeDateDistances, exludeDateDistances, breakToDistances, createTimeRangeString} from 'services/dateUtils';

const REDUX_PREFIX = 'ORDERS_MAP';

export const actionTypes = {
  GET_LIST: `${REDUX_PREFIX}_GET_LIST`
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

const defaultQuery = {
  order: 'desc',
  orderBy: 'id',
  filter: {},
  offset: 0,
  limit: 1000
};

export const getList =
  (param = {}) =>
  async (dispatch) => {
    const actionType = actionTypes.GET_LIST;
    const next = {
      ...defaultQuery,
      ...param,
      filter: {
        // is_active: 'yes',
        ...param.filter
      }
    };

    const request = Query.createGetRequest(next);
    if (!next.filter?.is_active) {
      request.where.is_active = 'yes';
    } else if (next.filter?.is_active === 'all') {
      delete request.where.is_active;
    }

    dispatch(
      updateRedux(actionType, {
        loading: true,
        params: next,
        list: []
      })
    );

    try {
      const response = await ordersApi.getList(request);
      const count = response.count;
      const list = response.results.filter((it) =>
        mapUtils.isValidGeopoint(it.geopoint)
      );

      const res = {
        list,
        count,
        loading: false
      };

      dispatch(updateRedux(actionType, res));
      return Promise.resolve(res);

      // if (!list || !list.length) {
      //   dispatch(updateRedux(actionType, res));
      //   return Promise.resolve(res);
      // }

      // const result = {};
      // const executors_ids = Array.from(new Set(list.filter(it => it.executor).map(it => it.executor.id)));
      // if (!executors_ids || !executors_ids.length) {
      //   dispatch(updateRedux(actionType, res));
      //   return Promise.resolve(res);
      // }

      // const start = moment(request.where.date_from.$gte);
      // const end = moment(request.where.date_from.$lte);
      // const addData = await Promise.all([
      //   executorsApi.getSchedule({
      //     offset: 0,
      //     limit: 1000,
      //     where: {
      //       executor_id: executors_ids
      //     }
      //   }),
      //   executorsApi.getAvailableList({
      //     order: 'date_from',
      //     offset: 0,
      //     limit: 1000,
      //     where: {
      //       executor_id: executors_ids,
      //       date_from: request.where.date_from,
      //     }
      //   }),
      // ]);
      // const scheduleList = addData[0].results;
      // const availableList = addData[1].results;
      // const ordersList = list;

      // executors_ids.forEach( (id) => {
      //   if (!result[id]) {
      //     result[id] = {
      //       id,
      //       orders:[],
      //       available:[],
      //       schedule: {},
      //     }
      //   }
      // });

      // ordersList.forEach(it => {
      //   const { executor_id } = it;
      //   if (result[executor_id]) {
      //     result[executor_id].orders.push(it)
      //   }
      // });

      // availableList.forEach(it => {
      //   const { executor_id } = it;
      //   if (result[executor_id]) {
      //     result[executor_id].available.push(it)
      //   }
      // });

      // scheduleList.forEach(it => {
      //   const { executor_id, week_day } = it;
      //   if (result[executor_id]) {
      //     if (!result[executor_id].schedule[week_day]) {
      //       result[executor_id].schedule[week_day] = [];
      //     }
      //     result[executor_id].schedule[week_day].push({
      //       ...it,
      //     });
      //   }
      // });

      // Object.values(result).forEach(data => {
      //   const { orders = [], available = [], schedule = {}, id: executor_id  } = data;
      //   const availableYes = [];
      //   const availableNo = [];

      //   if (orders && orders.length) {
      //     orders.forEach(it => {
      //       if (it.is_active === 'yes'){
      //         availableNo.push({
      //           from: moment(it.date_from),
      //           to: moment(it.date_to),
      //         });
      //       }
      //     });
      //   }

      //   if (available && available.length) {
      //     available.forEach(it => {
      //       const from = moment(it.date_from);
      //       const to = moment(it.date_to);
      //       if (it.is_available === 'no') {
      //         availableNo.push({
      //           from,
      //           to,
      //         })
      //       } else if (it.is_available === 'yes') {
      //         availableYes.push({
      //           from,
      //           to,
      //         });
      //       }
      //     });
      //   }

      //   for (let i = 0; i < 3; i++) {
      //     const cur = start.clone().add(i, 'd');
      //     const weekDay = cur.isoWeekday();
      //     const times = schedule[weekDay];
      //     if (times && times.length) {
      //       times.forEach(it => {
      //         const from = moment(cur.format('YYYY-MM-DD') + 'T' + it.time_from);
      //         const to = moment(cur.format('YYYY-MM-DD') + 'T' + it.time_to);
      //         availableYes.push({
      //           from,
      //           to,
      //         });
      //       });
      //     }
      //   }

      //   const timeline = {};
      //   const availableMerged = mergeDateDistances(availableYes);
      //   const unAvailableMerged = mergeDateDistances(availableNo);
      //   const availableWithoutUnavailable = exludeDateDistances(availableMerged, unAvailableMerged);
      //   const availableBreaked = breakToDistances(availableWithoutUnavailable);
      //   availableBreaked.forEach( (it,i) => {
      //     const _date = it.from.format('DD.MM.YYYY');
      //     if (!timeline[_date]) {
      //       timeline[_date] = [];
      //     }
      //     const titleTimeRange = createTimeRangeString(it.from, it.to);
      //     timeline[_date].push({
      //       key: `ex-${executor_id}-available-${i}`,
      //       executor_id,
      //       title: titleTimeRange,
      //       start: it.from.toISOString(),
      //       end: it.to.toISOString(),
      //     });
      //   });
      //   result[executor_id].timeline = timeline;
      // });

      // const resultList = list.map(it => {
      //   if (!it.executor_id || !result[it.executor_id]) {
      //     return it;
      //   }
      //   return {
      //     ...it,
      //     timeline: result[it.executor_id].timeline
      //   }
      // });
      // dispatch(updateRedux(actionType, {
      //   list: resultList,
      //   count,
      //   loading: false,
      // }));
      // return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      dispatch(updateRedux(actionType, { loading: false }));
      return Promise.reject(err);
    }
  };
