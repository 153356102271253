import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/config';

import { inChildren } from 'services/utils';

import { Btn } from 'components/buttons/Btn';
import { IconBtnClose, IconBtnRefresh } from 'components/buttons/IconBtn';
import Form, { FormTextField } from 'components/Form';
import NotFoundItems from 'components/NotFoundItems';
import Rbac, { useCan } from 'components/Rbac';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const formName = 'ADD_ORDER_CLIENT_MESSAGE';

const useStyles = makeStyles((theme) => ({
  topWrapper: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  topWrapperLeft: {
    marginTop: -5,
    marginLeft: theme.spacing(2)
  },
  form: {
    flexGrow: 1,
    marginBottom: theme.spacing(2),
    maxWidth: 1000
  },
  container: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  formBtnAdd: {
    marginLeft: theme.spacing(2)
  },
  loadmoreWrapper: {
    textAlign: 'center',
    paddingTop: theme.spacing(2)
    // paddingBottom: theme.spacing(2),
  }
}));

class Container extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetched: false
    };
  }
  componentDidMount() {
    this.getList();
  }

  getList = async (params) => {
    const { client, actions } = this.props;
    await actions.getClientMessages(client.id, params);
    this.setState({ fetched: true });
  };

  refresh = () => {
    const { params = {} } = this.props.record;
    this.getList({ ...params, offset: 0 });
  };

  handleCreate = async (params) => {
    const { client, actions } = this.props;
    const res = await actions.createClientMessage(client.id, params);
    this.refresh();
    return res;
  };

  handleLoad = async (params) => {
    const { client, actions } = this.props;
    actions.getClientMessagesLoad(client.id, params);
  };

  handleDelete = async (item_id) => {
    const { client, actions, otherActions } = this.props;
    const res = await otherActions.showModalConfirm({
      title: 'Удаление',
      content: 'Удалить заметку?'
    });

    if (res) {
      try {
        await actions.deleteClientMessage(client.id, item_id);
        this.refresh();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      state: { ...this.state },
      handleGet: this.getList,
      handleRefresh: this.refresh,
      handleLoad: this.handleLoad,
      handleCreate: this.handleCreate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { clientMessages = {} } = target;
  return {
    record: clientMessages
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const {
    state,
    record,
    handleGet,
    handleDelete,
    handleCreate,
    handleRefresh,
    handleLoad
  } = props;

  const [submitting, setSubmitting] = React.useState(false);

  const onSubmit = async (params) => {
    if (submitting) return;
    const { message = '' } = params;
    if (!message.trim()) {
      return;
    }
    setSubmitting(true);
    await handleCreate(params);
    dispatch(change(formName, 'message', ''));
    setSubmitting(false);
  };

  const { loading, list = [], count, params = {} } = record;
  const isCanDeleteMessage = useCan('client_messages:delete');

  return (
    <Box p={2}>
      <Rbac operation="client_messages:create">
        <Grid container className={classes.topWrapper}>
          <Grid item xs={12} sm>
            <Form
              form={formName}
              className={classes.form}
              onSubmit={onSubmit}
              disabled={submitting}
              initialValues={{
                message: ''
              }}>
              <div className={classes.container}>
                <FormTextField
                  source="message"
                  placeholder="Сообщение"
                  disabled={submitting}
                  multiline={true}
                  maxLength="65535"
                />
                <Btn
                  type="submit"
                  color="primary"
                  className={classes.formBtnAdd}
                  disabled={submitting}>
                  Отправить
                </Btn>
              </div>
            </Form>
          </Grid>
          <div className={classes.topWrapperLeft}>
            <Select
              labelId="demo-simple-select-label"
              value={params.order}
              onChange={(evt) => handleGet({ order: evt.target.value })}>
              <MenuItem value="asc">Сначала старые</MenuItem>
              <MenuItem value="desc">Сначала новые</MenuItem>
            </Select>
            <IconBtnRefresh onClick={() => handleRefresh()} />
          </div>
        </Grid>
      </Rbac>
      <div>
        {list.map((it) => {
          return (
            <React.Fragment key={it.id}>
              <ListItem
                item={it}
                onDelete={() => handleDelete(it.id)}
                deletadble={isCanDeleteMessage}
              />
              <Divider />
            </React.Fragment>
          );
        })}
        {state.fetched && !list.length && !loading ? (
          <NotFoundItems message="Нет сообщений" />
        ) : null}
      </div>
      {count > list.length && !loading ? (
        <div className={classes.loadmoreWrapper}>
          <Btn disabled={loading} color="primary" onClick={() => handleLoad()}>
            Показать Еще
          </Btn>
        </div>
      ) : null}
    </Box>
  );
};

const ShowClientMessagesList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ShowClientMessagesList;

const useListItemStyles = makeStyles((theme) => ({
  listItemRoot: {
    position: 'relative',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.08)',
      '& $close': {
        display: 'block'
      }
    }
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 'bold'
  },
  date: {
    fontSize: 12,
    fontWeight: 'normal',
    paddingRight: 40
  },
  msg: {
    whiteSpace: 'pre-line'
  },
  close: {
    position: 'absolute',
    top: -5,
    right: -5,
    display: 'none'
  },
  point: {
    width: 16,
    height: 16,
    marginRight: 8,
    flexShrink: 0,
    display: 'inline-block',
    borderRadius: '50%',
    verticalAlign: 'middle'
  },
  titleText: {
    verticalAlign: 'middle'
  }
}));

const ListItem = ({ item, deletadble, onDelete }) => {
  const classes = useListItemStyles();
  const { message, created_at, user } = item;
  const { first_name, last_name } = user;
  return (
    <div className={classes.listItemRoot}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          {item?.user?.color ? (
            <span
              className={classes.point}
              style={{ background: item?.user?.color ?? '#fff' }}
            />
          ) : null}
          <span
            className={classes.titleText}>{`${first_name} ${last_name}`}</span>
        </div>
        <div className={classes.date}>
          {moment(created_at).format(`${DATE_FORMAT}, ${TIME_FORMAT}`)}
        </div>
        {!!deletadble && (
          <IconBtnClose className={classes.close} onClick={onDelete} />
        )}
      </div>
      <div className={classes.msg}>{message}</div>
    </div>
  );
};
