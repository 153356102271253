export const SHOW_MODAL = '@REDUX_MODALS@/SHOW_MODAL';
export const REMOVE_MODAL = '@REDUX_MODALS@/REMOVE_MODAL';

export function showModal(component, props) {
  return (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: SHOW_MODAL,
        component,
        resolve,
        props: {
          ...props
        }
      });
    });
}

export function removeModal(uid) {
  return {
    type: REMOVE_MODAL,
    uid
  };
}
