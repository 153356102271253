import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import { BackBtn, DeleteBtn, EditBtn } from 'components/buttons/Btn';
import { LabelField } from 'components/Field';
import FooterBar from 'components/FooterBar';
import NotFoundItems from 'components/NotFoundItems';
import Progress from 'components/Progress';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as authActions from '../../store/actions/authActions';

import * as actions from './actions';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false
    };
  }
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      remindersActions,
      authActions
    } = this.props;
    try {
      await remindersActions.getOne(id);
      await authActions.updateUserStatus();
    } catch (err) {
      this.setState({ notFound: true });
    }
  };

  handleDelete = async () => {
    const {
      remindersActions,
      record = {},
      dispatch,
      history = {}
    } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить напоминание?'
      })
    );

    if (res) {
      try {
        await remindersActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      notFound: this.state.notFound,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  const admin = state.auth.admin;
  return {
    record: one.data,
    loading: one.loading,
    admin
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  remindersActions: bindActionCreators(actions, dispatch),
  authActions: bindActionCreators(authActions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleDelete,
    record = {},
    loading,
    basePath = '/',
    notFound,
    admin
  } = props;

  const itemReceived = !!(record && record.id);
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Напоминание: ${record.id}`;
  }

  const isCanWrite = useCan('reminders:write');
  const isCanOwnWrite = useCan('reminders:own_write');

  return (
    <Screen title={title}>
      {notFound && <NotFoundItems message="Напоминание не найдено" />}
      {loading && <Progress />}
      {itemReceived && (
        <Box p={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <LabelField
                width={150}
                label="Клиент:"
                value={record.client.name}
              />
            </Grid>
            <Grid item>
              <LabelField
                width={150}
                label="Пользователь:"
                value={record.user.first_name + ' ' + record.user.last_name}
              />
            </Grid>
            <Grid item>
              <LabelField
                width={150}
                label="Дата события:"
                type="date"
                value={record.date_start}
              />
            </Grid>
            <Grid item>
              <LabelField
                width={150}
                label="Сообщение:"
                value={record.message}
                type="longtext"
              />
            </Grid>
            <Grid item>
              <LabelField
                width={150}
                label="Дата создания:"
                type="date"
                value={record.created_at}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <FooterBar>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <BackBtn onClick={back}>Назад</BackBtn>
            </Grid>
            <Grid item>
              {itemReceived &&
                (isCanWrite ||
                  (isCanOwnWrite && record.user_id === admin.id)) && (
                  <EditBtn
                    to={{
                      pathname: `${basePath}/edit/${record.id}`,
                      state: { from: `${basePath}/show/${record.id}` }
                    }}>
                    Редактировать
                  </EditBtn>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {itemReceived &&
            (isCanWrite || (isCanOwnWrite && record.user_id === admin.id)) && (
              <DeleteBtn onClick={handleDelete}>Удалить</DeleteBtn>
            )}
        </Grid>
      </FooterBar>
    </Screen>
  );
};

const RemindersShow = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default RemindersShow;
