import React from 'react';
import { useDispatch } from 'react-redux';

import { subscribe } from 'services/push';

import { onPushData } from 'store/actions/push';

const Push = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    subscribe((e) => {
      dispatch(onPushData(e.data));
    })
      .then(() => {
        // eslint-disable-next-line no-console
        console.log('sub - ok');
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, [dispatch]);
  return null;
};

export default Push;
