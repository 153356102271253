import React from 'react';
import { v4 as uuid } from 'uuid';

export const FilterContext = React.createContext({});

class FilterProvider extends React.Component {
  constructor(props) {
    super(props);
    this.formName = props.formName || uuid();

    this.state = {
      registredFilters: [],
      registredSources: [],
      unvisibleSources: []
    };
  }

  registerFilters = (filters) => {
    this.setState((state) => {
      const unvisibleSources = [];
      const sources = Object.keys(filters).map((source) => {
        if (filters[source].unvisible) {
          unvisibleSources.push(source);
        }
        return source;
      });

      return {
        registredFilters: {
          ...state.registredFilters,
          ...filters
        },
        registredSources: state.registredSources.concat(sources),
        unvisibleSources
      };
    });
  };

  showFilter = (source) => {
    this.setState((state) => {
      const unvisibleSources = state.unvisibleSources.filter(
        (it) => it !== source
      );
      const filter = state.registredFilters[source];
      return {
        ...state,
        unvisibleSources,
        registredFilters: {
          ...state.registredFilters,
          [source]: {
            ...filter,
            unvisible: false
          }
        }
      };
    });
  };

  showFilters = (sources) => {
    this.setState((state) => {
      const { registredFilters = {} } = state;
      let { unvisibleSources = [] } = state;
      sources.forEach((source) => {
        if (registredFilters[source])
          registredFilters[source].unvisible = false;
        unvisibleSources = unvisibleSources.filter((it) => it !== source);
      });
      return {
        ...state,
        unvisibleSources,
        registredFilters: {
          ...registredFilters
        }
      };
    });
  };

  hideFilter = (source) => {
    this.setState((state) => {
      const unvisibleSources = state.unvisibleSources.filter(
        (it) => it !== source
      );
      unvisibleSources.push(source);
      const filter = state.registredFilters[source];
      return {
        ...state,
        unvisibleSources,
        registredFilters: {
          ...state.registredFilters,
          [source]: {
            ...filter,
            unvisible: true
          }
        }
      };
    });
  };

  render() {
    const { children } = this.props;
    return (
      <FilterContext.Provider
        value={{
          formName: this.formName,
          registerFilters: this.registerFilters,
          showFilter: this.showFilter,
          showFilters: this.showFilters,
          hideFilter: this.hideFilter,
          ...this.state
        }}>
        {children}
      </FilterContext.Provider>
    );
  }
}

export default React.memo(FilterProvider);
