import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import { FormTextField, SimpleForm } from 'components/Form';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';

import * as actions from './actions';
import { validateForm } from './validation';

const defaultFormName = 'add_geo_region';

class Container extends Component {
  handleCreate = async (data) => {
    const { loading, regionsActions, dispatch, history = {} } = this.props;
    if (loading) return;
    try {
      await regionsActions.createOne(data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(defaultFormName, { name: 'Уже существует' }));
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleCreate: this.handleCreate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  regionsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

export const AddForm = (props) => {
  const {
    handleCreate,
    loading,
    formName = defaultFormName,
    footerBar = true
  } = props;

  const history = useHistory();
  const back = history.goBack;

  const is_writable = useCan('geo:write');

  if (!is_writable) {
    return null;
  }

  return (
    <SimpleForm
      loading={loading}
      form={formName}
      onSubmit={handleCreate}
      disabled={loading}
      onBack={back}
      direction="row"
      footerBar={footerBar}
      validate={validateForm}
      parse={(values) => {
        const { name, alt_name, old_name, name_lang1, name_lang2 } = values;
        const res = {
          name: name ? name.trim() : '',
          alt_name: alt_name ? alt_name.trim() : '',
          old_name: old_name ? old_name.trim() : '',
          name_lang1: name_lang1 ? name_lang1.trim() : '',
          name_lang2: name_lang2 ? name_lang2.trim() : ''
        };
        return res;
      }}>
      <Grid item xs={12}>
        <FormTextField source="name" label="Название" disabled={loading} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          source="alt_name"
          label="Альтернативное название"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          source="old_name"
          label="Старое название"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField source="name_lang1" label="Язык №1" disabled={loading} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          source="name_lang2"
          label="Язык №2:"
          disabled={loading}
        />
      </Grid>
    </SimpleForm>
  );
};

const RegionsAdd = (props) => (
  <Screen title="Добавить регион">
    <ContainerConnect {...props}>
      <AddForm />
    </ContainerConnect>
  </Screen>
);

export default RegionsAdd;
