import { actionTypes } from './actions';

const initialState = {
  list: null,
  itemType: '',
  itemId: '',
  historyDrawerOpened: false,
  params: {},
  showLoadMore: false,
  refresh: false
};

const ResourceHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_HISTORY_DRAWER: {
      const { historyDrawerOpened } = state;
      if (historyDrawerOpened) return state;
      const { payload = {} } = action;
      const { itemType, itemId } = payload;
      const { itemType: prevItemType, itemId: prevItemId } = state;
      if (itemType !== prevItemType || itemId !== prevItemId) {
        return {
          ...initialState,
          historyDrawerOpened: true,
          itemType,
          itemId
        };
      }
      return {
        ...state,
        historyDrawerOpened: true
      };
    }
    case actionTypes.HIDE_HISTORY_DRAWER: {
      const { historyDrawerOpened } = state;
      if (!historyDrawerOpened) return state;
      return {
        ...state,
        historyDrawerOpened: false
      };
    }
    case actionTypes.HISTORY_LIST_GET: {
      const { payload = {} } = action;
      return {
        ...state,
        ...payload
      };
    }
    default:
      return state;
  }
};

export default ResourceHistoryReducer;
