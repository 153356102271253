import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { AVAILABLE_TYPES } from 'constants/config';

import Query from 'services/Query';
import { inChildren } from 'services/utils';

import * as executorsActions from 'resources/Executors/actions';
import { AddBtn } from 'components/buttons/Btn';
import {
  IconBtnCopy,
  IconBtnDelete,
  IconBtnEdit
} from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterDateRangeField,
  FilterSearchElasticExecutorField,
  FilterSearchField,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { executorsAvailableActions } = this.props;
      executorsAvailableActions.getList(params);
    }
  };

  handleDelete = async (row) => {
    const { executor = {}, id: available_id } = row;
    const { id: executor_id } = executor;

    const { otherActions, executorsActions, record = {} } = this.props;
    const { params = {}, list = [] } = record;

    const res = await otherActions.showModalConfirm({
      title: 'Удаление',
      content: 'Удалить запись?'
    });

    if (res) {
      try {
        await executorsActions.deleteExecutorAvailable(
          executor_id,
          available_id
        );
        const { offset = 0, limit = 0 } = params;
        if (offset && list.length === 1) {
          params.offset = offset - limit;
        }
        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  handleShowCopy = (row) => {
    const { history, location, basePath } = this.props;
    let from = location.pathname;
    if (location.search) from += location.search;
    history.push({
      pathname: `${basePath}/add`,
      state: { from, copy_id: row.id }
    });
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleDelete: this.handleDelete,
      handleShowCopy: this.handleShowCopy
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorsAvailableActions: bindActionCreators(actions, dispatch),
  executorsActions: bindActionCreators(executorsActions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleDelete, handleShowCopy, basePath = '/' } = props;

  const is_writable = useCan('available:write');

  return (
    <Screen title="Отсутствие">
      <List
        {...props}
        rowLink={(row) => `${basePath}/show/${row.id}`}
        toolBarInner={is_writable ? <AddBtn to={`${basePath}/add`} /> : null}
        filter={
          <Filter>
            <FilterSearchElasticExecutorField
              source="executor_id"
              label="Дезинфектор"
              alwaysOn
            />
            <FilterSearchField source="id" label="ID" />
            <FilterDateRangeField
              source="created_at"
              labels={['Создан,с', 'Создан,по']}
              label="Дата создания"
              maxWidth={300}
            />
            <FilterSelectField
              source="is_available"
              label="Доступен"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Доступен' },
                { value: 'no', label: 'Не доступен' }
              ]}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field label="Дезинфектор" source="executor">
          {({ value }) => {
            if (!value) return '';
            return <div>{value.name}</div>;
          }}
        </Field>
        <Field
          label="Доступен"
          source="is_available"
          format={(v) => (v === 'yes' ? 'Да' : 'Нет')}
        />
        <Field label="Причина" source="available_type">
          {({ value, row }) => {
            if (row.is_available === 'yes') return '';
            if (!value) return 'Не указана';
            return AVAILABLE_TYPES[value];
          }}
        </Field>
        <Field label="Дата" type="daterange" source="*" />
        <Field
          label="Дата, создания"
          source="created_at"
          type="date"
          sortable
        />
        <Field source="id" isAction>
          {({ value, row }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {is_writable ? (
                  <IconBtnCopy onClick={() => handleShowCopy(row)} />
                ) : null}
                {is_writable ? (
                  <IconBtnEdit to={`${basePath}/edit/${value}`} />
                ) : null}
                {is_writable ? (
                  <IconBtnDelete onClick={() => handleDelete(row)} />
                ) : null}
              </div>
            );
          }}
        </Field>
      </List>
    </Screen>
  );
};

const ExecutorsAvailableList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ExecutorsAvailableList;
