import { history as historyApi } from 'services/api';
import ErrorService from 'services/ErrorService';

export const actionTypes = {
  SHOW_HISTORY_DRAWER: 'RESOURCE_HISTORY_DRAWER_SHOW',
  HIDE_HISTORY_DRAWER: 'RESOURCE_HISTORY_DRAWER_HIDE',

  HISTORY_LIST_GET: 'RESOURCE_HISTORY_LIST_GET'
};

export const showHistoryDrawer = (payload) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_HISTORY_DRAWER,
    payload
  });
};

export const hideHistoryDrawer = () => ({
  type: actionTypes.HIDE_HISTORY_DRAWER
});

export const getHistoryList =
  (req = {}) =>
  async (dispatch, getState) => {
    const actionType = actionTypes.HISTORY_LIST_GET;
    const { requestType = 'refresh', itemType, itemId, ...params } = req;

    const next = {
      order: '-id',
      offset: 0,
      limit: 50,
      filter: {},
      ...params
    };

    dispatch({
      type: actionType,
      payload: {
        requestType,
        params: next,
        showLoadMore: false
      }
    });

    try {
      const request = {
        order: next.order,
        offset: next.offset,
        limit: next.limit,
        where: {
          ...next.filter,
          item_type: itemType,
          item_id: itemId
        }
      };

      let newList = null;
      const { results: list = [] } = await historyApi.getList(request);
      if (requestType === 'loadmore') {
        const oldList = getState().resourceHistory.list;
        newList = oldList ? oldList.concat(list) : list;
      } else {
        newList = list;
      }
      dispatch({
        type: actionType,
        payload: {
          list: newList,
          requestType: false,
          showLoadMore: list.length < request.limit ? false : true
        }
      });
      return Promise.resolve(list);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      dispatch({
        type: actionType,
        payload: {
          requestType: false
        }
      });
      return Promise.reject(err);
    }
  };
