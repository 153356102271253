import ApiRequest from './ApiRequest';

export default class Methods extends ApiRequest {
  getList(params) {
    return this.get(`/methods`, {
      qs: params
    });
  }
  getOne(id) {
    return this.get(`/methods/${id}`);
  }
  createOne(req) {
    return this.post(`/methods`, {
      body: req
    });
  }
  updateOne(id, req) {
    return this.put(`/methods/${id}`, {
      body: req
    });
  }
  deleteOne(id) {
    return this.delete(`/methods/${id}`);
  }
}
