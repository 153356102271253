// TODO
import ApiRequest from './ApiRequest';

export default class Geo extends ApiRequest {
  getRegions(params) {
    return this.get(`/geo/regions`, {
      qs: params
    });
  }
  getRegion(id) {
    return this.get(`/geo/regions/${id}`);
  }
  createRegion(req) {
    return this.post(`/geo/regions`, {
      body: req
    });
  }
  updateRegion(id, req) {
    return this.put(`/geo/regions/${id}`, {
      body: req
    });
  }

  getDistricts(params) {
    return this.get(`/geo/district`, {
      qs: params
    });
  }
  getDistrict(id) {
    return this.get(`/geo/district/${id}`);
  }
  createDistrict(req) {
    return this.post(`/geo/district`, {
      body: req
    });
  }
  updateDistrict(id, req) {
    return this.put(`/geo/district/${id}`, {
      body: req
    });
  }
  getLocalities(params) {
    return this.get(`/geo/locality`, {
      qs: params
    });
  }
  getLocality(id) {
    return this.get(`/geo/locality/${id}`);
  }
  createLocality(req) {
    return this.post(`/geo/locality`, {
      body: req
    });
  }
  updateLocality(id, req) {
    return this.put(`/geo/locality/${id}`, {
      body: req
    });
  }
  getStreets(params) {
    return this.get(`/geo/streets`, {
      qs: params
    });
  }
  getStreet(id) {
    return this.get(`/geo/streets/${id}`);
  }
  createStreets(req) {
    return this.post(`/geo/streets`, {
      body: req
    });
  }
  updateStreets(id, req) {
    return this.put(`/geo/streets/${id}`, {
      body: req
    });
  }
}
