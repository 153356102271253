import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { executors as resourceApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';
import { showSnackbarError, showSnackbarSuccess } from 'modules/notifier';

export const actionTypes = {
  GET_LIST: 'EXECUTORS_GET_LIST',

  GET_ONE: 'EXECUTORS_GET_ONE',
  CREATE_ONE: 'EXECUTORS_CREATE_ONE',
  UPDATE_ONE: 'EXECUTORS_UPDATE_ONE',
  DELETE_ONE: 'EXECUTORS_DELETE_ONE',

  GET_LIST_USERS: 'EXECUTORS_GET_LIST_USERS',
  UPDATE_LIST_USERS: 'EXECUTORS_UPDATE_LIST_USERS',
  GET_LIST_AVAILABLE: 'EXECUTORS_GET_LIST_AVAILABLE',
  GET_LIST_SCHEDULE: 'EXECUTORS_GET_LIST_SCHEDULE',
  GET_LIST_MILEAGE: 'EXECUTORS_GET_LIST_MILEAGE'
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

export const getList = (param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST;
  const next = {
    order: 'desc',
    orderBy: 'sort',
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param,
    filter: {
      is_active: 'yes',
      ...param.filter
    }
  };

  const request = Query.createGetRequest({
    ...next
  });

  if (next.filter.is_active === 'all') delete request.where.is_active;

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } = await resourceApi.getList(
      request
    );

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const getOne = (id) => async (dispatch, getState) => {
  const actionType = actionTypes.GET_ONE;

  const payload = { loading: true };
  const oldItem = getState().executors.one.data;
  if (oldItem && id !== oldItem.id) {
    payload.data = null;
  }
  dispatch(updateRedux(actionType, payload));

  try {
    const res = await resourceApi.getOne(id);
    dispatch(
      updateRedux(actionType, {
        data: res,
        loading: false
      })
    );
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Дезинфектор не найден' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const createOne = (data) => async (dispatch) => {
  const actionType = actionTypes.CREATE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));
  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.createOne(request);
    dispatch(
      updateRedux(actionType, {
        // data: res,
        loading: false
      })
    );
    dispatch(showSnackbarSuccess({ message: 'Дезинфектор создан' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.code === 'api/executor-exist') {
      dispatch(showSnackbarError({ message: 'Такой дезинфектор существует' }));
    } else if (err && err.code === 'api/owner-exist') {
      dispatch(showSnackbarError({ message: 'Уже используется' }));
    } else if (err && err.code === 'api/user-not-exist') {
      dispatch(showSnackbarError({ message: 'Пользователь не существует' }));
    } else if (err && err.code === 'api/place-not-exist') {
      dispatch(showSnackbarError({ message: 'Дислокация не найдена' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const updateOne = (id, data) => async (dispatch) => {
  const actionType = actionTypes.UPDATE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));

  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.updateOne(id, request);
    dispatch(
      updateRedux(actionType, {
        // data: res,
        loading: false
      })
    );
    dispatch(showSnackbarSuccess({ message: 'Запись обновлена' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.code === 'api/executor-exist') {
      dispatch(showSnackbarError({ message: 'Такой дезинфектор существует' }));
    } else if (err && err.code === 'api/owner-exist') {
      dispatch(showSnackbarError({ message: 'Уже используется' }));
    } else if (err && err.code === 'api/user-not-exist') {
      dispatch(showSnackbarError({ message: 'Пользователь не существует' }));
    } else if (err && err.code === 'api/place-not-exist') {
      dispatch(showSnackbarError({ message: 'Дислокация не найдена' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const deleteOne = (id) => async (dispatch) => {
  const actionType = actionTypes.DELETE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));

  try {
    const res = await resourceApi.deleteOne(id);
    dispatch(updateRedux(actionType, { loading: false, data: null }));
    dispatch(showSnackbarSuccess({ message: 'Дезинфектор удален' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};

export const getExecutorsUsers = (id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_USERS;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } =
      await resourceApi.getExecutorsUsers(id, request);

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const attachUserExecutor =
  (executor_id, user_id) => async (dispatch) => {
    try {
      const res = await resourceApi.attachUserExecutor(executor_id, user_id);
      dispatch(showSnackbarSuccess({ message: 'Связь добавлена' }));
      return Promise.resolve(res);
    } catch (err) {
      if (err && err.code === 'api/exist')
        dispatch(showSnackbarError({ message: 'Такая связь уже существует' }));
      else dispatch(ErrorService.dispatchError(err));
    }
  };

export const updateUserExecutor =
  (executor_id, user_id, data) => async (dispatch) => {
    const actionType = actionTypes.UPDATE_LIST_USERS;
    try {
      const res = await resourceApi.updateUserExecutor(
        executor_id,
        user_id,
        data
      );
      dispatch(
        updateRedux(actionType, {
          executor_id,
          user_id,
          data
        })
      );
      dispatch(showSnackbarSuccess({ message: 'Успешно' }));
      return Promise.resolve(res);
    } catch (err) {
      ErrorService.dispatchError(err);
    }
  };

export const detachUserExecutor =
  (executor_id, user_id) => async (dispatch) => {
    try {
      const res = await resourceApi.detachUserExecutor(executor_id, user_id);
      dispatch(showSnackbarSuccess({ message: 'Связь удалена' }));
      return Promise.resolve(res);
    } catch (err) {
      if (err && err.code === 'api/exist')
        dispatch(showSnackbarError({ message: 'Такая связь уже существует' }));
      else dispatch(ErrorService.dispatchError(err));
    }
  };

export const getExecutorsAvailable = (id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_AVAILABLE;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } =
      await resourceApi.getExecutorsAvailable(id, request);

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const createExecutorAvailable =
  (executor_id, data) => async (dispatch) => {
    try {
      const request = Query.createRequest(data);
      const res = await resourceApi.createExecutorAvailable(
        executor_id,
        request
      );
      dispatch(showSnackbarSuccess({ message: 'Добавлено' }));
      return Promise.resolve(res);
    } catch (err) {
      if (err && err.code === 'api/exist')
        dispatch(
          showSnackbarError({
            message: 'Эти даты уже входят в другой диапозон'
          })
        );
      else dispatch(ErrorService.dispatchError(err));
      return Promise.reject(err);
    }
  };

export const updateExecutorAvailable =
  (executor_id, available_id, data) => async (dispatch) => {
    try {
      const request = Query.createRequest(data);
      const res = await resourceApi.updateExecutorAvailable(
        executor_id,
        available_id,
        request
      );
      dispatch(showSnackbarSuccess({ message: 'Обновлено' }));
      return Promise.resolve(res);
    } catch (err) {
      if (err && err.code === 'api/exist')
        dispatch(
          showSnackbarError({
            message: 'Эти даты уже входят в другой диапозон'
          })
        );
      else dispatch(ErrorService.dispatchError(err));
      return Promise.reject(err);
    }
  };

export const deleteExecutorAvailable =
  (executor_id, available_id) => async (dispatch) => {
    try {
      const res = await resourceApi.deleteExecutorAvailable(
        executor_id,
        available_id
      );
      dispatch(showSnackbarSuccess({ message: 'Удалено' }));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      return Promise.reject(err);
    }
  };

export const getExecutorsSchedule = (id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_SCHEDULE;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } =
      await resourceApi.getExecutorsSchedule(id, request);

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const createExecutorSchedule =
  (executor_id, data) => async (dispatch) => {
    try {
      const request = Query.createRequest(data);
      const res = await resourceApi.createExecutorSchedule(
        executor_id,
        request
      );
      dispatch(showSnackbarSuccess({ message: 'Добавлено' }));
      return Promise.resolve(res);
    } catch (err) {
      if (err && err.code === 'api/exist')
        dispatch(showSnackbarError({ message: 'Уже есть диапозон' }));
      else dispatch(ErrorService.dispatchError(err));
      return Promise.reject(err);
    }
  };

export const updateExecutorSchedule =
  (executor_id, schedule_id, data) => async (dispatch) => {
    try {
      const request = Query.createRequest(data);
      const res = await resourceApi.updateExecutorSchedule(
        executor_id,
        schedule_id,
        request
      );
      dispatch(showSnackbarSuccess({ message: 'Обновлено' }));
      return Promise.resolve(res);
    } catch (err) {
      if (err && err.code === 'api/exist')
        dispatch(showSnackbarError({ message: 'Уже есть диапозон' }));
      else dispatch(ErrorService.dispatchError(err));
      return Promise.reject(err);
    }
  };

export const deleteExecutorSchedule =
  (executor_id, schedule_id) => async (dispatch) => {
    try {
      const res = await resourceApi.deleteExecutorSchedule(
        executor_id,
        schedule_id
      );
      dispatch(showSnackbarSuccess({ message: 'Удалено' }));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      return Promise.reject(err);
    }
  };

export const getExecutorsMileage = (id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_MILEAGE;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } = await resourceApi.getMileageList(
      id,
      request
    );

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const createExecutorMileage =
  (executor_id, data) => async (dispatch) => {
    try {
      const request = Query.createRequest(data);
      const res = await resourceApi.createMileage(executor_id, request);
      dispatch(showSnackbarSuccess({ message: 'Добавлено' }));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      return Promise.reject(err);
    }
  };

export const updateExecutorMileage =
  (executor_id, mileage_id, data) => async (dispatch) => {
    try {
      const request = Query.createRequest(data);
      const res = await resourceApi.updateMileage(
        executor_id,
        mileage_id,
        request
      );
      dispatch(showSnackbarSuccess({ message: 'Обновлено' }));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      return Promise.reject(err);
    }
  };

export const deleteExecutorMileage =
  (executor_id, mileage_id) => async (dispatch) => {
    try {
      const res = await resourceApi.deleteMileage(executor_id, mileage_id);
      dispatch(showSnackbarSuccess({ message: 'Удалено' }));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      return Promise.reject(err);
    }
  };
