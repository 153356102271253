import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { reminders as resourceApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';
import { showSnackbarError, showSnackbarSuccess } from 'modules/notifier';

import { updateUserStatus } from 'store/actions/authActions';

const REDUX_PREFIX = 'REMINDERS';

export const actionTypes = {
  GET_LIST: `${REDUX_PREFIX}_GET_LIST`,
  GET_ONE: `${REDUX_PREFIX}_GET_ONE`,
  CREATE_ONE: `${REDUX_PREFIX}_CREATE_ONE`,
  UPDATE_ONE: `${REDUX_PREFIX}_UPDATE_ONE`,
  DELETE_ONE: `${REDUX_PREFIX}_DELETE_ONE`,
  READ_REMINDER: `READ_REMINDER`
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

export const getList = (param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  if (next.filter.is_alarmed === 'all') delete request.where.is_alarmed;

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } = await resourceApi.getList(
      request
    );
    const res = {
      list,
      count,
      loading: false
    };
    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const getOne = (id) => async (dispatch, getState) => {
  const actionType = actionTypes.GET_ONE;
  const payload = { loading: true };
  const oldItem = getState().reminders.one.data;
  if (oldItem && Number(id) !== oldItem.id) {
    payload.data = null;
  }
  dispatch(updateRedux(actionType, payload));

  try {
    const res = await resourceApi.getOne(id);
    dispatch(
      updateRedux(actionType, {
        data: res,
        loading: false
      })
    );
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Не найдено' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const createOne = (data) => async (dispatch) => {
  const actionType = actionTypes.CREATE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));
  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.createOne(request);
    dispatch(
      updateRedux(actionType, {
        loading: false
      })
    );
    dispatch(showSnackbarSuccess({ message: 'Создано' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.code === 'api/exist') {
      dispatch(
        showSnackbarError({ message: 'Такое напоминание уже существует' })
      );
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const updateOne = (id, data) => async (dispatch) => {
  const actionType = actionTypes.UPDATE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));

  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.updateOne(id, request);
    dispatch(updateUserStatus());
    dispatch(
      updateRedux(actionType, {
        loading: false
      })
    );
    dispatch(showSnackbarSuccess({ message: 'Обновлено' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.code === 'api/exist') {
      dispatch(showSnackbarError({ message: 'Уже существует' }));
    } else if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Не найдено' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const deleteOne = (id) => async (dispatch) => {
  const actionType = actionTypes.DELETE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));
  try {
    const res = await resourceApi.deleteOne(id);
    dispatch(updateUserStatus());
    dispatch(updateRedux(actionType, { loading: false, data: null }));
    dispatch(showSnackbarSuccess({ message: 'Удалено' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};

export const updateRead = (id, is_alarmed) => async (dispatch) => {
  try {
    const next_is_alarmed = is_alarmed === 'yes' ? 'no' : 'yes';
    dispatch({
      type: actionTypes.READ_REMINDER,
      payload: {
        id,
        is_alarmed: next_is_alarmed
      }
    });
    await resourceApi.updateRead(id);
    dispatch(updateUserStatus());
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch({
      type: actionTypes.READ_REMINDER,
      payload: {
        id,
        is_alarmed
      }
    });
    return Promise.reject(err);
  }
};
