import React from 'react';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import { LabelField } from 'components/Field';

const GroupInfo = (props) => {
  const { record = {} } = props;
  return (
    <div>
      {record && record.id ? (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <LabelField width={100} label="Группа:" value={record.name} />
          </Grid>
          <Grid item>
            <LabelField
              width={100}
              label="Роли:"
              value={record.roles}
              format={(v) => (v ? JSON.parse(v) : [])}>
              {({ value }) => value.map((it) => <Chip key={it} label={it} />)}
            </LabelField>
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default GroupInfo;
