import React from 'react';

import { FormSearchGeoStreetsField } from 'components/Form';

const FilterSearchGeoStreetsField = (props) => {
  const { source, children, ...rest } = props;

  return (
    <FormSearchGeoStreetsField
      name={source}
      submitOnChange={true}
      forced={true}
      disableClearable={false}
      {...rest}
    />
  );
};

export default React.memo(FilterSearchGeoStreetsField);
