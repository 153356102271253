import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';

import Query from 'services/Query';
import { inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import { IconBtnDelete, IconBtnEdit } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterDateRangeField,
  FilterSearchElasticClientField,
  FilterSearchField,
  FilterSearchSelectField,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';
import Rbac, { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { remindersActions } = this.props;
      remindersActions.getList(params);
    }
  };

  handleDelete = async (id) => {
    const { remindersActions, record, dispatch } = this.props;
    const { params = {}, list = [] } = record;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить напоминание?'
      })
    );

    if (res) {
      try {
        await remindersActions.deleteOne(id);
        const { offset = 0, limit = 0 } = params;
        if (offset && list.length === 1) {
          params.offset = offset - limit;
        }

        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  updateRead = async (id, is_alarmed) => {
    const { remindersActions } = this.props;
    try {
      await remindersActions.updateRead(id, is_alarmed);
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleDelete: this.handleDelete,
      updateRead: this.updateRead
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  const admin = state.auth.admin;

  return {
    record: list,
    admin: admin
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  remindersActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const useListItemStyles = makeStyles((theme) => ({
  nonAlarmed: {
    '& *': {
      color: '#d8d8d8'
    }
  }
}));

const View = (props) => {
  const classes = useListItemStyles();
  const { handleDelete, basePath = '/', updateRead = () => {}, admin } = props;

  const isCanWrite = useCan('reminders:write');
  const isCanOwnWrite = useCan('reminders:own_write');

  return (
    <Screen title="Напоминания">
      <List
        {...props}
        rowLink={(row) => `${basePath}/show/${row.id}`}
        toolBarInner={
          <Rbac anyOf={['reminders:own_write', 'reminders:write']}>
            <AddBtn to={`${basePath}/add`} />
          </Rbac>
        }
        rowClass={(row) => {
          if (row.is_alarmed === 'no') {
            return classes.nonAlarmed;
          }
        }}
        filter={
          <Filter>
            <FilterSearchField source="id" label="ID" alwaysOn searchType="" />
            <FilterDateRangeField
              source="date_start"
              labels={['Дата,с', 'Дата,по']}
              alwaysOn
              maxWidth={275}
            />
            <FilterDateRangeField
              source="created_at"
              labels={['Создан,с', 'Создан,по']}
              label="Дата создания"
              maxWidth={300}
            />
            <FilterSearchElasticClientField
              source="client_id"
              alwaysOn
              label="Клиент"
            />
            <FilterSelectField
              source="is_alarmed"
              label="Активен"
              alwaysOn
              options={[
                { value: 'all', label: 'Все' },
                { value: 'yes', label: 'Активные' },
                { value: 'no', label: 'Не активные' }
              ]}
            />
            <FilterSearchSelectField
              source="user_id"
              alwaysOn
              label="Оператор"
              requestPath="/users"
              responseFormat={(v) => v.results}
              getOptionLabel={(it) =>
                it ? [it.first_name, it.last_name].join(' ') : ''
              }
              searchBy={['first_name', 'last_name']}
              getValue={(o) => o.id}
            />
          </Filter>
        }>
        <Field
          source="id,user_id,is_alarmed"
          className="checkbox"
          format={(item) => {
            if (isCanWrite || (isCanOwnWrite && item.user_id === admin.id)) {
              return (
                <ListItemIcon onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    checked={item.is_alarmed === 'yes'}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': item.id }}
                    onChange={() => updateRead(item.id, item.is_alarmed)}
                  />
                </ListItemIcon>
              );
            } else {
              return <div>{item.user_id}</div>;
            }
          }}></Field>
        <Field
          label="Клиент"
          source="client"
          format={(client) => {
            if (!client) return '';
            return client.name;
          }}
        />
        <Field
          label="Дата"
          type="date"
          source="date_start"
          sortable="date_start"
        />
        <Field
          label="Сообщение"
          source="message"
          format={(message) => {
            if (!message) return '';
            return `${message.substr(0, 150)}`;
          }}
        />
        <Field source="id" isAction>
          {({ value, row }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                <Rbac
                  {...(row.user_id === admin.id
                    ? { operation: 'reminders:own_write' }
                    : { operation: 'reminders:write' })}>
                  <IconBtnEdit to={`${basePath}/edit/${value}`} />
                </Rbac>
                <Rbac
                  {...(row.user_id === admin.id
                    ? { operation: 'reminders:own_write' }
                    : { operation: 'reminders:write' })}>
                  <IconBtnDelete onClick={() => handleDelete(value)} />
                </Rbac>
              </div>
            );
          }}
        </Field>
      </List>
    </Screen>
  );
};

const RemindersList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default RemindersList;
