import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { MATERIAL_UNIT } from 'constants/config';

import Query from 'services/Query';
import { inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import { IconBtnDelete, IconBtnEdit } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, { FilterSearchField } from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    }
    const { actions } = this.props;
    actions.getList(params);
  };

  handleDelete = async (id) => {
    const { actions, record, dispatch } = this.props;
    const { params = {}, list = [] } = record;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить запись?'
      })
    );

    if (res) {
      try {
        await actions.deleteOne(id);
        const { offset = 0, limit = 0 } = params;
        if (offset && list.length === 1) {
          params.offset = offset - limit;
        }
        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  goToShow = (row) => {
    const { history = {}, basePath = '/' } = this.props;
    history.push({
      pathname: `${basePath}/edit/${row.id}`,
      state: { from: basePath }
    });
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleDelete, basePath } = props;

  const is_writable = useCan('materials:write');

  return (
    <Screen title="Материалы">
      <List
        {...props}
        rowLink={(row) => is_writable && `${basePath}/edit/${row.id}`}
        toolBarInner={is_writable && <AddBtn to={`${basePath}/add`} />}
        filter={
          <Filter>
            <FilterSearchField source="name" label="Название" alwaysOn />
            <FilterSearchField source="id" label="ID" />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field label="Название" source="name" sortable />
        <Field label="Цена" source="price" type="price" sortable />
        <Field
          label="Ед. измерения"
          source="unit"
          format={(type) => {
            if (MATERIAL_UNIT[type]) {
              return `${MATERIAL_UNIT[type].label} (${MATERIAL_UNIT[type].short})`;
            }
            return '';
          }}
        />
        <Field source="id" isAction>
          {({ value }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {is_writable && (
                  <IconBtnEdit to={`${basePath}/edit/${value}`} />
                )}
                {is_writable && (
                  <IconBtnDelete onClick={() => handleDelete(value)} />
                )}
              </div>
            );
          }}
        </Field>
      </List>
    </Screen>
  );
};

const MaterialsList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default MaterialsList;
