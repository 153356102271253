import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Grid from '@material-ui/core/Grid';

import { inChildren, validateErrors } from 'services/utils';

import { ReferenceField } from 'components/Field';
import { FormSelectField, FormTextField, SimpleForm } from 'components/Form';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};
  const { name } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  return errors;
};

const formName = 'update_group';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      groupsActions,
      history = {}
    } = this.props;
    try {
      await groupsActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const { groupsActions, record = {}, loading, history } = this.props;
    if (loading) return;
    try {
      await groupsActions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };

  handleDelete = async () => {
    const { groupsActions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить группу?'
      })
    );

    if (res) {
      try {
        await groupsActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  groupsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleUpdate, handleDelete, record = {}, loading } = props;

  const itemReceived = record && record.id;
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Редактировать группу: ${record.name}`;
  }
  return (
    <Screen title={title}>
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        onBack={back}
        onDelete={handleDelete}
        validate={validate}
        initialValues={{
          ...record,
          roles: record && record.roles ? JSON.parse(record.roles) : []
        }}
        parse={(values) => {
          const roles = values.roles ? values.roles : [];
          return {
            name: values.name || '',
            roles: JSON.stringify(roles)
          };
        }}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="name"
            label="Название группы"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ReferenceField path="/roles" format={(res) => res.results}>
            {({ result }) => {
              return (
                <FormSelectField
                  label="Роли"
                  disabled={loading}
                  source="roles"
                  multiple={true}
                  options={
                    result && result.length
                      ? result.map((it) => ({ label: it, value: it }))
                      : []
                  }
                />
              );
            }}
          </ReferenceField>
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const GroupsEdit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default GroupsEdit;
