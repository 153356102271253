import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { bindActionCreators } from 'redux';

import Checkbox from '@material-ui/core/Checkbox';
import MaterialDivider from '@material-ui/core/Divider';
import MaterialList from '@material-ui/core/List';
import MaterialListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MaterialListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';

import Query from 'services/Query';
import { inChildren } from 'services/utils';

// import { Link } from 'react-router-dom';
import Pagination from 'components/Pagination';
import Progress from 'components/Progress';
import Screen from 'components/Screen';

import * as actions from './actions';
// import { makeUrlProjectId } from 'services/projectUtils';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { actions, admin } = this.props;
      if (!admin) return;
      actions.getList(admin.id, params);
    }
  };

  updateRead = (notif) => {
    const { actions, admin } = this.props;
    actions.updateRead(admin.id, notif);
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      updateRead: this.updateRead
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    admin: state.auth.admin,
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const useListItemStyles = makeStyles((theme) => ({
  listItemRoot: {
    paddingTop: 0,
    paddingBottom: 0
  },
  listItemRootRead: {
    color: '#d8d8d8'
  },
  listItemIconRoot: {
    minWidth: 24,
    marginRight: theme.spacing(1)
  },
  listItemIconMessage: {
    color: '#6d6d6d'
  },
  listItemIconMessageRead: {
    color: '#d8d8d8'
  }
}));

const CommontTitle = (props) => {
  const { title = '', projectName = '' } = props;
  return (
    <div>
      {!!projectName && <b>{projectName}: </b>}
      <span>{title}</span>
    </div>
  );
};

const ListItem = (props) => {
  const classes = useListItemStyles();
  const { id, title, message, is_read, onChange, payload = {} } = props;
  const isRead = is_read === 'yes';
  let to = '#';
  let project_name = '';
  if (payload) {
    const data = JSON.parse(payload);
    if (data.url) to = data.url;
    if (data.project_name) project_name = data.project_name;
  }

  return (
    <MaterialListItem
      button
      dense={false}
      component="a"
      href={to}
      target="_blank"
      alignItems="flex-start"
      classes={{
        root: clsx(classes.listItemRoot, isRead && classes.listItemRootRead)
      }}>
      <ListItemIcon onClick={(e) => e.stopPropagation()}>
        <Checkbox
          edge="start"
          checked={is_read === 'no'}
          tabIndex={-1}
          disableRipple
          inputProps={{ 'aria-labelledby': id }}
          onChange={onChange}
        />
      </ListItemIcon>
      <MaterialListItemText
        disableTypography={true}
        primary={<CommontTitle title={title} projectName={project_name} />}
        secondary={
          <div
            className={clsx(
              classes.listItemIconMessage,
              is_read === 'yes' && classes.listItemIconMessageRead
            )}>
            {message}
          </div>
        }
      />
    </MaterialListItem>
  );
};

const View = (props) => {
  const { getList, updateRead, record = {} } = props;

  const { params = {}, count = 0, list = [], loading } = record;

  // const getPageIndex = () => {
  //   const { limit = 0, offset = 0 } = params;
  //   if (!limit || !offset) return 0;
  //   return offset / limit;
  // }

  const handleChangePage = (event, newPage) => {
    if (typeof getList === 'function') {
      const { limit = 0 } = params;
      const nextParams = {
        ...params,
        offset: newPage * limit
      };
      getList(nextParams, {
        eventType: 'pagination'
      });
    }
  };

  return (
    <Screen title="Уведомления">
      {loading && <Progress variant="over" />}
      <Pagination
        count={count}
        offset={params.offset}
        limit={params.limit}
        onPageChange={handleChangePage}
      />
      <MaterialList dense={false}>
        {list.map((notif) => {
          return (
            <React.Fragment key={notif.id}>
              <ListItem {...notif} onChange={() => updateRead(notif)} />
              <MaterialDivider />
            </React.Fragment>
          );
        })}
      </MaterialList>
      <Pagination
        count={count}
        offset={params.offset}
        limit={params.limit}
        onPageChange={handleChangePage}
      />
    </Screen>
  );
};

const NotificationsList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default NotificationsList;
