import React from 'react';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { validateErrors } from 'services/utils';

import { IconBtnClose } from 'components/buttons/IconBtn';
import Form, { FormDateField, FormTextField } from 'components/Form';
import Progress from 'components/Progress';

const validate = (values) => {
  const errors = {};
  const { mileage_from, mileage_to, mileage_date } = values;

  errors.mileage_date = validateErrors(mileage_date, {
    require: 'Обязательное поле'
  });

  errors.mileage_from = validateErrors(mileage_from, {
    require: 'Обязательное поле',
    isPositiveInteger: 'Только целые числа'
  });

  if (mileage_to) {
    errors.mileage_to = validateErrors(mileage_to, {
      isPositiveInteger: 'Только целые числа'
    });
  }

  if (
    mileage_from &&
    mileage_to &&
    !errors.mileage_from &&
    !errors.mileage_to &&
    Number(mileage_from) >= Number(mileage_to)
  ) {
    errors.mileage_to = 'Должна быть больше пробега, с';
  }
  return errors;
};

const MileageDialog = (props) => {
  const {
    title = '',
    isOpen,
    handleClose = () => null,
    successText = 'Сохранить',
    cancelText = 'Закрыть',
    handleSubmit = () => null,
    initialValues = null
  } = props;

  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (params) => {
    if (loading) return;
    setLoading(true);
    await handleSubmit(params);
    if (isOpen) setLoading(false);
  };

  return (
    <Dialog
      open={isOpen}
      scroll={'paper'}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth={true}
      maxWidth="sm"
      // onClose={() => handleClose(false)}
    >
      <Form
        name="mileage-executor"
        onSubmit={onSubmit}
        initialValues={{
          ...initialValues,
          mileage_date:
            initialValues && initialValues.mileage_date
              ? initialValues.mileage_date
              : moment().toISOString()
        }}
        validate={validate}
        parse={(values) => {
          return {
            mileage_date: values.mileage_date
              ? moment(values.mileage_date).format('YYYY-MM-DD')
              : null,
            mileage_from: values.mileage_from
              ? Number(values.mileage_from)
              : null,
            mileage_to: values.mileage_to ? Number(values.mileage_to) : null
          };
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <DialogTitle>{title}</DialogTitle>
          <IconBtnClose onClick={handleClose} />
        </div>
        <DialogContent dividers={true}>
          {loading && <Progress variant="over" />}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormDateField
                label="Дата, с"
                source="mileage_date"
                dateType="date"
                disable={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    label="Пробег, с"
                    source="mileage_from"
                    type="number"
                    pattern="[0-9]*"
                    step="1"
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    label="Пробег, по"
                    source="mileage_to"
                    type="number"
                    pattern="[0-9]*"
                    step="1"
                    disabled={loading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{cancelText}</Button>
          <Button type="submit" color="primary" variant="contained">
            {successText}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default MileageDialog;
