import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

import { inChildren } from 'services/utils';
// import DialogTitle from '@material-ui/core/DialogTitle';
import { makeAction } from 'modules/redux-modals';

import * as actions from 'resources/Users/actions';
import { IconBtnAdd } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterSearchField,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';

class Container extends Component {
  componentDidMount() {
    this.getList();
  }

  getList = (params) => {
    const { usersActions } = this.props;
    usersActions.getList(params);
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state) => {
  const { users = {} } = state;
  const { list = {} } = users;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  usersActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const useStyles = makeStyles((theme) => ({
  modalDividers: {
    padding: 0
  },
  modalContentRoot: {
    padding: theme.spacing(1),
    '&:first-child': {
      paddingTop: theme.spacing(1)
    }
  }
}));

const AddUsersList = (props) => {
  const classes = useStyles();
  const {
    // title = "",
    isOpen,
    handleClose = () => null,
    cancelText = 'Закрыть',
    onSelect = () => null
  } = props;
  return (
    <Dialog
      open={isOpen}
      scroll={'paper'}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth={true}
      maxWidth="lg"
      // onClose={() => handleClose(false)}
    >
      {/* <DialogTitle>{title}</DialogTitle> */}
      <DialogContent
        dividers={true}
        classes={{
          root: classes.modalContentRoot,
          dividers: classes.modalDividers
        }}>
        <ContainerConnect>
          <List
            {...props}
            // Toolbar={false}
            filter={
              <Filter>
                <FilterSearchField source="login" label="Логин" />
                <FilterSearchField source="email" label="E-mail" />
                <FilterSearchField source="first_name" label="Имя" />
                <FilterSearchField source="last_name" label="Фамилия" />
                <FilterSearchField source="phone" label="Тел." />
                <FilterSelectField
                  source="is_active"
                  alwaysOn
                  label="Активен"
                  options={[
                    { value: '', label: 'Все' },
                    { value: 'yes', label: 'Активен' },
                    { value: 'no', label: 'Не активен' }
                  ]}
                />
              </Filter>
            }>
            <Field label="ID" source="id" sortable />
            <Field label="Логин" source="login" sortable />
            <Field label="E-mail" source="email" sortable />

            <Field label="Имя" source="first_name" sortable />
            <Field label="Фамилия" source="last_name" sortable />
            <Field label="Тел." source="phone" type="phone" />

            <Field
              label="Активен"
              source="is_active"
              format={(v) => v === 'yes'}
              type="boolean"
            />
            <Field label="Дата" source="created_at" type="date" sortable />

            <Field source="id" isAction>
              {({ value }) => {
                return (
                  <React.Fragment>
                    <IconBtnAdd onClick={() => onSelect(value)} />
                  </React.Fragment>
                );
              }}
            </Field>
          </List>
        </ContainerConnect>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => handleClose(false)}
          color="primary"
          variant="contained">
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const show = makeAction(AddUsersList);
export default AddUsersList;
