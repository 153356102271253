import React from 'react';
import { Link } from 'react-router-dom';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';

import { DATE_FORMAT, MATERIAL_UNIT } from 'constants/config';

import { makeUrlProjectId } from 'services/projectUtils';
import { convertAmountToUnits } from 'services/utils';

import { LabelField } from 'components/Field';
import Progress from 'components/Progress';

const labelWidth = 150;

const ShowInfo = (props) => {
  const { record = {} } = props;
  return (
    <div>
      {record && record.id ? (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <LabelField
              width={labelWidth}
              label="Дислокация:"
              value={record.place}
              format={(place) => {
                if (!place) return '';
                return place.name;
              }}
            />
          </Grid>
          <Grid item>
            <LabelField
              width={labelWidth}
              label="Материал:"
              value={record.material}
              format={(material) => {
                if (!material) return '';
                return material.name;
              }}
            />
          </Grid>
          <Grid item>
            <LabelField
              width={labelWidth}
              label="Тип:"
              value={record.direction}
              format={(value) => {
                if (value === 'INCOMING') {
                  return <Chip label="Приход" />;
                }
                if (value === 'OUTGOING') {
                  return <Chip label="Расход" />;
                }
                if (value === 'MOVE') {
                  return <Chip label="Перемещение" />;
                }
                return '';
              }}
            />
          </Grid>
          {record?.direction === 'MOVE' && record?.dst_place_id ? (
            <Grid item xs={12}>
              <LabelField
                label="Дислокация назначения:"
                type="reference"
                path="/executor_places"
                value={record.dst_place_id}
                width={labelWidth}>
                {({ loading, result }) => {
                  if (loading)
                    return (
                      <div>
                        <Progress size={18} />
                      </div>
                    );
                  if (!result) return '';
                  return result.name;
                }}
              </LabelField>
            </Grid>
          ) : null}

          <Grid item>
            <LabelField
              width={labelWidth}
              label="Кол-во:"
              value={record}
              format={(v) => {
                const { material = {}, value } = v;
                if (!material) {
                  return '';
                }
                const { unit } = material;
                if (!MATERIAL_UNIT[unit]) {
                  return 'Error';
                }
                return `${convertAmountToUnits(value, unit)} ${
                  MATERIAL_UNIT[unit].short
                }`;
              }}
            />
          </Grid>
          <Grid item>
            <LabelField
              width={labelWidth}
              label="Дата:"
              value={record.date}
              type="date"
              dateFormat={DATE_FORMAT}
            />
          </Grid>
          <Grid item>
            <LabelField
              width={labelWidth}
              label="Цена:"
              value={record.price}
              type="price"
            />
          </Grid>
          <Grid item>
            <LabelField
              width={labelWidth}
              label="Описание:"
              value={record.description}
              type="longtext"
            />
          </Grid>
          <Grid item>
            {record.order_id ? (
              <LabelField width={labelWidth} label="Заявка:">
                {() => {
                  if (!record.order_id) return '';
                  return (
                    <Link
                      to={makeUrlProjectId(
                        `/orders/show/${record.order_id}`
                      )}>{`Заявка: #${record.order_id}`}</Link>
                  );
                }}
              </LabelField>
            ) : null}
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default ShowInfo;
