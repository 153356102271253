import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Query from 'services/Query';
import { concatGeoFullName, inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import { IconBtnDelete, IconBtnEdit } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterDateRangeField,
  FilterSearchElasticExecutorField,
  FilterSearchGeoLocalityField,
  FilterSearchSelectField,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { executorsActions } = this.props;
      executorsActions.getList(params);
    }
  };

  handleDelete = async (id) => {
    const { executorsActions, record, dispatch } = this.props;
    const { params = {}, list = [] } = record;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить дезинфектора?'
      })
    );

    if (res) {
      try {
        await executorsActions.deleteOne(id);
        const { offset = 0, limit = 0 } = params;
        if (offset && list.length === 1) {
          params.offset = offset - limit;
        }
        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleDelete, basePath = '/' } = props;

  const is_writable = useCan('executors:write');

  return (
    <Screen title="Дезинфекторы">
      <List
        {...props}
        rowLink={(row) => `${basePath}/show/${row.id}`}
        toolBarInner={is_writable && <AddBtn to={`${basePath}/add`} />}
        filter={
          <Filter
            parse={(values) => {
              const res = { created_at: values.created_at };
              if (values.id) res.id = values.id;
              if (values.locality_id) res.locality_id = values.locality_id;
              if (values.place_id) res.place_id = values.place_id.id;
              if (values.is_active) res.is_active = values.is_active;
              return res;
            }}>
            <FilterSearchElasticExecutorField
              source="id"
              label="Дезинфектор"
              alwaysOn
            />
            <FilterSearchSelectField
              alwaysOn
              source="place_id"
              label="Дислокация"
              requestPath="/executor_places"
              responseFormat={(v) => v.results}
              searchBy="name"
            />
            <FilterSelectField
              source="is_active"
              alwaysOn
              label="Активен"
              options={[
                { value: 'all', label: 'Все' },
                { value: 'yes', label: 'Активен' },
                { value: 'no', label: 'Не активен' }
              ]}
            />
            <FilterSearchGeoLocalityField
              maxWidth={400}
              source="locality_id"
              label="Адрес"
            />
            <FilterDateRangeField
              source="created_at"
              labels={['Создан,с', 'Создан,по']}
              label="Дата создания"
              maxWidth={300}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field label="Имя" source="name" sortable />
        <Field label="E-mail" source="email" />
        <Field label="Тел." source="phone" type="phone" />
        <Field label="Дислокация" source="place">
          {({ value }) => {
            if (!value) return '';
            return <div>{value.name}</div>;
          }}
        </Field>
        <Field label="Населенный пункт" source="locality">
          {({ value }) => {
            if (!value) return '';
            return <div title={concatGeoFullName(value)}>{value.name}</div>;
          }}
        </Field>
        <Field
          label="Автопробег"
          source="total_mileage"
          type="number"
          sortable
        />
        <Field label="Дата создания" source="created_at" type="date" sortable />
        <Field
          label="Сортировка"
          source="sort"
          type="number"
          format={(v) => v || 0}
          sortable
        />
        <Field source="id" isAction>
          {({ value }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {is_writable && (
                  <IconBtnEdit to={`${basePath}/edit/${value}`} />
                )}
                {is_writable && (
                  <IconBtnDelete onClick={() => handleDelete(value)} />
                )}
              </div>
            );
          }}
        </Field>
      </List>
    </Screen>
  );
};

const ExecutorsList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ExecutorsList;
