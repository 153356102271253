import React from 'react';

import { FormSearchElasticClientField } from 'components/Form';

const FilterSearchElasticClientField = (props) => {
  const { source, children, ...rest } = props;

  return (
    <FormSearchElasticClientField
      name={source}
      submitOnChange={true}
      forced={true}
      disableClearable={false}
      {...rest}
    />
  );
};

export default React.memo(FilterSearchElasticClientField);
