import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { inChildren, validateErrors } from 'services/utils';

import {
  FormField,
  FormSearchGeoLocalityField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import { utils as mapUtils } from 'components/maps';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import PlacesMapField from './components/PlacesMapField';
import * as actions from './actions';

const validate = (values) => {
  const errors = {};

  const { name, locality, geopoint } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  errors.locality = validateErrors(locality, {
    require: 'Обязательное поле'
  });

  if (!geopoint) {
    errors.geopoint = 'Укажите точку на карте';
  }

  return errors;
};

const formName = 'edit_executor_places';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      geopoints: []
    };
  }

  componentDidMount() {
    this.handleGetOne();
  }

  componentDidUpdate(prev) {
    const { record } = this.props;
    if (record !== prev.record && record) {
      this.setState({
        locality: record.locality,
        zoom: mapUtils.getZoomWithEntries({
          geopoint: record.geopoint,
          locality: record.locality
        })
      });
    }
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      executorPlacesActions,
      history = {}
    } = this.props;
    try {
      await executorPlacesActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const { executorPlacesActions, record = {}, loading, history } = this.props;
    if (loading) return;
    try {
      await executorPlacesActions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };

  handleDelete = async () => {
    const {
      executorPlacesActions,
      record = {},
      dispatch,
      history = {}
    } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить дислокацию?'
      })
    );

    if (res) {
      try {
        await executorPlacesActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  handleChangeLocality = (locality) => {
    this.handleChangeGeopoint({
      locality
    });
  };

  handleChangeGeopoint = async ({ locality }) => {
    const { dispatch } = this.props;
    if (this.geopointTimer) {
      clearTimeout(this.geopointTimer);
    }
    this.geopointTimer = setTimeout(async () => {
      const geopoints = await mapUtils.getMapDataByEntries({ locality });
      const firstFinded = geopoints && geopoints.length ? geopoints[0] : null;
      const geopoint = mapUtils.getGeopointFromMapData(firstFinded);
      dispatch(
        change(formName, 'geopoint', geopoint || mapUtils.makeNullGeopoint())
      );
      this.setState({
        locality,
        geopoints: geopoints || [],
        zoom: mapUtils.getZoomWithEntries({
          geopoint: geopoint,
          locality: locality
        })
      });
    }, 500);
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      state: this.state,
      mapRef: this.mapRef,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete,
      handleChangeLocality: this.handleChangeLocality
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorPlacesActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleUpdate,
    handleDelete,
    handleChangeLocality,
    state,
    record = {},
    loading
  } = props;

  const itemReceived = record && record.id;
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Редактировать дислокацию: ${record.name}`;
  }

  return (
    <Screen title={title}>
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        onBack={back}
        onDelete={handleDelete}
        direction="row"
        validate={validate}
        initialValues={record}
        parse={(values) => {
          const res = {
            ...values,
            name: values.name || ''
          };
          if (values.locality) {
            res.locality_id = values.locality.id;
            delete res.locality;
          }
          return res;
        }}>
        <Grid item sm={12}>
          <FormTextField source="name" label="Название" disabled={loading} />
        </Grid>
        <Grid item xs={12}>
          <FormSearchGeoLocalityField
            source="locality"
            label="Населенный пункт"
            disabled={loading}
            onChange={handleChangeLocality}
          />
        </Grid>
        <Grid item xs={12}>
          {itemReceived ? (
            <FormField
              source="geopoint"
              locality={state.locality}
              zoom={state.zoom}
              options={state.geopoints}
              component={PlacesMapField}
            />
          ) : null}
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const ExecutorsPlacesEdit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ExecutorsPlacesEdit;
