import { v4 as uuid } from 'uuid';

import { REMOVE_MODAL, SHOW_MODAL } from './actions';

const initialState = {
  modals: []
};

const reduxModalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL: {
      const { component, props, resolve } = action;

      if (!component) return state;

      const modals = state.modals.concat({
        uid: uuid(),
        component,
        props,
        resolve
      });

      if (modals.length) document.body.classList.add('overflow-modal');

      return {
        ...state,
        modals
      };
    }

    case REMOVE_MODAL: {
      if (!state.modals.length) return state;
      const { uid } = action;

      const modals = state.modals.filter((it) => it.uid !== uid);
      if (!modals.length) document.body.classList.remove('overflow-modal');

      return {
        ...state,
        modals
      };
    }

    default:
      return state;
  }
};

export default reduxModalsReducer;
