import React from 'react';
import { connect, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

import { setTitle } from 'store/actions/uiActions';

const useStyles = makeStyles((theme) => ({
  container: {
    flexGrow: 1,
    padding: theme.spacing(1)
  },
  paper: {
    position: 'relative',
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const Screen = ({ children, title = '', ...props }) => {
  const classes = useStyles(props);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setTitle(title));
  }, [title, dispatch]);

  return (
    <Grid container direction="column" className={classes.container}>
      <Paper className={classes.paper}>{children}</Paper>
    </Grid>
  );
};

export default connect(null, {
  setTitle
})(Screen);
