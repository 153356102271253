import { actionTypes } from './actions';

const initialState = {
  list: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  one: {
    loading: false,
    data: null
  }
};

const GeoRegionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST: {
      const { payload = {} } = action;
      const { list = {} } = state;
      return {
        ...state,
        list: {
          ...list,
          ...payload
        }
      };
    }
    case actionTypes.GET_ONE:
    case actionTypes.CREATE_ONE:
    case actionTypes.UPDATE_ONE: {
      const { one = {} } = state;
      const { payload = {} } = action;
      return {
        ...state,
        one: {
          ...one,
          ...payload
        }
      };
    }
    default:
      return state;
  }
};

export default GeoRegionsReducer;
