import React from 'react';

import TablePagination from '@material-ui/core/TablePagination';

const Pagination = (props) => {
  const { limit = 0, offset = 0, count = 0, onPageChange } = props;

  const getPageIndex = () => {
    if (!limit || !offset) return 0;
    return offset / limit;
  };

  return (
    <TablePagination
      rowsPerPageOptions={[]}
      component="div"
      count={Number(count) || 0}
      rowsPerPage={limit ? Number(limit) : 0}
      page={count ? getPageIndex() : 0}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to === -1 ? count : to} из ${count}`
      }
      backIconButtonProps={{
        'aria-label': 'previous page'
      }}
      nextIconButtonProps={{
        'aria-label': 'next page'
      }}
      onPageChange={onPageChange}
    />
  );
};

export default Pagination;
