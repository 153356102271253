import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { reset } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { PHONE_FORMAT } from 'constants/config';

import { inChildren, validateErrors } from 'services/utils';

import {
  FormCheckboxField,
  FormColorPickerField,
  FormPasswordField,
  FormPhoneField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import Progress from 'components/Progress';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import Tabs, { Tab } from 'components/Tabs';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};
  const { login, first_name, last_name, email, phone } = values;

  errors.login = validateErrors(login, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  errors.first_name = validateErrors(first_name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  errors.last_name = validateErrors(last_name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  if (email) {
    errors.email = validateErrors(email, {
      email: 'Не валидный e-mail'
    });
  }

  if (phone) {
    errors.phone = validateErrors(phone, {
      phone: PHONE_FORMAT.placeholder
    });
  }

  return errors;
};

const validatePassword = (values) => {
  const errors = {};
  const { password, confirm_password } = values;

  errors.password = validateErrors(password, {
    require: 'Обязательное поле',
    'minLength=4': 'Не менее 4 символов'
  });

  errors.confirm_password = validateErrors(confirm_password, {
    require: 'Обязательное поле',
    'minLength=4': 'Не менее 4 символов'
  });

  if (password && confirm_password && password !== confirm_password) {
    errors.password = 'Пароли не совпадают';
    errors.confirm_password = 'Пароли не совпадают';
  }

  return errors;
};

const formName = 'update_user';
const formNamePass = 'update_user_password';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      userActions,
      history = {}
    } = this.props;
    try {
      await userActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const { userActions, record = {}, loading, history } = this.props;
    if (loading) return;
    try {
      await userActions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };

  handleUpdatePassword = async (data) => {
    const { userActions, record = {}, loading, dispatch } = this.props;
    if (loading) return;
    try {
      await userActions.updateOnePassword(record.id, data);
      dispatch(reset(formNamePass));
    } catch (err) {
      console.error(err);
    }
  };

  handleDelete = async () => {
    const { userActions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Вы уверены что хотите удалить пользователя?'
      })
    );

    if (res) {
      try {
        await userActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete,
      handleUpdatePassword: this.handleUpdatePassword
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  userActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleUpdate,
    handleDelete,
    handleUpdatePassword,
    record = {},
    loading
  } = props;

  const isCanDeleteUser = useCan('users:delete');
  const [tab, setTab] = React.useState('info');

  const itemReceived = record && record.id;
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Редактировать пользователя: ${record.first_name} ${record.last_name}`;
  }
  return (
    <Screen title={title}>
      {loading && !itemReceived && <Progress />}
      <Tabs value={tab} onChange={(key) => setTab(key)}>
        <Tab label="Редактировать" eventKey="info">
          {itemReceived && (
            <SimpleForm
              loading={loading}
              form={formName}
              onSubmit={handleUpdate}
              disabled={loading}
              onBack={back}
              onDelete={isCanDeleteUser && handleDelete}
              direction="row"
              validate={validate}
              initialValues={record}
              parse={(values) => {
                return {
                  login: values.login || '',
                  first_name: values.first_name || '',
                  last_name: values.last_name || '',
                  email: values.email || '',
                  phone: values.phone || '',
                  is_active: values.is_active || 'no',
                  color: values.color
                };
              }}>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  source="login"
                  label="Логин"
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormColorPickerField
                  source="color"
                  label="Цвет"
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  source="first_name"
                  label="Имя"
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  source="last_name"
                  label="Фамилия"
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormTextField
                  source="email"
                  label="E-mail"
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormPhoneField
                  source="phone"
                  label="Тел."
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={12}>
                <FormCheckboxField
                  source="is_active"
                  label="Активен"
                  disabled={loading}
                  parse={(v) => (v ? 'yes' : 'no')}
                  format={(v) => v === 'yes'}
                />
              </Grid>
            </SimpleForm>
          )}
        </Tab>
        <Tab label="Пароль" eventKey="password">
          <SimpleForm
            loading={loading}
            form={formNamePass}
            onSubmit={handleUpdatePassword}
            disabled={loading}
            onBack={back}
            onDelete={isCanDeleteUser && handleDelete}
            direction="row"
            validate={validatePassword}
            parse={(values) => {
              return {
                password: values.password || ''
              };
            }}>
            <Grid item xs={12} sm={6}>
              <FormPasswordField
                source="password"
                label="Пароль"
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormPasswordField
                source="confirm_password"
                label="Повторите пароль"
                disabled={loading}
              />
            </Grid>
          </SimpleForm>
        </Tab>
      </Tabs>
    </Screen>
  );
};

const UsersEdit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default UsersEdit;
