import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import {
  FormCheckboxField,
  FormDateField,
  FormSearchSelectField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};

  const { client_id, date } = values;

  if (!client_id) {
    errors.client_id = 'Обязательное поле';
  }
  if (!date) {
    errors.date = 'Обязательное поле';
  }

  return errors;
};
const formName = 'EDIT_REMINDERS';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      actions,
      history = {}
    } = this.props;
    try {
      await actions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const { actions, record = {}, loading, history } = this.props;
    if (loading) return;
    try {
      await actions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };

  handleDelete = async () => {
    const { actions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить напоминание?'
      })
    );

    if (res) {
      try {
        await actions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleUpdate, handleDelete, record = {}, loading } = props;

  const itemReceived = record && record.id;
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Редактировать напоминание: ${record.id}`;
  }

  const isChangeableUser = useCan('reminders:write');

  return (
    <Screen title={title}>
      <SimpleForm
        direction="row"
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        onBack={back}
        onDelete={handleDelete}
        validate={validate}
        initialValues={record}>
        <Grid item xs={12} sm={6}>
          <FormSearchSelectField
            source="user_id"
            label="Пользователь"
            requestPath="/users"
            responseFormat={(v) => v.results}
            getOptionLabel={(it) =>
              it ? [it.first_name, it.last_name].join(' ') : ''
            }
            disabled={isChangeableUser ? loading : true}
            searchBy={['first_name', 'last_name']}
            getValue={(o) => o.id}
            disableClearable={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormSearchSelectField
            source="client_id"
            label="Клиент"
            requestPath="/clients"
            responseFormat={(v) => v.results}
            getOptionLabel={(it) => (it ? [it.name, it.phone].join(' ') : '')}
            disabled={loading}
            searchBy={['name', 'phone']}
            getValue={(o) => o.id}
            disableClearable={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormDateField
            label="Дата"
            source="date_start"
            dateType="datetime"
            disabled={loading}
          />
        </Grid>

        <Grid item container alignItems="center" xs={12} sm={6}>
          <FormCheckboxField
            source="is_alarmed"
            label="Активен"
            parse={(v) => (v ? 'yes' : 'no')}
            format={(v) => v === 'yes'}
          />
        </Grid>

        <Grid item xs={12}>
          <FormTextField
            source="message"
            label="Описание"
            disabled={loading}
            multiline={true}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const Edit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default Edit;
