import Add from './Add';
import Edit from './Edit';
import List from './List';
import reducer from './reducer';

const Reasons = {
  List,
  Add,
  Edit,
  reducer
};

export default Reasons;
