import React from 'react';
import Routes from 'Routes';

import { makeStyles } from '@material-ui/core/styles';

import { Drawer as HistoryDrawer } from 'resources/ResourceHistory';
import Push from 'components/Push';

import AppBar from './AppBar';
import AppDrawer from './AppDrawer';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexGrow: 1
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar
    },
    container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }
  };
});

const Dashboard = ({ children }) => {
  const classes = useStyles();
  const isMobile = window.innerWidth < 960;
  const [drawerOpen, setDrawerOpen] = React.useState(!isMobile);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className={classes.root}>
      <Push />
      <AppBar drawerOpen={drawerOpen} handleDrawerOpen={handleDrawerOpen} />
      <AppDrawer
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        classes={{ toolbar: classes.toolbar }}
      />
      <HistoryDrawer />
      <main className={classes.container}>
        <div className={classes.toolbar} />
        <Routes />
        {children}
      </main>
    </div>
  );
};

export default React.memo(Dashboard);
