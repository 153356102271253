import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { users as resourceApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';

import { updateUserAdmin } from 'store/actions/authActions';

export const actionTypes = {
  GET_LIST: 'NOTIFICATIONS_GET_LIST',
  READ_NOTIF: 'NOTIFICATIONS_LIST_READ'
};

export const getList = (user_id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch({
    type: actionType,
    payload: {
      loading: true,
      params: next,
      list: []
    }
  });

  try {
    const { results: list = [], count = 0 } =
      await resourceApi.getUserNotifications(user_id, request);

    const res = {
      list,
      count,
      loading: false
    };

    dispatch({
      type: actionType,
      payload: res
    });
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch({
      type: actionType,
      payload: {
        loading: false
      }
    });
    return Promise.reject(err);
  }
};

export const updateRead = (user_id, notif) => async (dispatch, getState) => {
  const { id, is_read } = notif;
  try {
    const { id } = notif;
    const next_is_read = is_read === 'yes' ? 'no' : 'yes';
    dispatch({
      type: actionTypes.READ_NOTIF,
      payload: {
        id,
        is_read: next_is_read
      }
    });
    await resourceApi.updateUserNotification(user_id, id, {
      is_read: next_is_read
    });
    const admin = getState().auth.admin;
    if (!admin) return;
    let { notifications_count } = admin;
    if (next_is_read === 'yes') {
      notifications_count--;
    } else {
      notifications_count++;
    }
    if (notifications_count < 0) return;
    dispatch(updateUserAdmin({ notifications_count }));
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch({
      type: actionTypes.READ_NOTIF,
      payload: {
        id,
        is_read
      }
    });
    return Promise.reject(err);
  }
};
