import ApiRequest from './ApiRequest';

export default class Stats extends ApiRequest {
  getStats() {
    return this.get(`/stats`);
  }
  getStatsBookkeeping() {
    return this.get(`/stats/bookkeeping`);
  }
}
