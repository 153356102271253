import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';

import { inChildren } from 'services/utils';

import NotFoundItems from 'components/NotFoundItems';
import Progress from 'components/Progress';
import Screen from 'components/Screen';

import * as actions from './actions';
import ShowOrderInfo from './ShowOrderInfo';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false
    };
  }

  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const { match: { params: { id } = {} } = {}, customersOrdersActions } =
      this.props;
    try {
      await customersOrdersActions.getOne(id);
    } catch (err) {
      this.setState({ notFound: true });
    }
  };

  componentWillUnmount() {
    const { customersOrdersActions } = this.props;
    customersOrdersActions.clearOne();
  }

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      notFound: this.state.notFound
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    record: one.data,
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  customersOrdersActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { record = {}, loading, notFound } = props;

  const itemReceived = !!(record && record.id);
  let title = '';
  if (itemReceived) {
    title = `Заявка №: ${record.id}`;
  }
  return (
    <Screen title={title}>
      {loading && <Progress />}
      {notFound && <NotFoundItems message="Заявка не найдена" />}
      {itemReceived && (
        <Box p={2}>
          <ShowOrderInfo {...props} />
        </Box>
      )}
    </Screen>
  );
};

const CustomersOrdersShow = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default CustomersOrdersShow;
