export const PAGINATION_TABLE_LIMIT = 25;
export const DATE_FORMAT = 'DD.MM.YYYY';
export const TIME_FORMAT = 'HH:mm';
export const PHONE_FORMAT = {
  format: '+XX XXX XXX XX XX',
  placeholder: '+38 0XX XXX XX XX'
};

export const AVAILABLE_TYPES = {
  day_off: 'Выходной',
  holiday: 'Праздник',
  sanitary_day: 'Санитарный день',
  vacation: 'Отпуск',
  relaxation: 'Отдых'
};

export const MATERIAL_UNIT = {
  piece: {
    label: 'кол-во',
    short: 'шт',
    rate: 1
  },
  ton: {
    label: 'тонна',
    short: 'т',
    rate: 0.001
  },
  kilogram: {
    label: 'килограмм',
    short: 'кг',
    rate: 1
  },
  gram: {
    label: 'грамм',
    short: 'г',
    rate: 1000
  },
  milligram: {
    label: 'милиграмм',
    short: 'мг',
    rate: 1000000
  },
  liter: {
    label: 'литр',
    short: 'л',
    rate: 1
  },
  milliliter: {
    label: 'милилитр',
    short: 'мл',
    rate: 1000
  },
  kilometer: {
    label: 'километр',
    short: 'км',
    rate: 0.001
  },
  meter: {
    label: 'метр',
    short: 'м',
    rate: 1
  },
  centimeter: {
    label: 'сантиметр',
    short: 'см',
    rate: 100
  },
  millimeter: {
    label: 'милиметр',
    short: 'мм',
    rate: 1000
  }
};
