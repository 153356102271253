import React from 'react';
import BN from 'bignumber.js';

import { MATERIAL_UNIT, PHONE_FORMAT } from 'constants/config';

import * as Validate from './validation';

export const inChildren = (children, props = {}) => {
  if (!children) return null;
  if (typeof children === 'function') return children(props);
  return React.cloneElement(children, { ...props });
};

export const classOf = (obj) => {
  if (obj === null) return 'null';
  if (obj === undefined) return 'undefined';
  if (typeof obj === 'function') return 'function';
  return Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();
};

export const deepCopyObj = (obj) => {
  if (typeof obj != 'object') {
    return obj;
  }

  if (obj === null) return obj;

  const copy = obj.constructor();
  for (let key in obj) {
    if (typeof obj[key] == 'object') {
      copy[key] = deepCopyObj(obj[key]);
    } else {
      copy[key] = obj[key];
    }
  }
  return copy;
};

export const deepMerge = (target, source) => {
  const res = deepCopyObj(target);
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object)
      Object.assign(source[key], deepMerge(res[key], source[key]));
  }
  Object.assign(res || {}, source);
  return res;
};

export const validateErrors = (val, conf) => {
  if (!conf || (!conf.require && !val)) return '';

  let checkVal = val;
  if (typeof checkVal === 'string') checkVal = checkVal.trim();

  for (let key in conf) {
    const res = Validate.validateByType(checkVal, key);
    if (!res) return conf[key] || 'error validation';
  }
  return '';
};

export const numberFormat = (value, separator = '') => {
  if (!value) return value;
  if (!Validate.isNumeric(value)) return NaN;

  let income = String(value);
  let result = '';
  let start = income.length - 1;
  let symbol_count = 0;

  const index_fraction = income.indexOf('.');
  if (index_fraction === 0) income = '0' + income;
  if (index_fraction > 0) {
    result = income.slice(index_fraction);
    start = index_fraction - 1;
  }
  for (let i = start; i >= 0; i--) {
    symbol_count++;
    const next = income[i - 1];
    result = income[i] + result;
    if (symbol_count >= 3 && income[i] && next && Validate.isNumeric(next)) {
      symbol_count = 0;
      result = separator + result;
    }
  }
  return result;
};

export function toBills(number, { withoutZero = false, separator } = {}) {
  if (!number) return number;
  if (!Validate.isInteger(number)) return 0;
  let res = (number / 100).toFixed(2);
  if (withoutZero) res = res.replace('.00', '');
  if (separator) res = numberFormat(res, separator);
  return res;
}

export function toCoins(number) {
  if (!number) return number;
  if (!Validate.isNumeric(number)) return 0;
  return Math.round(number * 100);
}

export function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export function concatGeoFullName(it, indexes) {
  let res = '';
  if (!it) return res;
  const { full_name } = it;
  if (!full_name) return res;
  try {
    const input = JSON.parse(full_name);
    res = joinArrayBySeparate(input, ', ', indexes);
  } catch (err) {
    res = '';
  }
  return res || '';
}

export function arrayParseJsonAndToString(array, indexes) {
  let res = '';

  if (!array) return res;

  let input = array;
  if (typeof input === 'string') {
    try {
      input = JSON.parse(input);
      // eslint-disable-next-line no-console
    } catch (e) {
      console.error(e);
    }
  }
  if (!Array.isArray(input)) return res;
  try {
    res = joinArrayBySeparate(input, ', ', indexes);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    res = '';
  }
  return res;
}

export function joinArrayBySeparate(arr, separator = ', ', indexes) {
  if (!arr || !Array.isArray(arr)) return '';
  if (indexes && indexes.length) {
    const tmpArray = [];
    indexes.forEach((ind) => {
      if (arr[ind]) {
        tmpArray.push(arr[ind]);
      }
    });
    return tmpArray.join(separator);
  }
  return arr.filter((it) => it).join(separator);
  // return arr.reduce((acc, it, inx) => {
  //   if (!it) return acc;
  //   if (indexes && indexes.indexOf(inx) === -1) return acc;
  //   return acc ? acc + separator + it : it;
  // }, '');
}

export function getContrastYIQ(hexcolor) {
  hexcolor = hexcolor.replace('#', '');
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}

export function phoneFormat(number) {
  if (!number) return '';
  number = String(number);
  const format = PHONE_FORMAT.format;
  if (number === '+') return number;
  const arr = number.match(/\d/g);
  if (arr === null) return '';

  let index = 0;
  let length = 0;

  number = format
    .replace(/X/g, function (num, strIndex) {
      const targetSubStr = arr[index];
      index++;
      if (targetSubStr) {
        length = strIndex + 1;
        return targetSubStr;
      }
      return '';
    })
    .substring(0, length);
  return number;
}

export function phoneParse(number) {
  if (!number) return '';
  const arr = number.match(/\d/g);
  if (number === '+') return number;
  if (arr === null) return '';
  const phone = arr.join('').substring(0, 12);
  return '+' + phone;
}

export const copyToClipboard = (str) => {
  try {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();

    const status = document.execCommand('copy');
    document.body.removeChild(el);
    return status;
  } catch (err) {
    return false;
  }
};

export const percentToNumber = (percent) => {
  if (!percent) {
    return 0;
  }
  return BN(percent).div(100, 10).toNumber();
};

export const numberToPercent = (number) => {
  return BN(number).times(100, 10).toNumber();
};

export const getPriceWithoutVat = (price, vat) => {
  if (!price) {
    return 0;
  }
  if (!vat) {
    return price;
  }
  return BN(price).div(BN(1).plus(vat)).toFixed(2);
};

export const convertAmountToUnits = (amount, unitType) => {
  if (!unitType || !MATERIAL_UNIT[unitType]) {
    return NaN;
  }
  const rate = MATERIAL_UNIT[unitType].rate;
  return BN(amount).times(rate).toString(10);
};

export const convertUnitsToAmount = (units, unitType) => {
  if (!unitType || !MATERIAL_UNIT[unitType]) {
    return NaN;
  }
  const rate = MATERIAL_UNIT[unitType].rate;
  return BN(units).div(rate).toString(10);
};

export const throttle = (
  f,
  { ms = 100, runLast = true, resetTime = false } = {}
) => {
  let isThrottled = false;
  let savedArgs = null;
  let timer = null;

  const stop = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  const run = () => {
    timer = setTimeout(function () {
      isThrottled = false;
      if (savedArgs && runLast) {
        f(...savedArgs);
        savedArgs = null;
      }
    }, ms);
  };

  return (...args) => {
    if (isThrottled) {
      savedArgs = args;
      if (resetTime) {
        stop();
        run();
      }
      return;
    }
    f(...args);
    isThrottled = true;
    run();
  };
};

export const userCanSeeTurnover = (user) => {
  return user && user.roles.includes('superadmin');
};
