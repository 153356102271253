import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Grid from '@material-ui/core/Grid';

import {
  inChildren,
  numberToPercent,
  percentToNumber,
  validateErrors
} from 'services/utils';

import { FormCheckboxField, FormTextField, SimpleForm } from 'components/Form';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const validate = ({ name = '', vat = '' }) => {
  const errors = {};
  errors.name = validateErrors(name, {
    require: 'Обязательное поле'
  });
  const _v = String(vat).trim();
  const vatError = validateErrors(_v, {
    require: 'Обязательное поле',
    isNumeric: 'Должно быть числом'
  });
  if (vatError) {
    errors.vat = vatError;
  } else if (_v < 0 || _v > 100) {
    errors.vat = 'Допускается от 0 до 100';
  }
  return errors;
};

const formName = 'edit_pay_type';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      payTypesActions,
      history = {}
    } = this.props;
    try {
      await payTypesActions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const { payTypesActions, record = {}, loading, history } = this.props;
    if (loading) return;
    try {
      await payTypesActions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };

  handleDelete = async () => {
    const { payTypesActions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить запись?'
      })
    );

    if (res) {
      try {
        await payTypesActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  payTypesActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleUpdate, handleDelete, record = {}, loading } = props;

  const itemReceived = record && record.id;
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Редактировать способ оплаты: ${record.name}`;
  }
  return (
    <Screen title={title}>
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        onBack={back}
        onDelete={handleDelete}
        validate={validate}
        direction="row"
        initialValues={{
          ...record,
          vat: numberToPercent((record && record.vat) || '0')
        }}
        parse={(v) => ({
          name: v.name || '',
          assign_creator: v.assign_creator,
          is_bookkeeping: v.is_bookkeeping,
          vat: percentToNumber(v.vat)
        })}>
        <Grid item xs={12} sm={6}>
          <FormTextField source="name" label="Название" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="vat"
            label="НДС, %"
            disabled={loading}
            type="number"
            inputProps={{
              step: '1'
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCheckboxField
            source="assign_creator"
            label="Оператор"
            disabled={loading}
            parse={(v) => (v ? 'yes' : 'no')}
            format={(v) => v === 'yes'}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCheckboxField
            source="is_bookkeeping"
            label="Для бухгалтера"
            disabled={loading}
            parse={(v) => (v ? 'yes' : 'no')}
            format={(v) => v === 'yes'}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const PayTypesEdit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default PayTypesEdit;
