import React from 'react';

import { FormSelectField } from 'components/Form';

const FilterSelectField = (props) => {
  const { source, children, searchType = '', searchPath = '', ...rest } = props;

  const additional = {};

  if (searchType || searchPath) {
    additional.format = (obj = {}) => {
      return obj.q || '';
    };

    additional.parse = (v) => {
      return {
        q: v,
        qt: searchType,
        qp: searchPath
      };
    };
  }

  return (
    <FormSelectField
      name={source}
      submitOnChange={true}
      {...rest}
      {...additional}
    />
  );
};

export default React.memo(FilterSelectField);
