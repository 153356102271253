import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { bindActionCreators } from 'redux';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { removeSnackbar } from './actions';

class Notifier extends Component {
  displayed = [];

  storeDisplayed = (id) => {
    this.displayed = [...this.displayed, id];
  };

  removeDisplayed = (id) => {
    this.displayed = this.displayed.filter((key) => id !== key);
  };

  createAction = (key) => {
    const { closeSnackbar } = this.props;
    return (
      <IconButton
        key="close"
        aria-label="close"
        color="inherit"
        onClick={() => closeSnackbar(key)}>
        <CloseIcon />
      </IconButton>
    );
  };

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          this.props.closeSnackbar(key);
          return;
        }
        // Do nothing if snackbar is already displayed
        if (this.displayed.includes(key)) return;
        // Display snackbar using notistack
        this.props.enqueueSnackbar(message, {
          key,
          action: this.createAction,
          autoHideDuration: 3000,
          ...options,
          onClose: (event, reason, key) => {
            if (options.onClose) {
              options.onClose(event, reason, key);
            }
          },
          onExited: (event, key) => {
            this.props.removeSnackbar(key);
            this.removeDisplayed(key);
          }
        });
        // Keep track of snackbars that we've displayed
        this.storeDisplayed(key);
      }
    );
  }

  render() {
    return null;
  }
}

const mapStateToProps = (store) => ({
  notifications: store.notifications.notifications
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ removeSnackbar }, dispatch);

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(Notifier)
);
