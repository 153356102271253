import { maps as mapsApi } from 'services/api';

import * as CONSTANTS from './constants';

const replacer = (str) => {
  const res = str.replace(
    /^(м\.|смт\.|с\.|сщ\.|срада\.|сщрада\.|\sр-н$|^р\.|вул\.|пр\.)/,
    ''
  );
  return res;
};

const replacerRegion = (str) => {
  const res = str.replace(/(обл\.)$/, '');
  return res;
};

export async function getMapDataByEntries({ locality, street, address_house }) {
  if (!locality) {
    return null;
  }
  try {
    let q = '';
    const nameArr = JSON.parse(locality.full_name);
    q = replacerRegion(nameArr[0]) + ' ' + replacer(nameArr[2]);
    if (street) {
      q = replacer(street.name) + ' ' + q;
      if (address_house) {
        q = `${address_house} ${q}`;
      }
    }
    const response = await mapsApi.search({ q });
    if (!response.body.length && street && address_house) {
      return await getMapDataByEntries({ locality, street });
    } else if (!response.body.length && street) {
      return await getMapDataByEntries({ locality });
    }
    return response && response.body;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
}

export const getGeopointFromMapData = (it) => {
  return it
    ? {
        type: 'Point',
        coordinates: [Number(it.lon), Number(it.lat)]
      }
    : null;
};

export const getCoordsFromGeopoint = (point) => {
  return { lat: point.coordinates[1], lon: point.coordinates[0] };
};

export const makeNullGeopoint = () => {
  return {
    type: 'Point',
    coordinates: [0, 0]
  };
};

export const isValidGeopoint = (it) => {
  if (
    it &&
    it.coordinates &&
    it.coordinates[0] !== 0 &&
    it.coordinates[1] !== 0
  ) {
    return true;
  }
  return false;
};

export const isEqualsGeopoints = (v1, v2) => {
  if (!v1 || !v2) {
    return false;
  }
  if (
    v1.coordinates[0] === v2.coordinates[0] &&
    v1.coordinates[1] === v2.coordinates[1]
  ) {
    return true;
  }
  return false;
};

export const getZoomWithEntries = ({
  geopoint,
  locality,
  street,
  address_house
}) => {
  let zoom = CONSTANTS.DEFAULT_ZOOM;
  if (locality && geopoint && isValidGeopoint(geopoint)) {
    zoom = CONSTANTS.CITY_ZOOM;
    if (street) {
      zoom = CONSTANTS.STREET_ZOOM;
      if (address_house) {
        zoom = CONSTANTS.HOUSE_ZOOM;
      }
    }
  }
  return zoom;
};
