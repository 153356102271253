import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import { BackBtn, EditBtn } from 'components/buttons/Btn';
import { LabelField } from 'components/Field';
import FooterBar from 'components/FooterBar';
import NotFoundItems from 'components/NotFoundItems';
import Progress from 'components/Progress';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';

import * as actions from './actions';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false
    };
  }
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const { match: { params: { id } = {} } = {}, geoActions } = this.props;
    try {
      await geoActions.getOne(id);
    } catch (err) {
      this.setState({ notFound: true });
      // history.goBack();
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      notFound: this.state.notFound
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    record: one.data,
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  geoActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { record = {}, loading, basePath = '/', notFound } = props;

  const history = useHistory();
  const back = history.goBack;

  const itemReceived = !!(record && record.id);
  let title = 'Улицу:';
  if (itemReceived) {
    title = `Улицу: ${record.name}`;
  }

  const isCanWrite = useCan('geo:write');

  return (
    <Screen title={title}>
      {loading && <Progress />}
      {notFound && <NotFoundItems message="Улица не найден" />}
      <StreetInfo record={record} />
      <FooterBar>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <BackBtn onClick={back}>Назад</BackBtn>
            </Grid>
            <Grid item>
              {itemReceived && isCanWrite ? (
                <EditBtn
                  to={{
                    pathname: `${basePath}/edit/${record.id}`,
                    state: { from: `${basePath}/show/${record.id}` }
                  }}>
                  Редактировать
                </EditBtn>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </FooterBar>
    </Screen>
  );
};

const Show = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default Show;

const StreetInfo = (props) => {
  const { record = {} } = props;
  const labelWidth = 150;
  return (
    <Box p={2}>
      {record && record.id ? (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Название:"
              value={record.name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField width={labelWidth} label="Вес:" value={record.weight} />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              label="Регион:"
              type="reference"
              path="/geo/regions"
              value={record.region_id}
              width={labelWidth}>
              {({ loading, result }) => {
                if (loading)
                  return (
                    <div>
                      <Progress size={18} />
                    </div>
                  );
                if (!result) return '';
                return result.name;
              }}
            </LabelField>
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              label="Район:"
              type="reference"
              path="/geo/district"
              value={record.district_id}
              width={labelWidth}>
              {({ loading, result }) => {
                if (loading) {
                  return (
                    <div>
                      <Progress size={18} />
                    </div>
                  );
                }
                if (!result) return '';
                return result.name;
              }}
            </LabelField>
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              label="Нас. Пункт:"
              type="reference"
              path="/geo/locality"
              value={record.locality_id}
              width={labelWidth}>
              {({ loading, result }) => {
                if (loading) {
                  return (
                    <div>
                      <Progress size={18} />
                    </div>
                  );
                }
                if (!result) return '';
                return result.name;
              }}
            </LabelField>
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Район нас. пункта:"
              value={record.locality_district}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Альтернативное название:"
              value={record.alt_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Старое название:"
              value={record.old_name}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Язык №1:"
              value={record.name_lang1}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Язык №2:"
              value={record.name_lang2}
            />
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};
