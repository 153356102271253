import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { reducer as notifierReducer } from 'modules/notifier';
import { reducer as reduxModals } from 'modules/redux-modals';

import app from './appReducer';
import auth from './authReducer';
import stats from './statsReducer';
import ui from './uiReducer';

const createReducer = (asyncReducers) =>
  combineReducers({
    ...asyncReducers,
    notifications: notifierReducer,
    form: formReducer,
    reduxModals,
    auth,
    ui,
    stats,
    app
  });

export default createReducer;
