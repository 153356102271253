import ApiRequest from './ApiRequest';

export default class Users extends ApiRequest {
  getList(params) {
    return this.get(`/users`, {
      qs: params
    });
  }
  getOne(id) {
    return this.get(`/users/${id}`);
  }
  createOne(req) {
    return this.post(`/users`, {
      body: req
    });
  }
  updateOne(id, req) {
    return this.put(`/users/${id}`, {
      body: req
    });
  }
  updateOnePassword(id, { password } = {}) {
    return this.post(`/users/${id}/password`, {
      body: { password }
    });
  }
  deleteOne(id) {
    return this.delete(`/users/${id}`);
  }
  getUserGroups(id, req) {
    return this.get(`/users/${id}/groups`, {
      qs: req
    });
  }
  getUserSessions(id, req) {
    return this.get(`/users/${id}/sessions`, {
      qs: req
    });
  }
  getUserNotifications(id, req) {
    return this.get(`/users/${id}/notifications`, {
      qs: req
    });
  }
  updateUserNotification(user_id, notif_id, params) {
    return this.put(`/users/${user_id}/notifications/${notif_id}`, {
      body: params
    });
  }
}
