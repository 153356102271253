import React from 'react';
import { Field } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import { PHONE_FORMAT } from 'constants/config';

import { phoneFormat, phoneParse } from 'services/utils';

const FormPhoneField = (props) => {
  const { source, ...rest } = props;

  return (
    <Field
      component={TextField}
      name={source}
      type="tel"
      format={phoneFormat}
      parse={phoneParse}
      {...rest}
    />
  );
};

export default FormPhoneField;

const TextField = ({ input = {}, meta = {}, ...attributes }) => {
  const { helperText = '', label = '', ...restAttrs } = attributes;
  const { touched, error } = meta;
  const visibleError = touched && error;

  const onBlur = (event) => {
    input.onBlur(event.currentTarget.value);
  };

  const onChange = (event) => {
    let value = event.currentTarget.value;
    if (value && value !== '+' && !/^\+3/.test(value)) {
      value = '+38' + value;
    }
    input.onChange(value);
  };

  return (
    <FormControl error={!!visibleError} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Input
        {...input}
        onBlur={onBlur}
        onChange={onChange}
        autoComplete="off"
        error={!!visibleError}
        maxLength="1024"
        {...restAttrs}
        inputProps={{
          type: 'tel',
          // pattern: "\\+380\\d{9}",
          placeholder: PHONE_FORMAT.placeholder
        }}
      />
      {(visibleError || helperText) && (
        <FormHelperText>{visibleError || helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
