import React from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/config';

import { inChildren, numberFormat, toBills } from 'services/utils';

import CopyBufferBtn from 'components/buttons/CopyBufferBtn';

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main
  },
  error: {
    color: theme.palette.error.main
  },
  nowrap: {
    whiteSpace: 'nowrap'
  },
  root: {
    whiteSpace: (props) => {
      switch (props.type) {
        case 'date':
          return 'nowrap';
        case 'phone':
          return 'nowrap';
        case 'longtext':
          return 'pre-line';
        default:
          return 'normal';
      }
    }
  }
}));

const Field = (props) => {
  const classes = useStyles(props);
  const {
    className = '',
    value = '',
    type = '',
    children,
    format,
    dateFormat = `${DATE_FORMAT}, ${TIME_FORMAT}`,
    numberSeparate = ',',
    component: Component = 'div',
    onClick = () => null,
    style = {},
    ...rest
  } = props;

  let result = value;
  if (typeof format === 'function') result = format(value);
  if (type === 'date') result = value ? moment(value).format(dateFormat) : null;
  if (type === 'number') result = numberFormat(result, numberSeparate);
  if (type === 'price') result = toBills(result, { separator: numberSeparate });
  if (type === 'daterange') {
    if (!value) result = '';
    const { date_from, date_to } = value;
    if (date_from && date_to) {
      const isSame = moment(date_from).isSame(date_to, 'day');
      if (isSame) {
        const d = moment(date_from).format(DATE_FORMAT);
        const t_from = moment(date_from).format(TIME_FORMAT);
        const t_to = moment(date_to).format(TIME_FORMAT);
        result = (
          <>
            <div style={{ whiteSpace: 'nowrap' }}>
              {d} (<b className={classes.nowrap}>{`${t_from} - ${t_to}`}</b>)
            </div>
          </>
        );
      } else {
        const d_from = moment(date_from).format(DATE_FORMAT);
        const t_from = moment(date_from).format(TIME_FORMAT);
        const d_to = moment(date_to).format(DATE_FORMAT);
        const t_to = moment(date_to).format(TIME_FORMAT);
        result = (
          <>
            <div style={{ whiteSpace: 'nowrap' }}>
              {d_from} (<b className={classes.nowrap}>{t_from}</b>)
            </div>
            <div style={{ whiteSpace: 'nowrap' }}>
              {d_to} (<b className={classes.nowrap}>{t_to}</b>)
            </div>
          </>
        );
      }
    } else if (date_from) {
      result = moment(date_from).format(DATE_FORMAT);
    } else if (date_to) {
      result = moment(date_to).format(DATE_FORMAT);
    } else {
      result = '';
    }
  }

  if (children) {
    result = inChildren(children, {
      value: result,
      sourceValue: value,
      ...rest
    });
  }

  if (type === 'boolean') {
    result = result ? 'Да' : 'Нет';
  }

  if (type === 'phone') {
    return result ? (
      <span style={{ whiteSpace: 'nowrap' }}>
        <a href={`tel:${result}`} onClick={onClick} style={style}>
          {result}
        </a>
        <CopyBufferBtn value={result} />
      </span>
    ) : (
      ''
    );
  }

  if (Component) {
    return (
      <Component
        className={`${classes.root} ${className}`}
        onClick={onClick}
        style={style}>
        {result}
      </Component>
    );
  }
  return result;
};

export default React.memo(Field);
