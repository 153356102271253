import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';

import QueryService from 'services/Query';
import { inChildren } from 'services/utils';

import Btn from 'components/buttons/Btn';
import Filter, {
  FilterDateRangeField,
  FilterProvider
} from 'components/Filter';
import Form, { FormSearchElasticClientField } from 'components/Form';
import Rbac from 'components/Rbac';
import Screen from 'components/Screen';

import * as actions from './actions';

const API_URL = process.env.REACT_APP_API_URL;

const validate = (values) => {
  const errors = {};
  const { date_from, created_at } = values;

  const notValid = [];
  if (!(date_from && date_from.q && date_from.q.$gte && date_from.q.$lte))
    notValid.push('date_from');
  if (!(created_at && created_at.q && created_at.q.$gte && created_at.q.$lte))
    notValid.push('created_at');
  if (notValid.length > 1) {
    notValid.forEach((key) => (errors[key] = 'Укажите дату'));
  }
  if (date_from && date_from.q) {
    if (
      (date_from.q.$gte && !date_from.q.$lte) ||
      (!date_from.q.$gte && date_from.q.$lte)
    )
      errors.date_from = 'Укажите дату';
  }
  if (created_at && created_at.q) {
    if (
      (created_at.q.$gte && !created_at.q.$lte) ||
      (!created_at.q.$gte && created_at.q.$lte)
    )
      errors.created_at = 'Укажите дату';
  }

  return errors;
};

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date_from: {
        q: {
          $gte: moment().startOf('month').toISOString(),
          $lte: moment().endOf('month').toISOString()
        }
      },
      isValid: true
    };
  }

  onFilter = (values) => {
    const { dispatch } = this.props;
    const errors = validate(values);
    let isValid = Object.keys(errors).length ? false : true;
    dispatch(stopSubmit('reports', errors));
    if (isValid) {
      this.setState({
        date_from: values.date_from,
        created_at: values.created_at,
        isValid
      });
    } else {
      this.setState({
        isValid
      });
    }
  };

  createReportLink = async (report_path) => {
    const { actionsReports } = this.props;
    const { date_from, created_at } = this.state;

    try {
      const res = await actionsReports.getReportToken();

      const filter = {
        date_from,
        created_at,
        token: res.token
      };

      const link = document.createElement('a');
      // link.target = '_blank';
      link.style.position = 'absolute';
      link.style.zIndex = '-1';
      link.style.left = '-9999px';
      link.style.opacity = 0;
      link.download = true;

      const queryString = QueryService.createGetLink(filter);
      link.href = `${API_URL}${report_path}?${queryString}`;
      // link.href=`${API_URL}${report_path}?date_from=${date_from && date_from.q}&created_at=${created_at && created_at.q}&token=${res.token}`;
      // link.href=`${API_URL}${report_path}?date_from=${date_from}&date_to=${date_to}&token=${res.token}&project_id=${project_id}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      date_from: this.state.date_from,
      created_at: this.state.created_at,
      isValid: this.state.isValid,
      onFilter: this.onFilter,
      createReportLink: this.createReportLink
    });
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.auth.admin
  };
};

const ConnectedContainer = connect(mapStateToProps, (dispatch) => {
  return {
    dispatch,
    actionsReports: bindActionCreators(actions, dispatch)
  };
})(Container);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
  filter: {
    marginBottom: theme.spacing(1)
  },
  fieldset: {
    border: '1px solid #eee',
    marginBottom: 8
  }
}));

const View = (props) => {
  const classes = useStyles();

  const { onFilter, createReportLink, date_from, created_at, isValid, admin } =
    props;

  const [client, setClient] = React.useState(null);

  return (
    <Screen
      title="Отчеты"
      classes={{
        paper: classes.root
      }}>
      <FilterProvider formName="reports">
        <Filter
          justifyContent="center"
          className={classes.filter}
          onFilter={onFilter}
          initialValues={{
            date_from,
            created_at
          }}>
          <FilterDateRangeField
            source="date_from"
            labels={['Заявка,с', 'Заявка,по']}
            alwaysOn
            maxWidth={275}
          />
          <FilterDateRangeField
            source="created_at"
            labels={['Создан,с', 'Создан,по']}
            alwaysOn
            label="Дата создания"
            maxWidth={300}
          />
        </Filter>
      </FilterProvider>
      <Rbac operation="reports:read">
        <fieldset className={classes.fieldset}>
          <legend>Общие отчеты</legend>
          <Grid container wrap="wrap" spacing={1} justifyContent="center">
            <Grid item>
              <SelectBtn
                onClick={() => createReportLink('/reports/places')}
                download
                disabled={!isValid}>
                Города
              </SelectBtn>
            </Grid>
            <Grid item>
              <SelectBtn
                onClick={() => createReportLink('/reports/executors')}
                download
                disabled={!isValid}>
                Дезинфекторы
              </SelectBtn>
            </Grid>
            <Grid item>
              <SelectBtn
                onClick={() => createReportLink('/reports/executors_day')}
                download
                disabled={!isValid}>
                Дезинфекторы (по дням)
              </SelectBtn>
            </Grid>
            <Grid item>
              <SelectBtn
                onClick={() => createReportLink('/reports/users')}
                download
                disabled={!isValid}>
                Операторы
              </SelectBtn>
            </Grid>
            <Grid item>
              <SelectBtn
                onClick={() => createReportLink('/reports/orders')}
                download
                disabled={!isValid}>
                Заявки
              </SelectBtn>
            </Grid>
          </Grid>
        </fieldset>
      </Rbac>
      <fieldset className={classes.fieldset}>
        <legend>По оператору</legend>
        <Grid container wrap="wrap" spacing={1} justifyContent="center">
          <Grid item>
            <SelectBtn
              onClick={() => createReportLink(`/reports/users/${admin.id}`)}
              download
              disabled={!isValid}>
              оператор
            </SelectBtn>
          </Grid>
          <Grid item>
            <SelectBtn
              onClick={() => createReportLink(`/reports/users_day/${admin.id}`)}
              download
              disabled={!isValid}>
              оператор (по дням)
            </SelectBtn>
          </Grid>
        </Grid>
      </fieldset>
      <fieldset className={classes.fieldset}>
        <legend>По клиентам</legend>
        <Grid container wrap="wrap" spacing={1} justifyContent="center">
          <Grid item>
            <SelectBtn
              onClick={() => createReportLink(`/reports/clients`)}
              download
              disabled={!isValid}>
              все клиенты
            </SelectBtn>
          </Grid>
          <Grid item>
            <SelectBtn
              onClick={() =>
                createReportLink(`/reports/clients_by_user/${admin.id}`)
              }
              download
              disabled={!isValid}>
              все клиенты (по оператору)
            </SelectBtn>
          </Grid>
        </Grid>
      </fieldset>
      <fieldset className={classes.fieldset}>
        <legend>По одному клиенту</legend>
        <Grid
          container
          wrap="wrap"
          spacing={1}
          justifyContent="center"
          alignItems="center">
          <Grid item xs={12} style={{ maxWidth: 300 }}>
            <Form name="client_report">
              <FormSearchElasticClientField
                source="client"
                label="Клиент"
                disableClearable={true}
                onChange={(client) => setClient(client)}
              />
            </Form>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <SelectBtn
                  onClick={() =>
                    createReportLink(`/reports/clients/${client.id}`)
                  }
                  download
                  disabled={!isValid || !client}>
                  клиент
                </SelectBtn>
              </Grid>
              <Grid item>
                <SelectBtn
                  onClick={() =>
                    createReportLink(`/reports/clients_day/${client.id}`)
                  }
                  download
                  disabled={!isValid || !client}>
                  клиент (по дням)
                </SelectBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </fieldset>
    </Screen>
  );
};

const SelectBtn = (props) => {
  const { children, ...rest } = props;
  return (
    <Btn
      ariaLabel="add"
      color="primary"
      size="small"
      startIcon={<GetAppIcon fontSize="inherit" />}
      {...rest}>
      {children}
    </Btn>
  );
};

const Reports = (props) => (
  <ConnectedContainer {...props}>
    <View />
  </ConnectedContainer>
);

export default Reports;
