import React from 'react';

import { phoneFormat } from 'services/utils';

import FormSearchElasticField from './FormSearchElasticField';

function renderOptionDefault(props) {
  const { name, phone, street = {} } = props;

  const address = (street && street.full_name_text) || '';

  return (
    <div>
      <div>{name}</div>
      {phone && <div style={{ fontSize: '0.75em' }}>{phoneFormat(phone)}</div>}
      <div style={{ fontSize: '0.75em' }}>{address}</div>
    </div>
  );
}

const FormSearchElasticClientField = (props) => {
  return (
    <FormSearchElasticField
      requestPath="/clients"
      getOptionLabel={(opt) =>
        opt ? [opt.name, phoneFormat(opt.phone)].join(', ') : ''
      }
      renderOption={renderOptionDefault}
      {...props}
    />
  );
};

export default FormSearchElasticClientField;
