import React from 'react';

import { FormTextField } from 'components/Form';

const FilterTextField = (props) => {
  const { source, ...rest } = props;

  return <FormTextField name={source} {...rest} />;
};

export default React.memo(FilterTextField);
