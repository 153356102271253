import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { groups as resourceApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';
import { showSnackbarError, showSnackbarSuccess } from 'modules/notifier';

export const actionTypes = {
  GET_LIST: 'GROUPS_GET_LIST',

  GET_ONE: 'GROUPS_GET_ONE',
  CREATE_ONE: 'GROUPS_CREATE_ONE',
  UPDATE_ONE: 'GROUPS_UPDATE_ONE',
  DELETE_ONE: 'GROUPS_DELETE_ONE',

  GET_LIST_USERS: 'GROUPS_GET_LIST_USERS'
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

export const getList = (param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } = await resourceApi.getList(
      request
    );

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const getOne = (id) => async (dispatch, getState) => {
  const actionType = actionTypes.GET_ONE;

  const payload = { loading: true };
  const oldItem = getState().groups.one.data;
  if (oldItem && Number(id) !== oldItem.id) {
    payload.data = null;
  }
  dispatch(updateRedux(actionType, payload));

  try {
    const res = await resourceApi.getOne(id);
    dispatch(
      updateRedux(actionType, {
        data: res,
        loading: false
      })
    );
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Группа не найдена' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const createOne = (data) => async (dispatch) => {
  const actionType = actionTypes.CREATE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));
  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.createOne(request);
    dispatch(
      updateRedux(actionType, {
        loading: false
      })
    );
    dispatch(showSnackbarSuccess({ message: 'Группа создана' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.code === 'api/exist') {
      dispatch(showSnackbarError({ message: 'Такая группа существует' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const updateOne = (id, data) => async (dispatch) => {
  const actionType = actionTypes.UPDATE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));

  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.updateOne(id, request);
    dispatch(
      updateRedux(actionType, {
        loading: false
      })
    );
    dispatch(showSnackbarSuccess({ message: 'Группа обновлена' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    if (err && err.code === 'api/exist') {
      dispatch(showSnackbarError({ message: 'Такая группа существует' }));
    } else if (err && err.statusCode === 404) {
      dispatch(showSnackbarError({ message: 'Группа не найдена' }));
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
    return Promise.reject(err);
  }
};

export const deleteOne = (id) => async (dispatch) => {
  const actionType = actionTypes.DELETE_ONE;

  dispatch(updateRedux(actionType, { loading: true }));

  try {
    const res = await resourceApi.deleteOne(id);
    dispatch(updateRedux(actionType, { loading: false, data: null }));
    dispatch(showSnackbarSuccess({ message: 'Группа удалена' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};

export const getGroupsUsers = (id, param) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST_USERS;
  const next = {
    order: 'desc',
    orderBy: 'id',
    filter: {},
    offset: 0,
    limit: PAGINATION_TABLE_LIMIT,
    ...param
  };

  const request = Query.createGetRequest(next);

  dispatch(
    updateRedux(actionType, {
      loading: true,
      params: next,
      list: []
    })
  );

  try {
    const { results: list = [], count = 0 } = await resourceApi.getGroupUsers(
      id,
      request
    );

    const res = {
      list,
      count,
      loading: false
    };

    dispatch(updateRedux(actionType, res));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    dispatch(updateRedux(actionType, { loading: false }));
    return Promise.reject(err);
  }
};

export const attachUserGroup = (group_id, user_id) => async (dispatch) => {
  try {
    const res = await resourceApi.attachUserGroup(group_id, user_id);
    dispatch(showSnackbarSuccess({ message: 'Связь добавлена' }));
    return Promise.resolve(res);
  } catch (err) {
    if (err && err.code === 'api/exist')
      dispatch(showSnackbarError({ message: 'Такая связь уже существует' }));
    else dispatch(ErrorService.dispatchError(err));
  }
};

export const detachUserGroup = (group_id, user_id) => async (dispatch) => {
  try {
    const res = await resourceApi.detachUserGroup(group_id, user_id);
    dispatch(
      showSnackbarSuccess({ message: 'Пользователь отвязан от группы' })
    );
    return Promise.resolve(res);
  } catch (err) {
    if (err && err.code === 'api/exist')
      dispatch(showSnackbarError({ message: 'Такая связь уже существует' }));
    else dispatch(ErrorService.dispatchError(err));
  }
};
