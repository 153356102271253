import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { inChildren, percentToNumber, validateErrors } from 'services/utils';

import { FormCheckboxField, FormTextField, SimpleForm } from 'components/Form';
import Screen from 'components/Screen';

import * as actions from './actions';

const validate = ({ name = '', vat = '' }) => {
  const errors = {};
  errors.name = validateErrors(name, {
    require: 'Обязательное поле'
  });
  const _v = String(vat).trim();
  const vatError = validateErrors(_v, {
    require: 'Обязательное поле',
    isNumeric: 'Должно быть числом'
  });
  if (vatError) {
    errors.vat = vatError;
  } else if (_v < 0 || _v > 100) {
    errors.vat = 'Допускается от 0 до 100';
  }
  return errors;
};

const formName = 'add_pay_type';

class Container extends Component {
  handleCreate = async (data) => {
    const { loading, payTypesActions, dispatch, history = {} } = this.props;
    if (loading) return;

    try {
      await payTypesActions.createOne(data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(formName, { name: 'Уже существует' }));
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleCreate: this.handleCreate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  payTypesActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleCreate, loading } = props;
  const history = useHistory();
  const back = history.goBack;
  return (
    <Screen title="Добавить способ оплаты">
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleCreate}
        disabled={loading}
        onBack={back}
        validate={validate}
        direction="row"
        initialValues={{
          name: '',
          vat: '0',
          assign_creator: 'no',
          is_bookkeeping: 'no'
        }}
        parse={(values) => {
          return {
            ...values,
            vat: percentToNumber(values.vat)
          };
        }}>
        <Grid item xs={12} sm={6}>
          <FormTextField source="name" label="Название" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="vat"
            label="НДС, %"
            disabled={loading}
            type="number"
            inputProps={{
              step: '1'
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCheckboxField
            source="assign_creator"
            label="Оператор"
            disabled={loading}
            parse={(v) => (v ? 'yes' : 'no')}
            format={(v) => v === 'yes'}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCheckboxField
            source="is_bookkeeping"
            label="Для бухгалтера"
            disabled={loading}
            parse={(v) => (v ? 'yes' : 'no')}
            format={(v) => v === 'yes'}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const PayTypesAdd = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default PayTypesAdd;
