import { auth as authApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import {
  getProjectIdFromUrl,
  saveProjectIdInStorage
} from 'services/projectUtils';
import { unsubscribe } from 'services/push';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_PROCESS = 'AUTH_PROCESS';
export const AUTH_CHECK = 'AUTH_CHECK';
export const UPDATE_USER_ADMIN = 'UPDATE_USER_ADMIN';
export const UPDATE_PROJECT_ID = 'UPDATE_PROJECT_ID';

export const logout = () => async (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  await unsubscribe();
  dispatch({
    type: AUTH_LOGOUT
  });
  window.location.href = '/login';
};

export const setProcess = (status) => ({
  type: AUTH_PROCESS,
  status
});

export const updateProjectId = (project_id) => (dispatch) => {
  saveProjectIdInStorage(project_id);
  dispatch({
    type: UPDATE_PROJECT_ID,
    project_id
  });
};

export const setUser = (admin) => (dispatch) => {
  let project_id = getProjectIdFromUrl();
  if (!project_id) {
    const { projects } = admin;
    if (projects && projects.length) {
      project_id = projects[0] && projects[0].project && projects[0].project.id;
    }
  }
  saveProjectIdInStorage(project_id);
  dispatch({
    type: AUTH_LOGIN,
    payload: {
      admin: admin,
      project_id
    }
  });
};

export const login = (params) => async (dispatch) => {
  dispatch(setProcess(true));
  try {
    const res = await authApi.login(params);
    localStorage.setItem('token', res.token);
    localStorage.setItem('refresh_token', res.refresh_token);
    const admin = await authApi.status();
    dispatch(setUser(admin));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(setProcess(false));
    if (err && err.code === 'api/bad-request')
      dispatch(ErrorService.dispatchError(new Error('Ошибка авторизации')));
    else if (err && err.statusCode === 401)
      dispatch(
        ErrorService.dispatchError(new Error('Неверный логин или пароль'))
      );
    else dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};

export const status = () => async (dispatch) => {
  const token = localStorage.getItem('token');
  if (!token) {
    dispatch({
      type: AUTH_CHECK
    });
    return;
  }
  try {
    const res = await authApi.status();
    dispatch(setUser(res));
  } catch (err) {
    if (err && err.statusCode === 401) {
      dispatch({
        type: AUTH_CHECK
      });
    } else {
      dispatch(ErrorService.dispatchError(err));
    }
  }
};

export const updateUserStatus = () => async (dispatch) => {
  try {
    const res = await authApi.status();
    dispatch(updateUserAdmin(res));
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
  }
};

export const updateUserAdmin = (payload) => {
  return {
    type: UPDATE_USER_ADMIN,
    payload
  };
};
