import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { removeModal } from './actions';

export const Modal = (props) => {
  const { uid, component: Component, props: passedProps, resolve } = props;

  const dispatch = useDispatch();
  const [isOpen, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const handleClose = (response) => {
    setShow(false);
    dispatch(removeModal(uid));
    resolve(response);
    if (passedProps.onClose) {
      passedProps.onClose(response);
    }
  };

  return (
    <Component {...passedProps} isOpen={isOpen} handleClose={handleClose} />
  );
};

export default Modal;
