import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import BN from 'bignumber.js';
import { bindActionCreators } from 'redux';

import { MATERIAL_UNIT } from 'constants/config';

import { convertAmountToUnits, inChildren, toBills } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import { IconBtnDelete } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, { FilterSearchSelectField } from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';
import ModalOrderWarehouseOutDialog from './ModalOrderWarehouseOutDialog';

class Container extends Component {
  componentDidMount() {
    this.getList();
  }

  refresh = () => {
    const { params = {} } = this.props.record;
    this.getList(params);
  };

  getList = (params) => {
    const { order, actions } = this.props;
    actions.getOrderWarehouse(order.id, params);
  };

  handleCreate = async (params) => {
    const { order, actions } = this.props;
    return actions.createOrderWarehouse(order.id, params);
  };

  handleDelete = async (item_id) => {
    const { order, actions, otherActions } = this.props;
    const res = await otherActions.showModalConfirm({
      title: 'Удаление',
      content: 'Удалить расход материала?'
    });

    if (res) {
      try {
        await actions.deleteOrderWarehouse(order.id, item_id);
        this.refresh();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleRefresh: this.refresh,
      handleCreate: this.handleCreate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { warehouse = {} } = target;
  return {
    record: warehouse
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { order, handleDelete, handleRefresh, handleCreate } = props;

  const isCanCreateWarehouse = useCan('warehouse_order:create');
  const isCanDeleteWarehouse = useCan('warehouse_order:delete');
  const isCanUpdateOrderWarehouse = order && order.executor_id;

  const canCreate = isCanUpdateOrderWarehouse && isCanCreateWarehouse;
  const canDelete = isCanUpdateOrderWarehouse && isCanDeleteWarehouse;

  const [modalProps, setModalProps] = useState(false);

  const showAdd = () => {
    setModalProps({
      title: 'Добавить расход',
      initialValues: null,
      handleSubmit: async (props) => {
        try {
          await handleCreate(props);
          setModalProps(false);
          handleRefresh();
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const onClose = () => {
    setModalProps(false);
  };

  return (
    <div>
      <List
        {...props}
        title="Расход материалов"
        toolBarInner={canCreate && <AddBtn onClick={() => showAdd()} />}
        filter={
          <Filter>
            <FilterSearchSelectField
              source="material_id"
              alwaysOn
              label="Материал"
              requestPath="/materials"
              responseFormat={(v) => v.results}
              searchBy="name"
              getValue={(o) => o.id}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field
          label="Материал"
          source="material"
          format={(v) => {
            if (!v) {
              return '';
            }
            return v.name;
          }}
        />
        <Field
          label="Кол-во"
          source="*"
          align="right"
          sortable="value"
          format={(v) => {
            const { material = {}, value } = v;
            if (!material) {
              return '';
            }
            const { unit } = material;
            if (!MATERIAL_UNIT[unit]) {
              return 'Error';
            }
            return `${convertAmountToUnits(value, unit)} ${
              MATERIAL_UNIT[unit].short
            }`;
          }}
        />
        <Field
          label="Цена"
          source="material"
          align="right"
          sortable="value"
          format={(v) => {
            if (!v) {
              return '';
            }
            return `${toBills(v.price)}`;
          }}
        />
        <Field
          label="Итого"
          source="*"
          align="right"
          sortable="value"
          format={(v) => {
            const { material, value } = v;
            if (!material || !value) {
              return 'Error';
            }
            const { unit, price } = material;
            if (!MATERIAL_UNIT[unit]) {
              return 'Error';
            }
            const count = convertAmountToUnits(value, unit);
            return BN(price).times(count).div(100).toString(10);
          }}
        />
        <Field source="id" isAction>
          {({ value }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                {canDelete ? (
                  <IconBtnDelete onClick={() => handleDelete(value)} />
                ) : null}
              </div>
            );
          }}
        </Field>
      </List>
      <ModalOrderWarehouseOutDialog
        isOpen={!!modalProps}
        title={(modalProps && modalProps.title) || 'Указать расход'}
        {...modalProps}
        handleClose={onClose}
      />
    </div>
  );
};

const ShowOrderWarehouseList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ShowOrderWarehouseList;
