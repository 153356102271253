import React from 'react';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Pagination from 'components/Pagination';

import TableHead from './TableHead';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  }
}));

const useTableStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750
  },
  tableRow: {
    cursor: (props) => (props.hasShow && props.basePath ? 'pointer' : 'default')
  },
  tableCell: {
    padding: '0 6px',
    position: 'relative'
  },
  tableWrapper: {
    overflowX: 'auto'
  }
}));

const getValue = (data, source) => {
  if (source === '*') return data;
  if (!data || !source) return null;

  const source_arr = source.split(',');
  if (source_arr.length > 1) {
    let val_obj = {};
    source_arr.forEach((source) => {
      val_obj[source] = data[source] ? data[source] : null;
    });
    return val_obj;
  }

  let val = '';
  try {
    let sourcePath = source.split('.');
    let val = data[sourcePath[0]];
    if (sourcePath.length) {
      for (let i = 1; i < sourcePath.length; i++) {
        if (sourcePath[i]) val = val[sourcePath[i]];
      }
    }
    return val;
  } catch (e) {
    return val;
  }
};

const keyExtractExample = (it, i) => it.id || i;

const EnhancedTable = (props) => {
  const classes = useStyles(props);

  const {
    // rowClick,
    // rowLink,
    record = {},
    children,
    // rows = [],
    keyExtract = keyExtractExample,
    // rowStyle,
    getList
  } = props;

  const {
    // count,
    params = {}
  } = record;

  const handleRequestSort = (event, property) => {
    if (typeof getList === 'function') {
      const { orderBy, order } = params;
      let newSort = {};
      if (orderBy !== property) {
        newSort.orderBy = property;
        newSort.order = 'desc';
      } else {
        newSort.orderBy = property;
        newSort.order = order === 'desc' ? 'asc' : 'desc';
      }
      const nextParams = {
        ...params,
        ...newSort,
        offset: 0
      };
      getList(nextParams, {
        eventType: 'sort'
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    if (typeof getList === 'function') {
      const { limit = 0 } = params;
      const nextParams = {
        ...params,
        offset: newPage * limit
      };
      getList(nextParams, {
        eventType: 'pagination'
      });
    }
  };

  let issetHeader = false;

  const columns =
    React.Children.map(children, (child) => {
      const { props: childProps } = child;
      const { label, source, sortable, type, isAction, align } = childProps;

      if (!issetHeader && (label || sortable)) issetHeader = true;

      let tAlign = align;
      if (!tAlign)
        tAlign =
          type === 'number' || type === 'price' || isAction ? 'right' : 'left';

      return {
        label,
        source,
        sortable,
        type,
        align: tAlign
      };
    }) || [];

  return (
    <div className={classes.root}>
      <TableHtml
        columns={columns}
        issetHeader={issetHeader}
        handleChangePage={handleChangePage}
        handleRequestSort={handleRequestSort}
        keyExtract={keyExtract}
        {...props}
      />
    </div>
  );
};

export default React.memo(EnhancedTable);

const TableHtml = (props) => {
  const classes = useTableStyles();
  const {
    columns,
    issetHeader,
    handleChangePage,
    handleRequestSort,
    // getPageIndex,
    record = {},
    rowLink,
    rows = [],
    keyExtract,
    rowStyle,
    rowClass = '',
    children
  } = props;

  const { count, params = {} } = record;

  return (
    <div>
      <Pagination
        count={count}
        offset={params.offset}
        limit={params.limit}
        onPageChange={handleChangePage}
      />
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="table"
          size="small">
          {issetHeader && (
            <TableHead
              classes={classes}
              columns={columns}
              order={params.order}
              orderBy={params.orderBy}
              onRequestSort={handleRequestSort}
            />
          )}
          <TableBody>
            {rows &&
              !!rows.length &&
              rows.map((row, i) => {
                const key = keyExtract(row, i);
                let style = {};
                let className = '';
                if (rowStyle) style = rowStyle(row);
                if (rowClass) className = rowClass(row);
                return (
                  <TableRow
                    style={style}
                    hover={!!rowLink}
                    className={`${classes.tableRow} ${className}`}
                    tabIndex={-1}
                    key={key}>
                    {React.Children.map(children, (child, index) => {
                      const { props: childProps } = child;
                      const {
                        label,
                        sortable,
                        source,
                        isAction,
                        align,
                        ...restChildProps
                      } = childProps;
                      let { value, type } = restChildProps;
                      if (source) value = getValue(row, source);
                      let tAlign = align;
                      if (!tAlign)
                        tAlign =
                          type === 'number' || type === 'price' || isAction
                            ? 'right'
                            : 'left';
                      const notClick = isAction || type === 'phone';

                      return (
                        <TableCell
                          align={tAlign}
                          key={`${source}-${index}`}
                          className={classes.tableCell}>
                          {notClick ? (
                            React.createElement(child.type, {
                              ...restChildProps,
                              row,
                              value
                            })
                          ) : (
                            <RoWLink rowLink={rowLink} row={row}>
                              {React.createElement(child.type, {
                                ...restChildProps,
                                row,
                                value
                              })}
                            </RoWLink>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <Pagination
        count={count}
        offset={params.offset}
        limit={params.limit}
        onPageChange={handleChangePage}
      />
    </div>
  );
};

const RoWLink = (props) => {
  const { children, rowLink, row } = props;
  if (rowLink) {
    const to = rowLink(row);
    if (to) {
      return (
        <>
          <Link
            to={to}
            style={{
              display: 'block',
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0
            }}></Link>
          {children}
        </>
      );
    }
  }
  return children;
};
