import ApiRequest from './ApiRequest';

export default class Auth extends ApiRequest {
  status() {
    return this.get(`/auth/status`);
  }
  login(params) {
    return this.post(`/auth/signin`, {
      body: params
    });
  }
  refresh(params) {
    return this.post(`/auth/refresh`, {
      body: params
    });
  }
}
