import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import {
  FormDateField,
  FormSearchSelectField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import Rbac from 'components/Rbac';
import Screen from 'components/Screen';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};

  const { client_id, date } = values;

  if (!client_id) {
    errors.client_id = 'Обязательное поле';
  }
  if (!date) {
    errors.date = 'Обязательное поле';
  }

  return errors;
};

const formName = 'ADD_REMINDER';

class Container extends Component {
  handleCreate = async (data) => {
    const { loading, actions, dispatch, history = {} } = this.props;
    if (loading) return;

    try {
      await actions.createOne(data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(formName, { name: 'Уже существует' }));
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleCreate: this.handleCreate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleCreate, loading } = props;

  const history = useHistory();
  const back = history.goBack;

  return (
    <Screen title="Добавить напоминание">
      <SimpleForm
        direction="row"
        loading={loading}
        form={formName}
        onSubmit={handleCreate}
        disabled={loading}
        onBack={back}
        validate={validate}
        initialValues={{
          message: ''
        }}>
        <Rbac operation="reminders:write">
          <Grid item xs={12} sm={6}>
            <FormSearchSelectField
              source="user_id"
              label="Пользователь"
              requestPath="/users"
              responseFormat={(v) => v.results}
              getOptionLabel={(it) =>
                it ? [it.first_name, it.last_name].join(' ') : ''
              }
              disabled={loading}
              searchBy={['first_name', 'last_name']}
              getValue={(o) => o.id}
              disableClearable={true}
            />
          </Grid>
        </Rbac>

        <Grid item xs={12} sm={6}>
          <FormSearchSelectField
            source="client_id"
            label="Клиент"
            requestPath="/clients"
            responseFormat={(v) => v.results}
            getOptionLabel={(it) => (it ? [it.name, it.phone].join(' ') : '')}
            disabled={loading}
            searchBy={['name', 'phone']}
            getValue={(o) => o.id}
            disableClearable={true}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormDateField
            label="Дата"
            source="date_start"
            dateType="datetime"
            disabled={loading}
          />
        </Grid>

        <Grid item xs={12}>
          <FormTextField
            source="message"
            label="Описание"
            disabled={loading}
            multiline={true}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const Add = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default Add;
