import {
  AUTH_CHECK,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_PROCESS,
  UPDATE_PROJECT_ID,
  UPDATE_USER_ADMIN
} from 'store/actions/authActions';

const initialState = {
  checkAuth: false,
  inProcess: false,
  admin: null
};

/*email: "admin@gmail.com"
first_name: "админ"
id: 1
last_name: "админ"
login: "admin"
notifications_count: 0
phone: "+380631888390"
projects: (2) [{…}, {…}]
reminders_count: 0
roles: (2) ['admin', 'superadmin']
*/

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_CHECK: {
      return {
        ...state,
        checkAuth: true
      };
    }
    case AUTH_PROCESS: {
      const { status = {} } = action;
      return {
        ...state,
        inProcess: status
      };
    }
    case AUTH_LOGOUT: {
      return {
        ...state,
        admin: null,
        inProcess: false,
        checkAuth: true
      };
    }
    case AUTH_LOGIN: {
      const { payload = {} } = action;
      return {
        ...state,
        ...payload,
        inProcess: false,
        checkAuth: true
      };
    }
    case UPDATE_USER_ADMIN: {
      const { payload = {} } = action;
      const { admin } = state;
      if (!admin) return state;
      return {
        ...state,
        admin: {
          ...admin,
          ...payload
        }
      };
    }
    case UPDATE_PROJECT_ID: {
      const { project_id } = action;
      return {
        ...state,
        project_id
      };
    }

    default:
      return state;
  }
};

export default authReducer;
