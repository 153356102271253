import { Circle, CircleMarker, Popup } from 'react-leaflet';
import L from 'leaflet';

import * as constants from './constants';
import Map from './Map';
import MapAddressesList from './MapAddressesList';
import Marker from './Marker';
import MarkerChangePosition from './MarkerChangePosition';
import * as utils from './utils';

import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
});

export {
  Circle,
  CircleMarker,
  constants,
  MapAddressesList,
  Marker,
  MarkerChangePosition,
  Popup,
  utils
};

export default Map;
