import { reports } from 'services/api';
import ErrorService from 'services/ErrorService';

export const getReportToken = () => async (dispatch) => {
  try {
    const res = await reports.getReportToken();
    return Promise.resolve(res);
  } catch (err) {
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};
