import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { PHONE_FORMAT } from 'constants/config';

import { inChildren, validateErrors } from 'services/utils';

import {
  FormCheckboxField,
  FormColorPickerField,
  FormPasswordField,
  FormPhoneField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import Screen from 'components/Screen';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};
  const {
    login,
    password,
    confirm_password,
    first_name,
    last_name,
    email,
    phone
  } = values;

  errors.login = validateErrors(login, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  errors.password = validateErrors(password, {
    require: 'Обязательное поле',
    'minLength=4': 'Не менее 4 символов'
  });

  errors.confirm_password = validateErrors(confirm_password, {
    require: 'Обязательное поле',
    'minLength=4': 'Не менее 4 символов'
  });

  if (password && confirm_password && password !== confirm_password) {
    errors.password = 'Пароли не совпадают';
    errors.confirm_password = 'Пароли не совпадают';
  }

  errors.first_name = validateErrors(first_name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  errors.last_name = validateErrors(last_name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  if (email) {
    errors.email = validateErrors(email, {
      email: 'Не валидный e-mail'
    });
  }

  if (phone) {
    errors.phone = validateErrors(phone, {
      phone: PHONE_FORMAT.placeholder
    });
  }

  return errors;
};

const formName = 'add_user';

class Container extends Component {
  handleCreate = async (data) => {
    const { loading, userActions, dispatch, history = {} } = this.props;
    if (loading) return;

    try {
      await userActions.createOne(data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(formName, { name: 'Уже существует' }));
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleCreate: this.handleCreate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  userActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleCreate, loading } = props;
  const history = useHistory();
  const back = history.goBack;
  return (
    <Screen title="Добавить пользователя">
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleCreate}
        disabled={loading}
        direction="row"
        onBack={back}
        validate={validate}
        initialValues={{
          color: '#44b120'
        }}
        parse={(values) => {
          return {
            login: values.login || '',
            first_name: values.first_name || '',
            last_name: values.last_name || '',
            email: values.email || '',
            phone: values.phone || '',
            password: values.password || '',
            is_active: values.is_active || 'no',
            color: values.color
          };
        }}>
        <Grid item xs={12} sm={6}>
          <FormTextField source="login" label="Логин" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormColorPickerField
            source="color"
            label="Цвет"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormPasswordField
                source="password"
                label="Пароль"
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormPasswordField
                source="confirm_password"
                label="Повторите пароль"
                disabled={loading}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField source="first_name" label="Имя" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="last_name"
            label="Фамилия"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField source="email" label="E-mail" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormPhoneField source="phone" label="Тел." disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormCheckboxField
            source="is_active"
            label="Активен"
            disabled={loading}
            parse={(v) => (v ? 'yes' : 'no')}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const Add = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default Add;
