import React from 'react';

import { FormSearchSelectField } from 'components/Form';

const FilterSearchSelectField = (props) => {
  const { source, children, ...rest } = props;

  return (
    <FormSearchSelectField
      name={source}
      submitOnChange={true}
      forced={true}
      {...rest}
    />
  );
};

export default React.memo(FilterSearchSelectField);
