import React from 'react';
import { Field } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { request } from 'services/api';

function FormSearchMultipleField(props) {
  const { source, ...rest } = props;

  return (
    <Field
      component={FieldComponent}
      name={source}
      {...rest}
      type="select-multi"
    />
  );
}

export default FormSearchMultipleField;

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%'
  },
  chip: {
    margin: 2,
    height: 25,
    backgroundColor: 'red'
  },
  chipDisabled: {
    opacity: 0.5
  }
}));

const exclude = (arr1, arr2, by) => {
  const ex = arr2.map((it) => it[by]);
  if (!ex.length) return arr1;
  const res = arr1.filter((it) => ex.indexOf(it[by]) === -1);
  return res;
};

const _getOptionLabel = (opt) => (opt && opt.id ? String(opt.id) : '');

function FieldComponent({ input = {}, meta = {}, ...attrs }) {
  const {
    label = '',
    noOptionsText = '',
    loadingText = 'Загрузка...',

    requestPath = '/methods',
    responseFormat = (res) => res.results,
    searchBy = 'name',

    disabled,
    getOptionLabel = _getOptionLabel,
    helperText = '',

    filterOptions = (o, v) => exclude(o, v, 'id'),
    // submitOnChange,
    // forced,
    disableClearable,
    ...restAttrs
  } = attrs;

  const multiple = true;

  const timer = React.useRef();

  const classes = useStyles(restAttrs);
  const visibleError = meta.touched && meta.error;

  const [q, setQ] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getData = async () => {
    try {
      const where = {};
      if (q && searchBy) {
        where[searchBy] = { $like: `%${q}%` };
      }
      let response = await request.get(requestPath, {
        qs: { where }
      });
      if (responseFormat) response = responseFormat(response);
      return response;
    } catch (err) {
      return [];
    }
  };

  React.useEffect(() => {
    if (!open) setQ('');
  }, [open]);

  React.useEffect(() => {
    let active = true;
    if (q || open) {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(async () => {
        if (!active) return;
        setLoading(true);
        const result = await getData();
        if (active) {
          setOptions(result);
          setLoading(false);
        }
      }, 500);
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, open]);

  const _onChange = (event, option) => {
    if (disabled) return;
    input.onChange(option);
  };

  const _onInputChange = (event, value) => {
    if (disabled) return;
    if (event && event.type === 'change') setQ(value);
  };

  const value = input.value || [];
  const resOptions = filterOptions(options, value);

  return (
    <Autocomplete
      classes={{
        root: classes.fullWidth
      }}
      noOptionsText={noOptionsText}
      loadingText={loadingText}
      disabled={disabled}
      disableCloseOnSelect={multiple}
      disableClearable={disableClearable}
      options={resOptions}
      open={!disabled && open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      freeSolo={true}
      getOptionLabel={getOptionLabel}
      loading={open && loading}
      onChange={_onChange}
      onInputChange={_onInputChange}
      inputValue={q}
      value={value}
      {...restAttrs}
      multiple={multiple}
      // disableOpenOnFocus={true}
      renderInput={(params) => {
        return (
          <FormControl
            className={classes.formControl}
            error={!!visibleError}
            fullWidth>
            <TextField
              {...params}
              fullWidth
              label={label}
              error={!!visibleError}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
            {(visibleError || helperText) && (
              <FormHelperText>{visibleError || helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}
