import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const makeRoutePath = (path) => {
  if (path && path.indexOf('/') !== 0) path = `/${path}`;
  return `/project/:project_id${path}`;
};

const project_id_regexp = new RegExp('^\\/project\\/([^\\/]+)');
export const getProjectIdFromUrl = () => {
  let w_path = window.location.pathname;
  const result = project_id_regexp.exec(w_path);
  const proj_id = result && result[1];
  if (proj_id) {
    return Number(proj_id);
  }
  return false;
};

export const getProjectId = () => {
  let project_id = getProjectIdFromUrl();
  if (!project_id) project_id = window.project_id;
  // const storageProjId = window.localStorage.getItem('project_id');
  // if (urlProjId && urlProjId !== storageProjId) saveProjectIdInStorage(urlProjId);
  return Number(project_id);
};

export const saveProjectIdInStorage = (project_id) => {
  // window.localStorage.setItem('project_id', project_id);
  window.project_id = project_id;
};

export const makeUrlProjectId = (path) => {
  const project_id = getProjectId();
  if (path && path.indexOf('/') !== 0) path = `/${path}`;
  return `/project/${project_id}${path}`;
};

export const useMakeUrlProjectId = () => {
  const project_id = useSelector((state) => state.auth.project_id);
  return (path) => {
    if (path && path.indexOf('/') !== 0) path = `/${path}`;
    return `/project/${project_id}${path}`;
  };
};

const replaceRegexp = new RegExp('^\\/project\\/[^\\/]+(\\/[^\\/]+)?(\\/.+)?');
export const useChangeProjId = () => {
  let history = useHistory();
  return (project_id) => {
    saveProjectIdInStorage(project_id);
    const nextUrl = window.location.pathname.replace(
      replaceRegexp,
      (srt, $1) => {
        let res = `/project/${project_id}`;
        if ($1) res += $1;
        return res;
      }
    );
    history.push(nextUrl);
  };
};
