import React from 'react';

import Grid from '@material-ui/core/Grid';

import { concatGeoFullName, joinArrayBySeparate } from 'services/utils';

import { LabelField } from 'components/Field';

const ClientInfo = (props) => {
  const { record = {} } = props;
  const labelWidth = 150;
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <LabelField width={labelWidth} label="Имя:" value={record.name} />
      </Grid>
      <Grid item>
        <LabelField width={labelWidth} label="E-mail:" value={record.email} />
      </Grid>
      <Grid item>
        <LabelField
          width={labelWidth}
          label="Тел.:"
          value={record.phone}
          type="phone"
        />
      </Grid>
      <Grid item>
        <LabelField
          width={labelWidth}
          label="Дата:"
          type="date"
          value={record.created_at}
        />
      </Grid>
      <Grid item>
        <LabelField width={labelWidth} label="Группа:" value={record.group}>
          {({ value }) => {
            if (!value) return '';
            return value.name;
          }}
        </LabelField>
      </Grid>
      <Grid item>
        <LabelField
          width={150}
          label="Адрес:"
          value={record}
          format={(record) => {
            const { street, address_house, address_apartment } = record;
            const rowValues = [
              concatGeoFullName(street),
              address_house ? `д.${address_house}` : '',
              address_apartment ? `кв.${address_apartment}` : ''
            ];
            return joinArrayBySeparate(rowValues);
          }}
        />
      </Grid>
      <Grid item>
        <LabelField
          width={150}
          label="Примечание:"
          value={record.comment}
          type="longtext"
        />
      </Grid>
      <Grid item>
        <LabelField
          width={labelWidth}
          label="Отправить СМС:"
          value={record.send_sms}
          format={(v) => {
            if (v === 'yes') return 'Да';
            if (v === 'no') return 'Нет';
            return 'Не указано';
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ClientInfo;
