import React from 'react';
import { Field, submit } from 'redux-form';

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { request } from 'services/api';

function FormSearchSelectField(props) {
  const { source, ...rest } = props;

  return <Field component={SelectSearch} name={source} {...rest} />;
}

export default FormSearchSelectField;

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%'
  }
}));

function getValueDefault(o) {
  return o;
}
function _getOptionLabel(o) {
  if (o && o.name) return o.name;
  return o && o.id ? String(o.id) : '';
}

function SelectSearch({ input = {}, meta = {}, ...attrs }) {
  const {
    label = ' ',
    noOptionsText = '',
    loadingText = 'Загрузка...',
    disabled,
    requestPath = '',
    responseFormat,
    getOptionLabel = _getOptionLabel,
    helperText = '',
    searchBy,
    submitOnChange,
    forced,
    disableClearable,
    getValue = getValueDefault,
    where = {},
    order = '',
    renderOption,
    onChangeHandler,
    ...restAttrs
  } = attrs;

  const classes = useStyles(restAttrs);
  const visibleError = meta.touched && meta.error;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(null);
  const [q, setQ] = React.useState('');
  const [timer, setTimer] = React.useState(null);
  const [selected, setSelected] = React.useState(null);

  const getData = async () => {
    try {
      const req = { ...where };
      if (q && searchBy) {
        if (Array.isArray(searchBy)) {
          req.$or = {};
          searchBy.forEach((it) => {
            req.$or[it] = { $like: `%${q}%` };
          });
        } else {
          req[searchBy] = { $like: `%${q}%` };
        }
      }

      const qs = { limit: 50, where: req };
      if (order) qs.order = order;
      let response = await request.get(requestPath, {
        qs
      });
      if (responseFormat) response = responseFormat(response);
      return response;
    } catch (err) {
      return [];
    }
  };

  React.useEffect(() => {
    if (!open) {
      setOptions(null);
      setQ('');
      return undefined;
    }
    let active = true;
    if (timer) clearTimeout(timer);
    const currenTimer = setTimeout(async () => {
      const result = await getData();
      if (active) {
        setOptions(result);
      }
    }, 100);
    setTimer(currenTimer);
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q, open]);

  React.useEffect(() => {
    const initial = input.value;
    if (!initial) {
      setValue(initial);
      return;
    }

    if (typeof initial === 'object') {
      setValue(initial);
      return;
    }

    let active = true;
    (async () => {
      try {
        let response = await request.get(`${requestPath}/${initial}`);
        if (active) setValue(response);
      } catch (err) {
        _onChange(null, null);
      }
    })();
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value]);

  const setValue = (v) => {
    setSelected(v);
    const savedValue = v ? getValue(v) : v;
    input.onChange(savedValue);
  };

  const _onChange = (event, option) => {
    if (disabled) return;
    setValue(option);
    onChangeHandler?.(option ? getValue(option) : option);
    setOpen(false);
    setTimeout(() => {
      if (submitOnChange) meta.dispatch(submit(meta.form));
    }, 0);
  };

  const _onInputChange = (event) => {
    if (event && event.target) setQ(event.target.value);
  };

  const loading = open && !options;
  let inputValue = '';
  if (open) inputValue = q;
  else inputValue = selected ? getOptionLabel(selected) : '';

  return (
    <Autocomplete
      classes={{
        root: classes.fullWidth
      }}
      filterOptions={(o) => o}
      noOptionsText={noOptionsText}
      open={!disabled && open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      freeSolo={true}
      getOptionLabel={getOptionLabel}
      loadingText={loadingText}
      loading={loading}
      disableClearable={disableClearable}
      disabled={disabled}
      options={options || []}
      onInputChange={_onInputChange}
      onChange={_onChange}
      inputValue={inputValue}
      value={input.value}
      renderOption={renderOption}
      // disableOpenOnFocus={true}
      renderInput={(params) => {
        return (
          <FormControl
            className={classes.formControl}
            error={!!visibleError}
            fullWidth>
            <TextField
              {...params}
              fullWidth
              label={label}
              error={!!visibleError}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
            {(visibleError || helperText) && (
              <FormHelperText>{visibleError || helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}
