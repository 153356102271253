import ApiRequest from './ApiRequest';

export default class Sessions extends ApiRequest {
  getList(params) {
    return this.get(`/sessions`, {
      qs: params
    });
  }
  getOne(id) {
    return this.get(`/sessions/${id}`);
  }
  updateOne(id, req) {
    return this.put(`/sessions/${id}`, {
      body: req
    });
  }
  deleteOne(id) {
    return this.delete(`/sessions/${id}`);
  }
}
