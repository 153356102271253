import { PAGINATION_TABLE_LIMIT } from 'constants/config';

import { orders as resourceApi, payTypes as payTypesApi } from 'services/api';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';
import { showSnackbarSuccess } from 'modules/notifier';

import { getStatsBookkeeping } from 'store/actions/statsActions';

export const actionTypes = {
  GET_LIST: 'BOOKKEEPER_ORDERS_NEW_GET_LIST'
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

const defaultQuery = {
  order: 'desc',
  orderBy: 'id',
  filter: {},
  offset: 0,
  limit: PAGINATION_TABLE_LIMIT
};

export const getList =
  (param = {}) =>
  async (dispatch) => {
    const actionType = actionTypes.GET_LIST;

    const next = {
      ...defaultQuery,
      ...param,
      filter: {
        is_new: 'yes',
        is_active: 'yes',
        ...param.filter
      }
    };

    const request = Query.createGetRequest(next);
    if (next.filter.is_active === 'all') delete request.where.is_active;
    try {
      if (!next.filter.pay_type_id) {
        const { results = [] } = await payTypesApi.getList({
          where: {
            is_bookkeeping: 'yes'
          }
        });
        if (results && results.length) {
          request.where.pay_type_id = results.map((it) => it.id);
        } else {
          const res = {
            list: [],
            count: 0,
            loading: false
          };

          dispatch(updateRedux(actionType, res));
          return Promise.resolve(res);
        }
      }
    } catch (err) {
      console.error(err);
    }
    request.where.price = { $gt: 0 };

    dispatch(
      updateRedux(actionType, {
        loading: true,
        params: next
        // list: []
      })
    );

    try {
      const { results: list = [], count = 0 } = await resourceApi.getList(
        request
      );
      const res = {
        list,
        count,
        loading: false
      };

      dispatch(updateRedux(actionType, res));
      return Promise.resolve(res);
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      dispatch(updateRedux(actionType, { loading: false }));
      return Promise.reject(err);
    }
  };

export const updateOne = (id, data) => async (dispatch) => {
  const actionType = actionTypes.GET_LIST;
  dispatch(updateRedux(actionType, { loading: true }));
  try {
    const request = Query.createRequest(data);
    const res = await resourceApi.updateOne(id, request);
    dispatch(
      updateRedux(actionType, {
        loading: false
      })
    );
    dispatch(getStatsBookkeeping());
    dispatch(showSnackbarSuccess({ message: 'Запись обновлена' }));
    return Promise.resolve(res);
  } catch (err) {
    dispatch(updateRedux(actionType, { loading: false }));
    dispatch(ErrorService.dispatchError(err));
    return Promise.reject(err);
  }
};
