import { actionTypes } from './actions';

const initialState = {
  list: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  one: {
    loading: false,
    data: null
  },
  users: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  available: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  schedule: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  mileage: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  }
};

const executorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST: {
      const { payload = {} } = action;
      const { list = {} } = state;
      return {
        ...state,
        list: {
          ...list,
          ...payload
        }
      };
    }
    case actionTypes.GET_ONE: {
      const { one = {} } = state;
      const { payload = {} } = action;
      return {
        ...state,
        one: {
          ...one,
          ...payload
        }
      };
    }
    case actionTypes.CREATE_ONE: {
      const { one = {} } = state;
      const { payload = {} } = action;
      return {
        ...state,
        one: {
          ...one,
          ...payload
        }
      };
    }
    case actionTypes.UPDATE_ONE: {
      const { one = {} } = state;
      const { payload = {} } = action;
      return {
        ...state,
        one: {
          ...one,
          ...payload
        }
      };
    }
    case actionTypes.DELETE_ONE: {
      const { payload = {} } = action;
      const { one = {} } = state;
      return {
        ...state,
        one: {
          ...one,
          ...payload
        }
      };
    }
    case actionTypes.GET_LIST_USERS: {
      const { payload = {} } = action;
      const { users = {} } = state;
      return {
        ...state,
        users: {
          ...users,
          ...payload
        }
      };
    }
    case actionTypes.UPDATE_LIST_USERS: {
      const { payload = {} } = action;
      const { user_id, data: updateDate } = payload;
      const { users = {} } = state;
      const { list = [] } = users;
      if (!list || !list.length) return state;
      const newList = list.map((it) => {
        if (!it || !it.user) return it;
        if (it.user.id === user_id) {
          return {
            ...it,
            ...updateDate
          };
        }
        return it;
      });

      return {
        ...state,
        users: {
          ...users,
          list: newList
        }
      };
    }
    case actionTypes.GET_LIST_AVAILABLE: {
      const { payload = {} } = action;
      const { available = {} } = state;
      return {
        ...state,
        available: {
          ...available,
          ...payload
        }
      };
    }
    case actionTypes.GET_LIST_SCHEDULE: {
      const { payload = {} } = action;
      const { schedule = {} } = state;
      return {
        ...state,
        schedule: {
          ...schedule,
          ...payload
        }
      };
    }
    case actionTypes.GET_LIST_MILEAGE: {
      const { payload = {} } = action;
      const { mileage = {} } = state;
      return {
        ...state,
        mileage: {
          ...mileage,
          ...payload
        }
      };
    }
    default:
      return state;
  }
};

export default executorsReducer;
