import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Query from 'services/Query';
import { inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import { IconBtnDelete, IconBtnEdit } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterDateRangeField,
  FilterSearchElasticClientField,
  FilterSearchGeoStreetsField
} from 'components/Filter';
import List from 'components/List';
import Rbac from 'components/Rbac';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    const { location: { search = '' } = {} } = this.props;
    const q = Query.parseQuery(search);
    this.getList(q);
  }

  componentDidUpdate(prev) {
    const { location: { search = '' } = {} } = this.props;
    const { location: { search: prevSearch = '' } = {} } = prev;
    if (prevSearch !== search) {
      const q = Query.parseQuery(search);
      this.getList(q);
    }
  }

  getList = (params) => {
    const { history = {} } = this.props;
    const qs = Query.createQs(params);
    const { location: { search = '' } = {} } = this.props;
    if (qs !== search) {
      history.push({
        search: qs
      });
    } else {
      const { clientsActions } = this.props;
      clientsActions.getList(params);
    }
  };

  handleDelete = async (id) => {
    const { clientsActions, record, dispatch } = this.props;
    const { params = {}, list = [] } = record;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить клиента?'
      })
    );

    if (res) {
      try {
        await clientsActions.deleteOne(id);
        const { offset = 0, limit = 0 } = params;
        if (offset && list.length === 1) {
          params.offset = offset - limit;
        }
        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { list = {} } = target;
  return {
    record: list
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  clientsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleDelete, basePath = '/' } = props;

  return (
    <Screen title="Клиенты">
      <List
        {...props}
        rowLink={(row) => `${basePath}/show/${row.id}`}
        toolBarInner={
          <Rbac operation="clients:create">
            <AddBtn to={`${basePath}/add`} />
          </Rbac>
        }
        filter={
          <Filter
            parse={(values) => {
              const res = {
                ...values
              };
              if (values.id) res.id = values.id;
              if (values.street_id) res.street_id = values.street_id;
              return res;
            }}>
            <FilterSearchElasticClientField
              source="id"
              label="Клиент"
              alwaysOn
            />
            <FilterSearchGeoStreetsField
              maxWidth={400}
              alwaysOn
              source="street_id"
              label="Адрес"
            />
            <FilterDateRangeField
              source="created_at"
              labels={['Создан,с', 'Создан,по']}
              label="Дата создания"
              maxWidth={300}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field label="Имя" source="name" sortable />
        <Field label="Тел." source="phone" type="phone" />
        <Field label="E-mail" source="email" />
        <Field label="Дата создания" source="created_at" type="date" sortable />

        <Field source="id" isAction>
          {({ value }) => {
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                <Rbac operation="clients:update">
                  <IconBtnEdit to={`${basePath}/edit/${value}`} />
                </Rbac>
                <Rbac operation="clients:delete">
                  <IconBtnDelete onClick={() => handleDelete(value)} />
                </Rbac>
              </div>
            );
          }}
        </Field>
      </List>
    </Screen>
  );
};

const ClientsList = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ClientsList;
