import React from 'react';
import { ChromePicker } from 'react-color';
import { Field } from 'redux-form';

import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

export default function FormColorPickerField(props) {
  const { source, ...rest } = props;
  return <Field name={source} component={ColorPicker} {...rest} />;
}

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    minWidth: '0 !important'
  }
}));

const useColorStyles = makeStyles((theme) => ({
  chip: {
    height: 18,
    background: (props) => props.color,
    color: '#000',
    border: '1px solid #000',
    '.MuiChip-label': {
      padding: '0 15px'
    }
  }
}));

const ColorPicker = ({ input = {}, meta = {}, ...attrs }) => {
  const { touched, error } = meta;
  const visibleError = touched && error;

  const {
    helperText = '',
    label = '',
    // defaultValue = '#fff',
    ...restAttrs
  } = attrs;
  const classes = useStyles(restAttrs);

  const _onChange = (v) => {
    input.onChange(v.hex);
  };
  const value = input.value || '';
  const chipClasses = useColorStyles({ color: value });
  return (
    <FormControl error={!!visibleError} fullWidth className={classes.control}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...restAttrs}
        input={<Input />}
        renderValue={(selected) => {
          return (
            <Chip className={chipClasses.chip} label={input.value || ''} />
          );
        }}
        value={value}
        MenuProps={{
          variant: 'menu',
          classes: {
            paper: classes.list,
            list: classes.list
          }
        }}>
        <MenuItem value={value} style={{ display: 'none' }}></MenuItem>
        <ChromePicker
          // defaultValue={defaultValue}
          autoComplete="off"
          onChange={_onChange}
          color={value}
        />
      </Select>
      {(visibleError || helperText) && (
        <FormHelperText>{visibleError || helperText}</FormHelperText>
      )}
    </FormControl>
  );
};
