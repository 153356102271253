import ApiRequest from './ApiRequest';

export default class Reminders extends ApiRequest {
  getList(params) {
    return this.get(`/reminders`, {
      qs: params
    });
  }
  getOne(id) {
    return this.get(`/reminders/${id}`);
  }
  createOne(req) {
    return this.post(`/reminders`, {
      body: req
    });
  }
  updateOne(id, req) {
    return this.put(`/reminders/${id}`, {
      body: req
    });
  }
  deleteOne(id) {
    return this.delete(`/reminders/${id}`);
  }

  updateRead(id) {
    return this.put(`/reminders/${id}/read`);
  }
}
