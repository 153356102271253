import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { change, stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { MATERIAL_UNIT } from 'constants/config';

import { convertUnitsToAmount, inChildren, toCoins } from 'services/utils';
import { isNumeric, isPrice } from 'services/validation';

import {
  FormDateField,
  FormSearchSelectField,
  FormSelectField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import Screen from 'components/Screen';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};
  const { place, material, dst_place, direction, value = 0, price } = values;

  if (!price && price !== 0) {
    errors.price = 'Укажите цену';
  } else if (!isPrice(price)) {
    errors.price = 'Невалидная цена';
  }

  if (!place) {
    errors.place = 'Укажите дислокацию';
  }

  if (!material) {
    errors.material = 'Укажите материал';
  }

  if (!value || !Number(value)) {
    errors.value = 'Укажите количество';
  } else if (!isNumeric(value)) {
    errors.value = 'Только положительное число';
  } else if (value < 0) {
    errors.value = 'Только положительное число';
  }

  if (!direction) {
    errors.direction = 'Укажите направление';
  } else if (direction === 'MOVE' && !dst_place) {
    errors.dst_place = 'Укажите направление';
  }

  return errors;
};

const formName = 'add_warehouse';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = { material: null };
  }
  handleCreate = async (data) => {
    const { loading, actions, dispatch, history = {} } = this.props;
    if (loading) return;

    try {
      await actions.createOne(data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(formName, { name: 'Уже существует' }));
      } else if (err && err.code === 'api/value-not-enough') {
        dispatch(stopSubmit(formName, { place: 'Недостаточно материала' }));
      }
    }
  };

  handleChangeMaterial = async (mat) => {
    const { dispatch } = this.props;
    dispatch(change(formName, 'value', 0));
    dispatch(change(formName, 'material', mat));
    this.setState({ material: mat });
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      state: { ...this.state },
      handleCreate: this.handleCreate,
      handleChangeMaterial: this.handleChangeMaterial
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const dispatch = useDispatch();
  const { state, handleCreate, handleChangeMaterial, loading } = props;

  const [direction, setDirection] = React.useState(null);

  const onChangeDirection = React.useCallback(
    (val) => {
      setDirection(val);
      dispatch(change(formName, 'dst_place', null));
    },
    [dispatch]
  );

  const history = useHistory();
  const back = history.goBack;

  const material = state.material || null;
  const unit = material ? material.unit : null;
  const materialUnit = unit && MATERIAL_UNIT[unit] ? MATERIAL_UNIT[unit] : null;

  return (
    <Screen title="Добавить запись">
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleCreate}
        disabled={loading}
        direction="row"
        onBack={back}
        validate={validate}
        enableReinitialize={false}
        initialValues={{
          value: 0,
          price: 0,
          description: '',
          date: moment()
        }}
        parse={(values) => {
          const res = {
            place_id: values.place ? values.place.id : null,
            dst_place_id: values.dst_place ? values.dst_place.id : null,
            material_id: values.material ? values.material.id : null,
            price: values.price >= 0 ? toCoins(values.price) : 0,
            date: values.date ? moment(values.date).format('YYYY-MM-DD') : null,
            direction: values.direction,
            description: values.description || '',
            value: values.material
              ? convertUnitsToAmount(values.value, values.material.unit)
              : 0
          };
          return res;
        }}>
        <Grid item xs={12} sm={6}>
          <FormSearchSelectField
            source="place"
            label="Дислокация"
            requestPath="/executor_places"
            responseFormat={(v) => v.results}
            renderOption={(it) => it.name}
            searchBy="name"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSearchSelectField
            source="material"
            label="Материал"
            requestPath="/materials"
            responseFormat={(v) => v.results}
            disabled={loading}
            searchBy="name"
            onChange={handleChangeMaterial}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormSelectField
            source="direction"
            label="Тип"
            disabled={loading}
            onChange={onChangeDirection}
            options={[
              { value: 'INCOMING', label: 'Приход' },
              { value: 'OUTGOING', label: 'Расход' },
              { value: 'MOVE', label: 'Перемещение' }
            ]}
          />
        </Grid>
        {direction === 'MOVE' ? (
          <Grid item xs={12} sm={6}>
            <FormSearchSelectField
              source="dst_place"
              label="Дислокация куда"
              requestPath="/executor_places"
              responseFormat={(v) => v.results}
              renderOption={(it) => it.name}
              searchBy="name"
              disabled={loading}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="value"
            label={`Кол-во ${materialUnit ? materialUnit.short : ''}`}
            disabled={loading || !materialUnit}
            type="number"
            inputProps={{
              step: '1'
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormDateField
            label="Дата"
            source="date"
            dateType="date"
            disabled={loading}
            clearable={false}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="price"
            label="Цена"
            disabled={loading}
            type="number"
            inputProps={{
              step: '0.01'
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            source="description"
            label="Описание"
            disabled={loading}
            multiline={true}
            maxLength="65535"
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const Add = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default Add;
