import React from 'react';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { MATERIAL_UNIT } from 'constants/config';

import { convertUnitsToAmount } from 'services/utils';
import { isNumeric } from 'services/validation';

import { IconBtnClose } from 'components/buttons/IconBtn';
import Form, { FormSearchSelectField, FormTextField } from 'components/Form';
import Progress from 'components/Progress';

const formName = 'order-add-warehouse-out';

const validate = (values) => {
  const errors = {};
  const { material, value = 0 } = values;

  if (!material) {
    errors.material = 'Укажите материал';
  }

  if (!value || !Number(value)) {
    errors.value = 'Укажите количество';
  } else if (!isNumeric(value)) {
    errors.value = 'Только положительное число';
  } else if (value < 0) {
    errors.value = 'Только положительное число';
  }

  return errors;
};

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const ModalMatOutDialog = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    title = '',
    isOpen,
    handleClose = () => null,
    successText = 'Сохранить',
    cancelText = 'Закрыть',
    handleSubmit = () => null,
    initialValues = null
  } = props;
  const [loading, setLoading] = React.useState(false);
  const [material, setMaterial] = React.useState(null);
  const changeMaterial = React.useCallback(
    (material) => {
      dispatch(change(formName, 'value', 0));
      dispatch(change(formName, 'material', material));
      setMaterial(material);
    },
    [dispatch]
  );

  const onSubmit = async (params) => {
    if (loading) return;
    setLoading(true);
    await handleSubmit(params);
    if (isOpen) setLoading(false);
  };

  const unit = material ? material.unit : null;
  const materialUnit = unit && MATERIAL_UNIT[unit] ? MATERIAL_UNIT[unit] : null;

  return (
    <Dialog
      open={isOpen}
      scroll={'paper'}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth={true}
      maxWidth="sm"
      // onClose={() => handleClose(false)}
    >
      <Form
        form={formName}
        onSubmit={onSubmit}
        disabled={loading}
        validate={validate}
        enableReinitialize={false}
        initialValues={{
          ...initialValues,
          value: 0,
          description: ''
        }}
        parse={(values) => {
          const res = {
            material_id: values.material ? values.material.id : null,
            // description: values.description || '',
            value: values.material
              ? convertUnitsToAmount(values.value, values.material.unit)
              : 0
          };
          return res;
        }}>
        <div className={classes.modalHeader}>
          <DialogTitle>{title}</DialogTitle>
          <IconBtnClose onClick={handleClose} />
        </div>
        <DialogContent dividers={true}>
          {loading && <Progress variant="over" />}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormSearchSelectField
                source="material"
                label="Материал"
                requestPath="/materials"
                responseFormat={(v) => v.results}
                disabled={loading}
                searchBy="name"
                onChange={changeMaterial}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormTextField
                source="value"
                label={`Кол-во ${materialUnit ? materialUnit.short : ''}`}
                disabled={loading || !materialUnit}
                type="number"
                inputProps={{
                  step: '1'
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <FormTextField
                source="description"
                label="Описание"
                disabled={loading}
                multiline={true}
                maxLength="65535"
              />
            </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{cancelText}</Button>
          <Button type="submit" color="primary" variant="contained">
            {successText}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default ModalMatOutDialog;
