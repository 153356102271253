import React from 'react';

import { phoneFormat } from 'services/utils';

import FormSearchSelectField from './FormSearchSelectField';

function renderOptionDefault(props) {
  const { name, phone, locality = {} } = props;

  const address = (locality && locality.full_name_text) || '';

  return (
    <div>
      <div>{name}</div>
      {phone && <div style={{ fontSize: '0.75em' }}>{phoneFormat(phone)}</div>}
      <div style={{ fontSize: '0.75em' }}>{address}</div>
    </div>
  );
}

const FormSearchElasticExecutorField = (props) => {
  const { is_active } = props;

  return (
    <FormSearchSelectField
      requestPath="/executors"
      renderOption={renderOptionDefault}
      responseFormat={(v) => v.results}
      searchBy="name"
      order="-sort"
      {...(is_active && { where: { is_active: is_active } })}
      {...props}
    />
  );
};

export default FormSearchElasticExecutorField;
