// rbac
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { inChildren } from 'services/utils';

import { ShowHistoryBtn } from 'resources/ResourceHistory';
import { BackBtn, DeleteBtn, EditBtn } from 'components/buttons/Btn';
import FooterBar from 'components/FooterBar';
import NotFoundItems from 'components/NotFoundItems';
import Progress from 'components/Progress';
import Rbac from 'components/Rbac';
import Screen from 'components/Screen';
import Tabs, { Tab } from 'components/Tabs';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';
import ShowClientFiles from './ShowClientFiles';
import ShowClientMessages from './ShowClientMessages';
import ShowInfo from './ShowInfo';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false
    };
  }
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const { match: { params: { id } = {} } = {}, clientsActions } = this.props;
    try {
      await clientsActions.getOne(id);
    } catch (err) {
      this.setState({ notFound: true });
    }
  };

  handleDelete = async () => {
    const { clientsActions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить заметку?'
      })
    );

    if (res) {
      try {
        await clientsActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      notFound: this.state.notFound,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    record: one.data,
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  clientsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleDelete,
    record = {},
    match = {},
    loading,
    basePath = '/',
    notFound,
    resourceName
  } = props;

  const [tab, setTab] = React.useState(match.url);

  const itemReceived = !!(record && record.id);
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Клиент: ${record.name}`;
  }
  return (
    <Screen title={title}>
      {loading && <Progress />}
      {notFound && <NotFoundItems message="Клиент не найден" />}
      {itemReceived ? (
        <Tabs key={record.id} value={tab} onChange={(path) => setTab(path)}>
          <Tab label="Инфо" eventKey={match.url}>
            <Box p={2}>
              {itemReceived && (
                <Grid container justifyContent="flex-end">
                  <ShowHistoryBtn itemType="clients" itemId={record.id} />
                </Grid>
              )}
              <ShowInfo {...props} />
            </Box>
            <FooterBar>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <BackBtn onClick={back}>Назад</BackBtn>
                  </Grid>
                  <Grid item>
                    {itemReceived && (
                      <Rbac operation="clients:update">
                        <EditBtn
                          to={{
                            pathname: `${basePath}/edit/${record.id}`,
                            state: { from: `${basePath}/show/${record.id}` }
                          }}>
                          Редактировать
                        </EditBtn>
                      </Rbac>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {itemReceived && (
                  <Rbac operation="clients:delete">
                    <DeleteBtn onClick={handleDelete}>Удалить</DeleteBtn>
                  </Rbac>
                )}
              </Grid>
            </FooterBar>
          </Tab>
          <Tab label="Заметки" eventKey={`${match.url}/messages`}>
            <Rbac operation="client_messages:read">
              <ShowClientMessages client={record} resourceName={resourceName} />
              <FooterBar>
                <Grid item>
                  <BackBtn onClick={back}>Назад</BackBtn>
                </Grid>
              </FooterBar>
            </Rbac>
          </Tab>
          <Tab label="Документы" eventKey={`${match.url}/files`}>
            <Rbac operation="files:read">
              <ShowClientFiles client={record} resourceName={resourceName} />
              <FooterBar>
                <Grid item>
                  <BackBtn onClick={back}>Назад</BackBtn>
                </Grid>
              </FooterBar>
            </Rbac>
          </Tab>
        </Tabs>
      ) : null}
    </Screen>
  );
};

const ClientsShow = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ClientsShow;
