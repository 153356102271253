import { APP_NEED_RELOAD } from 'store/actions/appActions';

const initialState = {
  needReload: false
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_NEED_RELOAD: {
      const { needReload } = action;
      return {
        ...state,
        needReload
      };
    }
    default:
      return state;
  }
};

export default appReducer;
