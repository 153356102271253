import { Marker as RLMarket } from 'react-leaflet';
import L from 'leaflet';

const svgTemplate = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97.713 97.713">
      <path d="M48.855,0C29.021,0,12.883,16.138,12.883,35.974c0,5.174,1.059,10.114,3.146,14.684
      c8.994,19.681,26.238,40.46,31.31,46.359c0.38,0.441,0.934,0.695,1.517,0.695s1.137-0.254,1.517-0.695
      c5.07-5.898,22.314-26.676,31.311-46.359c2.088-4.57,3.146-9.51,3.146-14.684C84.828,16.138,68.69,0,48.855,0z M48.855,54.659
      c-10.303,0-18.686-8.383-18.686-18.686c0-10.304,8.383-18.687,18.686-18.687s18.686,8.383,18.686,18.687
      C67.542,46.276,59.159,54.659,48.855,54.659z" fill="red" stroke="black" />
    </svg>`;

const myIcon = L.divIcon({
  className: 'map-market-secondary',
  html: svgTemplate,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32]
});

const Marker = ({ children, variant, ...rest }) => {
  if (variant === 'secondary') {
    return (
      <RLMarket icon={myIcon} {...rest}>
        {children}
      </RLMarket>
    );
  }
  return <RLMarket {...rest}>{children}</RLMarket>;
};

export default Marker;
