import ApiRequest from './ApiRequest';

export default class CustomerOrders extends ApiRequest {
  getList(params) {
    return this.get(`/customer_orders`, {
      qs: params
    });
  }

  getOne(id) {
    return this.get(`/customer_orders/${id}`);
  }

  updateOne(id, req) {
    return this.put(`/customer_orders/${id}`, {
      body: req
    });
  }
}
