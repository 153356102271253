import React, { useState } from 'react';
import { Field } from 'redux-form';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import TextField from './TextField';

const Adornment = ({ onClick, show }) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="toggle password visibility"
      onClick={onClick}
      tabIndex="-1">
      {show ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>
);

const FormPasswordField = (props) => {
  const [show, setShow] = useState(false);

  const { showDisable = false, InputProps = {}, source, ...rest } = props;

  return (
    <Field
      component={TextField}
      name={source}
      {...rest}
      type={show ? 'text' : 'password'}
      InputProps={{
        ...InputProps,
        endAdornment: showDisable ? null : (
          <Adornment show={show} onClick={() => setShow(!show)} />
        )
      }}
    />
  );
};

export default FormPasswordField;
