import { validateErrors } from 'services/utils';

export const validateForm = (values) => {
  const errors = {};
  const { name, region_id, locality_id, weight } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });
  errors.region_id = validateErrors(region_id, {
    require: 'Обязательное поле'
  });
  errors.locality_id = validateErrors(locality_id, {
    require: 'Обязательное поле'
  });
  if (!weight && weight !== 0) {
    errors.weight = 'Обязательное поле';
  } else if (weight < 0) {
    errors.weight = 'Целое положительно число';
  }

  return errors;
};

export const validateEditForm = (values) => {
  const errors = {};
  const { name, region_id, locality_id, weight } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  if (!region_id || !region_id.id) {
    errors.region_id = 'Обязательное поле';
  }

  if (!locality_id || !locality_id.id) {
    errors.locality_id = 'Обязательное поле';
  }

  if (!weight && weight !== 0) {
    errors.weight = 'Обязательное поле';
  } else if (weight < 0) {
    errors.weight = 'Целое положительно число';
  }
  return errors;
};
