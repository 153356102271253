import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { stopSubmit } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { PHONE_FORMAT } from 'constants/config';

import { inChildren, validateErrors } from 'services/utils';

import {
  FormCheckboxField,
  FormPhoneField,
  FormSearchGeoStreetsField,
  FormSearchSelectField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import { useCan } from 'components/Rbac';
import Screen from 'components/Screen';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};

  const { name, email, phone } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  if (email) {
    errors.email = validateErrors(email, {
      email: 'Не валидный e-mail'
    });
  }

  errors.phone = validateErrors(phone, {
    require: 'Обязательное поле',
    phone: PHONE_FORMAT.placeholder
  });

  return errors;
};

const defaultFormName = 'add_client';

class Container extends Component {
  handleCreate = async (data) => {
    const { loading, clientsActions, dispatch, history = {} } = this.props;
    if (loading) return;
    try {
      await clientsActions.createOne(data);
      history.goBack();
    } catch (err) {
      if (err && err.code === 'api/exist') {
        dispatch(stopSubmit(defaultFormName, { phone: 'Уже существует' }));
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleCreate: this.handleCreate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  clientsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

export const AddForm = (props) => {
  const {
    handleCreate,
    loading,
    formName = defaultFormName,
    footerBar = true,
    record = {}
  } = props;

  const history = useHistory();
  const back = history.goBack;

  const can_manage_user = useCan('clients:manage_user');

  return (
    <SimpleForm
      loading={loading}
      form={formName}
      onSubmit={handleCreate}
      disabled={loading}
      onBack={back}
      direction="row"
      footerBar={footerBar}
      validate={validate}
      initialValues={{
        ...record,
        send_sms: (record && record.send_sms) || 'yes'
      }}
      parse={(values) => {
        const res = {
          name: values.name || '',
          email: values.email || '',
          phone: values.phone || '',
          address_house: values.address_house || '',
          address_apartment: values.address_apartment || '',
          comment: values.comment || '',
          send_sms: values.send_sms
        };

        if (values.group) res.group_id = values.group.id;
        else res.group_id = null;

        if (values.street) {
          res.locality_id = values.street.locality_id;
          res.street_id = values.street.id;
        }
        if (can_manage_user) {
          if (values.user) {
            res.user_id = values.user.id;
          } else {
            res.user_id = null;
          }
        }
        return res;
      }}>
      <Grid item xs={12} sm={6}>
        <FormTextField source="name" label="Имя" disabled={loading} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormSearchSelectField
          source="group"
          label="Группа"
          requestPath="/client_groups"
          responseFormat={(v) => v.results}
          renderOption={(it) => it.name}
          searchBy="name"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField source="email" label="E-mail" disabled={loading} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormPhoneField source="phone" label="Тел." disabled={loading} />
      </Grid>
      <Grid item xs={12}>
        <FormSearchGeoStreetsField
          source="street"
          label="Адрес"
          disabled={loading}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField source="address_house" label="Дом" disabled={loading} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          source="address_apartment"
          label="Квартира"
          disabled={loading}
          type="number"
        />
      </Grid>
      {can_manage_user ? (
        <Grid item xs={12} sm={6}>
          <FormSearchSelectField
            source="user"
            alwaysOn
            label="Оператор"
            requestPath="/users"
            responseFormat={(v) => v.results}
            getOptionLabel={(it) =>
              it ? [it.first_name, it.last_name].join(' ') : ''
            }
            searchBy={['first_name', 'last_name']}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <FormTextField
          source="comment"
          label="Примечание"
          disabled={loading}
          multiline={true}
        />
      </Grid>
      <Grid item xs={12}>
        <FormCheckboxField
          source="send_sms"
          label="Отправлять СМС"
          disabled={loading}
          parse={(v) => (v ? 'yes' : 'no')}
          format={(v) => v === 'yes'}
        />
      </Grid>
    </SimpleForm>
  );
};

const Clients = (props) => (
  <Screen title="Добавить клиентa">
    <ContainerConnect {...props}>
      <AddForm />
    </ContainerConnect>
  </Screen>
);

export default Clients;
