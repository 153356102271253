import { actionTypes } from './actions';

const initialState = {
  list: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  one: {
    loading: false,
    data: {}
  },
  messages: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  },
  files: {
    loading: false,
    list: [],
    count: 0,
    params: {}
  }
};

const clientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LIST: {
      const { payload = {} } = action;
      const { list = {} } = state;
      return {
        ...state,
        list: {
          ...list,
          ...payload
        }
      };
    }
    case actionTypes.DELETE_ONE:
    case actionTypes.UPDATE_ONE:
    case actionTypes.CREATE_ONE:
    case actionTypes.GET_ONE: {
      const { one = {} } = state;
      const { payload = {} } = action;
      return {
        ...state,
        one: {
          ...one,
          ...payload
        }
      };
    }
    case actionTypes.GET_LIST_MESSAGES: {
      const { payload = {} } = action;
      const { messages = {} } = state;
      return {
        ...state,
        messages: {
          ...messages,
          ...payload
        }
      };
    }
    case actionTypes.GET_LIST_FILES: {
      const { payload = {} } = action;
      const { files = {} } = state;
      return {
        ...state,
        files: {
          ...files,
          ...payload
        }
      };
    }
    default:
      return state;
  }
};

export default clientsReducer;
