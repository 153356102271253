import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Btn from 'components/buttons/Btn';
import { IconBtnRefresh } from 'components/buttons/IconBtn';
import Progress from 'components/Progress';

import {
  getHistoryList as getHistoryListAction,
  hideHistoryDrawer as hideHistoryDrawerAction
} from './actions';
import HistoryList from './HistoryList';

const useStylesDrawer = makeStyles((theme) => ({
  drawerPaper: {
    width: 600,
    maxWidth: '90%',
    border: 'none'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 2),
    ...theme.mixins.toolbar
  },
  toolbarLogo: {
    margin: 0,
    fontSize: 20
  },
  listContainer: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  loadMore: {
    padding: theme.spacing(1)
  },
  loadMoreBtn: {
    width: '100%'
  }
}));

const NotFount = (props) => (
  <div
    style={{
      padding: 8,
      fontSize: 18,
      color: '#616161',
      textAlign: 'center'
    }}>
    Нет изменений
  </div>
);

const HistoryDrawer = (props) => {
  const { hideHistoryDrawerAction, getHistoryListAction } = props;

  const classes = useStylesDrawer();

  const { resourceHistory = {} } = props;
  const { historyDrawerOpened, requestType, list, showLoadMore } =
    resourceHistory;

  const get = (next) => {
    const { resourceHistory = {} } = props;
    const { params = {}, itemType, itemId } = resourceHistory;
    getHistoryListAction({
      ...params,
      ...next,
      itemType,
      itemId
    });
  };

  const getMore = () => {
    const { resourceHistory = {} } = props;
    const { params = {} } = resourceHistory;
    const { offset, limit } = params;
    get({
      requestType: 'loadmore',
      offset: Number(offset) + Number(limit)
    });
  };

  const getRefresh = () => {
    get({
      requestType: 'refresh',
      offset: 0
    });
  };

  React.useEffect(() => {
    if (historyDrawerOpened) {
      getRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyDrawerOpened]);

  return (
    <Drawer
      anchor="right"
      variant="temporary"
      onClose={hideHistoryDrawerAction}
      classes={{
        paper: clsx(classes.drawerPaper)
      }}
      open={historyDrawerOpened}>
      <div className={classes.toolbar}>
        <h1 className={classes.toolbarLogo}>История изменений</h1>
        <div>
          <IconBtnRefresh onClick={getRefresh} />
          <IconButton onClick={hideHistoryDrawerAction}>
            <ChevronRightIcon />
          </IconButton>
        </div>
      </div>
      <Divider />
      <div className={classes.listContainer}>
        {requestType === 'refresh' && <Progress variant="top" />}
        {!!list && (
          <>
            <div
              style={{
                flexGrow: 1,
                overflowY: 'auto'
              }}>
              {list.length ? <HistoryList list={list} /> : <NotFount />}
              <div>
                {requestType === 'loadmore' && <Progress />}
                {showLoadMore && (
                  <div className={classes.loadMore}>
                    <Btn
                      className={classes.loadMoreBtn}
                      color="success"
                      onClick={getMore}>
                      Показать еще
                    </Btn>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => ({
  resourceHistory: state.resourceHistory
});

export default connect(mapStateToProps, {
  hideHistoryDrawerAction,
  getHistoryListAction
})(HistoryDrawer);
