import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Checkbox from '@material-ui/core/Checkbox';

import { inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import { IconBtnDelete } from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterSearchField,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import AddUsersList from 'modals/AddUsersList';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

class Container extends Component {
  componentDidMount() {
    this.getList();
  }

  refresh = () => {
    const { groups = {} } = this.props;
    const { params = {} } = groups;
    this.getList(params);
  };

  getList = (params) => {
    const { executor_id, executorsActions } = this.props;
    executorsActions.getExecutorsUsers(executor_id, params);
  };

  handleAttach = async (user_id) => {
    const { executor_id, executorsActions } = this.props;
    try {
      await executorsActions.attachUserExecutor(executor_id, user_id);
      this.refresh();
    } catch (err) {
      console.error(err);
    }
  };

  handleUpdateExecutorUser = async (user_id, data) => {
    const { executor_id, executorsActions } = this.props;
    try {
      await executorsActions.updateUserExecutor(executor_id, user_id, data);
    } catch (err) {
      console.error(err);
    }
  };

  handleDetach = async (user_id) => {
    const { executor_id, otherActions, executorsActions } = this.props;
    const res = await otherActions.showModalConfirm({
      title: 'Редактирование',
      content: 'Удалить пользователя от дезинфектора?'
    });

    if (res) {
      try {
        await executorsActions.detachUserExecutor(executor_id, user_id);
        this.refresh();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleRefresh: this.refresh,
      handleDetach: this.handleDetach,
      handleAttach: this.handleAttach,
      handleUpdateExecutorUser: this.handleUpdateExecutorUser
    });
  }
}

const mapStateToProps = (state) => {
  const { executors = {} } = state;
  const { users = {}, one = {} } = executors;
  return {
    record: users,
    current: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorsActions: bindActionCreators(actions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleDetach, handleAttach, handleUpdateExecutorUser, current } =
    props;

  const [show, setShow] = useState(false);
  const onClose = () => {
    setShow(false);
  };
  const onSelect = (id) => {
    setShow(false);
    handleAttach(id);
  };

  const is_writable = useCan('executor_users:write');

  return (
    <div>
      <List
        {...props}
        title="Пользователи"
        toolBarInner={
          is_writable ? <AddBtn onClick={() => setShow(true)} /> : null
        }
        filter={
          <Filter>
            <FilterSearchField
              source="user_id"
              label="ID"
              searchPath="user.id"
              alwaysOn
            />
            <FilterSearchField
              source="user_login"
              label="Логин"
              searchPath="user.login"
            />
            <FilterSearchField
              source="user_email"
              label="E-mail"
              searchPath="user.email"
            />
            <FilterSearchField
              source="user_first_name"
              label="Имя"
              searchPath="user.first_name"
            />
            <FilterSearchField
              source="user_last_name"
              label="Фамилия"
              searchPath="user.last_name"
            />
            <FilterSearchField
              source="user_phone"
              label="Тел."
              searchPath="user.phone"
            />
            <FilterSelectField
              source="user_is_active"
              searchPath="user.is_active"
              label="Активен"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Активен' },
                { value: 'no', label: 'Не активен' }
              ]}
            />
          </Filter>
        }>
        <Field label="ID" source="user.id" sortable />
        <Field label="Логин" source="user.login" sortable />
        <Field label="E-mail" source="user.email" sortable />

        <Field label="Имя" source="user.first_name" sortable />
        <Field label="Фамилия" source="user.last_name" sortable />
        <Field label="Тел." source="user.phone" type="phone" />

        <Field label="Запись" source="write">
          {({ value, row }) => {
            const user_id = row && row.user && row.user.id;
            if (!user_id) return null;
            return (
              <Checkbox
                disabled={!is_writable}
                checked={value === 'yes'}
                onChange={(evt) => {
                  const checked = evt.target.checked;
                  handleUpdateExecutorUser(user_id, {
                    write: checked ? 'yes' : 'no'
                  });
                }}
              />
            );
          }}
        </Field>

        <Field
          label="Активен"
          source="user.is_active"
          format={(v) => v === 'yes'}
          type="boolean"
        />
        <Field label="Дата" source="created_at" type="date" sortable />

        <Field source="user.id" isAction>
          {({ value, row }) => {
            const user_id = (row && row.user && row.user.id) || null;
            const owner_id = (current && current.owner_id) || null;
            return (
              <React.Fragment>
                {is_writable && user_id !== owner_id ? (
                  <IconBtnDelete onClick={() => handleDetach(value)} />
                ) : null}
              </React.Fragment>
            );
          }}
        </Field>
      </List>
      <AddUsersList
        isOpen={show}
        title="Добавить пользователя"
        handleClose={onClose}
        onSelect={onSelect}
      />
    </div>
  );
};

const ShowExecutorsUsers = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ShowExecutorsUsers;
