import ApiRequest from './ApiRequest';

export default class Reasons extends ApiRequest {
  getList(params) {
    return this.get(`/reasons`, {
      qs: params
    });
  }
  getOne(id) {
    return this.get(`/reasons/${id}`);
  }
  createOne(req) {
    return this.post(`/reasons`, {
      body: req
    });
  }
  updateOne(id, req) {
    return this.put(`/reasons/${id}`, {
      body: req
    });
  }
  deleteOne(id) {
    return this.delete(`/reasons/${id}`);
  }
}
