import React from 'react';
import { useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { DATE_FORMAT } from 'constants/config';

import { concatGeoFullName, joinArrayBySeparate } from 'services/utils';

import { Btn } from 'components/buttons/Btn';
import Field, { FieldSelect, LabelField } from 'components/Field';
import { useCan } from 'components/Rbac';

import * as actions from './actions';

const OrderInfo = (props) => {
  const dispatch = useDispatch();
  const { record = {} } = props;
  const labelWidth = 150;

  const handleUpdate = async (order_id, req) => {
    try {
      await dispatch(actions.updateOne(order_id, req));
    } catch (err) {
      console.error(err);
    }
  };

  const is_writable = useCan('customer_orders:write');

  return (
    <div>
      {record && record.id ? (
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <LabelField
              width={labelWidth}
              label="Клиент:"
              value={record.customer}
              format={(customer) => {
                if (!customer) return '';
                return <div>{customer.name}</div>;
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelField
              width={labelWidth}
              label="E-mail:"
              value={record.customer}
              format={(customer) => {
                if (!customer) return '';
                return <div>{customer.email}</div>;
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelField
              width={labelWidth}
              label="Телефон клиента:"
              value={record.customer}
              format={(customer) => {
                if (!customer) return '';
                if (!customer.phone) return '';
                return (
                  <div>
                    {customer.phone && (
                      <Field type="phone" value={customer.phone} />
                    )}
                  </div>
                );
              }}
            />
          </Grid>
          {record.phone &&
          record.customer.phone &&
          record.phone !== record.customer.phone ? (
            <Grid item xs={12}>
              <LabelField
                width={labelWidth}
                label="Телефон в заявке:"
                value={record.customer}
                format={(customer) => {
                  return (
                    <div>{<Field type="phone" value={record.phone} />}</div>
                  );
                }}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <LabelField
              width={labelWidth}
              label="Заявка на:"
              type="date"
              value={record.date}
              dateFormat={DATE_FORMAT}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelField
              width={labelWidth}
              label="Дата создания:"
              type="date"
              value={record.created_at}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelField
              width={150}
              label="Адрес:"
              value={record}
              format={(record) => {
                const { street, locality, address_house, address_apartment } =
                  record;
                const target = street || locality;
                const rowValues = [
                  concatGeoFullName(target),
                  address_house ? `д.${address_house}` : '',
                  address_apartment ? `кв.${address_apartment}` : ''
                ];
                return joinArrayBySeparate(rowValues);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelField
              width={labelWidth}
              label="Статус:"
              value={record.status}
              format={(value) => {
                let label = '';
                let color = 'default';
                let style = {};
                switch (value) {
                  case 'new': {
                    label = 'Новая';
                    color = 'success';
                    break;
                  }
                  case 'processing': {
                    label = 'В работе';
                    color = 'primary';
                    break;
                  }
                  case 'closed': {
                    label = 'Закрыта';
                    color = 'default';
                    style = { color: '#919191' };
                    break;
                  }
                  default: {
                    label = '';
                    color = 'default';
                    style = {};
                  }
                }
                return (
                  <Btn
                    size="small"
                    color={color}
                    style={style}
                    disabled={!is_writable}>
                    <FieldSelect
                      value={label}
                      onChange={(it) => {
                        if (it.value !== value)
                          handleUpdate(record.id, { status: it.value });
                      }}
                      options={[
                        {
                          value: 'new',
                          label: 'Новая'
                        },
                        {
                          value: 'processing',
                          label: 'В работе'
                        },
                        {
                          value: 'closed',
                          label: 'Закрыта'
                        }
                      ]}
                    />
                  </Btn>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LabelField
              width={labelWidth}
              label="Примечание:"
              value={record.comment}
              type="longtext"
            />
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default OrderInfo;
