import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import { FormTextField } from 'components/Form';

const Adornment = () => (
  <InputAdornment position="end">
    <IconButton aria-label="search" type="submit" tabIndex="-1">
      <SearchIcon />
    </IconButton>
  </InputAdornment>
);

const FilterSearchField = (props) => {
  const {
    source,
    InputProps = {},
    searchType = 'like',
    searchPath = '',
    ...rest
  } = props;
  const additional = {};
  if (searchType || searchPath) {
    additional.format = (obj = {}) => {
      return obj.q || '';
    };

    additional.parse = (v) => {
      return {
        q: v,
        qt: searchType,
        qp: searchPath
      };
    };
  }

  return (
    <FormTextField
      name={source}
      InputProps={{
        endAdornment: <Adornment />,
        ...InputProps
      }}
      {...rest}
      {...additional}
    />
  );
};

export default React.memo(FilterSearchField);
