import ApiRequest from './ApiRequest';

export default class ClientGroups extends ApiRequest {
  getList(params) {
    return this.get(`/client_groups`, {
      qs: params
    });
  }

  getOne(id) {
    return this.get(`/client_groups/${id}`);
  }

  createOne(req) {
    return this.post(`/client_groups`, {
      body: req
    });
  }

  updateOne(id, req) {
    return this.put(`/client_groups/${id}`, {
      body: req
    });
  }

  deleteOne(id) {
    return this.delete(`/client_groups/${id}`);
  }
}
