import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

import { makeAction } from 'modules/redux-modals';

import { IconBtnClose } from 'components/buttons/IconBtn';

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: 300
  },
  modalDividers: {
    padding: 0,
    overflow: 'hidden'
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const SimpleModal = (props) => {
  const {
    title = '',
    isOpen,
    handleClose = () => null,
    confirmText = 'Да',
    cancelText = 'Нет',
    children,
    handleSuccess,
    maxWidth
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      maxWidth={maxWidth}
      // onClose={() => handleClose(false)}
      scroll={'body'}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      classes={{
        paper: classes.paper
      }}>
      <div className={classes.modalHeader}>
        <DialogTitle>{title}</DialogTitle>
        <IconBtnClose onClick={handleClose} />
      </div>
      <DialogContent
        dividers={true}
        classes={{
          dividers: classes.modalDividers
        }}>
        {children}
      </DialogContent>
      <DialogActions>
        {cancelText && handleClose && (
          <Button onClick={handleClose} color="primary">
            {cancelText}
          </Button>
        )}
        {confirmText && handleSuccess && (
          <Button onClick={handleSuccess} color="primary" variant="contained">
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const show = makeAction(SimpleModal);
export default SimpleModal;
