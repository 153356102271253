import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';

import Grid from '@material-ui/core/Grid';

import { MATERIAL_UNIT } from 'constants/config';

import { convertAmountToUnits, inChildren, toBills } from 'services/utils';
import { isNumeric, isPrice } from 'services/validation';

import {
  FormDateField,
  FormSearchSelectField,
  FormSelectField,
  FormTextField,
  SimpleForm
} from 'components/Form';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};
  const { place, material, direction, value = 0, price } = values;

  if (!price && price !== 0) {
    errors.price = 'Укажите цену';
  } else if (!isPrice(price)) {
    errors.price = 'Невалидная цена';
  }

  if (!place) {
    errors.place = 'Укажите дислокацию';
  }

  if (!material) {
    errors.material = 'Укажите материал';
  }

  if (!value) {
    errors.value = 'Укажите количество';
  } else if (!isNumeric(value)) {
    errors.value = 'Только положительное число';
  } else if (value < 0) {
    errors.value = 'Только положительное число';
  }

  if (!direction) {
    errors.direction = 'Укажите направление';
  }

  return errors;
};

const formName = 'EDIT_WAREHOUSE';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = { material: null };
  }

  componentDidMount() {
    this.handleGetOne();
  }

  componentDidUpdate(prev) {
    const { record } = this.props;
    if (record !== prev.record && record) {
      this.setState({
        material: record.material
      });
    }
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      actions,
      history = {}
    } = this.props;
    try {
      await actions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const { actions, record = {}, loading, history } = this.props;
    if (loading) return;
    try {
      await actions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };

  handleDelete = async () => {
    const { actions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Вы уверены что хотите удалить запись?'
      })
    );

    if (res) {
      try {
        await actions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  handleChangeMaterial = async (mat) => {
    const { dispatch } = this.props;
    dispatch(change(formName, 'value', 0));
    this.setState({ material: mat });
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      state: { ...this.state },
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete,
      handleChangeMaterial: this.handleChangeMaterial
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    state,
    handleUpdate,
    record = {},
    loading
    // handleChangeMaterial,
  } = props;

  const itemReceived = record && record.id;
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `${record.material?.name || ''}, ${record.place.name}`;
  }

  const material = state.material || null;
  const unit = material ? material.unit : null;
  const materialUnit = unit && MATERIAL_UNIT[unit] ? MATERIAL_UNIT[unit] : null;
  return (
    <Screen title={title}>
      {itemReceived ? (
        <SimpleForm
          loading={loading}
          form={formName}
          onSubmit={handleUpdate}
          disabled={loading}
          direction="row"
          onBack={back}
          validate={validate}
          initialValues={{
            ...record,
            value: record.material
              ? convertAmountToUnits(record.value, record.material.unit)
              : '',
            price: record && record.price >= 0 ? toBills(record.price) : '0'
          }}
          parse={(values) => {
            const res = {
              description: values.description || ''
            };
            return res;
          }}>
          <Grid item xs={12} sm={6}>
            <FormSearchSelectField
              source="place"
              label="Дислокация"
              requestPath="/executor_places"
              responseFormat={(v) => v.results}
              renderOption={(it) => it.name}
              searchBy="name"
              disabled={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSearchSelectField
              source="material"
              label="Материал"
              requestPath="/materials"
              responseFormat={(v) => v.results}
              disabled={true}
              searchBy="name"
              // onChange={handleChangeMaterial}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormSelectField
              source="direction"
              label="Тип"
              disabled={true}
              options={[
                { value: 'INCOMING', label: 'Приход' },
                { value: 'OUTGOING', label: 'Расход' },
                { value: 'MOVE', label: 'Перемещение' }
              ]}
            />
          </Grid>
          {record?.direction === 'MOVE' && record.dst_place_id ? (
            <Grid item xs={12} sm={6}>
              <FormSearchSelectField
                source="dst_place_id"
                disabled={true}
                label="Дислокация куда"
                requestPath="/executor_places"
                responseFormat={(v) => v.results}
                renderOption={(it) => it.name}
                searchBy="name"
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <FormTextField
              source="value"
              label={`Кол-во ${materialUnit ? materialUnit.short : ''}`}
              disabled={true}
              type="number"
              inputProps={{
                step: '1'
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormDateField
              label="Дата"
              source="date"
              dateType="date"
              disabled={true}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormTextField
              source="price"
              label="Цена"
              disabled={true}
              type="number"
              inputProps={{
                step: '0.01'
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              source="description"
              label="Описание"
              disabled={loading}
              multiline={true}
              maxLength="65535"
            />
          </Grid>
        </SimpleForm>
      ) : null}
    </Screen>
  );
};

const Edit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default Edit;
