import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const DropMenu = ({ component: Component = null, items = [], ...rest }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!Component) return null;

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <div>
        {items.map((item, index) => {
          return (
            <MenuItem
              key={index}
              aria-label="logout"
              color="inherit"
              onClick={() => {
                handleMenuClose();
                item.onClick && item.onClick();
              }}>
              {item.title}
            </MenuItem>
          );
        })}
      </div>
    </Menu>
  );

  return (
    <>
      <Component {...rest} onClick={handleProfileMenuOpen} />
      {renderMenu}
    </>
  );
};

export default DropMenu;
