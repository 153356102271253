import { STATS_GET } from 'store/actions/statsActions';

const initialState = {};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATS_GET: {
      const { payload = {} } = action;
      return {
        ...state,
        ...payload
      };
    }
    default:
      return state;
  }
};

export default statsReducer;
