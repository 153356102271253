import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import PhoneIcon from '@material-ui/icons/Phone';

import { inChildren } from 'services/utils';

import ShowClientFiles from 'resources/Clients/ShowClientFiles';
import { ShowHistoryBtn } from 'resources/ResourceHistory';
import Btn, {
  BackBtn,
  CopyBtn,
  DeleteBtn,
  EditBtn
} from 'components/buttons/Btn';
import FooterBar from 'components/FooterBar';
import NotFoundItems from 'components/NotFoundItems';
import Progress from 'components/Progress';
import Rbac from 'components/Rbac';
import Screen from 'components/Screen';
import Tabs, { Tab } from 'components/Tabs';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';
import ModalOrderExecutorUsersCall from './ModalOrderExecutorUsersCall';
import ShowClientMessages from './ShowClientMessages';
import ShowOrderFiles from './ShowOrderFiles';
import ShowOrderInfo from './ShowOrderInfo';
import ShowOrderMessages from './ShowOrderMessages';
import ShowOrderWarehouse from './ShowOrderWarehouse';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notFound: false
    };
  }

  componentDidMount() {
    this.handleGetOne();
  }

  componentWillUnmount() {
    const { ordersActions } = this.props;
    ordersActions.clearOne();
  }

  handleGetOne = async () => {
    const { match: { params: { id } = {} } = {}, ordersActions } = this.props;
    try {
      await ordersActions.getOne(id);
    } catch (err) {
      this.setState({ notFound: true });
    }
  };

  handleDelete = async () => {
    const { ordersActions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить заявку?'
      })
    );

    if (res) {
      try {
        await ordersActions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  handleShowCopy = () => {
    const { history, location, basePath, record = {} } = this.props;
    if (!record || !record.id) return;
    let from = location.pathname;
    if (location.search) from += location.search;
    history.push({
      pathname: `${basePath}/add`,
      state: { from, copy_id: record.id }
    });
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      notFound: this.state.notFound,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete,
      handleShowCopy: this.handleShowCopy
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    record: one.data,
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ordersActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleDelete,
    handleShowCopy,
    record = {},
    match = {},
    loading,
    basePath = '/',
    notFound,
    resourceName
  } = props;

  const [tab, setTab] = React.useState(match.url);

  const itemReceived = !!(record && record.id);
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Заявка №: ${record.id}`;
  }

  const [modalExecutorCallParams, setModalsExecutorParams] = React.useState({
    opened: false,
    data: {}
  });

  const showModalCreateCall = (executorId) => {
    setModalsExecutorParams({
      opened: true,
      data: {
        order: record,
        executorId
      }
    });
  };

  const hideModalCreateCall = () => {
    setModalsExecutorParams({
      opened: false,
      data: {}
    });
  };

  return (
    <Screen title={title}>
      {loading && <Progress />}
      {notFound && <NotFoundItems message="Заявка не найдена" />}
      {itemReceived && (
        <Tabs key={record.id} value={tab} onChange={(path) => setTab(path)}>
          <Tab label="Инфо" eventKey={match.url}>
            <Box p={2}>
              {itemReceived && (
                <Grid
                  container
                  spacing={1}
                  wrap="wrap"
                  alignItems="center"
                  justifyContent="flex-end">
                  {record && record.executor ? (
                    <Rbac operation="orders:phone_call">
                      <Grid item>
                        <Btn
                          onClick={() =>
                            showModalCreateCall(record.executor.id)
                          }
                          ariaLabel="call"
                          startIcon={<PhoneIcon fontSize="inherit" />}>
                          Создать звонок
                        </Btn>
                      </Grid>
                    </Rbac>
                  ) : null}
                  <Rbac operation="orders:create">
                    <Grid item>
                      <CopyBtn onClick={handleShowCopy}>Дубликат</CopyBtn>
                    </Grid>
                  </Rbac>
                  <Grid item>
                    <ShowHistoryBtn itemType="orders" itemId={record.id} />
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box p={2}>
              <ShowOrderInfo {...props} />
            </Box>
            <FooterBar>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <BackBtn onClick={back}>Назад</BackBtn>
                  </Grid>
                  <Grid item>
                    {itemReceived && (
                      <Rbac operation="orders:update">
                        <EditBtn to={`${basePath}/edit/${record.id}`}>
                          Редактировать
                        </EditBtn>
                      </Rbac>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {itemReceived && (
                  <Rbac operation="orders:delete">
                    <DeleteBtn onClick={handleDelete}>Удалить</DeleteBtn>
                  </Rbac>
                )}
              </Grid>
            </FooterBar>
          </Tab>
          <Tab label="Материалы" eventKey={`${match.url}/warehouse`}>
            <Rbac operation="warehouse_order:read">
              <ShowOrderWarehouse order={record} resourceName={resourceName} />
              <FooterBar>
                <Grid item>
                  <BackBtn onClick={back}>Назад</BackBtn>
                </Grid>
              </FooterBar>
            </Rbac>
          </Tab>
          <Tab label="Сообщения" eventKey={`${match.url}/messages`}>
            <Rbac operation="messages:read">
              <ShowOrderMessages order={record} resourceName={resourceName} />
              <FooterBar>
                <Grid item>
                  <BackBtn onClick={back}>Назад</BackBtn>
                </Grid>
              </FooterBar>
            </Rbac>
          </Tab>
          {record?.client ? (
            <Tab
              label="Заметки клиент"
              eventKey={`${match.url}/client_messages`}>
              <Rbac operation="client_messages:read">
                <ShowClientMessages
                  client={record.client}
                  resourceName={resourceName}
                />
                <FooterBar>
                  <Grid item>
                    <BackBtn onClick={back}>Назад</BackBtn>
                  </Grid>
                </FooterBar>
              </Rbac>
            </Tab>
          ) : null}
          <Tab label="Документы" eventKey={`${match.url}/files`}>
            <Rbac operation="files:read">
              <ShowOrderFiles order={record} resourceName={resourceName} />
              <FooterBar>
                <Grid item>
                  <BackBtn onClick={back}>Назад</BackBtn>
                </Grid>
              </FooterBar>
            </Rbac>
          </Tab>
          {record?.client ? (
            <Tab
              label="Документы клиент"
              eventKey={`${match.url}/client-files`}>
              <Rbac operation="files:read">
                <ShowClientFiles
                  client={record.client}
                  resourceName="clients"
                />
                <FooterBar>
                  <Grid item>
                    <BackBtn onClick={back}>Назад</BackBtn>
                  </Grid>
                </FooterBar>
              </Rbac>
            </Tab>
          ) : null}
        </Tabs>
      )}
      <ModalOrderExecutorUsersCall
        isOpen={modalExecutorCallParams?.opened}
        data={modalExecutorCallParams?.data}
        handleClose={hideModalCreateCall}
      />
    </Screen>
  );
};

const OrdersShow = (props) => {
  const { location } = props;
  const { key } = location;
  return (
    <ContainerConnect key={key} {...props}>
      <View />
    </ContainerConnect>
  );
};

export default OrdersShow;
