import React from 'react';
import clsx from 'clsx';
import { Field, submit } from 'redux-form';

import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2,
    height: 25
  },
  chipDisabled: {
    opacity: 0.5
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};
const renderOptionDefault = (opt) => (
  <MenuItem key={opt.value} value={opt.value}>
    {opt.label}
  </MenuItem>
);

const MultipleSelect = ({ input, meta, ...attr }, ...t) => {
  const {
    multiple,
    options = [],
    helperText = '',
    label = '',
    disabled,
    submitOnChange,
    renderOption = renderOptionDefault,
    ...restAttr
  } = attr;

  const dispatch = meta.dispatch;
  const form = meta.form;
  const visibleError = meta.touched && meta.error;
  const classes = useStyles(restAttr);

  const targetValue =
    multiple && !Array.isArray(input.value) ? [] : input.value;

  const handleChange = (event) => {
    if (disabled) return;
    input.onChange(event.target.value);
    setTimeout(() => {
      if (submitOnChange) dispatch(submit(form));
    }, 0);
  };

  return (
    <FormControl fullWidth error={!!visibleError}>
      <InputLabel>{label}</InputLabel>
      <Select
        fullWidth
        multiple={multiple}
        disabled={disabled}
        {...restAttr}
        {...input}
        onChange={handleChange}
        value={targetValue}
        input={<Input />}
        renderValue={(selected) => {
          if (!multiple) {
            const opt = options.find((opt) => opt.value === selected);
            return opt ? opt.label : '';
          }
          if (!selected || !selected.length) return '';
          return (
            <div className={classes.chips}>
              {selected.map((it) => {
                const opt = options.find((opt) => opt.value === it);
                if (opt)
                  return (
                    <Chip
                      key={opt.value}
                      label={opt.label}
                      className={clsx(classes.chip, {
                        chipDisabled: disabled
                      })}
                      // onDelete={()=>handleDelete(it)}
                      variant="outlined"
                    />
                  );
                return null;
              })}
            </div>
          );
        }}
        MenuProps={MenuProps}>
        {options.map((it) => renderOption(it))}
      </Select>
      {(visibleError || helperText) && (
        <FormHelperText>{visibleError || helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

const FormSelectField = (props) => {
  const { source, ...rest } = props;
  return <Field component={MultipleSelect} name={source} {...rest} />;
};

export default FormSelectField;
