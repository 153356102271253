import React from 'react';
import { useSelector, useStore } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import { makeRoutePath, useMakeUrlProjectId } from 'services/projectUtils';

import BookkeeperOrdersChanged from 'resources/BookkeeperOrdersChanged';
import BookkeeperOrdersNew from 'resources/BookkeeperOrdersNew';
import ClientGroups from 'resources/ClientGroups';
import Clients from 'resources/Clients';
import CustomersOrders from 'resources/CustomersOrders';
import ExecutorPlaces from 'resources/ExecutorPlaces';
import Executors from 'resources/Executors';
import ExecutorsAvailable from 'resources/ExecutorsAvailable';
import GeoDistricts from 'resources/GeoDistricts';
import GeoLocality from 'resources/GeoLocality';
import GeoRegions from 'resources/GeoRegions';
import GeoStreets from 'resources/GeoStreets';
import Groups from 'resources/Groups';
import Materials from 'resources/Materials';
import Methods from 'resources/Methods';
import Notifications from 'resources/Notifications';
import Orders from 'resources/Orders';
import OrdersHistory from 'resources/OrdersHistory';
import OrdersMap from 'resources/OrdersMap';
import PayTypes from 'resources/PayTypes';
import Projects from 'resources/Projects';
import Reasons from 'resources/Reasons';
import Reminders from 'resources/Reminders';
import Reports from 'resources/Reports';
import ResourceHistory from 'resources/ResourceHistory';
import Schedule from 'resources/Schedule';
import Users from 'resources/Users';
import Warehouse from 'resources/Warehouse';
import WarehouseBalances from 'resources/WarehouseBalances';
import ErrorBoundary from 'components/ErrorBoundary';
import Rbac from 'components/Rbac';

export const Resource = (conf) => {
  const makeUrlProjectId = useMakeUrlProjectId();
  const project_id = useSelector((state) => state.auth.project_id);
  const { layouts = {}, ...restConf } = conf;
  const { disabled, basePath = '/', isRoot } = restConf;

  if (disabled) return null;

  const hasList = !!layouts.List;
  const hasAdd = !!layouts.Add;
  const hasEdit = !!layouts.Edit;
  const hasShow = !!layouts.Show;

  if (!hasList && !hasAdd && !hasEdit && !hasShow) return null;
  if (!basePath) return null;

  const routePath = isRoot ? basePath : makeRoutePath(basePath);
  const nestedUrl = isRoot ? basePath : makeUrlProjectId(basePath);

  return (
    <Switch key={project_id}>
      {hasList && (
        <Route
          path={routePath}
          exact={true}
          render={(props) => (
            <ErrorBoundary>
              <layouts.List {...props} {...restConf} basePath={nestedUrl} />
            </ErrorBoundary>
          )}
        />
      )}
      {hasAdd && (
        <Route
          path={`${routePath}/add`}
          render={(props) => (
            <ErrorBoundary>
              <layouts.Add {...props} {...restConf} basePath={nestedUrl} />
            </ErrorBoundary>
          )}
        />
      )}
      {hasEdit && (
        <Route
          path={`${routePath}/edit/:id`}
          render={(props) => (
            <ErrorBoundary>
              <layouts.Edit {...props} {...restConf} basePath={nestedUrl} />
            </ErrorBoundary>
          )}
        />
      )}
      {hasShow && (
        <Route
          path={`${routePath}/show/:id`}
          render={(props) => (
            <ErrorBoundary>
              <layouts.Show {...props} {...restConf} basePath={nestedUrl} />
            </ErrorBoundary>
          )}
        />
      )}
    </Switch>
  );
};

const resources = [
  {
    operation: 'projects:read',
    key: 'projects',
    basePath: '/projects',
    resourceName: 'projects',
    layouts: Projects
  },
  {
    operation: 'client_groups:read',
    key: 'clientGroups',
    basePath: '/client-groups',
    resourceName: 'clientGroups',
    layouts: ClientGroups
  },
  {
    operation: 'clients:read',
    key: 'clients',
    basePath: '/clients',
    resourceName: 'clients',
    layouts: Clients
  },
  {
    operation: 'groups:read',
    key: 'groups',
    basePath: '/groups',
    resourceName: 'groups',
    layouts: Groups
  },
  {
    operation: 'users:read',
    key: 'users',
    basePath: '/users',
    resourceName: 'users',
    layouts: Users
  },
  {
    operation: 'reasons:read',
    key: 'reasons',
    basePath: '/reasons',
    resourceName: 'reasons',
    layouts: Reasons
  },
  {
    operation: 'pay_types:read',
    key: 'payTypes',
    basePath: '/pay-types',
    resourceName: 'payTypes',
    layouts: PayTypes
  },
  {
    operation: 'methods:read',
    key: 'methods',
    basePath: '/methods',
    resourceName: 'methods',
    layouts: Methods
  },
  {
    operation: 'materials:read',
    key: 'materials',
    basePath: '/materials',
    resourceName: 'materials',
    layouts: Materials
  },
  {
    operation: 'executors:read',
    key: 'executors',
    basePath: '/executors',
    resourceName: 'executors',
    layouts: Executors
  },
  {
    operation: 'places:read',
    key: 'executor_places',
    basePath: '/executor_places',
    resourceName: 'executorPlaces',
    layouts: ExecutorPlaces
  },
  {
    operation: 'available:read',
    key: 'executors_available',
    basePath: '/executors_available',
    resourceName: 'executorsAvailable',
    layouts: ExecutorsAvailable
  },
  {
    operation: 'orders:read',
    key: 'orders',
    basePath: '/orders',
    resourceName: 'orders',
    layouts: Orders
  },
  {
    operation: 'orders:read',
    key: 'ordersMap',
    basePath: '/orders-map',
    resourceName: 'ordersMap',
    layouts: OrdersMap
  },
  {
    operation: 'orders:read',
    key: 'ordersHistory',
    basePath: '/orders-history',
    resourceName: 'ordersHistory',
    layouts: OrdersHistory
  },
  {
    operation: 'customer_orders:read',
    key: 'customers-orders',
    basePath: '/customers-orders',
    resourceName: 'customersOrders',
    layouts: CustomersOrders
  },
  {
    operation: 'orders:bookkeeping',
    key: 'bookkeeper-orders-new',
    basePath: '/bookkeeper-orders-new',
    resourceName: 'bookkeeperOrdersNew',
    layouts: BookkeeperOrdersNew
  },
  {
    operation: 'orders:bookkeeping',
    key: 'bookkeeper-orders-changed',
    basePath: '/bookkeeper-orders-changed',
    resourceName: 'bookkeeperOrdersChanged',
    layouts: BookkeeperOrdersChanged
  },
  {
    operation: 'orders:read',
    key: 'schedule',
    basePath: '/schedule',
    resourceName: 'schedule',
    layouts: Schedule
  },
  {
    key: 'notifications',
    basePath: '/notifications',
    resourceName: 'notificationsUser',
    layouts: Notifications
  },
  {
    key: 'reminders',
    basePath: '/reminders',
    resourceName: 'reminders',
    layouts: Reminders
  },
  {
    key: 'reports',
    basePath: '/reports',
    resourceName: 'reports',
    layouts: Reports
  },
  {
    key: 'resourceHistory',
    resourceName: 'resourceHistory',
    layouts: ResourceHistory
  },
  {
    operation: 'warehouse:read',
    key: 'warehouse',
    basePath: '/warehouse',
    resourceName: 'warehouse',
    layouts: Warehouse
  },
  {
    operation: 'warehouse:read',
    key: 'warehouse-balances',
    basePath: '/warehouse-balances',
    resourceName: 'warehouseBalances',
    layouts: WarehouseBalances
  },
  {
    operation: 'geo:read',
    key: 'geo-regions',
    basePath: '/geo-regions',
    resourceName: 'geoRegions',
    layouts: GeoRegions
  },
  {
    operation: 'geo:read',
    key: 'geo-districts',
    basePath: '/geo-districts',
    resourceName: 'geoDistricts',
    layouts: GeoDistricts
  },
  {
    operation: 'geo:read',
    key: 'geo-locality',
    basePath: '/geo-locality',
    resourceName: 'geoLocality',
    layouts: GeoLocality
  },
  {
    operation: 'geo:read',
    key: 'geo-streets',
    basePath: '/geo-streets',
    resourceName: 'geoStreets',
    layouts: GeoStreets
  }
];

const Routes = () => {
  const reducers = resources.reduce((acc, resource) => {
    const { resourceName, layouts = {} } = resource;
    const { reducer } = layouts;
    if (resourceName && reducer) {
      acc[resourceName] = reducer;
      return acc;
    }
    return acc;
  }, {});

  const store = useStore();
  store.asyncReducers = reducers;
  store.injectReducer();

  return (
    <>
      {resources.map((res) => {
        const { operation, anyOf, everyOf, key, ...rest } = res;
        if (operation || anyOf || everyOf) {
          return (
            <Rbac
              key={key}
              operation={operation}
              anyOf={anyOf}
              everyOf={everyOf}>
              <Resource {...rest} />
            </Rbac>
          );
        }
        return <Resource key={key} {...rest} />;
      })}
      {}
    </>
  );
};

export default React.memo(Routes);
