import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import { global, theme } from 'styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import {
  MuiThemeProvider,
  StylesProvider,
  withStyles
} from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { getProjectId } from 'services/projectUtils';
import { inChildren } from 'services/utils';
import Notifier from 'modules/notifier';
import Modals from 'modules/redux-modals';

import { status, updateProjectId } from 'store/actions/authActions';

import Login from 'resources/Login';
import PrivacyScreen from 'screens/Privacy';
import Dashboard from 'components/Dashboard';
import Progress from 'components/Progress';
import UpdateMessage from 'components/UpdateMessage';

import 'moment/locale/ru';

moment.locale('ru');

class App extends Component {
  componentDidMount() {
    const { status } = this.props;
    status();
  }

  render() {
    const { children, checkAuth, admin, project_id } = this.props;
    if (!checkAuth) return <Progress variant="over" />;
    return inChildren(children, {
      admin,
      checkAuth,
      project_id
    });
  }
}

const mapStateToProps = (state) => ({
  ...state.auth
});

const AppContainer = connect(mapStateToProps, {
  status
})(App);

const MainApp = (props) => {
  const { admin, checkAuth, project_id } = props;
  const dispatch = useDispatch();

  let isHome = useRouteMatch('/');
  isHome = isHome && isHome.isExact;

  let location = useLocation();
  React.useEffect(() => {
    const target_project_id = getProjectId();
    if (target_project_id && Number(target_project_id) !== project_id) {
      dispatch(updateProjectId(target_project_id));
    }
  }, [location, project_id, dispatch]);

  if (!admin && checkAuth) return <Redirect to="/login" />;
  if (!admin) return null;

  return (
    <>
      <UpdateMessage />
      <Dashboard>
        {isHome && project_id ? (
          <Redirect to={`/project/${project_id}/schedule`} />
        ) : null}
      </Dashboard>
    </>
  );
};

export default withStyles(global)(() => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate={true}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}>
        <MuiPickersUtilsProvider
          utils={MomentUtils}
          libInstance={moment}
          locale="ru">
          <Switch>
            <Route path="/privacy">
              <PrivacyScreen />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <AppContainer>
              <MainApp />
            </AppContainer>
          </Switch>
        </MuiPickersUtilsProvider>
        <Notifier />
        <Modals />
      </SnackbarProvider>
    </MuiThemeProvider>
  </StylesProvider>
));
