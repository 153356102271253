import { useCallback, useEffect, useRef, useState } from 'react';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useSafeState(initialState) {
  const [state, setState] = useState(initialState);

  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);
  const safeSetState = useCallback(
    (args) => mountedRef.current && setState(args),
    [mountedRef, setState]
  );

  return [state, safeSetState];
}
