import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';

import { inChildren } from 'services/utils';

import {
  IconBtnAdd,
  IconBtnDelete,
  IconBtnEdit
} from 'components/buttons/IconBtn';
import Form from 'components/Form';
import Progress from 'components/Progress';
import { useCan } from 'components/Rbac';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';
import ModalScheduleDialog from './ModalScheduleDialog';

class Container extends Component {
  componentDidMount() {
    this.getList();
  }

  refresh = () => {
    const { groups = {} } = this.props;
    const { params = {} } = groups;
    this.getList(params);
  };

  getList = (params) => {
    const { executor_id, executorsActions } = this.props;
    executorsActions.getExecutorsSchedule(executor_id, {
      order: '-id',
      ...params
    });
  };

  handleCreateSchedule = async (params) => {
    const { executor_id, executorsActions } = this.props;
    return executorsActions.createExecutorSchedule(executor_id, params);
  };

  handleUpdateSchedule = async (schedule_id, params) => {
    const { executor_id, executorsActions } = this.props;
    return executorsActions.updateExecutorSchedule(
      executor_id,
      schedule_id,
      params
    );
  };

  handleDeleteSchedule = async (schedule_id) => {
    const { executor_id, otherActions, executorsActions } = this.props;
    const res = await otherActions.showModalConfirm({
      title: 'Удаление',
      content: 'Удалить?'
    });

    if (res) {
      try {
        await executorsActions.deleteExecutorSchedule(executor_id, schedule_id);
        this.refresh();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleRefresh: this.refresh,
      handleUpdateSchedule: this.handleUpdateSchedule,
      handleCreateSchedule: this.handleCreateSchedule,
      handleDeleteSchedule: this.handleDeleteSchedule
    });
  }
}

const mapStateToProps = (state) => {
  const { executors = {} } = state;
  const { schedule = {} } = executors;
  return {
    record: schedule
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorsActions: bindActionCreators(actions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const useStyles = makeStyles((theme) => ({
  labelContainer: {
    width: 150
  },
  range: {
    paddingRight: 10
  }
}));

const View = (props) => {
  const {
    handleRefresh,
    handleCreateSchedule,
    handleUpdateSchedule,
    handleDeleteSchedule,
    record
  } = props;

  const { loading, list = [] } = record;

  const [modalProps, setModalProps] = useState(false);

  const showAdd = (week_day) => {
    setModalProps({
      initialValues: null,
      handleSubmit: async (props) => {
        try {
          await handleCreateSchedule({
            week_day,
            ...props
          });
          setModalProps(false);
          handleRefresh();
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const showEdit = (id, item) => {
    setModalProps({
      initialValues: item,
      handleSubmit: async (params) => {
        try {
          await handleUpdateSchedule(id, params);
          setModalProps(false);
          handleRefresh();
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const onClose = (props) => {
    setModalProps(false);
  };

  const classes = useStyles();

  const [days, setDays] = React.useState([]);

  React.useEffect(() => {
    const week = moment.weekdays(true);
    const days = week.map((it, i) => {
      return {
        label: it,
        num: i + 1
      };
    });
    setDays(days);
  }, []);

  const schedule = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: []
  };

  list.forEach((it) => {
    const { week_day } = it;
    schedule[week_day].push(it);
  });

  const is_writable = useCan('schedule:write');

  return (
    <Form className={classes.form}>
      {loading && <Progress variant="over" />}
      <Box p={2}>
        <Grid container direction="column" spacing={2}>
          {days.map((day) => {
            const ranges = schedule[day.num];
            return (
              <Grid key={day.num} item container>
                <Grid item className={classes.labelContainer}>
                  {day.label}
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm
                  container
                  spacing={1}
                  alignItems="flex-end">
                  <Grid item>
                    {ranges.map((range) => {
                      return (
                        <Grid key={range.id} container alignItems="center">
                          <Grid item className={classes.range}>
                            {`${range.time_from} - ${range.time_to}`}
                          </Grid>
                          <Grid item>
                            {is_writable ? (
                              <IconBtnEdit
                                size="small"
                                onClick={() => showEdit(range.id, range)}
                              />
                            ) : null}
                            {is_writable ? (
                              <IconBtnDelete
                                size="small"
                                onClick={() => handleDeleteSchedule(range.id)}
                              />
                            ) : null}
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Grid item>
                    {is_writable ? (
                      <IconBtnAdd
                        size="small"
                        onClick={() => showAdd(day.num)}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <ModalScheduleDialog
        isOpen={!!modalProps}
        title="Время работы"
        {...modalProps}
        handleClose={onClose}
      />
    </Form>
  );
};

const ShowExecutorsSchedule = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ShowExecutorsSchedule;
