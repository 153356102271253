import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';

import { AVAILABLE_TYPES } from 'constants/config';

import { inChildren } from 'services/utils';

import { AddBtn } from 'components/buttons/Btn';
import {
  IconBtnCopy,
  IconBtnDelete,
  IconBtnEdit
} from 'components/buttons/IconBtn';
import Field from 'components/Field';
import Filter, {
  FilterDateField,
  FilterDateRangeField,
  FilterSelectField
} from 'components/Filter';
import List from 'components/List';
import { useCan } from 'components/Rbac';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';
import ModalAvailableDialog from './ModalAvailableDialog';

class Container extends Component {
  componentDidMount() {
    this.getList();
  }

  refresh = () => {
    const { record = {} } = this.props;
    const { params = {} } = record;
    this.getList(params);
  };

  getList = (params) => {
    const { executor_id, executorsActions } = this.props;
    executorsActions.getExecutorsAvailable(executor_id, params);
  };

  handleCreateAvailable = async (params) => {
    const { executor_id, executorsActions } = this.props;
    return executorsActions.createExecutorAvailable(executor_id, params);
  };

  handleUpdateAvailable = async (available_id, params) => {
    const { executor_id, executorsActions } = this.props;
    return executorsActions.updateExecutorAvailable(
      executor_id,
      available_id,
      params
    );
  };

  handleDeleteAvailable = async (available_id) => {
    const {
      executor_id,
      otherActions,
      executorsActions,
      record = {}
    } = this.props;
    const res = await otherActions.showModalConfirm({
      title: 'Удаление',
      content: 'Удалить?'
    });

    if (res) {
      try {
        const { params = {}, list = [] } = record;
        await executorsActions.deleteExecutorAvailable(
          executor_id,
          available_id
        );
        const { offset = 0, limit = 0 } = params;
        if (offset && list.length === 1) {
          params.offset = offset - limit;
        }
        this.getList(params);
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      getList: this.getList,
      handleRefresh: this.refresh,
      handleUpdateAvailable: this.handleUpdateAvailable,
      handleCreateAvailable: this.handleCreateAvailable,
      handleDeleteAvailable: this.handleDeleteAvailable
    });
  }
}

const mapStateToProps = (state) => {
  const { executors = {} } = state;
  const { available = {} } = executors;
  return {
    record: available
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  executorsActions: bindActionCreators(actions, dispatch),
  otherActions: bindActionCreators(
    {
      showModalConfirm
    },
    dispatch
  )
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const {
    handleRefresh,
    handleCreateAvailable,
    handleUpdateAvailable,
    handleDeleteAvailable
  } = props;

  const [modalProps, setModalProps] = useState(false);

  const showAdd = () => {
    setModalProps({
      initialValues: null,
      handleSubmit: async (props) => {
        try {
          await handleCreateAvailable(props);
          setModalProps(false);
          handleRefresh();
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const showEdit = (id, item) => {
    setModalProps({
      initialValues: item,
      handleSubmit: async (params) => {
        try {
          await handleUpdateAvailable(id, params);
          setModalProps(false);
          handleRefresh();
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const showCopy = (item) => {
    setModalProps({
      initialValues: {
        ...item,
        date_from: moment(item.date_from).add(7, 'd'),
        date_to: moment(item.date_to).add(7, 'd')
      },
      handleSubmit: async (params) => {
        try {
          await handleCreateAvailable(params);
          setModalProps(false);
          handleRefresh();
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const onClose = (props) => {
    setModalProps(false);
  };

  const is_writable = useCan('available:write');

  return (
    <div>
      <List
        {...props}
        toolBarInner={is_writable ? <AddBtn onClick={() => showAdd()} /> : null}
        filter={
          <Filter>
            <FilterDateField
              source="date_from"
              label="Дата, с"
              searchType="from"
              alwaysOn
            />
            <FilterDateField
              source="date_to"
              label="Дата, по"
              searchType="to"
              alwaysOn
            />
            <FilterDateRangeField
              source="created_at"
              labels={['Создан,с', 'Создан,по']}
              label="Дата создания"
              maxWidth={300}
            />
            <FilterSelectField
              source="is_available"
              label="Доступен"
              options={[
                { value: '', label: 'Все' },
                { value: 'yes', label: 'Активен' },
                { value: 'no', label: 'Не активен' }
              ]}
            />
          </Filter>
        }>
        <Field label="ID" source="id" sortable />
        <Field
          label="Доступен"
          source="is_available"
          format={(v) => (v === 'yes' ? 'Да' : 'Нет')}
        />
        <Field label="Причина" source="available_type">
          {({ value, row }) => {
            if (row.is_available === 'yes') return '';
            if (!value) return 'Не указана';
            return AVAILABLE_TYPES[value];
          }}
        </Field>
        {/* <Field label="Дата, с" source="date_from" type="date" sortable/>
        <Field label="Дата, по" source="date_to" type="date" sortable/> */}
        <Field label="Дата" type="daterange" source="*" sortable="date_from" />
        <Field
          label="Дата, создания"
          source="created_at"
          type="date"
          sortable
        />
        <Field source="id" isAction>
          {({ value, row }) => {
            return (
              <React.Fragment>
                {is_writable ? (
                  <IconBtnCopy onClick={() => showCopy(row)} />
                ) : null}
                {is_writable ? (
                  <IconBtnEdit onClick={() => showEdit(value, row)} />
                ) : null}
                {is_writable ? (
                  <IconBtnDelete onClick={() => handleDeleteAvailable(value)} />
                ) : null}
              </React.Fragment>
            );
          }}
        </Field>
      </List>
      <ModalAvailableDialog
        isOpen={!!modalProps}
        title={(modalProps && modalProps.title) || 'Указать отсутствие'}
        {...modalProps}
        handleClose={onClose}
      />
    </div>
  );
};

const ShowExecutorsAvailable = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default ShowExecutorsAvailable;
