import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';

import Map, {
  constants as MAP_CONSTANTS,
  MapAddressesList,
  Marker,
  utils as mapUtils
} from 'components/maps';

import OrderMapMarker from './OrderMapMarker';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    height: '400px'
  }
}));

const OrderMapField = ({ input = {}, meta = {}, ...attributes }) => {
  const classes = useStyles();
  const { onChange, value } = input;
  const { options = [], zoom, nearOrders, onSelectTimepoint } = attributes;
  const changeHandler = React.useCallback(
    (it) => {
      onChange(mapUtils.getGeopointFromMapData(it));
    },
    [onChange]
  );

  const { isValidGeopoint, center } = React.useMemo(() => {
    const isValid = mapUtils.isValidGeopoint(value);
    return {
      isValidGeopoint: isValid,
      center: isValid
        ? mapUtils.getCoordsFromGeopoint(value)
        : MAP_CONSTANTS.DEFAULT_CENTER
    };
  }, [value]);

  return (
    <FormControl fullWidth>
      <Map
        center={center}
        zoom={zoom}
        classes={{
          container: classes.mapContainer
        }}>
        {isValidGeopoint ? <Marker position={center} /> : null}
        {nearOrders && nearOrders.length
          ? nearOrders.map((order) => {
              const { geopoint } = order;
              if (!geopoint) return null;
              return (
                <OrderMapMarker
                  key={order.id}
                  order={order}
                  onSelectTimepoint={onSelectTimepoint}
                />
              );
            })
          : null}
      </Map>
      <MapAddressesList options={options} onSelect={changeHandler} />
    </FormControl>
  );
};

export default OrderMapField;
