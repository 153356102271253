import React from 'react';

export default function Tab(props) {
  const { children, value, eventKey } = props;

  const [mounted, setMounted] = React.useState(false);
  const visible = value === eventKey;

  React.useEffect(() => {
    if (visible && !mounted) setMounted(true);
  }, [visible, mounted]);

  if (!visible) {
    return null;
  }

  return (
    <div
      hidden={!visible}
      // component="div"
      role="tabpanel"
      aria-labelledby={`tab-${eventKey}`}>
      {mounted && children}
    </div>
  );
}
