import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Grid from '@material-ui/core/Grid';

import { MATERIAL_UNIT } from 'constants/config';

import { inChildren, toBills, toCoins } from 'services/utils';
import { isPrice } from 'services/validation';

import { FormSelectField, FormTextField, SimpleForm } from 'components/Form';
import Screen from 'components/Screen';
import { show as showModalConfirm } from 'modals/Confirm';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};

  const { name, unit, price = '' } = values;

  if (!name) {
    errors.name = 'Обязательное поле';
  }

  if (!price) {
    errors.price = 'Укажите цену';
  } else if (!isPrice(price)) {
    errors.price = 'Невалидная цена';
  }

  if (!unit) {
    errors.unit = 'Обязательное поле';
  }

  return errors;
};

const formName = 'EDIT_MATERIAL';

class Container extends Component {
  componentDidMount() {
    this.handleGetOne();
  }

  handleGetOne = async () => {
    const {
      match: { params: { id } = {} } = {},
      actions,
      history = {}
    } = this.props;
    try {
      await actions.getOne(id);
    } catch (err) {
      history.goBack();
    }
  };

  handleUpdate = async (data) => {
    const { actions, record = {}, loading, history } = this.props;
    if (loading) return;
    try {
      await actions.updateOne(record.id, data);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };

  handleDelete = async () => {
    const { actions, record = {}, dispatch, history = {} } = this.props;

    const res = await dispatch(
      showModalConfirm({
        title: 'Удаление',
        content: 'Удалить запись?'
      })
    );

    if (res) {
      try {
        await actions.deleteOne(record.id);
        history.goBack();
      } catch (err) {
        console.error(err);
      }
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleUpdate: this.handleUpdate,
      handleDelete: this.handleDelete
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading,
    record: one.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  actions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleUpdate, handleDelete, record = {}, loading } = props;

  const itemReceived = record && record.id;
  const history = useHistory();
  const back = history.goBack;
  let title = '';
  if (itemReceived) {
    title = `Редактировать материал: ${record.name}`;
  }

  return (
    <Screen title={title}>
      <SimpleForm
        direction="row"
        loading={loading}
        form={formName}
        onSubmit={handleUpdate}
        disabled={loading}
        onBack={back}
        onDelete={handleDelete}
        validate={validate}
        initialValues={{
          ...record,
          price: record && record.price >= 0 ? toBills(record.price) : '0'
        }}
        parse={(values) => {
          const res = {
            ...values,
            price: values.price >= 0 ? toCoins(values.price) : 0
          };
          return res;
        }}>
        <Grid item xs={12} sm={6}>
          <FormTextField source="name" label="Название" disabled={loading} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormSelectField
            source="unit"
            label="Единица измерения"
            disabled={loading}
            options={Object.entries(MATERIAL_UNIT).map(([key, data]) => ({
              value: key,
              label: `${data.label} (${data.short})`
            }))}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormTextField
            source="price"
            label="Цена"
            disabled={loading}
            type="number"
            inputProps={{
              step: '0.01'
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormTextField
            source="description"
            label="Описание"
            disabled={loading}
            multiline={true}
          />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const Edit = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default Edit;
