import React from 'react';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Field from './Field';

const FieldSelect = (props) => {
  const { options = [], onChange, ...rest } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (it) => {
    handleMenuClose();
    if (onChange) onChange(it);
  };

  const renderMenu = (
    <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
      {options.map((it) => {
        return (
          <MenuItem
            key={it.value}
            onClick={() => handleClick(it)}
            aria-label="item"
            color="inherit">
            {it.label}
          </MenuItem>
        );
      })}
    </Menu>
  );

  return (
    <div>
      <div onClick={handleProfileMenuOpen} style={{ cursor: 'pointer' }}>
        <Field {...rest} />
      </div>
      {renderMenu}
    </div>
  );
};

export default React.memo(FieldSelect);
