import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';

import { IconBtnClose } from 'components/buttons/IconBtn';
import Form, { FormDateFieldClock } from 'components/Form';
import Progress from 'components/Progress';

const validate = (values) => {
  const errors = {};
  const { time_from, time_to } = values;
  if (!time_from) errors.time_from = 'Укажите время';
  if (!time_to) errors.time_to = 'Укажите время';
  if (time_from && time_to) {
    if (time_from >= time_to) {
      errors.time_to = 'Должно быть больше время, с';
    }
  }

  return errors;
};

const AvailableDialog = (props) => {
  const {
    title = '',
    isOpen,
    handleClose = () => null,
    successText = 'Сохранить',
    cancelText = 'Закрыть',
    handleSubmit = () => null,
    initialValues = null
  } = props;

  const [loading, setLoading] = React.useState(false);

  const onSubmit = async (params) => {
    if (loading) return;
    setLoading(true);
    await handleSubmit(params);
    if (isOpen) setLoading(false);
  };

  return (
    <Dialog
      open={isOpen}
      scroll={'paper'}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth={true}
      maxWidth="sm"
      // onClose={() => handleClose(false)}
    >
      <Form
        name="available-executor"
        onSubmit={onSubmit}
        initialValues={initialValues}
        parse={(values) => {
          return {
            time_to: values.time_to,
            time_from: values.time_from
          };
        }}
        validate={validate}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <DialogTitle>{title}</DialogTitle>
          <IconBtnClose onClick={handleClose} />
        </div>
        <DialogContent dividers={true}>
          {loading && <Progress variant="over" />}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <FormDateFieldClock
                    label="Время, с"
                    source="time_from"
                    dateType="time"
                    withSeconds={true}
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormDateFieldClock
                    label="Время, по"
                    source="time_to"
                    dateType="time"
                    withSeconds={true}
                    disabled={loading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>{cancelText}</Button>
          <Button type="submit" color="primary" variant="contained">
            {successText}
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default AvailableDialog;
