import React from 'react';
import moment from 'moment';
import { Field, submit } from 'redux-form';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import EventIcon from '@material-ui/icons/Event';
import { DatePicker, DateTimePicker, TimePicker } from '@material-ui/pickers';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/config';

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    backgroundColor: '#3f51b5'
  },
  modalHeaderTitle: {
    padding: theme.spacing(2),
    lineHeight: 1,
    color: '#efefef',
    display: 'flex',
    fontSize: 20,
    justifyContent: 'flex-end',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  modalHeaderTitleTime: {
    fontSize: 32
  }
}));

const FilterDateRangeField = (props) => {
  const { source, searchPath = '', ...rest } = props;
  return (
    <Field
      component={FieldComponent}
      name={source}
      submitOnChange={true}
      validate={(val) => {
        if (!val) return null;
        const { q } = val;
        if (!q) return null;
        const { $gte, $lte } = q;
        if ($gte && $lte && moment($gte).isSameOrAfter($lte)) {
          return { to: 'не может быть меньше даты, с' };
        }
        return '';
      }}
      format={(v) => (v ? v.q : null)}
      parse={(v) => {
        return v ? { q: v, qp: searchPath } : null;
      }}
      {...rest}
    />
  );
};

export default React.memo(FilterDateRangeField);

const FieldComponent = (props) => {
  const classes = useStyles();
  const { input = {}, meta = {}, dateType = 'date', ...attrs } = props;

  const {
    disabled,
    helperText = [],
    labels = ['Дата, с', 'Дата, по'],
    submitOnChange,
    dateformat,
    clearable = true
  } = attrs;

  const [labelTextFrom, labelTextTo] = labels;
  const [helperTextFrom, helperTextTo] = helperText;
  const error = meta.error;
  const error_from = error && error.from;
  const error_to = error && error.to;

  let Control = DatePicker;
  let df = DATE_FORMAT;
  if (dateType === 'datetime') {
    Control = DateTimePicker;
    df = `${DATE_FORMAT}, ${TIME_FORMAT}`;
  } else if (dateType === 'time') {
    Control = TimePicker;
    df = `${TIME_FORMAT}`;
  }
  if (dateformat) df = dateformat;

  const dispatch = meta.dispatch;
  const form = meta.form;

  const parseValue = (value, type) => {
    let res = null;
    if (value) {
      if (dateType === 'time') {
        res = value.second(0).format('HH:mm:ss');
      } else if (dateType === 'datetime') {
        res = value.second(0).toISOString();
      } else {
        res =
          type === 'to'
            ? value.endOf('day').toISOString()
            : value.startOf('day').toISOString();
      }
    }
    return res;
  };

  const formatValue = (value) => {
    let res = null;
    if (value && dateType === 'time') {
      res = moment(value, [moment.ISO_8601, 'HH:mm:ss']);
    } else if (value) {
      res = moment(value);
    }
    return res;
  };

  const value = input.value || null;
  let from = formatValue((value && value.$gte) || null);
  let to = formatValue((value && value.$lte) || null);

  const _onChange = (from, to) => {
    let res = null;
    if (from || to) {
      res = {};
      if (from) res.$gte = from;
      if (to) res.$lte = to;
    }
    input.onChange(res);
    setTimeout(() => {
      if (submitOnChange) dispatch(submit(form));
    }, 0);
  };

  const _onChangeFrom = (date) => {
    const parsedFrom = parseValue(date, 'from');
    const parsedTo = parseValue(to, 'to');
    _onChange(parsedFrom, parsedTo);
  };
  const _onChangeTo = (date) => {
    const parsedFrom = parseValue(from, 'from');
    const parsedTo = parseValue(date, 'to');
    _onChange(parsedFrom, parsedTo);
  };

  const isDate = dateType === 'datetime' || dateType === 'date';
  const isTime = dateType === 'datetime' || dateType === 'time';

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <FormControl fullWidth error={!!error_from}>
          <Control
            clearable={clearable}
            variant="dialog"
            onChange={_onChangeFrom}
            value={from}
            tabIndex={-1}
            error={!!error_from}
            autoComplete="off"
            cancelLabel="Отмена"
            okLabel="Готово"
            clearLabel="Сбросить"
            ampm={false}
            format={df}
            label={labelTextFrom}
            disabled={disabled}
            // disableToolbar={true}
            ToolbarComponent={(_data) => {
              return (
                <div className={classes.modalHeader}>
                  <div className={classes.modalHeaderTitle}>
                    {isDate && (
                      <div>
                        {_data.date
                          ? _data.date.format(`dd, ${DATE_FORMAT}`)
                          : ''}
                      </div>
                    )}
                    {isTime && (
                      <div className={classes.modalHeaderTitleTime}>
                        {_data.date ? _data.date.format('HH:mm:ss') : ''}
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
            InputProps={{
              endAdornment: <Adornment />
            }}
          />
          {(error_from || helperTextFrom) && (
            <FormHelperText>{error_from || helperTextFrom}</FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth error={!!error_to}>
          <Control
            clearable={clearable}
            variant="dialog"
            value={to}
            onChange={_onChangeTo}
            tabIndex={-1}
            error={!!error_to}
            autoComplete="off"
            cancelLabel="Отмена"
            okLabel="Готово"
            clearLabel="Сбросить"
            ampm={false}
            format={df}
            label={labelTextTo}
            disabled={disabled}
            InputProps={{
              endAdornment: <Adornment />
            }}
            ToolbarComponent={(_data) => {
              return (
                <div className={classes.modalHeader}>
                  <div className={classes.modalHeaderTitle}>
                    {isDate && (
                      <div>
                        {_data.date
                          ? _data.date.format(`dd, ${DATE_FORMAT}`)
                          : ''}
                      </div>
                    )}
                    {isTime && (
                      <div className={classes.modalHeaderTitleTime}>
                        {_data.date ? _data.date.format('HH:mm:ss') : ''}
                      </div>
                    )}
                  </div>
                </div>
              );
            }}
          />
          {(error_to || helperTextTo) && (
            <FormHelperText>{error_to || helperTextTo}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );
};

const Adornment = () => (
  <InputAdornment position="end">
    <IconButton aria-label="calendar" size="small" tabIndex={-1}>
      <EventIcon />
    </IconButton>
  </InputAdornment>
);
