import queryString from 'qs';

export class Request {
  constructor(options) {
    const { headers, ...rest } = options;

    this.options = {
      baseUrl: '',
      ...rest
    };

    this.headers = {
      // Accept: 'application/json',
      // 'Content-Type': 'application/json',
      ...headers
    };

    this.isRefreshing = false;
    this.failedQueue = [];
  }

  setHeader(key, value) {
    this.headers[key] = value;
  }

  __processQueue(error, token = null) {
    this.failedQueue.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    this.failedQueue = [];
  }

  async fetch(request) {
    // if (this.isRefreshing && !request._retry) {
    //   return new Promise((resolve, reject) => {
    //     this.failedQueue.push({resolve, reject});
    //   })
    //     .then((token) => {
    //       request._retry = true;
    //       return this.fetch(request);
    //     })
    //     .catch((err) => {
    //       return Promise.reject(err);
    //     });
    // }

    // if (process.env.NODE_ENV !== 'production') {
    //   /* eslint-disable */
    //   console.log('REQUEST:', JSON.stringify(request, null, 2));
    //   /* eslint-enable */
    // }

    try {
      const url = this.options.baseUrl + request.url;
      const _req = {
        method: request.method,
        headers: {
          ...this.headers,
          ...request.headers
        },
        body: request.body
        // mode: 'no-cors'
      };
      const resp = await fetch(url, _req);
      // if (
      //   (resp.status < 200 || resp.status >= 400) &&
      //   !request._retry &&
      //   this.options.refreshIntercept
      // ) {
      //   if (await this.options.refreshIntercept(resp)) {
      //     this.isRefreshing = true;

      //     return new Promise((resolve, reject) => {
      //       this.options
      //         .handleTokenRefresh(this)
      //         .then(() => {
      //           request._retry = true;

      //           resolve(this.fetch(request));
      //           this.__processQueue(null);
      //         })
      //         .catch((err) => {
      //           reject(err);
      //           this.__processQueue(err);
      //         })
      //         .finally(() => {
      //           this.isRefreshing = false;
      //         });
      //     });
      //   }
      // }

      // resp.rawBody = await resp.text();

      // if (process.env.NODE_ENV !== 'production') {
      //   /* eslint-disable */
      //   console.log('REQ:', JSON.stringify(request, null, 2));
      //   console.log('RES:', resp.rawBody);
      //   /* eslint-enable */
      // }
      const contentType = resp.headers.get('content-type');
      if (resp.status === 204 || resp.status === 404) {
        return Promise.resolve(resp);
      }

      if (resp.status >= 200 && resp.status < 400) {
        if (contentType && contentType.indexOf('application/json') !== -1) {
          const result = await resp.json();
          return Promise.resolve({
            status: resp.status,
            headers: resp.headers,
            body: result
          });
        }
        return Promise.resolve(resp);
      }

      if (contentType && contentType.indexOf('application/json') !== -1) {
        return Promise.reject({
          status: resp.status,
          headers: resp.headers,
          body: await resp.json()
        });
      }
      return Promise.reject(resp);
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export class ApiRequest {
  constructor(request) {
    this.request = request;
  }

  __prepareUrl(path, qs) {
    let url = path;

    if (qs) {
      if (qs.where && Object.keys(qs.where).length) {
        qs.where = JSON.stringify(qs.where);
      } else {
        delete qs.where;
      }
      url += '?' + queryString.stringify(qs);
    }

    return url;
  }

  __prepareBody(req, body, encodeType = 'json') {
    if (encodeType === 'text') {
      req.headers['Content-Type'] = 'text/plain';
      req.body = body;
    }

    if (encodeType === 'json') {
      req.headers['Content-Type'] = 'application/json; charset=utf-8';
      req.body = JSON.stringify(body);
    }

    if (encodeType === 'urlencoded') {
      req.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      req.body = queryString.stringify(body);
    }

    if (encodeType === 'form') {
      req.headers['Content-Type'] = 'multipart/form-data';
      const formData = new FormData();

      for (let key in body) {
        formData.append(key, body[key]);
      }

      req.body = formData;
    }
  }

  get(path, { qs = null, options = {} } = {}) {
    return this.request.fetch({
      method: 'GET',
      url: this.__prepareUrl(path, qs),
      ...options
    });
  }

  post(path, { qs = null, body = {}, encodeType = 'json', options = {} } = {}) {
    const req = {
      method: 'POST',
      url: this.__prepareUrl(path, qs),
      headers: {},
      ...options
    };

    this.__prepareBody(req, body, encodeType);

    return this.request.fetch(req);
  }

  patch(
    path,
    { qs = null, body = {}, encodeType = 'json', options = {} } = {}
  ) {
    const req = {
      method: 'PATCH',
      url: this.__prepareUrl(path, qs),
      headers: {},
      ...options
    };

    this.__prepareBody(req, body, encodeType);

    return this.request.fetch(req);
  }

  put(path, { qs = null, body = {}, encodeType = 'json', options = {} } = {}) {
    const req = {
      method: 'PUT',
      url: this.__prepareUrl(path, qs),
      headers: {},
      ...options
    };

    this.__prepareBody(req, body, encodeType);

    return this.request.fetch(req);
  }

  delete(path, { qs = null, options = {} } = {}) {
    return this.request.fetch({
      method: 'DELETE',
      url: this.__prepareUrl(path, qs),
      ...options
    });
  }
}
