import { validateErrors } from 'services/utils';

export const validateForm = (values) => {
  const errors = {};
  const { name } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });
  return errors;
};
