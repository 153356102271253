import moment from 'moment';

import { AVAILABLE_TYPES } from 'constants/config';

import {
  executor_places as executorPlacesApi,
  executors as executorsApi,
  orders as ordersApi
} from 'services/api';
import {
  breakToDistances,
  createTimeRangeString,
  exludeDateDistances,
  mergeDateDistances
} from 'services/dateUtils';
import ErrorService from 'services/ErrorService';
import Query from 'services/Query';
import { concatGeoFullName, userCanSeeTurnover } from 'services/utils';

const eventsConf = {
  order: {
    bgColor: '#40a9ff',
    disabledColor: 'rgba(64, 169, 255, 0.4)',
    showPopover: true,
    resizable: false,
    eventType: 'order'
  },
  notSchedule: {
    bgColor: '#808080',
    showPopover: false,
    resizable: false,
    eventType: 'notSchedule'
  },
  notAvailable: {
    bgColor: '#ff0000',
    showPopover: true,
    resizable: false,
    eventType: 'notAvailable'
  },
  available: {
    bgColor: '#eeeeee',
    showPopover: false,
    resizable: false,
    eventType: 'available'
  }
};

// const DAY_START = 8;
// const DAY_END = 22;

export const actionTypes = {
  SCHEDULE_UPDATE: 'SCHEDULE_UPDATE'
  // GET_EXECUTORS_LIST: 'SCHEDULE_EXECUTORS_GET_LIST',
  // GET_LOCATIONS_LIST: 'SCHEDULE_LOCATIONS_GET_LIST',
};

export const updateRedux = (type, payload) => ({
  type,
  payload
});

const defaultQuery = {
  order: 'desc',
  orderBy: 'id',
  offset: 0,
  limit: 1000
};

export const getLocations = () => async (dispatch) => {
  try {
    const requestExecutors = Query.createGetRequest({
      ...defaultQuery
    });
    const { results: places } = await executorPlacesApi.getList(
      requestExecutors
    );
    dispatch(
      updateRedux(actionTypes.SCHEDULE_UPDATE, {
        locations: places
      })
    );
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getScheduleData =
  (params = {}) =>
  async (dispatch, getState) => {
    const currentUser = getState().auth.admin;

    dispatch(
      updateRedux(actionTypes.SCHEDULE_UPDATE, {
        loading: true,
        params,
        events: []
      })
    );

    try {
      const {
        viewType,
        currentDate,
        filter = {}
        // executors = {},
        // schedule = {},
        // available = {},
        // orders = {}
      } = params;

      const { executor_id, user_id, place_id } = filter;

      let startDate = null;
      let endDate = null;

      if (viewType === 'week') {
        startDate = currentDate.clone().startOf('day');
        endDate = startDate.clone().add(6, 'd').endOf('day');
      } else {
        startDate = currentDate.clone().startOf('day');
        endDate = currentDate.clone().endOf('day');
      }

      const dateRange = {
        date_from: {
          qt: 'to',
          q: endDate.toISOString()
        },
        date_to: {
          qt: 'from',
          q: startDate.toISOString()
        }
      };

      const execFilter = {};
      if (executor_id) execFilter.id = executor_id;
      if (place_id) execFilter.place_id = place_id;

      const requestExecutors = Query.createGetRequest({
        ...defaultQuery,
        order: 'desc',
        orderBy: 'sort',
        filter: {
          ...execFilter,
          is_active: 'yes'
        }
      });

      const { results: executorsList } = await executorsApi.getList(
        requestExecutors
      );
      const executors_ids = executorsList.map((it) => it.id);

      let scheduleList = [];
      let availableList = [];
      let ordersList = [];
      let turnoverList = [];

      if (executors_ids.length) {
        const requestSchedule = Query.createGetRequest({
          ...defaultQuery,
          order: 'id',
          count: 0,
          filter: {
            executor_id: {
              q: executors_ids,
              qt: 'in'
            }
          }
        });

        const requestAvailable = Query.createGetRequest({
          ...defaultQuery,
          order: 'date_from',
          count: 0,
          filter: {
            ...dateRange,
            executor_id: {
              q: executors_ids,
              qt: 'in'
            }
          }
        });

        const requestOrders = Query.createGetRequest({
          ...defaultQuery,
          order: 'date_from',
          count: 0,
          filter: {
            ...dateRange,
            executor_id: {
              q: executors_ids,
              qt: 'in'
            }
            // is_active: 'yes'
          }
        });

        const requestTurnover = Query.createGetRequest({
          ...defaultQuery,
          order: 'id',
          count: 0,
          filter: {
            executor_id: {
              q: executors_ids,
              qt: 'in'
            }
          }
        });

        const showTurnover = userCanSeeTurnover(currentUser);

        try {
          const res = await Promise.all([
            executorsApi.getSchedule(requestSchedule),
            executorsApi.getAvailableList(requestAvailable),
            ordersApi.getList(requestOrders),
            showTurnover
              ? executorsApi.getExecutorsTurnover(
                  currentDate.toISOString(),
                  requestTurnover
                )
              : []
          ]);
          scheduleList = res[0].results;
          availableList = res[1].results;
          const {
            methods = [],
            reasons = [],
            materials = [],
            files = [],
            results: orders = []
          } = res[2];
          turnoverList = res[3].results;

          const ordersMap = {};
          ordersList = orders.map((it) => {
            it.reasons = [];
            it.methods = [];
            ordersMap[it.id] = it;
            return it;
          });

          methods.forEach((methodItem) => {
            if (ordersMap[methodItem.order_id]) {
              ordersMap[methodItem.order_id].methods.push(methodItem.method);
            }
          });
          reasons.forEach((reasonItem) => {
            if (ordersMap[reasonItem.order_id]) {
              ordersMap[reasonItem.order_id].reasons.push(reasonItem.reason);
            }
          });
          materials.forEach((materialItem) => {
            if (ordersMap[materialItem.order_id]) {
              ordersMap[materialItem.order_id].materials_count =
                materialItem.total_count;
            }
          });
          files.forEach((fileItem) => {
            if (ordersMap[fileItem.item_id]) {
              ordersMap[fileItem.item_id].files_count = fileItem.total_count;
            }
          });
        } catch (err) {
          console.error(err);
        }
      }

      const [resources = [], events = []] = createData({
        viewType,
        currentDate,
        startDate,
        endDate,
        executors: executorsList,
        schedule: scheduleList,
        available: availableList,
        orders: ordersList,
        turnover: turnoverList,
        selected_user_id: user_id
      });

      dispatch({
        type: actionTypes.SCHEDULE_UPDATE,
        payload: {
          loading: false,
          resources,
          events
        }
      });
    } catch (err) {
      dispatch(ErrorService.dispatchError(err));
      dispatch(
        updateRedux(actionTypes.SCHEDULE_UPDATE, {
          loading: false
        })
      );
      return Promise.reject(err);
    }
  };

const createData = (params = {}) => {
  const {
    viewType,
    currentDate,
    startDate,
    endDate,
    executors = [],
    schedule = [],
    available = [],
    orders = [],
    turnover = [],

    selected_user_id
  } = params;

  const resources = executors || [];
  let events = [];

  const resourcesRels = {};
  if (!executors || !executors.length) return [resources, events];

  const rels = {};

  executors.forEach((ex, i) => {
    const { id, name, color } = ex;
    ex.title = name;
    if (!resourcesRels[id]) {
      rels[id] = i;
      resourcesRels[id] = {
        id,
        name,
        orders: [],
        available: [],
        schedule: {},
        color
      };
    }
  });

  turnover.forEach((it) => {
    const { executor_id } = it;
    if (resourcesRels[executor_id]) {
      const index = rels[executor_id];
      resources[index].sum_price = it.sum_price;
      resources[index].total_count = it.total_count;
    }
  });

  orders.forEach((it) => {
    const { executor_id } = it;
    if (resourcesRels[executor_id]) {
      resourcesRels[executor_id].orders.push(it);
    }
  });

  available.forEach((it) => {
    const { executor_id } = it;
    if (resourcesRels[executor_id]) {
      resourcesRels[executor_id].available.push(it);
    }
  });

  const weekDay = currentDate.isoWeekday();

  schedule.forEach((it) => {
    const { executor_id, week_day } = it;
    if (resourcesRels[executor_id]) {
      if (viewType === 'day' && weekDay !== week_day) return;
      if (!resourcesRels[executor_id].schedule[week_day]) {
        resourcesRels[executor_id].schedule[week_day] = [];
      }
      resourcesRels[executor_id].schedule[week_day].push({
        ...it
      });
    }
  });

  Object.keys(resourcesRels).forEach((resource_id) => {
    const resource = resourcesRels[resource_id];
    const { orders = [], available = [], schedule = {} } = resource;

    const resourceEvents = [];
    const availableYes = [];
    const availableNo = [];

    if (orders && orders.length) {
      orders.forEach((it) => {
        const start = moment(it.date_from);
        const end = moment(it.date_to);

        let disabledIfElseUser = false;

        const user = it.user;
        const user_id = user && Number(user.id);
        const user_color = user && user.color;

        if (
          !(selected_user_id && user && Number(selected_user_id) !== user_id)
        ) {
          const titleTimeRange = createTimeRangeString(start, end);

          const { street, locality } = it;
          let address = null;
          if (street) address = concatGeoFullName(street, [2, 4]);
          else if (locality) address = concatGeoFullName(locality, [2]);

          const title = [titleTimeRange, address].join(', ');
          const bgColor = user_color || '#eeeeee';
          resourceEvents.push({
            resourceId: Number(resource_id),
            id: `ex-${resource_id}-order-${it.id}`,
            title,
            start,
            end,
            order: it,
            ...eventsConf.order,
            disabledIfElseUser,
            bgColor
          });
        }
        if (it.is_active === 'yes') {
          availableNo.push({
            from: start.clone(),
            to: end.clone()
          });
        }
      });
    }

    if (available && available.length) {
      available.forEach((it) => {
        if (it.is_available === 'no') {
          const start = moment(it.date_from);
          const end = moment(it.date_to);

          const titleTimeRange = createTimeRangeString(start, end);

          let reason = 'Не указана';
          if (AVAILABLE_TYPES[it.available_type])
            reason = AVAILABLE_TYPES[it.available_type];

          const title = `${titleTimeRange}, ${reason}`;

          resourceEvents.push({
            resourceId: Number(resource_id),
            id: `ex-${resource_id}-notavailable-${it.id}`,
            item: it,
            title,
            start,
            end,
            ...eventsConf.notAvailable
          });
          availableNo.push({
            from: moment(it.date_from),
            to: moment(it.date_to)
          });
        }
        if (it.is_available === 'yes') {
          const from = moment(it.date_from);
          const to = moment(it.date_to);

          availableYes.push({
            from,
            to
          });
        }
      });
    }

    if (viewType === 'day') {
      const weekDay = currentDate.isoWeekday();
      const times = schedule[weekDay];
      if (times && times.length) {
        times.forEach((it) => {
          const from = moment(
            currentDate.format('YYYY-MM-DD') + 'T' + it.time_from
          );
          const to = moment(
            currentDate.format('YYYY-MM-DD') + 'T' + it.time_to
          );
          availableYes.push({
            from,
            to
          });
        });
      }
    } else if (viewType === 'week') {
      const diff = endDate.diff(startDate, 'd');
      for (let i = 0; i <= diff; i++) {
        const cur = startDate.clone().add(i, 'd');
        const weekDay = cur.isoWeekday();
        const times = schedule[weekDay];
        if (times && times.length) {
          times.forEach((it) => {
            const from = moment(cur.format('YYYY-MM-DD') + 'T' + it.time_from);
            const to = moment(cur.format('YYYY-MM-DD') + 'T' + it.time_to);
            availableYes.push({
              from,
              to
            });
          });
        }
      }
    }

    const availableMerged = mergeDateDistances(availableYes);
    const unAvailableMerged = mergeDateDistances(availableNo);
    const availableWithoutUnavailable = exludeDateDistances(
      availableMerged,
      unAvailableMerged
    );
    const availableBreaked = breakToDistances(availableWithoutUnavailable);
    availableBreaked.forEach((it, i) => {
      // const startH = it.from.clone().hour(8).startOf('hour');
      // const endH = it.to.clone().hour(22).startOf('hour');

      // if ( it.to.isBefore(startH) || it.from.isAfter(endH) ) return;

      const titleTimeRange = createTimeRangeString(it.from, it.to);

      resourceEvents.push({
        resourceId: Number(resource_id),
        id: `ex-${resource_id}-available-${i}`,
        title: titleTimeRange,
        start: it.from,
        end: it.to,
        ...eventsConf.available
        // bgColor: '#eee' || resource.color || eventsConf.available.bgColor
      });
    });
    events = events.concat(resourceEvents);
  });

  return [resources, events];
};
