import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Grid from '@material-ui/core/Grid';

import { PHONE_FORMAT } from 'constants/config';

import { inChildren, validateErrors } from 'services/utils';

import { FormPhoneField, FormTextField, SimpleForm } from 'components/Form';
import Screen from 'components/Screen';

import * as actions from './actions';

const validate = (values) => {
  const errors = {};

  const { name, email, phone } = values;

  errors.name = validateErrors(name, {
    require: 'Обязательное поле',
    'minLength=2': 'Не менее 2 символов'
  });

  if (email) {
    errors.email = validateErrors(email, {
      email: 'Не валидный e-mail'
    });
  }

  if (phone) {
    errors.phone = validateErrors(phone, {
      phone: PHONE_FORMAT.placeholder
    });
  }

  return errors;
};

const formName = 'add_client_group';

class Container extends Component {
  handleCreate = async (data) => {
    const { loading, clientGroupsActions, history = {} } = this.props;
    if (loading) return;

    try {
      await clientGroupsActions.createOne(data);
      history.goBack();
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { children, ...rest } = this.props;
    return inChildren(children, {
      ...rest,
      handleCreate: this.handleCreate
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { resourceName = '' } = ownProps;
  const target = state[resourceName] || {};
  const { one = {} } = target;
  return {
    loading: one.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  clientGroupsActions: bindActionCreators(actions, dispatch)
});

const ContainerConnect = connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);

const View = (props) => {
  const { handleCreate, loading } = props;
  const history = useHistory();
  const back = history.goBack;
  return (
    <Screen title="Добавить группу клиентов">
      <SimpleForm
        loading={loading}
        form={formName}
        onSubmit={handleCreate}
        disabled={loading}
        onBack={back}
        validate={validate}
        parse={(values) => {
          const res = {
            name: values.name || '',
            email: values.email || '',
            phone: values.phone || ''
          };
          return res;
        }}>
        <Grid item xs={12} sm={6}>
          <FormTextField
            source="name"
            label="Название группы"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormTextField source="email" label="E-mail" disabled={loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormPhoneField source="phone" label="Тел." disabled={loading} />
        </Grid>
      </SimpleForm>
    </Screen>
  );
};

const Add = (props) => (
  <ContainerConnect {...props}>
    <View />
  </ContainerConnect>
);

export default Add;
